import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

const Filled_btn = ({ title, onClick, disabled, type, style = {}, enable }) => {
  const theme = useTheme();
  return (
    <div className="filled-btn-1">
      <Button
        style={style}
        onClick={onClick}
        type={type}
        disabled={disabled}
        sx={{
          backgroundColor: !disabled
            ? theme.palette.appcolors.primaryColor
            : theme.palette.appcolors.grey4,
          borderRadius: "8px",
          color: theme.palette.appcolors.secondaryColor10,
          textTransform: "initial",
          width: "100%",
          padding: "12px",
          textDecoration: "none",
          fontWeight: 400,
          cursor: "pointer",
          flex: "none",
          fontStyle: "normal",
          order: 0,
          flexGrow: 0,
        }}
      >
        {title}
      </Button>
    </div>
  );
};

export default Filled_btn;
