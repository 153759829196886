import { styled } from "@mui/material/styles";
import MarkLatex from "../../atom/Marklatex/MarkLatex";

export const TextQuestionRow = styled(MarkLatex)(({ theme }) => ({
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  flex: "1 0 0",
  maxWidth: "100%",
  "& img": {
    maxWidth: "100%",
  },
  "& p": {
    maxWidth: "100%",
  },
  "& .math": {
    overflowX: "auto",
    overflowY: "visible",
    scrollbarWidth: "thin",
  },
  [theme.breakpoints.down(900)]: {
    lineHeight: "20px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
}));
