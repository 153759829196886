/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

export const questionViewerSlice = createSlice({
  name: "questionViewer",
  initialState: {
    question: null,
  },
  reducers: {
    setQuestionToView: (state, action) => {
      state.question = action.payload;
    },
    setToggleQuestionViewerAnswerVisibility: (state, action) => {
      const index = action.payload.index;
      const isAnswerVisible =
        state.question?.multipartQuestion?.question[index].showResult;
      if (isAnswerVisible) {
        state.question.multipartQuestion.question[index].showResult = false;
      } else {
        state.question.multipartQuestion.question[index].showResult = true;
      }
    },
  },
});

export const { setQuestionToView, setToggleQuestionViewerAnswerVisibility } =
  questionViewerSlice.actions;
