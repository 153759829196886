/** Libraries */
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";

/** Atoms */
import { PressEnterIcon } from "../../atom/PressEnter/PressEnter";

/** Hooks */
import { useAiTutorStore } from "../../../hooks";

/** Sound Effect */
import buttonClick from "../../../assets/Sounds/buttonClick2.wav";

/** Material UI - Custom elements */
const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  zIndex: 1060,
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const MqContainerFull = styled(Box)(({ theme }) => ({
  width: "100%",
  zIndex: 1060,
}));

const MqMainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1600px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 32px",
  flexDirection: "row",
  margin: "0rem auto",
  [theme.breakpoints.up(1200)]: {
    gap: "72px",
  },
  [theme.breakpoints.down(1200)]: {
    padding: "0px 40px",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down(600)]: {
    padding: "0px 20px",
  },
}));

const MqVoidContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "900px",
  display: "flex",
  justifyContent: "flex-start",
  [theme.breakpoints.down(1200)]: {
    display: "none",
  },
}));

const MqButtonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "466px",
  padding: "1rem",
  paddingBottom: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  [theme.breakpoints.down(1560)]: {
    maxWidth: "490px",
  },
  [theme.breakpoints.down(1200)]: {
    maxWidth: "900px",
  },
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "738px",
  padding: "1rem",
  paddingBottom: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
}));

const AnswerButton = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  borderRadius: "16px",
  cursor: "pointer",
  border: "none",
  background: theme.palette.appcolors.primaryColor,
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  "&:hover": {
    transform: "scale(1.03)",
    transition: "all 0.3s ease-in-out",
  },
  [theme.breakpoints.down(600)]: {
    borderRadius: "8px",
  },
}));

const TextButton = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.secondaryColor10,
  fontWeight: 600,
  [theme.breakpoints.down(600)]: {
    fontSize: "14px",
  },
}));

const ExerciseBottomBar = (props) => {
  const {
    isActive: isAIActive,
    switchAIKeyPress,
    startAiTutorLogout,
    isRequestInProcess,
  } = useAiTutorStore();
  const isFeedbackOpen = useSelector(
    (state) => state.feedbackQuestion.isFeedbackPopupOpened
  );

  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(buttonClick);
    audioRef.current.load();

    const handleEnterClick = (e) => {
      if (!isRequestInProcess && !isAIActive && e.keyCode === 13) {
        startAiTutorLogout();
        e.preventDefault();
        if (props?.Next) {
          handleClick();
        }
        if (props.submitAnswer || props.changeQuestion) {
          handleMqClick();
        }
      }
    };

    !isFeedbackOpen &&
      !isAIActive &&
      !isRequestInProcess &&
      document.addEventListener("keydown", handleEnterClick);
    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [
    props?.Next,
    props?.submitAnswer,
    props?.changeQuestion,
    props,
    isFeedbackOpen,
    isAIActive,
    isRequestInProcess,
  ]);

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Error playing sound:", error);
      });
    }
  };

  const handleClick = (e) => {
    playSound();
    props.Next && props.Next(props.question);
  };

  const handleMqClick = () => {
    playSound();
    props.changeQuestion && props.changeQuestion();
    props.submitAnswer && props.submitAnswer(props.question);
  };

  return props.mq ? (
    <MqContainerFull>
      <MqMainContainer>
        <MqVoidContainer />
        <MqButtonContainer>
          <PressEnterIcon />
          <AnswerButton onClick={handleMqClick}>
            <TextButton>{props.mqresult ? "Nästa" : "Svara"}</TextButton>
          </AnswerButton>
        </MqButtonContainer>
      </MqMainContainer>
    </MqContainerFull>
  ) : (
    <MainContainer>
      <ButtonContainer>
        <PressEnterIcon />
        <AnswerButton onClick={handleClick}>
          <TextButton>
            {props.question.answer || props.isAnswerVisible ? "Nästa" : "Svara"}
          </TextButton>
        </AnswerButton>
      </ButtonContainer>
    </MainContainer>
  );
};

export default ExerciseBottomBar;
