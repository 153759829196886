/** Libraries */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import {
  setQuestionToView,
  setToggleQuestionViewerAnswerVisibility,
} from "../../redux/slices";

/** Axios Instances */
import { EndPoints, instance2 } from "../../components/service/Route";

export const useQuestionViewerStore = () => {
  const dispatch = useDispatch();
  const { question } = useSelector((state) => state.questionViewer);

  const [isRequestExecuted, setIsRequestExecuted] = useState(false);

  const startLoadingQuestion = (id) => {
    if (!isRequestExecuted && !question) {
      setIsRequestExecuted(true);
      const URL = `${EndPoints.getQuestionById}${id}`;
      instance2
        .get(URL)
        .then((response) => {
          if (response?.data?.questionStatement) {
            dispatch(setQuestionToView(response?.data));
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
  };

  const startSettingToggleQuestionViewerAnswerVisibility = (index) => {
    dispatch(setToggleQuestionViewerAnswerVisibility({ index }));
  };

  return {
    /** Values */
    question,

    /** Functions */
    startLoadingQuestion,
    startSettingToggleQuestionViewerAnswerVisibility,
  };
};
