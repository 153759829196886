/** Libraries */
import React from "react";
import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

/** Molecules */
import RadioButtonOptions from "../RadioButtonsOptions";

/** Atoms */
import MarkLatex from "../../atom/Marklatex/MarkLatex";
import { formatText } from "../../../tools";

const ImageContainer = styled(Box)(({ theme, wrong, correct }) => ({
  border: `1px solid ${theme.palette.appcolors.grey3}`,
  borderBottom: `3px solid ${theme.palette.appcolors.grey3}`,
  display: "flex",
  background: theme.palette.appcolors.secondaryColor4,
  borderRadius: "16px",
  width: "100%",
  minHeight: "150px",
  padding: "10px 30px 10px 10px",
  color: wrong
    ? theme.palette.appcolors.error6
    : correct
    ? theme.palette.appcolors.success4
    : theme.palette.appcolors.grey,
  [theme.breakpoints.down(600)]: {
    width: "100%",
    height: "auto",
  },
}));

const RowContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  padding: "24px 25px",
  alignItems: "center",
  gap: "32px",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.appcolors.grey3}`,
  borderBottom: `3px solid ${theme.palette.appcolors.grey3}`,
  background: theme.palette.appcolors.secondaryColor4,
  [theme.breakpoints.down(600)]: {
    padding: "24px 12px",
  },
}));

const AnswerContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
}));

const MarkLatexImage = styled(MarkLatex)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    width: "158px",
    maxHeight: "143px",
    minHeight: "0",
    paddingTop: "0",
  },
  [theme.breakpoints.down(900)]: {
    "& img": {
      width: "144px",
    },
  },
  [theme.breakpoints.down(601)]: {
    height: "auto",
    "& img": {
      width: "100%",
      flexShrink: 0,
    },
  },
}));

const MarkLatexQuestionRow = styled(MarkLatex)(({ theme }) => ({
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  flex: "1 0 0",
  maxWidth: "100%",
  "& img": {
    maxWidth: "100%",
  },
  "& p": {
    maxWidth: "100%",
  },
  "& .math": {
    overflowX: "auto",
    overflowY: "visible",
    scrollbarWidth: "thin",
  },
  [theme.breakpoints.down(900)]: {
    lineHeight: "20px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
}));

const OptionsComponent = (props) => {
  const options = props.resultComponent
    ? props?.question?.options[0]?.options
    : props?.question?.option[0]?.options;
  const theme = useTheme();

  const handleOptionsColor = (isWrongAnswer, isCorrectAnswer) => {
    if (isCorrectAnswer === "true") {
      return theme.palette.appcolors.success4;
    }
    if (isWrongAnswer === "true") {
      return theme.palette.appcolors.error6;
    }

    return theme.palette.appcolors.grey;
  };

  return (
    <Box
      style={{
        margin: "0.5rem 0",
        width: "100%",
        display: "grid",
        gridTemplateColumns:
          options[0].value.includes("hp-appen.s3.eu-north-1.amazonaws.com") &&
          "repeat(2, 49.5%)",
        gap: "0.5rem",
      }}
    >
      {options.map((option, index) => {
        const wrongAnswer =
          props.question?.answer.option !== option._id &&
          props.question.optionId === option._id
            ? "true"
            : "false";

        const correctAnswer =
          props.question?.answer &&
          props.question?.answer?.option === option._id
            ? "true"
            : "false";

        const isImageOption = option.value.includes(
          "hp-appen.s3.eu-north-1.amazonaws.com"
        );

        return (
          <AnswerContainer key={index}>
            {isImageOption ? (
              <ImageContainer wrong={wrongAnswer} correct={correctAnswer}>
                <RadioButtonOptions
                  option={option}
                  question={props.question}
                  optionIndex={index}
                />
                {option?.value && (
                  <MarkLatexImage content={formatText(option?.value)} />
                )}
              </ImageContainer>
            ) : (
              <RowContainer>
                <RadioButtonOptions
                  option={option}
                  question={props.question}
                  optionIndex={index}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "90%",
                    overflowX: "clip",
                    color: handleOptionsColor(wrongAnswer, correctAnswer),
                  }}
                >
                  <MarkLatexQuestionRow content={formatText(option?.value)} />
                </Box>
              </RowContainer>
            )}
          </AnswerContainer>
        );
      })}
    </Box>
  );
};

export default OptionsComponent;
