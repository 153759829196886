import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const StyledHelpOutlineIcon = styled(HelpOutlineIcon)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "2rem",
  fontSize: "30px",
  color: theme.palette.appcolors.black,
  "&:hover": {
    color: theme.palette.appcolors.primaryColor,
    transform: "scale(1.05)",
    transition: "all 0.3s ease",
  },
  [theme.breakpoints.down(600)]: {
    marginRight: "0rem",
    fontSize: "22px",
  },
}));
