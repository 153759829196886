import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isBannerVisible: true,
};

export const bannerSlice = createSlice({
  name: "bannerChoices",
  initialState,
  reducers: {
    setBannerVisible: (state, action) => {
      state.isBannerVisible = action.payload;
    },
  },
});

export const { setBannerVisible } = bannerSlice.actions;

export default bannerSlice.reducer;
