import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { styled } from "@mui/material/styles";

export const DownArrowIcon = styled(KeyboardArrowDownRoundedIcon)(
  ({ theme }) => ({
    fontSize: "42px",
    color: theme.palette.appcolors.black,
  })
);

export const UpArrowIcon = styled(KeyboardArrowUpRoundedIcon)(({ theme }) => ({
  fontSize: "42px",
  color: theme.palette.appcolors.black,
}));

export const LeftArrowIcon = styled(ArrowBackIosRoundedIcon)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "38px",
  color: theme.palette.appcolors.black,
  "&:hover": {
    color: theme.palette.appcolors.primaryColor,
    transform: "scale(1.1)",
    transition: "all 0.2s ease-in-out",
  },
}));

export const RightArrowIcon = styled(ArrowForwardIosRoundedIcon)(
  ({ theme }) => ({
    cursor: "pointer",
    fontSize: "38px",
    color: theme.palette.appcolors.black,
    "&:hover": {
      color: theme.palette.appcolors.primaryColor,
      transform: "scale(1.1)",
      transition: "all 0.2s ease-in-out",
    },
  })
);
