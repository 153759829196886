/** Libraries */
import React from "react";

import PropTypes from "prop-types";

/** Styles */
import { MainContainer, StyledAvatar } from "./Pagination.styles";

const Pagination = ({ count, selectedIndex, handlePagination, ...props }) => {
  const avatars = Array.from({ length: count }, (_, index) => index);

  return (
    <MainContainer>
      {avatars.map((_, index) => (
        <StyledAvatar
          key={index}
          index={index}
          selectedIndex={selectedIndex}
          onClick={() => {
            if (selectedIndex !== index) {
              handlePagination(index);
            }
          }}
        >
          {index + 1}
        </StyledAvatar>
      ))}
    </MainContainer>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
};

export default Pagination;
