/** Libraries */
import { createSlice, current } from "@reduxjs/toolkit";

export const customExamSlice = createSlice({
  name: "customExam",
  initialState: {
    quiz: [],
    currentQuestion: null,
    selectedIndex: 0,
    totalQuestions: 0,
    selectedOption: null,
    isAnswerVisible: false,
    topBar: {
      progress: 0,
    },
    newSecondsCounter: 0,
  },
  reducers: {
    setCustomExamQuiz: (state, action) => {
      const { quiz, questionIndex } = action.payload;
      state.quiz = quiz;
      state.currentQuestion = questionIndex ? quiz[questionIndex] : quiz[0];
      state.selectedIndex = questionIndex ? questionIndex : 0;
      state.totalQuestions = quiz.length;

      /** Calculate total number of questions */
      // let totalQuestions = 0;
      // quiz.forEach((question) => {
      //   if (question.multipartQuestion !== (null && {})) {
      //     totalQuestions += question.length;
      //   } else {
      //     totalQuestions += 1;
      //   }
      // });
    },
    setSelectedOptionCustomExamSQ: (state, action) => {
      const { optionId, optionIndex } = action.payload;
      state.currentQuestion.optionId = optionId;
      state.currentQuestion.optionIndex = optionIndex;
      state.selectedOption = optionId;
      state.quiz[state.selectedIndex].optionId = optionId;
    },
    setAnswerVisibleCustomExamSQ: (state, action) => {
      state.isAnswerVisible = true;
      state.quiz[state.selectedIndex] = {
        ...state.quiz[state.selectedIndex],
        ...action.payload,
      };
    },
    setPreviousQuestionCustomExamSQ: (state, action) => {
      const { secondsCounter, currTime, totalTime } = action.payload || {};

      // Save time for the current question before moving to the previous one
      state.quiz[state.selectedIndex].spendtime = secondsCounter;
      state.quiz[state.selectedIndex].timeleft = currTime;
      state.quiz[state.selectedIndex].totaltime = totalTime;

      if (state.selectedIndex > 0) {
        // Move to the previous question
        state.selectedIndex -= 1;
        state.currentQuestion = state.quiz[state.selectedIndex];
        state.selectedOption = state.currentQuestion.optionId;
        state.isAnswerVisible = false;
      }
    },
    setNextQuestionCustomExamSQ: (state, action) => {
      const { secondsCounter, currTime, totalTime } = action.payload || {};

      state.quiz[state.selectedIndex].spendtime = secondsCounter;
      state.quiz[state.selectedIndex].timeleft = currTime;
      state.quiz[state.selectedIndex].totaltime = totalTime;

      if (state.selectedIndex <= state.totalQuestions - 1) {
        state.selectedIndex += 1;
        state.currentQuestion = state.quiz[state.selectedIndex];
        state.selectedOption = state.currentQuestion.optionId;
        state.isAnswerVisible = false;
        state.quiz[state.selectedIndex].spendtime = 0;
        state.quiz[state.selectedIndex].timeleft = totalTime;
      }
    },
    setLastQuestionStartTime: (state, action) => {
      const { secondsCounter, currTime, totalTime } = action.payload || {};
      if (state.selectedIndex == state.totalQuestions - 1) {
        state.quiz[state.selectedIndex].spendtime = secondsCounter;
        state.quiz[state.selectedIndex].timeleft = currTime;
        state.quiz[state.selectedIndex].totaltime = totalTime;
      }
    },
    setNextQuestionInReviewCustomExamSQ: (state) => {
      if (state.selectedIndex + 1 !== state.totalQuestions) {
        state.currentQuestion = state.quiz[state.selectedIndex + 1];
        state.selectedIndex += 1;
      }
    },
    setPreviusQuestionInReviewCustomExamSQ: (state) => {
      if (state.selectedIndex !== 0) {
        state.currentQuestion = state.quiz[state.selectedIndex - 1];
        state.selectedIndex -= 1;
      }
    },
    setSpecificCustomExamSQ: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.totalQuestions) {
        state.currentQuestion = state.quiz[index];
        state.selectedIndex = index;
        state.selectedOption = state.currentQuestion.optionId;
        state.isAnswerVisible = false;
      }
    },
    customExamLogout: (state) => {
      state.quiz = [];
      state.currentQuestion = null;
      state.selectedIndex = 0;
      state.totalQuestions = 0;
      state.selectedOption = null;

      state.topBar.progress = 0;
    },
  },
});
export const {
  setCustomExamQuiz,
  setSelectedOptionCustomExamSQ,
  setAnswerVisibleCustomExamSQ,
  setNextQuestionCustomExamSQ,
  setPreviousQuestionCustomExamSQ,
  setNextQuestionInReviewCustomExamSQ,
  setPreviusQuestionInReviewCustomExamSQ,
  setSpecificCustomExamSQ,
  setLastQuestionStartTime,
  customExamLogout,
  newSecondsCounterstate,
} = customExamSlice.actions;
