/** Libraries */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

/** Custom hooks */
import { useAuthStore } from "../../hooks";

/** Atoms */
import Filled_btn from "../../components/atom/FilledBtn/FilledBtn";
import {
  AlertText,
  CustomLabelField,
  EyeIconContainer,
  LogoContainer,
  LogoImage,
  MainContainer,
  PageSubtitle,
  PageTitle,
} from "../../components/atom/SharedLoginSignupStyledComponents/SharedLoginSignupStyledComponents";

/** Templates */
import { AuthLayout } from "../../components/template";

/** Utils */
import { validateNewPassword } from "../../utils";
import { appColors } from "../../utils/commonService";

const ResetPassword = () => {
  const { startChangePassword } = useAuthStore();
  const params = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [updateThePassword, setUpdateThePassword] = useState(true);
  const [showPassword, setShowPassword] = useState(true);

  /** Alerts states */
  const [unAuthorized, setUnAuthorized] = useState(false);

  const getVal = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  /** Send the new password */
  const handleChangePassword = (e) => {
    e.preventDefault();
    setUnAuthorized(false);

    if (validateNewPassword(password, setUnAuthorized)) {
      startChangePassword(
        password,
        params?.token,
        params?.userId,
        setUpdateThePassword
      );
    }
  };

  const reusedImageContainer = () => {
    return (
      <LogoContainer>
        <LogoImage />
      </LogoContainer>
    );
  };

  const reusedPasswordLabelField = () => {
    return (
      <CustomLabelField
        type={showPassword ? "password" : "text"}
        placeholder="********"
        name="password"
        onChange={getVal}
        value={password}
        auth={unAuthorized}
      />
    );
  };

  const reusedTitleAndSubtitle = (reusedTitle, reusedSubtitle) => {
    return (
      <>
        <PageTitle>{reusedTitle}</PageTitle>
        <PageSubtitle>{reusedSubtitle}</PageSubtitle>
      </>
    );
  };

  return (
    <AuthLayout>
      {updateThePassword ? (
        <MainContainer>
          {reusedImageContainer()}
          {reusedTitleAndSubtitle(
            "Återställ lösenord.",
            "Välj ett nytt lösenord som skiljer sig från ditt gamla lösenord."
          )}
          {reusedPasswordLabelField()}
          <EyeIconContainer>
            <IconButton
              disableRipple
              onClick={() => setShowPassword(!showPassword)}
            >
              {password.length === 0 ? null : !showPassword ? (
                <VisibilityOutlinedIcon htmlColor={`${appColors.blackColor}`} />
              ) : (
                <VisibilityOffOutlinedIcon
                  htmlColor={`${appColors.blackColor}`}
                />
              )}
            </IconButton>
          </EyeIconContainer>
          {unAuthorized && (
            <AlertText style={{ marginTop: "-3rem", marginBottom: "1rem" }}>
              6+ bokstäver, 1 stor bokstav, 1 siffra.
            </AlertText>
          )}
          <Filled_btn
            className="filled-btn-1"
            type="submit"
            title="Återställ lösenord"
            disabled={password ? false : true}
            onClick={handleChangePassword}
          />
        </MainContainer>
      ) : (
        <MainContainer>
          {reusedImageContainer()}
          {reusedTitleAndSubtitle(
            "Lösenord uppdaterat.",
            "Du kan nu logga in med ditt nya lösenord."
          )}
          <Filled_btn
            className="filled-btn-1"
            type="submit"
            title="Tillbaka till Logga in"
            onClick={() => navigate("/login")}
          />
        </MainContainer>
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
