/** Libraries */
import React from "react";
import PropTypes from "prop-types";

/** Atoms */
import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../atom/CustomIcons/ArrowIcons";

/** Styles */
import {
  ButtonItemContainer,
  ButtonsContainer,
  FontText,
  MainContainer,
} from "./ResultFooter.styles";

const ResultFooter = ({
  questionIndex,
  questionLength,
  onLeftClick,
  onResultHandler,
  onRightClick,
  ...props
}) => {
  const handleOnLeftClick = () => {
    if (questionIndex > 0) {
      onLeftClick();
    }
  };
  const handleOnRightClick = () => {
    if (questionIndex < questionLength - 1) {
      onRightClick();
    }
  };
  return (
    <MainContainer {...props}>
      <ButtonsContainer padding={1}>
        <ButtonItemContainer onClick={handleOnLeftClick}>
          <LeftArrowIcon />
          <FontText>Föregående</FontText>
        </ButtonItemContainer>

        <ButtonItemContainer onClick={onResultHandler}>
          <FontText>Resultatsida</FontText>
        </ButtonItemContainer>

        <ButtonItemContainer onClick={handleOnRightClick}>
          <FontText>Nästa</FontText>
          <RightArrowIcon />
        </ButtonItemContainer>
      </ButtonsContainer>
    </MainContainer>
  );
};

ResultFooter.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  questionLength: PropTypes.number.isRequired,
  onLeftClick: PropTypes.func.isRequired,
  onResultHandler: PropTypes.func.isRequired,
  onRightClick: PropTypes.func.isRequired,
};

export default ResultFooter;
