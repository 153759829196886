/** Libraries */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

/** Slices */
import {
  lectureDashboardLogout,
  setLectureQuiz,
  setSelectedLectureQuizIndex,
  uiCloseProgressBackdrop,
  uiOpenProgressBackdrop,
} from "../../redux/slices";

/** Axios Instances */
import { EndPoints, instance2 } from "../../components/service/Route";

/** Tools */
import { SendSentryError } from "../../tools";
import { useExercisesSectionCategoriesStore } from "../exercise/useExercisesSectionCategoriesStore";

export const useLectureDashboardStore = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();

  const {
    lectureQuicez,
    nextLectureName,
    selectedLectureQuizIndex,
    lectureGeneralProgress,
  } = useSelector((state) => state.lectureDashboard);

  const currentSectionCategory = useExercisesSectionCategoriesStore();

  const startSettingLectureQuiz = async (
    sectionCategory,
    setAreLecturesLoading
  ) => {
    if (sectionCategory) {
      const getQuizesUrl = `${EndPoints.getLectureQuizes}/${sectionCategory._id}`;
      try {
        const data = await instance2.get(getQuizesUrl);

        if (data?.data.length !== 0) {
          dispatch(setLectureQuiz({ data: data.data, sectionCategory }));
        }
      } catch (error) {
        console.log("error: ", error);
        // SendSentryError("LECTURE", "Error getting the lecture quicez", {
        //   details: "Something went wrong from the backend.",
        // });
      } finally {
        setAreLecturesLoading(false);
      }
    } else {
      console.error("sectionCategory is requiered!!!");
      SendSentryError("LECTURE", "sectionCategory missed.", {
        details:
          "In order to get the lecture quicez we need the section category information.",
      });
    }
  };

  const startNextLecture = () => {
    const currLectureQuicez = lectureQuicez[currentSectionCategory.title];
    if (selectedLectureQuizIndex < currLectureQuicez.length - 1) {
      dispatch(uiOpenProgressBackdrop());
      navigate("/lecture/theory", {
        state: {
          sectionCategory: currentSectionCategory,
          lecture: currLectureQuicez[selectedLectureQuizIndex + 1],
          bypass: true,
        },
      });
      dispatch(
        setSelectedLectureQuizIndex({
          index: selectedLectureQuizIndex + 1,
          category: currentSectionCategory.title,
        })
      );
    } else {
      navigate("/category", {
        state: {
          item: currentSectionCategory,
        },
      });
    }
  };

  const startLectureDashboardLogout = () => {
    dispatch(lectureDashboardLogout());
  };

  return {
    /** Values */
    lectureQuicez,
    nextLectureName,
    lectureGeneralProgress,
    selectedLectureQuizIndex,

    /** Functions */
    startSettingLectureQuiz,
    startNextLecture,
    startLectureDashboardLogout,
  };
};
