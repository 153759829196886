/** Libraries */
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NavigationButtonsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "10px",
}));

export const NavigationButtonText = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
}));

export const StyledExamButton = styled(Button)(({ theme, disabled, mq }) => ({
  "&.MuiButtonBase-root": {
    border: `1px solid ${
      disabled
        ? theme.palette.appcolors.grey8
        : theme.palette.appcolors.primaryColor
    }`,
    backgroundColor: disabled ? theme.palette.appcolors.grey3 : "transparent",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    height: "46px",
    width: "100%",
    color: disabled
      ? theme.palette.appcolors.grey7
      : theme.palette.appcolors.primaryColor,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    marginBottom: mq && "20px",
    [theme.breakpoints.down(1200)]: {
      marginBottom: mq && "0rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
