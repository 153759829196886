/** Regex expressions */
export const isValidPassword =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=äöåÄÖÅ.`|{}:;!.?\"()\[\]-]{7,}$/;

export const validateSignUp = ({ email, fullName, password, popup }) => {
  if (fullName === "" || email === "" || password === "") {
    return false;
  }
  if (!isValidPassword.test(password)) {
    popup(true);
    return false;
  }

  return true;
};

export const validateLogin = ({ email, password }, setUnAuthorized) => {
  if (email === "" || password === "") {
    setUnAuthorized(true);
    return false;
  }
  if (!isValidPassword.test(password, setUnAuthorized)) {
    setUnAuthorized(true);
    return false;
  }

  return true;
};

export const validateNewPassword = (password, setUnAuthorized) => {
  if (!isValidPassword.test(password)) {
    setUnAuthorized(true);
    return false;
  }
  return true;
};

export const validateChangePassword = (password) => {
  if (!isValidPassword.test(password)) {
    return false;
  }
  return true;
};
