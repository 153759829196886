import JSZip from "jszip";
import usePdfDownload from "./usePdfDownload";

const useZipAndDownload = () => {
  const { links, isDownloadable, switchIsLoading, title, zipLink } =
    usePdfDownload();

  const zipAndDownload = async () => {
    switchIsLoading(); // turn on loading
    if (!isDownloadable) return;
    console.log("download");

    const zip = new JSZip();

    // Download and add each file to the zip
    async function addFileToZip(name, url) {
      const response = await fetch(url);
      if (response.ok) {
        const fileContent = await response.blob();
        zip.file(`${name}.pdf`, fileContent);
      } else {
        if (process.env.NODE_ENV !== "test") {
          console.error(`Error downloading ${name}`);
        }
      }
    }

    try {
      const downloadPromises = Object.entries(links).map(([name, url]) =>
        addFileToZip(name, url)
      );
      await Promise.all(downloadPromises);

      const zipContent = await zip.generateAsync({ type: "blob" });
      // Trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipContent);
      downloadLink.download = `${title}.zip`;
      // Append the link to the document body
      document.body.appendChild(downloadLink);
      // Click the link to trigger the download
      downloadLink.click();
      // Remove the link from the document body
      document.body.removeChild(downloadLink);
      switchIsLoading(); // turn off loading
    } catch (error) {
      if (process.env.NODE_ENV !== "test") {
        console.error("Error in zipAndDownload:", error.message);
      }
    }
  };

  const downloadZip = async () => {
    switchIsLoading(); // turn on loading
    if (!isDownloadable) return;
    console.log("download zip file");
    try {
      // Trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = zipLink;
      downloadLink.download = `${title}.zip`;
      // Append the link to the document body
      document.body.appendChild(downloadLink);
      // Click the link to trigger the download
      downloadLink.click();
      // Remove the link from the document body
      document.body.removeChild(downloadLink);
      switchIsLoading(); // turn off loading
    } catch (error) {
      if (process.env.NODE_ENV !== "test") {
        console.error("Error in zipAndDownload:", error.message);
      }
    }
  };

  return { zipAndDownload, downloadZip };
};

export default useZipAndDownload;
