/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    checking: true,
    user: null,
    token: "",
    studentPreference: {},
  },
  reducers: {
    authLogin: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;

      /** Some reusable validations */
      const isVerbalSectionActive =
        user?.features?.verbal?.enabled && user?.features?.verbal?.paid;
      const isKvantiativSectionActive =
        user?.features?.kvantiativ?.enabled && user?.features?.kvantiativ?.paid;
      const isAtLeastOneSectionActive =
        isVerbalSectionActive || isKvantiativSectionActive;

      const isOldPremiumUser = user?.isPremium && !isAtLeastOneSectionActive;
      const isNewPremiumUser = user?.isPremium && isAtLeastOneSectionActive;

      if (isOldPremiumUser) {
        state.user.areVerbalFeaturesEnabled = true;
        state.user.areKvantiativFeaturesEnabled = true;
        return;
      }

      if (isNewPremiumUser) {
        state.user.areVerbalFeaturesEnabled =
          user?.features.verbal.enabled && user?.features.verbal.paid;
        state.user.areKvantiativFeaturesEnabled =
          user?.features.kvantiativ.enabled && user?.features.kvantiativ.paid;
        return;
      }

      state.user.areVerbalFeaturesEnabled = false;
      state.user.areKvantiativFeaturesEnabled = false;
    },
    update: (state, action) => {
      const user = action.payload;

      state.user = { ...state.user, ...user };

      /** Some reusable validations */
      const isVerbalSectionActive =
        user?.features?.verbal?.enabled && user?.features?.verbal?.paid;
      const isKvantiativSectionActive =
        user?.features?.kvantiativ?.enabled && user?.features?.kvantiativ?.paid;
      const isAtLeastOneSectionActive =
        isVerbalSectionActive || isKvantiativSectionActive;

      const isOldPremiumUser = user?.isPremium && !isAtLeastOneSectionActive;
      const isNewPremiumUser = user?.isPremium && isAtLeastOneSectionActive;

      if (isOldPremiumUser) {
        state.user.areVerbalFeaturesEnabled = true;
        state.user.areKvantiativFeaturesEnabled = true;
        return;
      }

      if (isNewPremiumUser) {
        state.user.areVerbalFeaturesEnabled =
          user?.features.verbal.enabled && user?.features.verbal.paid;
        state.user.areKvantiativFeaturesEnabled =
          user?.features.kvantiativ.enabled && user?.features.kvantiativ.paid;
        return;
      }

      state.user.areVerbalFeaturesEnabled = false;
      state.user.areKvantiativFeaturesEnabled = false;
    },
    authCheckingFinish: (state) => {
      state.checking = false;
    },
    authLogout: (state) => {
      state.checking = false;
      state.user = null;
      state.token = "";
    },
    setStudentPreference: (state, action) => {
      state.studentPreference = action.payload;
    },
  },
});

export const {
  authLogin,
  authCheckingFinish,
  authLogout,
  update,
  setStudentPreference,
} = userSlice.actions;
