/** Libraries */
import React, { useEffect, useRef } from "react";

import { Collapse } from "@material-ui/core";

/** Molecules */
import ResultFooter from "../../../components/molecule/ResultFooter/ResultFooter";
import NoneAnsweredAlert from "../../../components/molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";

/** Organisms */
import PublicTestTopBar from "../../../components/organism/TopBars/ExerciseTopBar/PublicTestTopBar";

import { SQDescription } from "../../../components/organism/Quiz/descriptions/SQDescription/SQDescription";
import Options from "../../../components/organism/Quiz/Options/Options";
import { Answer } from "../../../components/organism/Quiz/Answer/Answer";

/** Templates */
import { SQTemplate } from "../../../components/template";

/** Custom hooks */
import { useCustomExamsSQStore } from "../../../hooks/customExams/SingleQuestions/useCustomExamsSQStore";

/** Ui */
import SubmitButton from "../../PublicTest/PublicTestSQ/ui/SubmitButton/SubmitButton";
import { Box } from "@mui/material";
import { StyledExamButton } from "../../../components/atom/Button/Button.styles";
import { NavigationButtonText } from "./CustomExamsSQ.styles";
import { PressEnterIcon } from "../../../components/atom/PressEnter/PressEnter";
import ExamTopBar from "../../../components/organism/TopBars/ExamTopBar/ExamTopBar";
import CommonPopup from "../../../components/molecule/CommonPopup/CommonPopup";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../../components/molecule/AppLoader";
import { useExamStore } from "../../../hooks";
import CuExOverview from "../../../components/organism/CustomizedExam/CuExOverview/CuExOverview";
import { useSelector } from "react-redux";
import { EmptyStyleContainer } from "../../../components/template/QuizTemplates/SQTemplate/SQTemplate.styles";

const useEnterKey = (callback) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        callback();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [callback]);
};

const CustomExamsSQ = ({
  saveExamSession,
  handleLamnaIn,
  lamnaInhandIn,
  setBackPressPopup,
  backPressPopup,
  time,
  overview,
  setOverview,
  isCustomExam,
  examNumber,
  loading,
  checkLastAttempted,
  timeOverPopUp,
  setTimeOverPopUp,
}) => {
  const {
    quiz,
    currentQuestion,
    selectedIndex,
    selectedOption,
    totalQuestions,
    isAnswerVisible,
    isReviewSection,
    topBar,
    categoryTitle,
    startSettingSelectedAnswerCustomExamSQ,
    startSettingAnswerVisibleCustomExamSQ,
    startSpecificQuestionCustomExamSQ,
    startNextQuestionCustomExamSQ,
    startPreviousQuestionCustomExamSQ,
    startSettingCustomExamSQ,
    startNavigatingResultPageInReview,
    startClosingQuizCustomExamSQ,
    startSettingNextQuestionInReviewCustomExamSQ,
    startSettingPreviusQuestionInReviewCustomExamSQ,
    handleLastQuestion,
  } = useCustomExamsSQStore();

  const navigate = useNavigate();
  const { startLoadingExamsData } = useExamStore();
  const sectionCategory = currentQuestion?.sectionCategories?.title;

  const handleNavigation = () => {
    navigate("/courses");
    startLoadingExamsData();
  };

  const { disabledTimer } = useSelector((state) => state.timeCorrection);

  useEnterKey(startNextQuestionCustomExamSQ);

  if (currentQuestion === null) return null;

  return (
    <>
      <SQTemplate
        loader={<AppLoader isLoading={loading} />}
        submitPopup={
          <CommonPopup
            status={lamnaInhandIn}
            closePopup={() => handleLamnaIn(false)}
            redirect={saveExamSession}
            title="Vill du lämna in?"
            description={`${
              checkLastAttempted
                ? "Efter att du har lämnat in detta provpass är provet klart."
                : "Efter att du har lämnat in kan du ta en paus innan du  påbörjar nästa provpass! Ditt resultat kommer sparas."
            }`}
            oneButtonPopup
            agreeBtnName="Lämna in provpasset"
          />
        }
        timeOverPopup={
          <CommonPopup
            status={timeOverPopUp}
            closePopup={() => setTimeOverPopUp(false)}
            redirect={saveExamSession}
            title="Provpasset är klart."
            description={`${
              checkLastAttempted
                ? "Efter att du har lämnat in detta provpass är provet klart."
                : "Efter att du har lämnat in kan du ta en paus innan du  påbörjar nästa provpass! Ditt resultat kommer sparas."
            }`}
            oneButtonPopup
            agreeBtnName="Lämna in provpasset"
          />
        }
        quitPopup={
          <CommonPopup
            status={backPressPopup}
            closePopup={() => setBackPressPopup(false)}
            title="Vill du avsluta provpasset?"
            description="Du måste göra klart provpasset för att få din poäng. Om du trycker
          på avsluta, sparas inte dina svar."
            cancelBtnName="Gör klart provpass"
            agreeBtnName="Avsluta prov"
            redirect={handleNavigation}
          />
        }
        overViewPopup={
          <CuExOverview
            quiz={quiz}
            setCurrentIndex={startSpecificQuestionCustomExamSQ}
            // timeLeft={currTime}
            // params={params}
            open={overview}
            handleStatus={setOverview}
            handleLamnaIn={() => handleLamnaIn(true)}
            // isResultSection={
            //   quiz?.question[currentIndex]?.questionAnswer ? true : false
            // }
            handleNavigationResultPage={handleNavigation}
          />
        }
        // {time !== undefined && (
        //   <ExamTopBar
        //     currentIndex={params?.state?.currentQuestion}
        //     quiz={params.state.quiz}
        //     time={time}
        //     status={!timeOverPopUp}
        //     setTimeLeft={setTime}
        //     setShouldNavigate={setTimeOverPopUp}
        //     width={"80%"}
        //   />
        // )}
        topBar={
          <ExamTopBar
            progress={!isReviewSection ? topBar.progress : 100}
            // quiz={quiz}
            isCustomExam={isCustomExam}
            currentIndex={selectedIndex}
            categoryTitle={categoryTitle}
            totalQuestions={totalQuestions}
            handleOpenOversikt={() => setOverview(true)}
            goBack={startClosingQuizCustomExamSQ}
            setBackPressPopup={setBackPressPopup}
            isReviewSection={isReviewSection}
            handleLamnaIn={() => handleLamnaIn(true)}
            time={disabledTimer ? undefined : time}
            examNumber={examNumber}
            sectionCategory={sectionCategory}
          />
        }
        nonAnsweredAlert={
          currentQuestion?.optionId === null &&
          isReviewSection && <NoneAnsweredAlert />
        }
        description={
          <SQDescription
            sectionTitle={categoryTitle}
            questionDescription={currentQuestion?.questionStatement}
            questionImage={currentQuestion?.questionStatement?.includes(
              "hp-appen.s3.eu-north-1.amazonaws.com"
            )}
            questionIndications={[
              currentQuestion?.information1,
              currentQuestion?.information2,
            ]}
            sx={{
              marginTop:
                currentQuestion?.optionId === null && isReviewSection
                  ? 0
                  : "2.5rem",
            }}
          />
        }
        options={
          <Options
            sectionTitle={categoryTitle}
            options={currentQuestion?.options.options}
            // answerId={currentQuestion?.answerObj?.option}
            answerExist={isAnswerVisible}
            selectedOption={selectedOption}
            handleSelectOption={startSettingSelectedAnswerCustomExamSQ}
          />
        }
        //   primaryButton={
        //     !isReviewSection ? (
        //       <SubmitButton
        //         isAnswerVisible={isAnswerVisible}
        //         isSvaraDisabled={!currentQuestion?.selectedQuestion}
        //         handleSvaraOnClick={startSettingAnswerVisibleCustomExamSQ}
        //         handleNastaOnClick={startNextQuestionCustomExamSQ}
        //       />
        //     ) : (
        //       <ResultFooter
        //         questionLength={totalQuestions}
        //         questionIndex={selectedIndex}
        //         onResultHandler={() => {
        //           startNavigatingResultPageInReview();
        //         }}
        //         onLeftClick={() => {
        //           startSettingPreviusQuestionInReviewCustomExamSQ();
        //         }}
        //         onRightClick={() => {
        //           startSettingNextQuestionInReviewCustomExamSQ();
        //         }}
        //       />
        //     )
        //   }

        primaryButton={
          <>
            <EmptyStyleContainer cx />
            <StyledExamButton
              left
              disabled={selectedIndex === 0}
              onClick={startPreviousQuestionCustomExamSQ}
            >
              Föregående
            </StyledExamButton>
          </>
        }
        secondaryButton={
          selectedIndex + 1 === totalQuestions ? (
            <StyledExamButton
              onClick={() => {
                handleLamnaIn(true);
                handleLastQuestion();
              }}
            >
              <NavigationButtonText>Lämna in</NavigationButtonText>
            </StyledExamButton>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
              }}
            >
              <PressEnterIcon />
              <StyledExamButton onClick={startNextQuestionCustomExamSQ}>
                <NavigationButtonText>Nästa</NavigationButtonText>
              </StyledExamButton>
            </Box>
          )
        }
        answer={
          <Collapse
            orientation="vertical"
            in={isAnswerVisible}
            sx={{ width: "100%" }}
            unmountOnExit
          >
            <Answer
              isAiTutor={false}
              isFeedbackDisabled={true}
              content={currentQuestion?.answerObj?.answer}
              questionId={currentQuestion.questionId}
            />
          </Collapse>
        }
      />
    </>
  );
};

export default CustomExamsSQ;
