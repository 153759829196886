import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  width: "100%",
  margin: "auto",
  alignItems: "center",
  overflowX: "hidden",
}));
