/** Libraries */
import React, { useRef, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import PricingPlan from "./PricingPlans";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
/** Organisms */
import DiscountForm from "../Discount/DiscountForm";

/** Molecules */
import MobileMenu from "../../molecule/MobileMenu/MobileMenu";

/** Atoms */
import { PricingReviewCards } from "../../atom/PricingReviewCards/PricingReviewCards";
import { CustomDialog } from "../HomeOrg/HomeRightBar/HomeRightBar.styles";
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { GuaranteePopup } from "../PaymentOrg/GuaranteePopup";
import Switch, { SwitchProps } from "@mui/material/Switch";

/** Components */
import { EndPoints, instance2 } from "../../service/Route";

/** Utils */
import { appColors } from "../../../utils/commonService";
import config from "../../../config/config";
import { update } from "../../../redux/slices";
import { SendSentryError } from "../../../tools";

/** Hooks */
import {
  useDiscountStore,
  useMixpanel,
  usePersistedStore,
  useUiStore,
} from "../../../hooks";

require("dotenv").config();

/** Material UI - Custom elements */
const MainContainer = styled(Container)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "60px 30px",
  backgroundColor: theme.palette.appcolors.secondaryColor,
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "1rem 0rem",
}));

const TitleText = styled("h1")(({ theme }) => ({
  fontSize: "28px",
  fontWeight: "600",
  textAlign: "center",
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
}));

const SubtitleText = styled("p")(({ theme }) => ({
  fontSize: "16px",
  maxWidth: "572px",
  textAlign: "center",
}));

const SubtitleText2 = styled("p")(({ theme }) => ({
  fontSize: "14px",
  textAlign: "center",
  fontFamily: "Figtree",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  color: appColors.grey35,
  maxWidth: "850px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const StripeLabel = styled("h1")(({ theme }) => ({
  paddingTop: "20px",
  paddingBottom: "20px",
  fontSize: "32px",
  fontWeight: "700",
}));

const BodyContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "2rem",
}));

const PlanSelectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "75px",
  marginBottom: "20px",
  padding: "0 4rem 0 0",
  gap: "32px",

  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    height: "auto",
    gap: "24px",
    padding: "0",
    alignItems: "center",
    marginBottom: "40px",
  },
}));

const PlanSelectionText = styled("h2")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600",
  color: theme.palette.appcolors.darkText,
  margin: 0,
  textAlign: "left",
  maxWidth: "500px",
  flex: "1",

  [theme.breakpoints.down("lg")]: {
    textAlign: "center",
    maxWidth: "80%",
    marginBottom: "8px",
    flex: "none",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    maxWidth: "90%",
  },
}));

const MonthlyToggleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  height: "28px",

  [theme.breakpoints.down("lg")]: {
    justifyContent: "center",
    width: "100%",
  },
}));

const SwitchContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0 8px",

  [theme.breakpoints.down("lg")]: {
    margin: "0 16px",
  },
}));

const MonthlyToggleText = styled("span")(({ theme, isActive }) => ({
  fontFamily: "Poppins",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "23.4px",
  color: isActive
    ? theme.palette.appcolors.primaryColor
    : theme.palette.appcolors.primaryColor6,
  transition: "color 0.3s ease-out",

  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));

const SavingsText = styled("span")(({ theme, isActive }) => ({
  position: "absolute",
  bottom: -20,
  fontSize: "14px",
  fontWeight: 500,
  color: isActive
    ? theme.palette.appcolors.primaryColor // Blue when active
    : theme.palette.appcolors.primaryColor6, // Light blue when inactive
  transition: "color 0.3s ease-out",
  whiteSpace: "nowrap",
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 40,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 32,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(28px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 22,
    height: 22,
    borderRadius: 16,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 40,
    opacity: 1,
    backgroundColor: "#1890ff",
    boxSizing: "border-box",
    height: 30,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1890ff",
    }),
  },
}));

const Pricing = () => {
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  //set price according to plan and pass to Klarna
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  // const { darkmode } = usePersistedStore();
  const [initiateKlarna, setInitiate] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { startErrorPopup, startGuaranteePopup, guaranteePopup } = useUiStore();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const { discount } = useDiscountStore();
  const [isHalfYearly, setIsHalfYearly] = useState(false);

  const isDiscountEmpty = Object.keys(discount).length === 0;

  const { id, amount_off } = isDiscountEmpty ? {} : discount;

  useEffect(() => {
    let orderAmount = user.order_amount;
    if (orderAmount && orderAmount > 300) {
      setIsHalfYearly(true);
    }
    mixpanel.pageVisited("Checkout");
  }, []);

  const PriceIds = (plan, sectionToBuy) => {
    if (plan.id === "planTwo") {
      return isHalfYearly
        ? config.FULL_ACCESS_6M_PRICE_ID
        : config.FULL_ACCESS_PRICE_ID;
    } else if (plan.id === "planThree") {
      if (sectionToBuy === "kvantitativ") {
        return isHalfYearly
          ? config.PARTIAL_QUANTITATIVE_6M_PRICE_ID
          : config.PARTIAL_QUANTITATIVE_PRICE_ID;
      } else {
        return isHalfYearly
          ? config.PARTIAL_VERBAL_6M_PRICE_ID
          : config.PARTIAL_VERBAL_PRICE_ID;
      }
    } else {
      return isHalfYearly
        ? config.FULL_ACCESS_6M_PRICE_ID
        : config.FULL_ACCESS_PRICE_ID;
    }
  };
  const goPayment = async (planDetails, sectionToBuy) => {
    setShowCheckoutForm("");
    setSelectedPlanDetails(planDetails);
    setInitiate(true);
    setDisableButton(true);
    const url = EndPoints.createCheckoutSession;
    const priceId = PriceIds(planDetails, sectionToBuy);
    if (!priceId) {
      return;
    }
    const payload = {
      customerId: user.customerId,
      priceId,
      isGroupOrder:
        planDetails?.isGroupOrder ||
        planDetails?.id === "planTwo" ||
        planDetails?.id === "payingFullFromPartial",
      isDiscountEnabled: !isDiscountEmpty,
      sectionsToBuy:
        planDetails?.id === "planThree"
          ? [sectionToBuy]
          : ["kvantitativ", "verbal"],
    };

    if (!isDiscountEmpty && id) {
      payload.couponId = id;
    }
    instance2
      .post(url, payload)
      .then((response) => {
        const orderId = response.data.clientSecret;
        SendSentryError("PAYCARD", "Checkout order creating Success!", {
          details: `Checkout order creating Success! 
        User Detail: name${user?.fullName}. OrderId: ${orderId}`,
        });
        dispatch(update({ order_id: orderId }));
        setShowCheckoutForm(orderId);
        scrollDown();
      })
      .catch((err) => {
        console.log("Checkout order creating failed");
        console.log(err);
        const message = err?.response?.data?.error?.message;
        SendSentryError("PAYCARD", "Checkout order creating failed!", {
          details: message,
        });
        startErrorPopup();
      })
      .finally(() => {
        setDisableButton(false);
      });
  };

  const scrollDown = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 1000);
  };

  return (
    <MainContainer>
      <MobileMenu />
      <CustomDialog
        open={guaranteePopup}
        onClose={() => startGuaranteePopup()}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            maxWidth: "1230px !important",
            maxHeight: "908px",
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.appcolors.popupOverlay,
            scrollbarColor: `${theme.palette.appcolors.grey3} ${theme.palette.appcolors.secondaryColor2}`,
            scrollbarWidth: "thin",
          },
        }}
      >
        <StyledCloseIconRight onClick={() => startGuaranteePopup()} />
        <GuaranteePopup />
      </CustomDialog>
      <HeaderContainer>
        <TitleText>Nå din drömutbildning</TitleText>
        <SubtitleText>
          Premium tar dig igenom hela resan till högskoleprovet, från start till
          mål. Ta ett viktigt steg mot din drömutbildning idag.
        </SubtitleText>
        <PricingReviewCards />
      </HeaderContainer>

      <BodyContainer>
        <PlanSelectionContainer>
          <PlanSelectionText>
            Spara 40% genom att prenumerera halvårsvis
          </PlanSelectionText>
          <MonthlyToggleContainer>
            <MonthlyToggleText isActive={!isHalfYearly}>
              Månadsvis
            </MonthlyToggleText>
            <SwitchContainer>
              <AntSwitch
                checked={isHalfYearly}
                onChange={(e) => setIsHalfYearly(e.target.checked)}
                disabled={showCheckoutForm.length > 0}
              />
              <SavingsText isActive={isHalfYearly}>Spara 40%</SavingsText>
            </SwitchContainer>
            <MonthlyToggleText isActive={isHalfYearly}>
              Halvårsvis
            </MonthlyToggleText>
          </MonthlyToggleContainer>
        </PlanSelectionContainer>

        <PricingPlan
          defaultPlan="planTwo"
          initiatePayment={initiateKlarna}
          price={selectedPlanDetails?.price}
          amount_off={amount_off || 0}
          disablePaymentButton={disableButton}
          isHalfYearly={isHalfYearly}
          goPayment={(planDetails, sectionToBuy) => {
            // if (!initiateKlarna) {
            goPayment(planDetails, sectionToBuy);
            // }
          }}
        />

        <div style={{ height: "20px" }}></div>

        {showCheckoutForm && (
          <div
            id="checkout"
            style={{
              height: "auto",
              width: "100%",
            }}
          >
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret: showCheckoutForm }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        )}

        {/* {htmlSnippet && scrollDown()} */}
        {!showCheckoutForm && !user.isPremium && !user.order_id && (
          <DiscountForm />
        )}

        {/* <div 
          style={{
            width: "100%",
            minWidth: "25rem",
            marginTop: "2rem",
          }}
          ref={checkoutContainer}
        /> */}

        <StripeLabel>{initiateKlarna ? "" : "Stripe."}</StripeLabel>

        <>
          <SubtitleText2>
            HP-appens abonnemang har ingen bindningstid. Observera att vald
            delplan (verbal eller kvantitativ) inte kan ändras, endast
            uppgraderas till Full Premium.
          </SubtitleText2>
        </>
      </BodyContainer>
    </MainContainer>
  );
};

export default Pricing;
