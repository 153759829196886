/** Libraries */
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/sv";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

/** Atoms */
import { RightArrowIcon } from "../../atom/CustomIcons/ArrowIcons";

/** Molecules */
import useWindowDimensions from "../WindowDimensions/dimension";

/** Custom hooks */
import { useMixpanel } from "../../../hooks";

/** Utils */
import { DTKNormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";
import { ELFNormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";
import { KVANormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";
import { LASNormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";
import { MEKNormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";
import { NOGNormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";
import { ORDNormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";
import { XYZNormeringValueFor } from "../../../utils/normringCalculations/NormringCalculator";

/** Material UI - Custom elements */
const MainContainer = styled("div")(({ theme }) => ({
  borderRadius: "10px",
  overflow: "hidden",
  marginBottom: "2rem",
}));

const TitlesBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  backgroundColor: theme.palette.appcolors.primaryColor4,
  borderBottom: `1px solid ${theme.palette.appcolors.grey3}`,
  padding: "1rem 2rem 2rem 1rem",
}));

const ListTitle = styled("h3")(
  ({
    theme,
    widthxl,
    widthlg,
    widthmd,
    width,
    widthxs,
    widthxss,
    justifycontent,
  }) => ({
    display: "flex",
    fontFamily: "Figtree",
    fontWeight: "700",
    fontSize: "12px",
    color: theme.palette.appcolors.primaryColor2,
    width: width,
    justifyContent: justifycontent,
    alignItems: "center",
    marginBottom: "0rem",
    [theme.breakpoints.up(2400)]: {
      width: widthxl,
    },
    [theme.breakpoints.down(1285)]: {
      width: widthlg,
    },
    [theme.breakpoints.down(800)]: {
      width: widthmd,
    },
    [theme.breakpoints.down(451)]: {
      width: widthxs,
    },
    [theme.breakpoints.down(391)]: {
      width: widthxss,
    },
  })
);

const CustomTableCell = styled(TableCell)(
  ({ theme, widthlg, widthmd, widthxs, widthxss }) => ({
    fontFamily: "Poppins",
    fontSize: ".75rem",
    width: "25%",
    color: theme.palette.appcolors.black,
    [theme.breakpoints.down(1400) && theme.breakpoints.up(1285)]: {
      width: widthlg,
    },
    [theme.breakpoints.down(800)]: {
      width: widthmd,
      fontSize: ".65rem",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: ".6rem",
      width: widthxs,
    },
    [theme.breakpoints.down(391)]: {
      width: widthxss,
    },
  })
);

const InformationTab = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.tertiaryColor2,
  padding: "8px 20px",
  height: "28px",
  marginTop: "-1rem",
  border: `2px solid ${theme.palette.appcolors.grey3}`,
  borderTop: "none",
  borderBottom: "none",
  [theme.breakpoints.down(400)]: {
    padding: "8px 14px",
  },
}));

const InformationText = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  fontSize: "10px",
  lineHeight: "12px",
  color: theme.palette.appcolors.black4,
  [theme.breakpoints.down(400)]: {
    fontSize: "9px",
    lineHeight: "11px",
  },
}));

export const CategoryTable = (props) => {
  const mixpanel = useMixpanel();
  const [categoryTable, setCategoryTable] = useState([]);
  const [sectionCategory, setSectionCategory] = useState("");
  const { user } = useSelector((state) => state.user);
  const { width, height } = useWindowDimensions();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    setSectionCategory(props?.sectionCategory);
  }, []);

  useEffect(() => {
    setCategoryTable(props?.tableHistory);
  }, [props?.tableHistory]);

  const ResultHandler = (row) => {
    navigate("/resultsummary", {
      state: {
        quizId: row.quiz,
        sectionCategory: sectionCategory,
        user: user._id,
        time: row.is_time_on,
      },
    });
  };

  const percentageCalculation = (value) => {
    const sectionCategoryTitle = props?.sectionCategory.title;
    switch (sectionCategoryTitle) {
      case "XYZ":
        return XYZNormeringValueFor(value);
      case "KVA":
        return KVANormeringValueFor(value);
      case "NOG":
        return NOGNormeringValueFor(value);
      case "DTK":
        return DTKNormeringValueFor(value);
      case "ELF":
        return ELFNormeringValueFor(value);
      case "LÄS":
        return LASNormeringValueFor(value);
      case "ORD":
        return ORDNormeringValueFor(value);
      case "MEK":
        return MEKNormeringValueFor(value);
      default:
        return null;
    }
  };

  return (
    <MainContainer>
      <TitlesBox>
        <ListTitle
          widthxl={"29%"}
          widthlg={"25%"}
          widthmd={"37%"}
          width={"30%"}
          widthxs={"35%"}
          justifycontent={"left"}
        >
          Datum
        </ListTitle>
        <ListTitle
          widthxl={"26%"}
          widthlg={"24%"}
          widthmd={"17%"}
          width={"25%"}
          widthxs={"20%"}
          widthxss={"27%"}
          justifycontent={"center"}
        >
          Resultat
        </ListTitle>
        <ListTitle
          widthxl={"30%"}
          widthlg={"25%"}
          width={"31%"}
          widthmd={"29%"}
          widthxs={"30%"}
          widthxss={"25%"}
          justifycontent={"center"}
        >
          Normering
        </ListTitle>
        <ListTitle />
      </TitlesBox>
      <InformationTab>
        <InformationText>
          De 60 senaste uppgifterna gjorda på tid räknas in i din normerade
          poäng
        </InformationText>
      </InformationTab>
      <Box
        component={Paper}
        elevation={0}
        style={{
          borderRadius: "0px 0px 10px 10px",
          maxHeight: width > 600 ? "40rem" : height - 420,
          overflow: "auto",
          marginBottom: "1rem",
          padding: "0px 20px",
          scrollbarWidth: "thin",
          backgroundColor: "transparent",
          scrollbarColor: `${theme.palette.appcolors.grey7} ${theme.palette.appcolors.grey3}`,
          borderBottom: `2px solid ${theme.palette.appcolors.grey3}`,
          borderRight: `2px solid ${theme.palette.appcolors.grey3}`,
          borderLeft: `2px solid ${theme.palette.appcolors.grey3}`,
        }}
      >
        <Table>
          <TableBody>
            {categoryTable.map((row, index) => {
              return (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                    "& .MuiTableCell-root": {
                      paddingLeft: "0px",
                    },
                  }}
                  key={index}
                >
                  <CustomTableCell
                    align={"left"}
                    widthxss={"38%"}
                    widthxs={"35%"}
                    widthmd={"34%"}
                    widthlg={"30%"}
                  >
                    {moment(row?.createdAt).fromNow(false)}
                  </CustomTableCell>

                  <CustomTableCell
                    align={"center"}
                    widthxss={"20%"}
                    widthxs={"18%"}
                    widthmd={"23%"}
                    widthlg={"25%"}
                  >
                    {row.correctAnswer} av {row.attemptedQuestion}
                  </CustomTableCell>

                  <CustomTableCell
                    align={"center"}
                    widthxs={"30%"}
                    widthmd={"25%"}
                    widthlg={"32%"}
                  >
                    {row.is_time_on
                      ? percentageCalculation(
                          (row.correctAnswer / row.attemptedQuestion) * 100
                        )
                      : "-"}
                  </CustomTableCell>

                  <TableCell
                    align="center"
                    style={{ cursor: "pointer", padding: "0px" }}
                  >
                    <RightArrowIcon
                      style={{ fontSize: "20px" }}
                      onClick={() => {
                        mixpanel.historyTableUsed("Exercises");
                        ResultHandler(row);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </MainContainer>
  );
};
