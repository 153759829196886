import { styled } from "@mui/material/styles";

const PhoneMenuDisplay = styled("div")(({ theme }) => ({
  position: "relative",
  zIndex: 1301,
  marginTop: "0.5rem",
  backgroundColor: theme.palette.appcolors.grey15,
  fill: theme.palette.appcolors.black,
  width: "40px",
  height: "40px",
  borderRadius: "8px",
  cursor: "pointer",
  [theme.breakpoints.up(600)]: {
    display: "none",
  },
}));

export default PhoneMenuDisplay;
