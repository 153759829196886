/** Libraries */
import PropTypes from "prop-types";

/** Atoms */
import FeedbackButtons from "../../../atom/FeedbackButtons/FeedbackButtons";

/** Molecules */
import FeedbackCard from "../../../molecule/FeedbackCard/FeedbackCard";

/** Styles */
import { MainContainer } from "./FeedbackQuestion.styles";

export const FeedbackQuestion = ({ questionId, takeScreenshot }) => {
  return (
    <MainContainer>
      <FeedbackButtons />
      <FeedbackCard takeScreenshot={takeScreenshot} questionId={questionId} />
    </MainContainer>
  );
};

FeedbackQuestion.propTypes = {
  questionId: PropTypes.string.isRequired,
  takeScreenshot: PropTypes.bool,
};

FeedbackQuestion.defaultProps = {
  takeScreenshot: false,
};
