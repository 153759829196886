import React from "react";
import ProfileMain from "../../components/organism/ProfileOrg/ProfileMain/ProfileMain";

const Profile = () => {
  console.log("profiledocker"); //testing docker
  return (
    <div>
      <ProfileMain />
    </div>
  );
};

export default Profile;
