// DiscountPopUp.js
import React from "react";
import PopUpDialog from "../Dialog/PopUpDialog";
import DiscountBox from "./DiscountBox";

function DiscountPopUp({ isOpen, onClose }) {
  return (
    <PopUpDialog maxWidth={"sm"} isOpen={isOpen} onClose={onClose}>
      <DiscountBox onClose={onClose} />
    </PopUpDialog>
  );
}

export default DiscountPopUp;
