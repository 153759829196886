import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

const OutlineBox = (props) => {
  const theme = useTheme();

  return (
    <div onClick={() => props.onChangeCheck(props.title)}>
      <Box
        sx={{
          fontFamily: "Figtree",
          fontSize: "16px",
          width:
            props.size === "large"
              ? "7.4rem"
              : props.mobile
              ? "3.125rem"
              : "4rem",
          height: props.mobile ? "3.125rem" : "4rem",
          backgroundColor: props.checked
            ? theme.palette.appcolors.primaryColor11
            : theme.palette.appcolors.secondaryColor4,
          borderRadius: "5px",
          marginLeft: ".25rem",
          marginRight: ".25rem",
          border: `1px solid ${theme.palette.appcolors.grey3}`,
          display: "flex",
          color: props.checked
            ? theme.palette.appcolors.secondaryColor4
            : theme.palette.appcolors.black,
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.05)",
            transition: "all 0.2s ease-in-out",
          },
        }}
      >
        {props.title}
      </Box>
    </div>
  );
};

export default OutlineBox;
