/** Libraries */
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";

/** Mixpanel instance */
import { Mixpanel } from "../../tools/mixpanel/MixpanelActions";

export const useMixpanel = () => {
  const [params] = useSearchParams();
  const utm_source = params?.get("utm_source") || null;
  const utm_medium = params?.get("utm_medium") || null;
  const utm_campaign = params?.get("utm_campaign") || null;
  const { user } = useSelector((state) => state.user);

  const { pathname } = useLocation();

  /** AUTH FLOW - START */
  const signUp = (
    status,
    userId,
    userName,
    userEmail,
    planType = "Free",
    registrationMethod = "Email",
    failureReason = null,
    medium = null,
    source = null,
    campaign = null,
    emailStatus = "Unverified"
  ) => {
    Mixpanel.alias(userId);
    Mixpanel.identify(userId);
    Mixpanel.track("Registration", {
      status: status,
      $user_id: userId,
      // prettier-ignore
      "$name": userName,
      // prettier-ignore
      "$email": userEmail,
      "Plan type": planType,
      "Email status": emailStatus,
      "Registration date": new Date().toDateString(),
      "Registration method": registrationMethod,
      Campaign: campaign,
      Medium: medium,
      Source: source,
      "Failure reason": failureReason || "No failure",
    });
    Mixpanel.people.set({
      // prettier-ignore
      "$user_id": userId,
      // prettier-ignore
      "$name": userName,
      // prettier-ignore
      "$email": userEmail,
      "Plan type": planType,
      "Email status": emailStatus,
      "Registration date": new Date().toDateString(),
      "Registration method": registrationMethod,
      Campaign: campaign,
      Medium: medium,
      Source: source,
    });
  };

  const login = ({ status, userId, method }) => {
    Mixpanel.identify(userId);
    Mixpanel.track("Login", {
      status: status,
      method: method || "Email",
    });
  };

  const sessionRefresh = ({ status, method }) => {
    Mixpanel.track("Login", {
      status: status,
      method: method || "Refresh",
    });
  };

  const updateEmailUserToVerified = (userId) => {
    console.log("userId: ", userId);
    Mixpanel.identify(userId);
    Mixpanel.track("Verification", {
      Type: "Email",
    });
    Mixpanel.people.set({
      "Email status": "Verified",
    });
  };
  /** AUTH FLOW - END */

  /** PAGES VISITED - START */
  const pageVisited = (pageName) => {
    Mixpanel.track("Page visited", {
      page: pageName,
    });
  };

  const SignUpVisited = () => {
    Mixpanel.track("SignUp Visited", {
      "Page name": "SignUp",
    });
  };

  const LoginVisited = () => {
    Mixpanel.track("Login Visited", {
      "Page name": "Login",
    });
  };
  /** PAGES VISITED - END */

  /** PREMIUM - START */
  const updateUserToPremium = (planFeature) => {
    Mixpanel.identify(user._id);
    Mixpanel.track("Upgrade", {
      Type: "Premium",
      "Plan feature": planFeature,
    });
    Mixpanel.people.set({
      "Plan type": "Paid",
      "Order amount": user?.order_amount,
      "Plan feature": planFeature,
    });
  };

  const bannerClicked = (variant_name) => {
    Mixpanel.track("Banner clicked", {
      Variant: variant_name,
      Place: pathname.split("/")[1].toUpperCase(),
    });
  };

  const premiumPlanClicked = (variant_name) => {
    Mixpanel.track("Premium Plan clicked", {
      Variant: variant_name,
    });
  };
  /** PREMIUM - END */

  /** GENERAL - START */
  const buttonClicked = (variant_name) => {
    Mixpanel.track("Button clicked", {
      Variant: variant_name,
      Place: pathname.split("/")[1].toUpperCase(),
    });
  };

  const cardClicked = (card_type, card_name) => {
    Mixpanel.track("Card clicked", {
      Type: card_type,
      Name: card_name,
    });
  };

  const historyTableUsed = (table_type) => {
    Mixpanel.track("History table used", {
      Type: table_type,
    });
  };

  const inputUsed = (variant_name) => {
    Mixpanel.track("Input used", {
      Variant: variant_name,
    });
  };

  const activityStarted = (
    activity_name,
    activity_category,
    lecture_name = null,
    activity_mode = null,
    activity_filters = null,
    exercises_questions_quantity = null
  ) => {
    Mixpanel.track("Activity started", {
      Type: activity_name,
      Category: activity_category,
      Lecture_name: lecture_name,
      Activity_mode: activity_mode,
      filters: activity_filters,
      Exercises_questions_quantity: exercises_questions_quantity,
      Campaign: utm_campaign,
      Medium: utm_medium,
      Source: utm_source,
    });
  };

  const activityEnded = (
    activity_name,
    activity_category = null,
    lecture_name = null
  ) => {
    Mixpanel.track("Activity ended", {
      Type: activity_name,
      Category: activity_category,
      Lecture_name: lecture_name,
      Campaign: utm_campaign,
      Medium: utm_medium,
      Source: utm_source,
    });
  };
  /** GENERAL - END */

  const startTest = (exerciceCategory, exerciseSettings) => {
    Mixpanel.track("Test Started", {
      Category: exerciceCategory,
      Settings: exerciseSettings,
    });
  };

  const endTest = (
    exerciceCategory,
    exerciseSettings,
    exerciseCompletion,
    SummaryResult
  ) => {
    Mixpanel.track("Test Ended", {
      Category: exerciceCategory,
      Settings: exerciseSettings,
      Completion: exerciseCompletion,
      "Summary Result": SummaryResult,
    });
  };

  const feedbackButtonClicked = (
    sectionCategory,
    questionCategory,
    questionId,
    feedbackType
  ) => {
    Mixpanel.track("Feedback Button Clicked", {
      "User Email": user?.email || "public",
      "Section Category": sectionCategory,
      "Question Category": questionCategory,
      "Question ID": questionId,
      "Feedback Type": feedbackType,
    });
  };

  const reset = () => {
    Mixpanel.reset();
  };

  return {
    /** Functions */
    signUp,
    login,
    sessionRefresh,
    updateEmailUserToVerified,
    pageVisited,
    SignUpVisited,
    LoginVisited,
    updateUserToPremium,
    bannerClicked,
    buttonClicked,
    cardClicked,
    premiumPlanClicked,
    startTest,
    endTest,
    feedbackButtonClicked,
    historyTableUsed,
    inputUsed,
    activityStarted,
    activityEnded,
    reset,
  };
};
