/** Libraries */
import mixpanel from "mixpanel-browser";

/** Instance initialization */
mixpanel.init("b8b92353db27b153a0e23b561331f1bf");

let env_check = true;
// let env_check = process.env.NODE_ENV === "production";

let actions = {
  identify: (id) => {
    if (env_check) {
      if (id) {
        mixpanel.identify(id);
      } else {
        console.error("Mixpanel: You cannot indentify a user without an ID");
      }
    }
  },
  alias: (id) => {
    if (env_check) {
      if (id) {
        mixpanel.alias(id);
      } else {
        console.error("Mixpanel: You cannot use alias without an ID");
      }
    }
  },
  track: (name, props) => {
    if (name && name.length !== 0) {
      mixpanel.track(name, props);
    } else {
      console.error("Mixpanel: You cannot send an event without name");
    }
  },
  people: {
    set: (props) => {
      if (env_check) {
        if (props) {
          mixpanel.people.set(props);
        } else {
          console.error(
            "Mixpanel: You need to send properties when you use people.set"
          );
        }
      }
    },
  },
  reset: () => {
    if (env_check) mixpanel.reset();
  },
};

export let Mixpanel = actions;
