import { Button } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

const RulerButton = (props) => {
  const theme = useTheme();
  return (
    <div className="exercise-btn-1">
      <Button
        variant={!props.isRulerOppened ? "contained" : "outlined"}
        onClick={props.onClick}
        xs={12}
        size="medium"
        style={{
          backgroundColor:
            !props.isRulerOppened && theme.palette.appcolors.primaryColor,
          color: !props.isRulerOppened
            ? theme.palette.appcolors.secondaryColor10
            : theme.palette.appcolors.error6,
          borderColor: props.isRulerOppened && theme.palette.appcolors.error6,
          borderRadius: ".6em",
          padding: ".5em 3em",
          textTransform: "none",
          fontWeight: "400",
        }}
      >
        {!props.isRulerOppened ? "Visa linjal" : "Dölj linjal"}
      </Button>
    </div>
  );
};

export default RulerButton;
