import PropTypes from "prop-types";
import {
  BannerButton,
  BannerButtonText,
  BannerText,
  ResultPremiumBannerContainer,
} from "./ResultPremiumBanner.styles";

function ResultPremiumBanner({ text, buttonText, onClick, isResult }) {
  return (
    <ResultPremiumBannerContainer>
      <BannerText isResult={isResult}>{text}</BannerText>
      <BannerButton onClick={onClick}>
        <BannerButtonText>{buttonText}</BannerButtonText>
      </BannerButton>
    </ResultPremiumBannerContainer>
  );
}

ResultPremiumBanner.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isResult: PropTypes.bool,
};

ResultPremiumBanner.defaultProps = {
  isResult: false,
};

export default ResultPremiumBanner;
