/** Libraries */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

/** Slices */
import {
  customExamLogout,
  setAnswerVisibleCustomExamSQ,
  setNextQuestionInReviewCustomExamSQ,
  setNextQuestionCustomExamSQ,
  setPreviousQuestionCustomExamSQ,
  setSpecificCustomExamSQ,
  setPreviusQuestionInReviewCustomExamSQ,
  setCustomExamQuiz,
  setSelectedOptionCustomExamSQ,
  uiOpenCommonPopup,
  setLastQuestionStartTime,
} from "../../../redux/slices";

/** Custom hooks */
import { useTimerStore } from "../../useTimer/useTimerStore";
import { useMixpanel } from "../../useMixpanel/useMixpanel";

/** Utils */
import { useNavigateUTM } from "../../utils/useNavigateUTM";

/** Assets */
import CustomExamsDummyData from "./CustomExamsDummyData.json";
import { useAllCustomExamStore } from "../AllCustomExams/useAllCustomExamsStore";

export const useCustomExamsSQStore = () => {
  const {
    currTime,
    secondsCounter,
    totalTime,
    startTimer,
    startPausingTimer,
    startReactivetingTimer,
    startTimerLogout,
  } = useTimerStore();
  const mixpanel = useMixpanel();
  const navigateUTM = useNavigateUTM();
  const { pathname, state } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    quiz,
    currentQuestion,
    selectedIndex,
    totalQuestions,
    selectedOption,
    isAnswerVisible,
    topBar,
  } = useSelector((state) => state.customExam);

  // const currTime = useSelector((state) => state.timer.currTime);
  // const totalTime = useSelector((state) => state.timer.totalTime);
  // const secondsCounter = useSelector((state) => state.timer.secondsCounter);
  const timerValues = { secondsCounter, currTime, totalTime };

  const { customExamSessionQuestions } = useSelector(
    (state) => state.allCustomExams
  );

  // useEffect(() => {
  //   if (currTime === 0 && totalTime !== 0) {
  //     if (pathname.split("/")[1] === "simuleraanpassatprov") {
  //       if (quiz[0]?.optionId) {
  //         dispatch(
  //           uiOpenCommonPopup({
  //             title: "Tiden är över.",
  //             description: "Bra kämpat! Gå vidare och checka ditt resultat.",
  //             agreeBtnName: "Se resultat",
  //             redirect: () => {
  //               console.log(
  //                 "Implement some logic here when the time is up and the user has answered at least one question."
  //               );
  //               // startSubmittingQuizCustomExamSQ()
  //             },
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           uiOpenCommonPopup({
  //             title: "Tiden är över.",
  //             description:
  //               "Ingen fråga är besvarad så du tas direkt tillbaka till övningssidan.",
  //             agreeBtnName: "Avsluta",
  //             redirect: () => {
  //               console.log(
  //                 "Implement some logic here when the time is up and the user hasn't answered any question."
  //               );
  //               startTimerLogout();
  //               navigateUTM("/courses");
  //             },
  //           })
  //         );
  //       }
  //     }
  //   }
  // }, [pathname, quiz, currTime]);

  const startSettingCustomExam = () => {
    if (!state?.isReviewSection) {
      let quiz = customExamSessionQuestions?.questions;
      if (quiz?.length === 0) {
        navigateUTM("/courses");
      }

      // mixpanel.activityStarted("Custom Exam", params?.category.toUpperCase());
      startTimer(state?.totalTime, true);
      dispatch(setCustomExamQuiz({ quiz, questionIndex: null }));
    } else {
      // const { quiz, time } = params?.state?.answerSubmittedState;
      startTimer(state?.totalTime);
      startPausingTimer();
      dispatch(
        setCustomExamQuiz({
          quiz: quiz,
          questionIndex: questionIndex,
        })
      );
    }
  };

  const startSubmittingQuizCustomExamSQ = () => {
    console.log("Implement some logic here when the user completes a provpass");
    startPausingTimer();
    // let resultAnswer = {
    //   answer: [],
    //   question: [],
    //   attemptedQuestion: 0,
    // };

    // quiz.forEach((question) => {
    //   const finalQuestion = question?.optionId
    //     ? question
    //     : {
    //         ...question,
    //         spendtime: 0,
    //         timeleft: currTime,
    //         totaltime: totalTime,
    //       };
    //   resultAnswer = {
    //     answer: [...resultAnswer.answer, question.answerObj],
    //     question: [
    //       ...resultAnswer.question,
    //       { ...finalQuestion, answer: finalQuestion.answerObj },
    //     ],
    //     attemptedQuestion: resultAnswer.attemptedQuestion + 1,
    //   };
    // });

    // console.log("Formated quiz: ", resultAnswer);

    // mixpanel.activityEnded("Custom Exam", params?.category.toUpperCase());
    startTimerLogout();
    // navigateUTM(
    //   `/public/${params?.category.toUpperCase()}/${
    //     params?.index
    //   }/resultsummary`,
    //   {
    //     state: {
    //       sectionCategory: params?.category.toUpperCase(),
    //       time: currTime,
    //       answerSubmittedState: resultAnswer,
    //     },
    //     replace: true,
    //   }
    // );
  };

  const startSettingSelectedAnswerCustomExamSQ = (option) => {
    dispatch(setSelectedOptionCustomExamSQ(option));
  };

  const startSettingAnswerVisibleCustomExamSQ = () => {
    dispatch(
      setAnswerVisibleCustomExamSQ({
        spendtime: secondsCounter,
        timeleft: currTime,
        totaltime: totalTime,
      })
    );
    startPausingTimer();
  };

  const handleLastQuestion = () => {
    dispatch(setLastQuestionStartTime(timerValues)); // Set the start time when last question is shown
  };

  const startNextQuestionCustomExamSQ = () => {
    if (selectedIndex < totalQuestions - 1) {
      startReactivetingTimer();
      dispatch(setNextQuestionCustomExamSQ(timerValues));
    } else {
      startSubmittingQuizCustomExamSQ();
    }
  };

  // #region startPreviousQuestionCustomExamSQ
  const startPreviousQuestionCustomExamSQ = () => {
    if (selectedIndex !== totalQuestions) {
      dispatch(setPreviousQuestionCustomExamSQ(timerValues));
      startReactivetingTimer();
    } else {
      startSubmittingQuizCustomExamSQ();
    }
  };

  const startSpecificQuestionCustomExamSQ = (index) => {
    dispatch(setSpecificCustomExamSQ(index));
  };

  const startNavigatingResultPageInReview = () => {
    console.log(
      "Implement some logic to go from a particular question that we are reviewing to the result page. - Ignore comments below, it was code from the public tests"
    );
    // navigateUTM(
    //   `/public/${params?.category.toUpperCase()}/${
    //     params?.index
    //   }/resultsummary`,
    //   {
    //     state: {
    //       sectionCategory: params?.category.toUpperCase(),
    //       time: state?.time,
    //       answerSubmittedState: state?.quiz,
    //     },
    //   }
    // );
  };

  const startSettingNextQuestionInReviewCustomExamSQ = () => {
    dispatch(setNextQuestionInReviewCustomExamSQ());
  };

  const startSettingPreviusQuestionInReviewCustomExamSQ = () => {
    dispatch(setPreviusQuestionInReviewCustomExamSQ());
  };

  const startClosingQuizCustomExamSQ = () => {
    console.log("state?.isReviewSection", state?.isReviewSection);
    if (!state?.isReviewSection) {
      if (quiz[0]?.optionId) {
        dispatch(
          uiOpenCommonPopup({
            title: "Vill du avsluta?",
            description: "Du tas nu till summeringssidan.",
            cancelBtnName: "Fortsätt öva",
            agreeBtnName: "Avsluta",
            redirect: () => {
              console.log(
                "Implement some logic here when the user closes the quiz and he answer at least one question"
              );
              // startSubmittingQuizCustomExamSQ()
            },
          })
        );
      }
    } else {
      console.log(
        "Implement some logic here when the user closes the quiz and he is at the review section"
      );
      startNavigatingResultPageInReview();
    }
  };

  const startLogoutCustomExamSQ = () => {
    dispatch(customExamLogout());
  };

  return {
    /** Values */
    quiz,
    currentQuestion,
    selectedIndex,
    selectedOption,
    totalQuestions,
    isReviewSection: state?.isReviewSection,
    isAnswerVisible: isAnswerVisible || state?.isReviewSection,
    topBar,
    categoryTitle: currentQuestion?.sectionCategories?.title,
    // categoryTitle: params?.category.toUpperCase(),

    /** Functions */
    startSettingCustomExam,
    startSettingSelectedAnswerCustomExamSQ,
    startSettingAnswerVisibleCustomExamSQ,
    startNextQuestionCustomExamSQ,
    startPreviousQuestionCustomExamSQ,
    startClosingQuizCustomExamSQ,
    startNavigatingResultPageInReview,
    startSettingNextQuestionInReviewCustomExamSQ,
    startSettingPreviusQuestionInReviewCustomExamSQ,
    startSpecificQuestionCustomExamSQ,
    startLogoutCustomExamSQ,
    handleLastQuestion,
  };
};
