import React from "react";
import Pricing from "./Pricing";
import LeftBar from "../LeftBarOrg/LeftBar";
import GridLayout from "../GridOrg/GridLayout";

const PaymentMain = () => {
  return <GridLayout leftBar={<LeftBar />} middle={<Pricing />} />;
};

export default PaymentMain;
