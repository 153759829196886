/** Libraries */
import { createSlice, current } from "@reduxjs/toolkit";

export const publicTestSQSlice = createSlice({
  name: "publicTestSQ",
  initialState: {
    quiz: [],
    currentQuestion: null,
    selectedIndex: 0,
    totalQuestions: 0,
    selectedQuestion: null,
    isAnswerVisible: false,
    topBar: {
      progress: 0,
    },
  },
  reducers: {
    setPublicTestSQQuiz: (state, action) => {
      const { quiz, questionIndex } = action.payload;
      // console.log("questionIndex: ", questionIndex);
      state.quiz = quiz;
      state.currentQuestion = questionIndex ? quiz[questionIndex] : quiz[0];
      state.totalQuestions = quiz.length;
      state.selectedIndex = questionIndex ? questionIndex : 0;
    },
    setSelectedQuestionPublicTestSQ: (state, action) => {
      state.currentQuestion.selectedQuestion = action.payload?.optionId;
      state.quiz[state.selectedIndex].optionId = action.payload?.optionId;
      state.quiz[state.selectedIndex].selectedQuestion =
        action.payload?.optionId;
    },
    setAnswerVisiblePublicTestSQ: (state, action) => {
      state.isAnswerVisible = true;
      state.quiz[state.selectedIndex] = {
        ...state.quiz[state.selectedIndex],
        ...action.payload,
      };

      state.topBar.progress =
        ((state.selectedIndex + 1) / state.totalQuestions) * 100;
    },
    setNextQuestionPublicTestSQ: (state) => {
      if (state.selectedIndex + 1 !== state.totalQuestions) {
        state.currentQuestion = state.quiz[state.selectedIndex + 1];
        state.selectedIndex += 1;
        state.selectedQuestion = null;
        state.isAnswerVisible = false;
      }
    },
    setNextQuestionInReviewPublicTestSQ: (state) => {
      if (state.selectedIndex + 1 !== state.totalQuestions) {
        state.currentQuestion = state.quiz[state.selectedIndex + 1];
        state.selectedIndex += 1;
      }
    },
    setPreviusQuestionInReviewPublicTestSQ: (state) => {
      if (state.selectedIndex !== 0) {
        state.currentQuestion = state.quiz[state.selectedIndex - 1];
        state.selectedIndex -= 1;
      }
    },
    publicTestSQLogout: (state) => {
      state.quiz = [];
      state.currentQuestion = null;
      state.selectedIndex = 0;
      state.totalQuestions = 0;
      state.selectedQuestion = null;
      state.isAnswerVisible = false;
      state.topBar.progress = 0;
    },
  },
});
export const {
  setPublicTestSQQuiz,
  setSelectedQuestionPublicTestSQ,
  setAnswerVisiblePublicTestSQ,
  setNextQuestionPublicTestSQ,
  setNextQuestionInReviewPublicTestSQ,
  setPreviusQuestionInReviewPublicTestSQ,
  publicTestSQLogout,
} = publicTestSQSlice.actions;
