import { styled } from "@mui/material/styles";
import MarkLatex from "../../atom/Marklatex/MarkLatex";

export const QuestionImage = styled(MarkLatex)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    width: "158px",
    maxHeight: "143px",
    minHeight: "0",
    paddingTop: "0",
  },
  [theme.breakpoints.down(900)]: {
    "& img": {
      width: "144px",
    },
  },
  [theme.breakpoints.down(601)]: {
    height: "auto",
    "& img": {
      width: "100px",
      flexShrink: 0,
    },
  },
}));
