/** Libraries */
import { styled } from "@mui/material/styles";
import { Box, Rating } from "@mui/material";

/** Assets */
import profilepicjasmine from "../../../assets/Imgs/profilepicjasmine.jpg";
import profilepictova from "../../../assets/Imgs/profilepictova.jpg";
import profilepichelin from "../../../assets/Imgs/profilepichelin.jpg";

/** Material UI - Custom elements */
const MainCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  maxWidth: "543px",
  maxHeight: "431px",
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.appcolors.secondaryColor15,
  borderRadius: "13px",
  gap: "2rem",
  [theme.breakpoints.up(2400)]: {
    maxWidth: "700px",
    maxHeight: "460px",
    gap: "3rem",
    padding: "3rem",
  },
  [theme.breakpoints.down(1080)]: {
    maxWidth: "511px",
    maxHeight: "268px",
    padding: "3rem",
    gap: "1rem",
  },
  [theme.breakpoints.down(600)]: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "0px",
    padding: "1.5rem",
    gap: "0rem",
  },
}));

const CardTop = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingBottom: "1rem",
}));

const QuoteIcon = styled("svg")(({ theme, mobile }) => ({
  width: mobile ? 25 : 250,
  height: mobile ? 25 : 50,
  position: mobile && "absolute",
  top: mobile && "10px",
  left: mobile && "10px",
}));

const CardReview = styled("p")(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.up(2400)]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down(1080)]: {
    fontFamily: "Figtree",
    fontSize: "11px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

const CardBottom = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "0rem 1rem",
}));

const CardProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

const CardProfilePicture = styled("img")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  width: "75px",
  height: "75px",
}));

const CardProfileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "10px",
}));

const CardBottomBox = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: "2rem 2rem 0rem",
  width: "100%",
}));

const CardProfileName = styled("p")(({ theme }) => ({
  fontWeight: 600,
  fontSize: "14px",
  color: theme.palette.appcolors.black,
  paddingBottom: "5px",
  [theme.breakpoints.down(1080)]: {
    fontWeight: 700,
    fontSize: "12px",
    fontFamily: "Figtree",
  },
  [theme.breakpoints.down(600)]: {
    fontWeight: 600,
    fontSize: "16px",
    paddingBottom: "0px",
  },
}));

const CardPoints = styled("p")(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.appcolors.primaryColor,
  [theme.breakpoints.down(1080)]: {
    fontFamily: "Figtree",
    fontWeight: 700,
    fontSize: "12px",
  },
  [theme.breakpoints.down(600)]: {
    fontWeight: 400,
  },
}));

const CardSubtext = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  fontSize: "10px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontWeight: 400,
    fontSize: "12px",
  },
}));

const CardRating = styled(Rating)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1.2rem",
  gap: "0.2rem",
  color: theme.palette.appcolors.tertiaryColor11,
  [theme.breakpoints.down(1080)]: {
    fontSize: "0.9rem",
    gap: "0.3rem",
  },
}));

export const MainReviewCard = (width, theme, selectedval) => {
  const pictures = [profilepicjasmine, profilepictova, profilepichelin];
  const testimonials = [
    "Med HP-appen har jag fått mycket bra pedagogisk hjälp och tips under min HP-resa. Det har såklart inte alltid varit lätt, men er hemsida och material har jag mycket att tacka för, så att slitet var värt det i slutändan! Jag är så glad över att det finns en hemsida som eran!",
    "Tidigare var jag väldigt osäker på hur jag skulle plugga till högskoleprovet, men med HP-appen har det känts så lätt! Prognoserna visade tydligt mina styrkor och svagheter, så jag snabbt kunde se vad jag behövde träna på. Rekommenderas starkt!",
    "Med allting samlat på en och samma hemsida kunde jag enkelt få till ett organiserat och effektivt plugg. Under tiden man studerade fick man en prognos som visade hur man låg till på de olika delarna vilket gjorde att det var ännu lättare att veta vad man skulle lägga fokus på.",
  ];
  const testimonials2 = [
    "Har tipsat er till många bekanta!",
    "Tidigare var jag väldigt osäker på hur jag skulle plugga till högskoleprovet, men med HP-appen har det känts så lätt!",
    "Skulle verkligen rekommendera den här hemsidan starkt till alla som ska skriva högskoleprovet!",
  ];
  const names = ["Jasmine", "Tova Mesch", "Helin"];

  return (
    <MainCardContainer>
      {width > 600 && (
        <CardTop>
          <QuoteIcon
            xmlns="http://www.w3.org/2000/svg"
            fill={theme.palette.appcolors.black}
            viewBox="0 0 16 16"
          >
            <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
          </QuoteIcon>
          <CardReview>{testimonials[selectedval]}</CardReview>
        </CardTop>
      )}
      <CardBottom>
        <CardProfileContainer>
          <CardProfilePicture src={pictures[selectedval]} />
          <CardProfileBox>
            <CardProfileName>{names[selectedval]}</CardProfileName>
            {selectedval === 0 && (
              <>
                <CardPoints>1.55</CardPoints>
                <CardSubtext>Uppnådd poäng</CardSubtext>
              </>
            )}
          </CardProfileBox>
        </CardProfileContainer>
        <CardRating value={5} readOnly />
      </CardBottom>
      {width < 600 && (
        <CardBottomBox>
          <QuoteIcon
            mobile
            xmlns="http://www.w3.org/2000/svg"
            fill={theme.palette.appcolors.black}
            viewBox="0 0 16 16"
          >
            <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
          </QuoteIcon>
          <CardReview>{testimonials2[selectedval]}</CardReview>
        </CardBottomBox>
      )}
    </MainCardContainer>
  );
};
