/** Libraries */
import React, { useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Radio } from "@mui/material";

/** Utils */
import { optionsCharacters } from "../../../utils/commonService";

/** Atoms */
import {
  StyledCorrectIcon,
  StyledWrongIcon,
  StyledRadiusCheckedIcon,
  StyledRadiusUnCheckedIcon,
  StyledRadiusOnHoverIcon,
} from "../../atom/RadioIcons/RadioIcons";

/** Styles */
import {
  RadiusIndexText,
  RowRadiusButtonFirstContainer,
  RowRadiusButtonSecondContainer,
  RowRadiusIndexContainer,
} from "./RadioButton.styles";

const RadioButton = ({
  isCorrect,
  isIncorrect,
  isSelected,
  isHover,
  indexLetter,
  disabled,
}) => {
  const [isOnHoverHere, setIsOnHoverHere] = useState(false);
  const theme = useTheme();

  const image = useMemo(() => {
    switch (true) {
      case isCorrect:
        return <StyledCorrectIcon />;
      case isIncorrect:
        return <StyledWrongIcon />;
      case isSelected:
        return <StyledRadiusCheckedIcon />;
      case disabled:
        return <StyledRadiusUnCheckedIcon />;
      case isOnHoverHere || isHover:
        return <StyledRadiusOnHoverIcon />;
      default:
        return <StyledRadiusUnCheckedIcon />;
    }
  }, [isCorrect, isIncorrect, isSelected, isHover, disabled, isOnHoverHere]);

  const changeColor = () => {
    if (isCorrect) {
      return theme.palette.appcolors.success4;
    } else if (isIncorrect) {
      return theme.palette.appcolors.error6;
    } else if ((isOnHoverHere || isHover || isSelected) && !disabled) {
      return theme.palette.appcolors.primaryColor;
    }
    return theme.palette.appcolors.grey3;
  };

  return (
    <RowRadiusButtonFirstContainer
      onMouseOver={() => setIsOnHoverHere(true)}
      onMouseLeave={() => setIsOnHoverHere(false)}
      disabled
    >
      <RowRadiusButtonSecondContainer>
        <Radio disabled checked={false} icon={image} />
      </RowRadiusButtonSecondContainer>
      <RowRadiusIndexContainer>
        <RadiusIndexText
          sx={{
            color: changeColor,
          }}
        >
          {optionsCharacters(indexLetter)}
        </RadiusIndexText>
      </RowRadiusIndexContainer>
    </RowRadiusButtonFirstContainer>
  );
};

RadioButton.propTypes = {
  isCorrect: PropTypes.bool,
  isIncorrect: PropTypes.bool,
  isSelected: PropTypes.bool,
  isHover: PropTypes.bool,
  indexLetter: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default RadioButton;
