import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { EndPoints, instance2 } from "../../components/service/Route";
import { useMixpanel } from "../../hooks";
import { setDiscount, update, validateDiscountCode } from "../../redux/slices";
import { SendSentryError } from "../../tools";
import { appColors } from "../../utils/commonService";
import CustomModal from "./Modal";
import { useUiStore } from "../../hooks";
import config from "../../config/config";

const PayConfirmation = () => {
  const { search } = useLocation();
  const mixpanel = useMixpanel();
  // Set true and false here for testing the UI
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [order_amount, setOrderAmount] = useState(0); //This is the price of the order. This will be used to show the price in the modal [Not implemented yet
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { startErrorPopup } = useUiStore();

  // const completionCheck = () => {
  //   const isAValidOrderId =
  //     user?.order_id !== undefined && typeof user?.order_id === "string";
  //   if (isAValidOrderId) {
  //     //Make a GET request to our API to see if this user.order_id is completed. Set isComplete to true

  //     instance2
  //       .get(EndPoints.getOrder + user.order_id)
  //       .then((res) => {
  //         console.log("PAYCARD", res);
  //         console.log("plan", res?.data?.user?.planDescription);
  //         console.log("user email", res?.data?.user?.email);
  //         SendSentryError(
  //           "PAYCARD",
  //           `Payment Confirmation Successful for user ${res?.data?.user?.email}`,
  //           {
  //             details: `Payment confirmation Success! ${res?.data?.user?.planDescription}`,
  //           }
  //         );
  //         dispatch(update(res.data.user));
  //         SendSentryError(
  //           "PAYCARD",
  //           `Payment Confirmation Successful for user ${res?.data?.user?.email}`,
  //           {
  //             details: `Payment confirmation Success! ${res?.data?.user?.planDescription}`,
  //           }
  //         );
  //         setIsComplete(true);
  //         if (!res?.data?.isTestEnvironment) {
  //           let planFeature = "";
  //           const isVerbal = res?.data?.user?.features?.verbal?.paid;
  //           const isKvantiativ = res?.data?.user?.features?.kvantiativ?.paid;
  //           if (isVerbal && isKvantiativ) {
  //             planFeature = "Full Access";
  //           } else if (isVerbal) {
  //             planFeature = "Verbal";
  //           } else if (isKvantiativ) {
  //             planFeature = "Kvantiativ";
  //           }
  //           mixpanel.updateUserToPremium(planFeature);
  //         }
  //         setOrderAmount(res.data.user.order_amount);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         console.log("Error", "Something went wrong from backend!");
  //         const { response } = error;
  //         const message = response?.data?.error?.message;
  //         console.log("message: ", message);
  //         const status = response?.status;
  //         const statusText = response?.statusText;

  //         if (status === 500) {
  //           SendSentryError("PAYCARD", "payment confirmation failed", {
  //             details: message,
  //           });
  //           console.log("errors");
  //         } else if (error.request) {
  //           SendSentryError("PAYCARD", "payment confirmation failed", {
  //             details: message,
  //           });
  //         } else {
  //           SendSentryError("PAYCARD", "payment confirmation failed", {
  //             details: message,
  //           });
  //         }
  //         startErrorPopup();
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   } else {
  //     setIsLoading(false);
  //     console.log("Error", "Order id not found");
  //     SendSentryError("PAYCARD", "payment confirmation failed", {
  //       details: "Order id not found or corrupted.",
  //     });
  //     //Handle error and set isComplete to false
  //   }
  // };
  const verifyPayment = (sessionId) => {
    const url = EndPoints.verifyPayment;
    instance2
      .post(url, { sessionId })
      .then((res) => {
        SendSentryError(
          "PAYCARD",
          `Payment Confirmation Successful for user ${res?.data?.user?.email}`,
          {
            details: `Payment confirmation Success! ${res?.data?.user?.planDescription}`,
          }
        );
        dispatch(setDiscount({}));
        dispatch(validateDiscountCode(false));
        dispatch(update(res.data.user));
        SendSentryError(
          "PAYCARD",
          `Payment Confirmation Successful for user ${res?.data?.user?.email}`,
          {
            details: `Payment confirmation Success! ${res?.data?.user?.planDescription}`,
          }
        );
        setIsComplete(true);
        if (!res?.data?.isTestEnvironment) {
          let planFeature = "";
          const isVerbal = res?.data?.user?.features?.verbal?.paid;
          const isKvantiativ = res?.data?.user?.features?.kvantiativ?.paid;
          if (isVerbal && isKvantiativ) {
            planFeature = "Full Access";
          } else if (isVerbal) {
            planFeature = "Verbal";
          } else if (isKvantiativ) {
            planFeature = "Kvantiativ";
          }
          mixpanel.updateUserToPremium(planFeature);
        }
        setOrderAmount(res.data.user.order_amount);
      })
      .catch((error) => {
        console.log(error);
        console.log("Error", "Something went wrong from backend!");
        const { response } = error;
        const message = response?.data?.error?.message;
        console.log("message: ", message);
        const status = response?.status;
        const statusText = response?.statusText;

        if (status === 500) {
          SendSentryError("PAYCARD", "payment confirmation failed", {
            details: message,
          });
          console.log("errors");
        } else if (error.request) {
          SendSentryError("PAYCARD", "payment confirmation failed", {
            details: message,
          });
        } else {
          SendSentryError("PAYCARD", "payment confirmation failed", {
            details: message,
          });
        }
        console.log("In the catch end");

        startErrorPopup();
      })
      .finally(() => {
        console.log("In the final state");

        setIsLoading(false);
      });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    let sessionId = searchParams.get("session_id");
    if (sessionId) {
      verifyPayment(sessionId);
    } else {
      setIsLoading(false);
    }
  }, [user?.order_id]);

  const navigate = useNavigate();

  const handleClose = async () => {
    // If paymentCompleted, navigate user to dashboard/home page
    if (isComplete === true) {
      window.location.href = config.REACT_APP_BASE_URL + `/home`;
    } else {
      navigate("/checkout");
    }
  };

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{
            color: appColors.whiteColor,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" size="5rem" />
        </Backdrop>
      ) : (
        <>
          <CustomModal
            order_id={user.order_id}
            handleClose={handleClose}
            isComplete={isComplete}
            order_amount={order_amount}
            showConfetti={true}
          />
        </>
      )}
    </>
  );
};

export default PayConfirmation;
