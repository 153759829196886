/** Libraries */
import { styled } from "@mui/material/styles";

/** MUI styled elements */
export const NavigationButtonText = styled("p")(({ theme }) => ({
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
}));
