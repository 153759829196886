import { Grid, Modal, Typography } from "@material-ui/core";
import { alpha, makeStyles } from "@material-ui/core/styles";
import React from "react";
import CheckmarkAnimated from "../../../src/assets/Imgs/CheckmarkAnimated.gif";
import ErrormarkAnimated from "../../../src/assets/Imgs/ErrormarkAnimated.gif";
import Konfeti from "../../components/atom/Konfeti/Konfeti";
import { appColors } from "../../utils/commonService";
import Payment from "../Payment/Payment";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `${alpha(appColors.whiteColor, 0.8)}`,
  },
  box: {
    width: "70vw",
    maxWidth: "540px",
    minWidth: "340px",
    position: "absolute",
    borderRadius: 30,
    border: 0,
    backgroundColor: appColors.whiteColor,
    padding: "30px 60px",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    marginLeft: "13rem",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 0px",
      marginLeft: "6rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  grid: {
    direction: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  textUpper: {
    color: appColors.grey70,
    fontSize: "13px",
    padding: "3px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  textLower: {
    color: appColors.grey70,
    fontSize: "15px",
    padding: "3px",
  },
  titleRed: {
    color: appColors.valentine_red,
    fontSize: "28px",
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  titleGreen: {
    color: appColors.darkMintGreen,
    fontSize: "28px",
    fontWeight: "600",
  },
  horizontalLine: {
    marginTop: "45px",
    marginBottom: "25px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  greenIcon: {
    //this needs extra styling because the icon in the gif is not perfectly centered
    width: "100%",
    height: "100%",
    paddingLeft: "1px",
    paddingBottom: "4px",
    display: "block",
    mixBlendMode: "screen",
  },
  redIcon: {
    width: "100%",
    mixBlendMode: "screen",
  },
}));

const CustomModal = ({
  order_id,
  handleClose,
  isComplete,
  order_amount,
  showConfetti,
}) => {
  const classes = useStyles();

  const successModal = () => {
    return (
      <>
        <div
          style={{
            background: appColors.darkMintGreen,
            border: 0,
            borderRadius: 90,
            width: "60px",
            height: "60px",
            marginBottom: "20px",
          }}
        >
          <img
            src={CheckmarkAnimated}
            alt="checkmark"
            className={classes.greenIcon}
          />
        </div>
        <Typography className={classes.titleGreen}>
          Tack för ditt köp!
        </Typography>
      </>
    );
  };

  const failureModal = () => {
    return (
      <>
        <div
          style={{
            background: appColors.valentine_red,
            border: 0,
            borderRadius: 10,
            width: "55px",
            height: "55px",
            marginBottom: "20px",
          }}
        >
          <img
            src={ErrormarkAnimated}
            alt="checkmark"
            className={classes.redIcon}
          />
        </div>
        <Typography className={classes.titleRed}>
          Betalning misslyckades
        </Typography>
      </>
    );
  };

  return (
    <div>
      <Modal open={true} onClose={handleClose} className={classes.modal}>
        <Grid container className={classes.box} direction={"column"}>
          {isComplete ? successModal() : failureModal()}
          <Typography className={classes.textUpper}>
            {isComplete
              ? `Transaktionsnummer: ${order_id.substring(0, 30)}...`
              : "Kontrollera dina uppgiter."}
          </Typography>
          <hr className={classes.horizontalLine} />
          <Grid container className={classes.grid}>
            <Typography className={classes.textLower}>Belopp:</Typography>
            <Typography className={classes.textLower}>
              {order_amount} SEK
            </Typography>
          </Grid>
          <Grid container className={classes.grid}>
            <Typography className={classes.textLower}>Bank:</Typography>
            <Typography className={classes.textLower}>Stripe</Typography>
          </Grid>
          <button
            onClick={() => handleClose()}
            style={{
              color: appColors.whiteColor,
              backgroundColor: appColors.grey80,
              display: "inline-block",
              padding: "8px 0px",
              border: "0px",
              borderRadius: "8px",
              fontSize: "14px",
              fontWeight: "500",
              marginTop: "20px",
              width: "160px",
              cursor: "pointer",
            }}
          >
            {isComplete ? "Slutför" : "Testa igen"}
          </button>
        </Grid>
      </Modal>
      <Konfeti show={showConfetti} />
      <Payment />
    </div>
  );
};

export default CustomModal;
