export const selectQuestionsWithOptionId = (state) => {
  const isTimeDisabled = state.timeCorrection.disabledTimer;
  return state.customExam.quiz.map((question) => ({
    isTimeDisabled,
    optionId: question.optionId ?? null,
    questionId: question._id,
    sectionCategory: question.sectionCategories._id,
    questionCategory: question.questionCategory[0],
    spendtime: isTimeDisabled ? 0 : question.spendtime,
    timeleft: isTimeDisabled ? 0 : question.timeleft,
    totaltime: question.totaltime,
  }));
};
