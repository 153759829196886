/** Libraries */
import { Box } from "@mui/material";

/** Organisms */
import GridLayout from "../../components/organism/GridOrg/GridLayout";
import LeftBar from "../../components/organism/LeftBarOrg/LeftBar";

const StudyPlan = () => {
  const renderMarkdownViewer = () => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "30%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2.5rem",
          marginBottom: "5rem",
          gap: "2.5rem",
        }}
      >
        <a
          href="https://form.typeform.com/to/nYFydIFC"
          target="_blank"
          rel="noreferrer"
        >
          Study Plan
        </a>
      </Box>
    );
  };

  return (
    <GridLayout
      leftBar={<LeftBar currentPage="admin" />}
      middle={renderMarkdownViewer()}
    />
  );
};

export default StudyPlan;
