/** Libraries */
import { useEffect } from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

/** Molecules */
import { FeedbackQuestion } from "../FeedbackQuestion/FeedbackQuestion";

/** Custom hooks */
import { useAuthStore, useFeedbackQuestionsStore } from "../../../../hooks";

/** Assets */
import { HPIcon } from "../../../atom/HP/HPIcon";

/** Styles */
import {
  MainContainer,
  ContentContainer,
  ItemsContainer,
  AvatarContainer,
  StyledAvatar,
  AvatarName,
  AnswerText,
  useStyles,
  MQExplanationTitle,
} from "./Answer.styles";
import { formatText } from "../../../../tools";

export const Answer = ({
  isMQ,
  content,
  sectionCategory,
  questionCategory,
  isFeedbackDisabled,
  isUser,
  isAiTutor,
  questionId,
  takeScreenshot,
  ...props
}) => {
  const { startLoadingFeedbackQuestionData } = useFeedbackQuestionsStore();
  const { user } = useAuthStore();
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    if (!isFeedbackDisabled) {
      startLoadingFeedbackQuestionData({
        questionId,
        sectionCategory,
        questionCategory,
      });
    }
  }, [questionId, sectionCategory, questionCategory, isFeedbackDisabled]);

  if (!content) return null;

  // Check if the content is a dot
  const isDot = content === "⚫";

  const returnUserAvatar = () => {
    return (
      <AvatarContainer>
        <Stack direction="row">
          <StyledAvatar
            sx={{ bgcolor: theme.palette.appcolors.error6 }}
            alt={user?.fullName || "Public User"}
            src={user?.fullName || "User"}
          />
        </Stack>
      </AvatarContainer>
    );
  };
  const returnAiAvatar = () => {
    return (
      <AvatarContainer>
        <Stack direction="row">
          <HPIcon small />
        </Stack>
      </AvatarContainer>
    );
  };

  const returnSQView = () => {
    return (
      <>
        {isAiTutor && returnAiAvatar()}
        {!isAiTutor && !isUser && returnAiAvatar()}
        <ContentContainer isMQ={isMQ}>
          {!isUser && <AvatarName>{"HP-appen studiecoach"}</AvatarName>}
          <ItemsContainer
            isuser={isUser}
            isFeedbackDisabled={isFeedbackDisabled}
          >
            {content && (
              <AnswerText
                content={formatText(content)}
                className={`${isDot ? classes.pulsatingDot : ""}`}
              />
            )}
            {content && !isFeedbackDisabled && (
              <FeedbackQuestion
                takeScreenshot={takeScreenshot}
                questionId={questionId}
              />
            )}
          </ItemsContainer>
        </ContentContainer>
      </>
    );
  };

  const returnMQView = () => {
    return (
      <>
        {isAiTutor && returnAiAvatar()}
        {!isAiTutor && !isUser && isMQ && returnAiAvatar()}
        <ContentContainer isMQ={isMQ}>
          {isMQ && !isUser && <AvatarName>{"HP-appen studiecoach"}</AvatarName>}
          <ItemsContainer isFeedbackDisabled={isFeedbackDisabled}>
            <MQExplanationTitle>Förklaring:</MQExplanationTitle>
            {content && (
              <AnswerText
                content={formatText(content)}
                className={` ${isDot ? classes.pulsatingDot : ""}`}
              />
            )}
          </ItemsContainer>
        </ContentContainer>
        {content && !isFeedbackDisabled && (
          <FeedbackQuestion
            takeScreenshot={takeScreenshot}
            questionId={questionId}
          />
        )}
      </>
    );
  };

  return (
    <MainContainer isMQ={isMQ} isuser={isUser}>
      {isMQ ? returnMQView(questionId) : returnSQView()}
    </MainContainer>
  );
};

Answer.propTypes = {
  isMQ: PropTypes.bool,
  content: PropTypes.string.isRequired,
  isFeedbackDisabled: PropTypes.bool,
  takeScreenshot: PropTypes.bool,
};

FeedbackQuestion.defaultProps = {
  isMQ: false,
  isFeedbackDisabled: false,
  takeScreenshot: false,
};
