/** Libraires */
import React from "react";
import { Navigate, useParams } from "react-router-dom";

/** Pages */
import PublicTestSQ from "../pages/PublicTest/PublicTestSQ";
import PublicTestMQ from "../pages/PublicTest/PublicTestMQ";

/** Lists of categories */
const validCategoriesSQ = ["XYZ", "ORD", "KVA", "NOG", "MEK"];
const validCategoriesMQ = ["DTK", "ELF", "LÄS", "LAS"];

const RouteSwitcher = () => {
  const { category } = useParams();

  if (validCategoriesSQ.includes(category.toUpperCase())) {
    return <PublicTestSQ />;
  } else if (validCategoriesMQ.includes(category.toUpperCase())) {
    return <PublicTestMQ />;
  } else {
    return <Navigate to="/" />;
  }
};

export default RouteSwitcher;
