/** Libraries */
import { createSlice, current } from "@reduxjs/toolkit";

export const lectureDashboardSlice = createSlice({
  name: "lectureDashboard",
  initialState: {
    lectureQuicez: {
      KVA: [],
      XYZ: [],
      MEK: [],
      NOG: [],
      ORD: [],
      ELF: [],
      LÄS: [],
      DTK: [],
    },
    selectedLectureQuizIndex: 0,
    lectureGeneralProgress: 0,
    nextLectureName: "",
  },
  reducers: {
    setLectureQuiz: (state, action) => {
      const { data, sectionCategory } = action.payload;

      state.lectureQuicez[sectionCategory.title] = data;
      state.lectureGeneralProgress =
        data.reduce((acc, e) => (acc += e.score), 0) / data.length;
      state.selectedLectureQuizIndex = 0;

      if (state.lectureQuicez[sectionCategory.title].length - 1 > 0) {
        state.nextLectureName =
          state.lectureQuicez[sectionCategory.title][1]?.lectureName;
      } else {
        state.nextLectureName = "";
      }
    },
    setSelectedLectureQuizIndex: (state, action) => {
      const { index, category } = action.payload;

      state.selectedLectureQuizIndex = index;

      if (state.lectureQuicez[category].length - 1 >= index + 1) {
        state.nextLectureName =
          state.lectureQuicez[category][index + 1]?.lectureName;
      } else {
        state.nextLectureName = "";
      }
    },
    lectureDashboardLogout: (state) => {
      state.lectureQuicez = {
        KVA: [],
        XYZ: [],
        MEK: [],
        NOG: [],
        ORD: [],
        ELF: [],
        LÄS: [],
        DTK: [],
      };
      state.selectedLectureQuizIndex = 0;
      state.lectureGeneralProgress = 0;
      state.nextLectureName = "";
    },
  },
});
export const {
  setLectureQuiz,
  setSelectedLectureQuizIndex,
  lectureDashboardLogout,
} = lectureDashboardSlice.actions;
