/** Libraries */
import { FormControlLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

/** Organisms */
import ExerciseTopBar from "../../../../../organism/TopBars/ExerciseTopBar/ExerciseTopBar";

/** Molecules */
import { SecondaryIsPremiumCard } from "../../../../../molecule/IsPremiumCard/IsPremiumCard";

/** Atoms */
import { RightArrowIcon } from "../../../../../atom/CustomIcons/ArrowIcons";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";

/** Assets */
import UnAttemptedCheckBox from "../../../../../../assets/Imgs/UnAttemptedCircle.png";

/** Tools */
import { SendSentryError } from "../../../../../../tools";

/** Utils */
import {
  dispSecondsAsMins,
  scrollTop,
} from "../../../../../../utils/commonService";
import { percentageCalculation } from "../../../../../../utils/Utils";
import { EndPoints, instance2 } from "../../../../../service/Route";

/** Custom hooks */
import {
  useAiTutorStore,
  useExercisesMQReviewStore,
  useExercisesMQStore,
  useExercisesSectionCategoriesStore,
  useMixpanel,
  useTimerStore,
  useUiStore,
} from "../../../../../../hooks";

/** Styles */
import {
  BackgroundContainer,
  BannerButtonText,
  BodyContainer,
  CustomLoader,
  FormControlBox,
  LoaderContainer,
  MainContainer,
  QuestionBox,
  QuestionsContainer,
  QuestionTimeContainer,
  ResultBody,
  ResultBox,
  ResultBoxContainer,
  ResultBoxText,
  ResultBoxTextContainer,
  ResultContainer,
  ResultImage,
  ResultNumberText,
  ResultTitle,
  StyledButton,
  StyledSkeleton,
  UppgiftText,
} from "./ResultSummaryOrg.styles";

const ResultSummaryOrg = () => {
  const mixpanel = useMixpanel();
  const { startAiTutorLogout } = useAiTutorStore();
  const { startMQReviewLogout } = useExercisesMQReviewStore();
  const { startExerciseMultiQuestionLogout } = useExercisesMQStore();
  const { startTimerLogout } = useTimerStore();
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();
  const params = useLocation();
  const [timePerQues, setTimePerQues] = useState();
  const [responseCollection, setresponseCollection] = useState();
  const [time, setTime] = useState(0);
  const navigate = useNavigate();
  const { user, token } = useSelector((state) => state.user);

  const currentSectionCategory = useExercisesSectionCategoriesStore();
  const categoryName = currentSectionCategory?.title;
  const isPremium = user?.isPremium;

  useEffect(() => {
    if (
      responseCollection &&
      responseCollection.question &&
      responseCollection.question.length > 0
    ) {
      setTime(
        responseCollection.question[responseCollection.question.length - 1]
          .timeleft
      );
    } else {
      setTime(0);
    }
  }, [responseCollection]);

  useEffect(() => {
    const URL = EndPoints.getQuizResult + params?.state?.quizId;
    let sumOfTimeSpent = 0;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    let attemptedQuestion = 0;

    instance2
      .get(URL, { headers })
      .then((response) => {
        response.data.question.map((item) => {
          sumOfTimeSpent = sumOfTimeSpent + item.spendTime;
          if (item.spendTime !== 0) {
            attemptedQuestion++;
          }
          return sumOfTimeSpent;
        });

        if (sumOfTimeSpent) {
          let timePerQuestion = sumOfTimeSpent / attemptedQuestion;
          setTimePerQues(timePerQuestion);
        } else {
          setTimePerQues(false);
        }
        startTimerLogout();
        setresponseCollection(response.data);
        mixpanel.endTest(categoryName, [], true, null);
        mixpanel.activityEnded("Exercise", categoryName);
      })
      .catch((error) => {
        console.error("Error getting quiz result:", error);
        SendSentryError("RESULT-SUMMARY", "Error getting quiz result", {
          details: "Failed to getting quiz result",
          error,
        });
        startErrorPopup();
        startErrorRedirectionTrue();
      });

    scrollTop();
    startAiTutorLogout();
    startMQReviewLogout();
    startExerciseMultiQuestionLogout();

    // return () => {
    //   clearInterval(timer);
    // };
  }, []);

  const handleCategoryNav = () => navigate("/category");

  return (
    <div>
      <ExerciseTopBar
        selectedIndex={responseCollection?.question?.length}
        totalQuestions={responseCollection?.question?.length}
        params={{ value: true }}
        status={false}
        time={time}
        nextPress={() => {}}
        onCloseTimer={() => {}}
        quiz={responseCollection?.question}
        timeLeft={(timer) => {}}
        callBackForTimer={(value) => {}}
        goBack={handleCategoryNav}
        resultSummary
      />
      <BackgroundContainer>
        <MainContainer>
          <BodyContainer>
            <ResultContainer>
              <ResultTitle>Resultat</ResultTitle>
              <ResultBody>
                <ResultBoxContainer>
                  <ResultBox>
                    <ResultBoxTextContainer>
                      {responseCollection?.totalQuestion &&
                      responseCollection?.correctAnswer != null ? (
                        <ResultNumberText>
                          {responseCollection &&
                            responseCollection.correctAnswer +
                              "/" +
                              responseCollection?.question?.length}
                        </ResultNumberText>
                      ) : (
                        <LoaderContainer>
                          <CustomLoader />
                        </LoaderContainer>
                      )}
                      <ResultBoxText>Dina poäng</ResultBoxText>
                    </ResultBoxTextContainer>
                  </ResultBox>
                  {params?.state?.time ? (
                    <ResultBox>
                      <ResultBoxTextContainer>
                        {responseCollection ? (
                          <ResultNumberText>
                            {percentageCalculation(
                              (responseCollection.correctAnswer /
                                responseCollection?.question?.length) *
                                100,
                              categoryName
                            )}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}
                        <ResultBoxText>Din normerade poäng</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>
                  ) : (
                    <ResultBox>
                      <ResultBoxTextContainer>
                        {timePerQues ? (
                          <ResultNumberText>
                            {dispSecondsAsMins(timePerQues?.toFixed(0))}{" "}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}

                        <ResultBoxText>Tid per fråga</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>
                  )}
                </ResultBoxContainer>

                {params?.state?.time && (
                  <ResultBoxContainer>
                    <ResultBox>
                      <ResultBoxTextContainer>
                        {timePerQues ? (
                          <ResultNumberText>
                            {dispSecondsAsMins(timePerQues?.toFixed(0))}{" "}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}

                        <ResultBoxText>Tid per fråga</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>

                    <ResultBox>
                      <ResultBoxTextContainer>
                        {responseCollection ? (
                          <ResultNumberText>
                            {dispSecondsAsMins(time)}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}

                        <ResultBoxText>Tid kvar</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>
                  </ResultBoxContainer>
                )}
              </ResultBody>
            </ResultContainer>
            <QuestionsContainer>
              <ResultTitle>Dina svar</ResultTitle>
              <ResultBody>
                {responseCollection ? (
                  responseCollection?.question?.map((item, index) => {
                    return (
                      <QuestionBox
                        key={index}
                        onClick={() => {
                          let questionIndex =
                            responseCollection.question.findIndex(
                              (element) => element._id === item._id
                            );
                          navigate("/question", {
                            state: {
                              questionIndex,
                              quiz: responseCollection,
                              quizId: params?.state?.quizId,
                              data: {
                                value: false,
                              },
                              time: params?.state?.time,
                            },
                          });
                        }}
                      >
                        <FormControlBox>
                          <FormControlLabel
                            control={
                              item.optionId === item.answer.option ? (
                                <StyledCorrectIcon />
                              ) : item.optionId === null ? (
                                <ResultImage
                                  src={UnAttemptedCheckBox}
                                  alt="resultImg"
                                />
                              ) : (
                                <StyledWrongIcon />
                              )
                            }
                          />
                          <UppgiftText>
                            {"Uppgift " +
                              `${index + 1}` +
                              " av " +
                              responseCollection?.question?.length}
                          </UppgiftText>
                        </FormControlBox>
                        <QuestionTimeContainer>
                          <UppgiftText>
                            {item?.spendTime
                              ? "Tid: " + dispSecondsAsMins(item?.spendTime)
                              : "Tid: 00:00"}
                          </UppgiftText>
                          <FormControlBox>
                            <RightArrowIcon />
                          </FormControlBox>
                        </QuestionTimeContainer>
                      </QuestionBox>
                    );
                  })
                ) : (
                  <div
                    style={{
                      maxWidth: "740px",
                      width: "100%",
                    }}
                    key={"loading-skeleton"}
                  >
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                  </div>
                )}
              </ResultBody>
            </QuestionsContainer>
            {!isPremium && <SecondaryIsPremiumCard isPremium={isPremium} />}
            <StyledButton onClick={handleCategoryNav}>
              <BannerButtonText>Klar</BannerButtonText>
            </StyledButton>
          </BodyContainer>
        </MainContainer>
      </BackgroundContainer>
    </div>
  );
};

export default ResultSummaryOrg;
