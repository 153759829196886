/** Libraries */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Material UI custom elements */
export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
}));

export const BodyContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: "1rem",
  [theme.breakpoints.down(1280)]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  maxWidth: "900px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const OptionsContainer = styled(Box)(({ theme }) => ({
  maxWidth: "500px",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: "1rem",
  marginLeft: "1rem",
  [theme.breakpoints.down(1280)]: {
    flexDirection: "column",
    maxWidth: "900px",
    width: "100%",
    marginLeft: "0rem",
    marginTop: "1rem",
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: "10px",
  marginBottom: "1rem",
}));

export const AnswerAndChatbotContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "1.5rem",
  marginBottom: "5rem",
}));
