/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import { selectItemById } from "../../redux/slices/exercise/exercisesSectionCategoriesSlice";

export const useExercisesSectionCategoriesStore = () => {
  const { sectionCategoryId } = useSelector(
    (state) => state.exercisesSectionCategories
  );

  return useSelector(selectItemById(sectionCategoryId));
};

export default useExercisesSectionCategoriesStore;
