import { Box, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import ArrowSalt from "../../../assets/Icons/ArrowSalt.svg";
import QuestionStatement from "../QuestionStatement/QuestionStatement";
import ExpansionDialog from "../../atom/ExpansionDialog/ExpansionDialog";
import { appColors } from "../../../utils/commonService";

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: 3,
      height: 5,
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: appColors.grey70,
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: appColors.grey60,
    },
  },
}));

const ExamTextView = ({ text, title, questionLength }) => {
  const [extendedView, setExtendView] = useState(false);
  const [showRuler, setShowRuler] = useState(false);
  const [position, setPosition] = useState({
    left: "85%",
    top: "37.5%",
    width: "75px",
    height: "450px",
    rotation: "0deg",
  });

  const handleShowRuler = () => {
    setShowRuler((prevState) => !prevState);
    setPosition({
      left: "85%",
      top: "37.5%",
      width: "75px",
      height: "450px",
      rotation: "0deg",
    });
  };

  const classes = useStyles();

  const closeExtended = () => {
    setExtendView(false);
  };

  return (
    <Box
      mt={5}
      paddingX={{ xs: 4, md: 10 }}
      paddingY={2}
      sx={{
        backgroundColor: appColors.whiteColor,
        width: "100%",
        height: 373,
        overflow: "auto",
        border: `1px solid ${appColors.grey10}`,
      }}
      className={classes.scrollbar}
      style={{ position: "relative" }}
    >
      <QuestionStatement
        numberOfQuestions={questionLength}
        title={title}
        description={text}
      />

      <img
        onClick={() => setExtendView(true)}
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          cursor: "pointer",
        }}
        src={ArrowSalt}
      />
      <ExpansionDialog
        open={extendedView}
        onClose={closeExtended}
        title={title}
        text={text}
        questionLength={questionLength}
        handleShowRuler={handleShowRuler}
        showRuler={showRuler}
      />
    </Box>
  );
};

export default ExamTextView;
