/** Libraries */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Material UI - Custom elements */
export const BannerButton = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "247px",
  height: "41px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  backgroundColor: theme.palette.appcolors.primaryColor,
  textTransform: "none",
  cursor: "pointer",
  "&:hover": {
    borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
    backgroundColor: theme.palette.appcolors.primaryColor,
    transition: "transform 0.3s ease",
    transform: "scale(1.03)",
  },
}));

export const BannerButtonText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.secondaryColor10,
  fontSize: "0.8rem",
  fontWeight: 500,
  lineHeight: "23.4px",
}));

export const BannerText = styled("p")(({ theme, isResult }) => ({
  cursor: "default",
  textAlign: "left",
  fontSize: "1rem",
  fontWeight: 600,
  lineHeight: "20px",
  letterSpacing: "0.05px",
  color: theme.palette.appcolors.black,
  maxWidth: "329px",
  [theme.breakpoints.down(700)]: {
    textAlign: isResult ? "center !important" : "inherit",
  },
}));

export const ResultPremiumBannerContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: "1",
  display: "flex",
  height: "140px",
  width: "100%",
  padding: "0rem 3rem",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "10px",
  background: `linear-gradient(160.98deg, ${theme.palette.appcolors.primaryColor18} 38.41%, ${theme.palette.appcolors.primaryColor5} 99.98%)`,
  overflow: "hidden",
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
    height: "220px",
    padding: "3rem 0rem",
  },
}));
