/** Libraries */
import { Navigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "../config/config";

/** Custom hooks */
import { useAuthStore } from "../hooks";

/** Data */
import influencersUTM from "../assets/Static/influencersUTM.json";

const StripeLayout = ({ children }) => {
  const { user } = useAuthStore();
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY);

  if (user) {
    if (
      window.screen.width < 600 &&
      influencersUTM.includes(user?.utm?.split("-")[0])
    ) {
      window.location.href = "https://www.hpappen.se/mobil";
      return <></>;
    } else {
      return <Elements stripe={stripePromise}>{children}</Elements>;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default StripeLayout;
