import GridLayout from "../../../../GridOrg/GridLayout";
import LeftBar from "../../../../LeftBarOrg/LeftBar";
import CategoryPagesFeedContent from "../../CategoryPages/CategoryPagesContentFeed/CategoryPagesContentFeed";

const CategoryPagesMain = () => {
  return (
    <GridLayout leftBar={<LeftBar />} middle={<CategoryPagesFeedContent />} />
  );
};

export default CategoryPagesMain;
