import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Label, Input } from "reactstrap";
import { Link } from "react-router-dom";

/** Assets */
import { ReactComponent as Logo } from "../../../assets/Icons/mobileLogo.svg";

/** Material UI - Custom elements */
export const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "2rem",
  maxWidth: "500px",
  minHeight: "700px",
  height: "100%",
  [theme.breakpoints.down(600)]: {
    justifyContent: "center",
    padding: "1rem",
    margin: "2rem 1rem",
  },
}));

export const PageTitle = styled("h1")(({ theme, signup, reset }) => ({
  fontWeight: signup ? 400 : 600,
  fontSize: signup ? "38px" : "28px",
  textAlign: signup ? "left" : "center",
  marginBottom: signup || reset ? "1rem" : "4rem",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1080)]: {
    marginBottom: "1rem",
    fontWeight: 400,
    fontSize: "32px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "24px",
  },
}));

export const PageSubtitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  marginBottom: "1rem",
  textAlign: "center",
  [theme.breakpoints.down(600)]: {
    fontSize: "14px",
  },
}));

export const ContainerTitleAndAlerts = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "2rem",
  [theme.breakpoints.up(1080)]: {
    display: "none",
  },
}));

export const LogoImage = styled(Logo)(({ theme }) => ({
  fill: theme.palette.appcolors.primaryColor,
  width: "240px",
  height: "86px",
  [theme.breakpoints.down(600)]: {
    width: "123px",
    height: "45px",
  },
}));

export const InputTitle = styled(Label)(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.appcolors.black,
  marginBottom: "0",
  [theme.breakpoints.down(1080)]: {
    fontSize: "15px",
  },
}));

export const CustomLabelField = styled(Input)(({ theme, auth }) => ({
  width: "100%",
  padding: "1rem",
  border: auth
    ? `1px solid ${theme.palette.appcolors.error6}`
    : `1px solid ${theme.palette.appcolors.grey4}`,
  backgroundColor: theme.palette.appcolors.secondaryColor2,
  color: theme.palette.appcolors.black,
  marginTop: ".3rem",
  borderRadius: "8px",
  marginBottom: "0.5rem",
  outline: "none",
  "&::placeholder": {
    color: theme.palette.appcolors.grey7,
  },
  "&:focus": {
    borderColor: theme.palette.appcolors.primaryColor,
  },
  [theme.breakpoints.down(600)]: {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
}));

export const FooterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontSize: "14px !important",
}));

export const FooterLink = styled(Link)(({ theme, reset }) => ({
  textDecoration: "none",
  color: theme.palette.appcolors.primaryColor,
  textDecorationLine: "underline",
  fontWeight: reset ? 600 : 400,
  fontSize: reset && "16px !important",
  marginTop: reset && "1rem",
}));

export const FooterText = styled("p")(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.appcolors.black,
  fontSize: "12px",
  whiteSpace: "pre-wrap",
}));

export const StyledButton = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "3rem",
  marginTop: "1rem",
  marginBottom: "1rem",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  backgroundColor: "transparent",
  borderRadius: "8px",
  cursor: "pointer",
}));

export const GoogleButton = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  padding: "1rem",
}));

export const AlertText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.error6,
  fontSize: "12px",
  marginTop: "-6px",
}));

export const EyeIconContainer = styled("span")(({ theme }) => ({
  position: "relative",
  maxWidth: "40px",
  top: -56,
  left: "85%",
  [theme.breakpoints.up(800)]: {
    left: "90%",
  },
  [theme.breakpoints.up(600)]: {
    left: "87%",
  },
  [theme.breakpoints.down(346)]: {
    left: "75%",
  },
}));
