import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const NogOptionsInfoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: 100,
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderRadius: "16px",
  background: theme.palette.appcolors.secondaryColor4,
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const NogOptionsInfoTitle = styled("p")(({ theme }) => ({
  marginLeft: "50px",
  fontSize: "16px",
  fontWeight: "bold",
  color: theme.palette.appcolors.grey,
  [theme.breakpoints.down(600)]: {
    marginLeft: "20px",
  },
  [theme.breakpoints.down(440)]: {
    marginLeft: "10px",
    fontSize: "13px",
  },
}));

export const NogOptionsInfo = () => {
  return (
    <NogOptionsInfoContainer>
      <NogOptionsInfoTitle>
        Tillräcklig information för lösningen erhålls
      </NogOptionsInfoTitle>
    </NogOptionsInfoContainer>
  );
};
