/** Libraries */
import { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUiStore } from "../../../hooks";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/** Atoms */
import { StyledCloseIconRight } from "../CustomIcons/StyledCloseIcon";
import { Transition } from "../SlideTransition/SlideTransition";

/** Custom components */
const MainContainer = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    zIndex: 9001,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "3rem",
    maxWidth: "538px !important",
    width: "100%",
    backgroundColor: theme.palette.appcolors.secondaryColor3,
    backgroundImage: "none",
    borderRadius: "10px",
    gap: "1rem",
    "& p": {
      textAlign: "center",
    },
    [theme.breakpoints.down(600)]: {
      padding: "2rem",
      gap: "0.5rem",
    },
  },
}));

const StyledTitle = styled("p")(({ theme }) => ({
  fontSize: "28px",
  color: theme.palette.appcolors.black,
  cursor: "default",
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
}));

const StyledDescription = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  cursor: "default",
  paddingBottom: "1rem",
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

const StyledButton = styled("div")(({ theme }) => ({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
  cursor: "pointer",
  borderRadius: "10px",
  backgroundColor: theme.palette.appcolors.primaryColor,
  "&:hover": {
    transform: "scale(1.03)",
    transition: "all 0.3s ease-in-out",
  },
  [theme.breakpoints.down(600)]: {
    width: "80%",
  },
}));

const StyledButtonText = styled("p")(({ theme }) => ({
  fontSize: "14px",
  color: theme.palette.appcolors.secondaryColor10,
}));

export const ErrorPopup = ({ title, description }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { startErrorPopup, startErrorRedirectionFalse } = useUiStore();
  const errorPopup = useSelector((state) => state.ui.errorPopup);
  const errorRedirection = useSelector((state) => state.ui.errorRedirection);

  useEffect(() => {
    if (!errorPopup) {
      if (errorRedirection) {
        navigate("/");
        startErrorRedirectionFalse();
      }
    }
  }, [errorRedirection, navigate, errorPopup]);

  return (
    <MainContainer
      open={errorPopup}
      onClose={() => startErrorPopup()}
      TransitionComponent={Transition}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
            zIndex: 9000,
          },
        },
      }}
    >
      <StyledCloseIconRight onClick={() => startErrorPopup()} />
      <StyledTitle>{title ? title : "Oj då! Ett fel inträffade"}</StyledTitle>
      <StyledDescription>
        {description
          ? description
          : "Försök igen om en stund eller kontakta oss om problemet kvarstår. Tack för ditt tålamod!"}
      </StyledDescription>
      <StyledButton onClick={() => startErrorPopup()}>
        <StyledButtonText>Stäng</StyledButtonText>
      </StyledButton>
    </MainContainer>
  );
};

ErrorPopup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
