/** Libraries */
import { styled } from "@mui/material/styles";
import { LinearProgress, Skeleton } from "@mui/material";

/** Material UI custom elements */
export const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100px",
  display: "flex",
  margin: "2px",
  alignItems: "flex-start",
  gap: "8px",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderBottom: `4px solid ${theme.palette.appcolors.grey4}`,
  background: theme.palette.appcolors.secondaryColor4,
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    transition: "all 0.3s ease",
    transform: "scale(1.01)",
    cursor: "pointer",
    border: `1px solid ${theme.palette.appcolors.primaryColor6}`,
    borderBottom: `4px solid ${theme.palette.appcolors.primaryColor6}`,
  },
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  height: "100px",
  display: "flex",
  margin: "2px",
  alignItems: "flex-start",
  gap: "8px",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderBottom: `4px solid ${theme.palette.appcolors.grey4}`,
  background: theme.palette.appcolors.secondaryColor4,
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    transition: "all 0.3s ease",
    transform: "scale(1.01)",
    cursor: "pointer",
    border: `1px solid ${theme.palette.appcolors.primaryColor6}`,
    borderBottom: `4px solid ${theme.palette.appcolors.primaryColor6}`,
  },
}));

export const PremiumImage = styled("div")(({ theme, isPremium }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "0",
  right: "0",
  marginTop: "-1px",
  marginRight: "-1px",
  visibility: isPremium ? "hidden" : "visible",
  borderRadius: "0 10px 0 10px",
  width: "70px",
  height: "21px",
  backgroundColor: theme.palette.appcolors.primaryColor,
}));

export const PremiumTitle = styled("p")(({ theme }) => ({
  fontSize: "11px",
  fontWeight: 500,
  textAlign: "center",
  color: theme.palette.appcolors.tertiaryColor10,
}));

export const ItemsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "5px",
}));

export const LectureImage = styled("img")(({ theme }) => ({
  height: "100%",
}));

export const SubItemsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "start",
  paddingRight: "10px",
}));

export const LectureTitleFont = styled("p")(({ theme }) => ({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  color: theme.palette.appcolors.primaryColor2,
  fontFamily: "Figtree",
  fontSize: "16px",
  fontWeight: 600,
  textAlign: "start",
  lineHeight: "20px",
  [theme.breakpoints.down(600)]: {
    fontSize: "11px",
  },
}));

export const ProgressBarContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "20%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "5px",
}));

export const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: "85%",
  height: "8px",
  borderRadius: "5rem",
  backgroundColor: theme.palette.appcolors.primaryColor10,
  "& .MuiLinearProgress-bar": {
    backgroundColor: theme.palette.appcolors.success4,
    borderRadius: "5rem",
  },
  [theme.breakpoints.down(600)]: {
    height: "7px",
  },
}));

export const ProgressPercentageFont = styled("p")(({ theme }) => ({
  width: "15%",
  color: theme.palette.appcolors.primaryColor,
  textAlign: "right",
  fontFamily: "Figtree",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "16.55px",
  flexWrap: "nowrap",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down(600)]: {
    fontSize: "10px",
  },
}));
