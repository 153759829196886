/** Libraries */
import { Box, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

/** Utils */
import { appColors } from "../../../../utils/commonService";

/** Material UI - Custom elements */
export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  marginBottom: "-16px",
  [theme.breakpoints.down(900)]: {
    marginBottom: 0,
  },
}));

export const NotificationContainer = styled(Box)(({ theme }) => ({
  width: "78px",
  height: "16px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "10px",
  [theme.breakpoints.down(900)]: {
    display: "none",
  },
}));

export const TextNotification = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    color: appColors.grey70,
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "30px",
    span: {
      fontWeight: 700,
    },
  },
}));

export const EnterCustomIcon = styled("img")(({ theme }) => ({
  width: "17px",
  height: "12px",
  flexShrink: 0,
  strokeWidth: "1.5px",
  stroke: appColors.grey70,
}));
