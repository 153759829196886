/** Libraries */
import React, { useState, useEffect } from "react";
import styled from "@mui/material/styles/styled";

/** Organisms */
import LeftBar from "../../organism/LeftBarOrg/LeftBar";

/** Atoms */
import PhoneMenuDisplay from "../../atom/PhoneMenuDisplay/PhoneMenuDisplay";
import MenuHamburgerIcon from "../../atom/HamburgerIcon/MenuHamburgerIcon";

const BackgroundContainer = styled("div")(({ theme, openDialog }) => ({
  zIndex: openDialog ? 1299 : -1, //when menu isn't open the background won't block normal website functionality like clicking on links.
  position: "absolute",
  top: 0,
  left: 0,
  opacity: openDialog ? 0.7 : 0.0,
  transition: "opacity 300ms, 600ms", //300ms to open, 600ms to close.
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.appcolors.popupOverlay,
}));

const SlidingLeftBarContainer = styled("div")(({ theme, openDialog }) => ({
  display: "none",
  position: "absolute",
  left: openDialog ? 0 : -620,
  transition: "left 300ms, 600ms",
  width: "100%",
  height: "100%",
  [theme.breakpoints.down(600)]: {
    display: "block",
  },
}));

const MobileMenu = () => {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (openDialog) {
      document.body.style.overflow = "hidden";
      return () => (document.body.style.overflow = "scroll");
    }
  });

  return (
    <>
      <SlidingLeftBarContainer openDialog={openDialog}>
        <LeftBar />
      </SlidingLeftBarContainer>
      <BackgroundContainer
        openDialog={openDialog}
        onClick={() => setOpenDialog(false)}
      />
      <PhoneMenuDisplay onClick={() => setOpenDialog(true)} sx={{ zIndex: 0 }}>
        {MenuHamburgerIcon()}
      </PhoneMenuDisplay>
    </>
  );
};

export default MobileMenu;
