/** Libraries */
import React, { useEffect, useRef } from "react";

/** Atoms */
import AppLoader from "../../../components/molecule/AppLoader";
import { StyledButton } from "../../../components/atom/Button/Button.styles";
import { PressEnterIcon } from "../../../components/atom/PressEnter/PressEnter";

/** Organisms */
import { LectureTopBar } from "../../../components/organism/TopBars/LectureTopBar/LectureTopBar";
import { SQDescription } from "../../../components/organism/Quiz/descriptions/SQDescription/SQDescription";
import { LectureStartupDialog } from "../../../components/organism/StartInfoPopup/StartInfoPopup";

/** Custom */
import { useLectureStore, useUiStore } from "../../../hooks";

/** Style */
import {
  MainContainer,
  BodyContainer,
  DescriptionContainer,
} from "./Lecture.style";

const LectureTheory = () => {
  const buttonRef = useRef(null);
  const sqRef = useRef(null);

  const { progressBackdrop } = useUiStore();
  const {
    isLoading,
    selectedIndex,
    currLecture,
    topBar,
    lectureName,
    startSettingLecture,
    startBackLectureQuestion,
    startNextLectureQuestion,
    startHandleCloseButton,
  } = useLectureStore();

  useEffect(() => {
    startSettingLecture();
  }, []);

  useEffect(() => {
    if (sqRef.current) {
      sqRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const handleKeypress = (e) => {
      if (e.keyCode === 13) {
        buttonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeypress);

    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  }, []);

  if (progressBackdrop || currLecture === null || isLoading)
    return <AppLoader isLoading={true} />;

  return (
    <MainContainer>
      <LectureStartupDialog />

      <LectureTopBar
        proptitle={lectureName}
        propback={startBackLectureQuestion}
        propforward={startNextLectureQuestion}
        propclose={startHandleCloseButton}
        propprogress={topBar.progress}
        isBackArrowDisabled={topBar.isBackArrowDisabled}
        isForwardArrowDisabled={topBar.isForwardArrowDisabled}
      />
      <BodyContainer>
        <DescriptionContainer>
          <SQDescription
            yScrollEnabled={true}
            ref={sqRef}
            questionDescription={currLecture?.content}
            sectionTitle={""}
            questionImage={currLecture?.content?.includes(
              "hp-appen.s3.eu-north-1.amazonaws.com"
            )}
          />
          <StyledButton
            lecture
            ref={buttonRef}
            onClick={startNextLectureQuestion}
          >
            {selectedIndex === 0 ? "Starta" : "Fortsätt"}
          </StyledButton>
          <PressEnterIcon />
        </DescriptionContainer>
      </BodyContainer>
    </MainContainer>
  );
};

export default LectureTheory;
