/** Libraries */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";

/** Molecules */
import AppLoader from "../../../molecule/AppLoader";

/** Organisms */
import LeftBar from "../../LeftBarOrg/LeftBar";
import CoursesFeedContent from "../CoursesFeedContent/CoursesFeedContent";

/** Templates */
import GridLayout from "../../GridOrg/GridLayout";

/** Custom hooks */
import {
  useExamStore,
  useLectureStore,
  useTimerStore,
  useUiStore,
} from "../../../../hooks";
import { useAllCustomExamStore } from "../../../../hooks/customExams/AllCustomExams/useAllCustomExamsStore";

const CoursesMain = () => {
  const {
    previousExams,
    allPreviousExams,
    provpassOrderBySeason,
    provHistoryData,
    provpassSeasons,
    startLoadMoreExams,
    startLoadLessExams,
    startSeachingExams,
    startLoadingExamsData,
  } = useExamStore();

  const { customizedExams } = useAllCustomExamStore();

  const { startExamDialog, sessionExamDialog } = useSelector(
    (state) => state.allCustomExams
  );

  const allCustomExams = [];

  const { examsProgressBackdrop } = useUiStore();
  const { startTimerLogout } = useTimerStore();

  const [isExamsListExpanded, setIsExamsListExpanded] = useState(false);
  const [customExams, setCustomExams] = useState([]);

  const latestExam = useMemo(() => {
    return allPreviousExams[0];
  }, [allPreviousExams]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    startTimerLogout();
    // startLoadingExamsData();

    customizedExams(signal).then((response) => {
      // Only update state if component is still mounted
      setCustomExams(response);
    });

    // Cleanup function
    return () => {
      controller.abort(); // Set flag to false when component unmounts
    };
  }, []);

  return (
    <>
      <AppLoader
        // #TODO fix the exam progress backdrop
        isLoading={examsProgressBackdrop}
        // isLoading={allPreviousExams.length === 0 || customExamSessionLoading}
      />
      <GridLayout
        leftBar={<LeftBar />}
        middle={
          <CoursesFeedContent
            previousExams={
              isExamsListExpanded ? allPreviousExams : previousExams
            }
            allCustomExams={customExams}
            data={provHistoryData}
            loadMore={() => setIsExamsListExpanded(true)}
            loadLess={() => setIsExamsListExpanded(false)}
            // loadMore={startLoadMoreExams}
            // loadLess={startLoadLessExams}
            seasons={provpassSeasons}
            provpassOrderBySeason={provpassOrderBySeason}
            searchExams={startSeachingExams}
            latestExam={latestExam}
          />
        }
      />
    </>
  );
};

export default CoursesMain;
