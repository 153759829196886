/** Libraries */
import { Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";

/** Atoms */
import MarkLatex from "../../../atom/Marklatex/MarkLatex";

export const RowOptionText = styled(MarkLatex)(({ theme }) => ({
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "30px",
  paddingLeft: "16px",
  flex: "1 0 0",
  maxWidth: "100%",
  "& img": {
    maxWidth: "100%",
  },
  "& p": {
    maxWidth: "100%",
  },
  "& .math": {
    overflowX: "auto",
    overflowY: "visible",
    scrollbarWidth: "thin",
  },
  [theme.breakpoints.down(900)]: {
    lineHeight: "20px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
}));

export const RowOptionItem = styled(Box)(({ theme, mq }) => ({
  // display: "flex",
  // alignItems: "center",
  // minHeight: "73px",
  // width: "100%",
  // borderRadius: "5px",
  // background: theme.palette.appcolors.secondaryColor4,
  // boxSizing: "border-box",
  // gap: "1rem",
  // paddingLeft: "1rem",
  // [theme.breakpoints.down("md")]: {
  //   strokeWidth: "1px",
  // },

  display: "flex",
  alignItems: "center",
  minHeight: "73px",
  width: "100%",
  borderRadius: "16px",
  paddingRight: "16px",
  background: mq ? "transparent" : theme.palette.appcolors.secondaryColor4,
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    strokeWidth: "1px",
    padding: "1rem 0rem",
  },
}));

export const RowTextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "0.75rem",
  width: "600px",
  justifyContent: "flex-start",
  alignItems: "center",
}));

export const RowRadiusButtonFirstContainer = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "space-between",
  marginTop: 0,
  marginLeft: "2rem",
  marginRight: "0.75rem",
  [theme.breakpoints.down("md")]: {
    marginRight: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.75rem",
    marginRight: "2rem",
  },
}));
