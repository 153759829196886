/** Libraries */
import React, { useEffect } from "react";

/** Molecules */
import PublicPremiumBanner from "../../../components/molecule/Banners/PublicPremiumBanner/PublicPremiumBanner";

/** Organisms */
import ExerciseTopBar from "../../../components/organism/TopBars/ExerciseTopBar/ExerciseTopBar";
import { MQDescription, MQQuestion } from "../../../components/organism/Quiz";
import MQQuestionReview from "../../../components/organism/Quiz/MQQuestion/MQQuestionReview/MQQuestionReview";

/** Templates */
import { MQTemplate } from "../../../components/template";

/** Custom hooks */
import { usePublicTestMQStore } from "../../../hooks/publicTest/MultiQuestions/usePublicTestMQStore";
import NoneAnsweredAlert from "../../../components/molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";

const PublicTestMQ = () => {
  const {
    quiz,
    currentQuestion,
    selectedIndex,
    selectedCurrMQIndex,
    totalQuestions,
    isAnswerVisible,
    areCurrMQSelected,
    isReviewSection,
    topBar,
    categoryTitle,
    startSettingSelectedAnswerPublicTestMQ,
    startSettingAnswerVisiblePublicTestMQ,
    startNextQuestionPublicTestMQ,
    startSettingPublicTestMQ,
    startNavigatingResultPageInReview,
    startClosingQuizPublicTestMQ,
    startSettingNextQuestionInReviewPublicTestMQ,
    startSettingPreviusQuestionInReviewPublicTestMQ,
    startShowingPublicTestMQ,
    startSettingSelectedCurrPublicTestMQIndex,
  } = usePublicTestMQStore();

  useEffect(() => {
    startSettingPublicTestMQ();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Your custom function to execute

      // Prompt the user with a confirmation dialog
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  if (currentQuestion === null) return null;

  return (
    <MQTemplate
      topBar={
        <ExerciseTopBar
          progress={!isReviewSection ? topBar.progress : 100}
          selectedIndex={
            topBar.currNumQuestion !== 0 ? topBar.currNumQuestion : 1
          }
          totalQuestions={totalQuestions}
          goBack={startClosingQuizPublicTestMQ}
          isReviewSection={isReviewSection}
        />
      }
      banner={
        <PublicPremiumBanner
          buttonText="Kom igång"
          text="Gör över 11 000 övningar och höj dina poäng på högskoleprovet"
          isResult={false}
        />
      }
      nonAnsweredAlert={
        currentQuestion?.question[0].optionId === null &&
        isReviewSection && <NoneAnsweredAlert />
      }
      dialogBox={
        <MQDescription
          description={currentQuestion?.description}
          sectionTitle={categoryTitle}
          questionTitle={currentQuestion?.title}
          currNumberOfQuestions={currentQuestion?.question.length}
        />
      }
      questions={
        <>
          {!isAnswerVisible ? (
            <MQQuestion
              questions={currentQuestion?.question}
              sectionCategory={categoryTitle}
              selectedCurrMQIndex={selectedCurrMQIndex}
              handleSelectOption={startSettingSelectedAnswerPublicTestMQ}
              handlePagination={startSettingSelectedCurrPublicTestMQIndex}
              handleSubmitQuestions={startSettingAnswerVisiblePublicTestMQ}
              enableSubmitButton={areCurrMQSelected}
              isAnswerVisible={isAnswerVisible}
            />
          ) : (
            <MQQuestionReview
              isResultPage={isReviewSection}
              sectionTitle={categoryTitle}
              questions={currentQuestion?.question}
              selectedIndex={selectedIndex}
              totalQuestions={quiz.length}
              handleResultButton={startNavigatingResultPageInReview}
              handleLeftButton={startSettingPreviusQuestionInReviewPublicTestMQ}
              handleRightButton={startSettingNextQuestionInReviewPublicTestMQ}
              handleShowAnswer={startShowingPublicTestMQ}
              handleOnClickNasta={startNextQuestionPublicTestMQ}
            />
          )}
        </>
      }
    />
  );
};

export default PublicTestMQ;
