import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledAnswerText = styled(Box)(
  ({ theme, amazonpic, oplength, emptyimage }) => ({
    alignItems: "center",
    display: !amazonpic && "flex",
    marginLeft: oplength || emptyimage ? "1rem" : "0",
    maxWidth: amazonpic ? "100%" : "700px",
    overflowX: "clip",
    justifyContent: oplength || !amazonpic ? "flex-start" : "center",
  })
);
