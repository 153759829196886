/** Libraries */
import React from "react";

/** Organisms */
import MultiQuestionSummary from "../../organism/HomeOrg/HomePages/QuestionPages/ResultSummaryOrg/MultiQuestionSummary";
import QuestionViewDTKOrg from "../../organism/HomeOrg/HomePages/QuestionPages/QuestionViewDtkOrg/QuestionViewDtkOrg";
import SingleQuestionViewOrg from "../../organism/HomeOrg/HomePages/QuestionPages/SingleQuestionViewOrg/SingleQuestionViewOrg";

/** Styles */
import "../../../styles/QuestionBody.css";

const QuestionBody = (props) => {
  const updateQuiz = (value) => {
    let quiz = [...props.quiz];
    const index = quiz.findIndex((obj) => obj._id === value._id);
    quiz.splice(index, 1, value);
    props.updateQuiz(quiz);
  };

  if (props.question.type === "multiple") {
    return (
      <QuestionViewDTKOrg
        isTimeRestricted={props.isTimeRestricted}
        question={props.question}
        selectedOption={props.selectedOption}
        totalQuestions={props.totalQuestions}
        selectedIndex={props.selectedIndex}
        onRightClick={() => props.onRightClick()}
        onLeftClick={() => props.onLeftClick()}
        onResultHandler={() => props.onResultHandler()}
        callBackForTimer={(value) => props.setTimeLeft(value)}
        paragraphIndex={props?.paragraphIndex}
        questionIndex={props.questionIndex}
        timeLeft={props.timeLeft}
        quizId={props.quizId}
        totalTime={props.totalTime}
        quiz={props.quiz}
        updateQuiz={(value) => updateQuiz(value)}
        changeIndex={() => props.changeIndex()}
        sectionCategory={props?.sectionCategory}
        nextQuestion={() => props.nextQuestion()}
        stopTimer={() => props.stopTime()}
        startTimer={() => props.startTime()}
        previosQuestion={() => props.previosQuestion()}
        PopupTimeEnd={props.PopupTimeEnd}
        updateCompleteQuiz={(quiz) => props.updateCompleteQuiz(quiz)}
        setCurrentQuestion={props.setCurrentQuestion}
      />
    );
  } else if (props.question.multipartQuestion) {
    return (
      <MultiQuestionSummary
        question={props?.question}
        totalQuestions={props.totalQuestions}
        selectedIndex={props.selectedIndex}
        onRightClick={() => props.onRightClick()}
        questionIndex={props.questionIndex}
        quiz={props.quiz}
        onResultHandler={() => props.onResultHandler()}
        onLeftClick={() => props.onLeftClick()}
      />
    );
  } else {
    return (
      <SingleQuestionViewOrg selectedIndex={props.selectedIndex} {...props} />
    );
  }
};

export default QuestionBody;
