/** Custom hooks */
import { useNavigateUTM } from "../../../../hooks";

/** Styles */
import {
  BannerButton,
  BannerButtonText,
  BannerTextPremium,
  ResultPremiumWaveContainer,
  MaxWidthContainer,
} from "./PublicPremiumBanner.styles";

function PublicPremiumBanner({ text, buttonText }) {
  const navigate = useNavigateUTM();
  return (
    <ResultPremiumWaveContainer>
      <MaxWidthContainer>
        <BannerTextPremium>{text}</BannerTextPremium>
        <BannerButton onClick={() => navigate("/")}>
          <BannerButtonText>{buttonText}</BannerButtonText>
        </BannerButton>
      </MaxWidthContainer>
    </ResultPremiumWaveContainer>
  );
}

export default PublicPremiumBanner;
