/** Libraries */
import { useEffect, useState } from "react";
import { FormControlLabel } from "@material-ui/core";
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

/** Organisms */
import ExerciseTopBar from "../../TopBars/ExerciseTopBar/ExerciseTopBar";

/** Molecules */
import PublicResultPremiumBanner from "../../../../components/molecule/Banners/ResultPremiumBanner/ResultPremiumBanner";

/** Atoms */
import { RightArrowIcon } from "../../../atom/CustomIcons/ArrowIcons";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
} from "../../../atom/RadioIcons/RadioIcons";

/** Utils */
import { percentageCalculation } from "../../../../utils/Utils";
import { dispSecondsAsMins } from "../../../../utils/commonService";

/** Assets */
import UnAttemptedCheckBox from "../../../../assets/Imgs/UnAttemptedCircle.png";

/** Styles */
import {
  BackgroundContainer,
  BannerButtonText,
  BodyContainer,
  CustomLoader,
  FormControlBox,
  LoaderContainer,
  MainContainer,
  QuestionBox,
  QuestionsContainer,
  QuestionTimeContainer,
  ResultBody,
  ResultBox,
  ResultBoxContainer,
  ResultBoxText,
  ResultBoxTextContainer,
  ResultContainer,
  ResultImage,
  ResultNumberText,
  ResultTitle,
  StyledButton,
  StyledSkeleton,
  UppgiftText,
} from "../../../organism/HomeOrg/HomePages/QuestionPages/ResultSummaryOrg/ResultSummaryOrg.styles";

const bannerText =
  "Gör över 11 000 övningar och höj dina poäng på högskoleprovet";

const PublicResultSummary = () => {
  const [paramsUTM] = useSearchParams();
  const utm_source = paramsUTM.get("utm_source");
  const utm_medium = paramsUTM.get("utm_medium");
  const utm_campaign = paramsUTM.get("utm_campaign");
  const utm_url = utm_source
    ? `?utm_campaign=${utm_campaign}&utm_medium=${utm_medium}&utm_source=${utm_source}`
    : "";

  const params = useLocation();
  const [responseCollection, setResponseCollection] = useState();
  const [time, setTime] = useState(0);
  const [timePerQues, setTimePerQues] = useState();
  const routeParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("params: ", params);
    if (
      responseCollection &&
      responseCollection.question &&
      responseCollection.question.length > 0
    ) {
      setTime(
        responseCollection.question[responseCollection.question.length - 1]
          .timeleft
      );
    } else {
      setTime(0);
    }
  }, [responseCollection]);

  useEffect(() => {
    if (params.state.answerSubmittedState) {
      let sumOfTimeSpent = 0;
      let attemptedQuestion = 0;
      params.state.answerSubmittedState.question?.map((item) => {
        sumOfTimeSpent = sumOfTimeSpent + item.spendtime;
        if (item.spendTime !== 0) {
          attemptedQuestion++;
        }
        return sumOfTimeSpent;
      });

      const correctAnswer =
        params.state?.answerSubmittedState?.question?.reduce((acc, item) => {
          if (item.optionId === item.answer.option) {
            return acc + 1;
          }
          return acc;
        }, 0);

      if (sumOfTimeSpent) {
        let timePerQuestion = sumOfTimeSpent / attemptedQuestion;
        setTimePerQues(timePerQuestion);
      } else {
        setTimePerQues(false);
      }
      setResponseCollection({
        ...params.state?.answerSubmittedState,
        correctAnswer,
        totalQuestion: params.state?.answerSubmittedState?.question?.length,
      });
    }
  }, []);

  const navigationHandler = () => {
    navigate(`/${utm_url}`);
  };

  return (
    <div>
      <ExerciseTopBar
        selectedIndex={responseCollection?.question?.length}
        totalQuestions={responseCollection?.question?.length}
        params={{ value: true }}
        status={false}
        time={time}
        nextPress={() => {}}
        onCloseTimer={() => {}}
        quiz={responseCollection?.question}
        timeLeft={(timer) => {}}
        callBackForTimer={(value) => {}}
        goBack={navigationHandler}
        sectionCategoryTitle={params?.state?.sectionCategory}
        resultSummary
      />
      <BackgroundContainer>
        <MainContainer>
          <BodyContainer>
            <ResultContainer>
              <ResultTitle>Resultat</ResultTitle>
              <ResultBody>
                <ResultBoxContainer>
                  <ResultBox>
                    <ResultBoxTextContainer>
                      {responseCollection?.totalQuestion &&
                      responseCollection?.correctAnswer != null ? (
                        <ResultNumberText>
                          {responseCollection &&
                            responseCollection.correctAnswer +
                              "/" +
                              responseCollection?.question?.length}
                        </ResultNumberText>
                      ) : (
                        <LoaderContainer>
                          <CustomLoader />
                        </LoaderContainer>
                      )}
                      <ResultBoxText>Dina poäng</ResultBoxText>
                    </ResultBoxTextContainer>
                  </ResultBox>
                  {params?.state?.time ? (
                    <ResultBox>
                      <ResultBoxTextContainer>
                        {responseCollection ? (
                          <ResultNumberText>
                            {percentageCalculation(
                              (responseCollection.correctAnswer /
                                responseCollection?.question?.length) *
                                100,
                              params?.state?.sectionCategory
                            )}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}
                        <ResultBoxText>Din normerade poäng</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>
                  ) : (
                    <ResultBox>
                      <ResultBoxTextContainer>
                        {timePerQues ? (
                          <ResultNumberText>
                            {dispSecondsAsMins(timePerQues?.toFixed(0))}{" "}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}

                        <ResultBoxText>Tid per fråga</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>
                  )}
                </ResultBoxContainer>
                {params?.state?.time && (
                  <ResultBoxContainer>
                    <ResultBox>
                      <ResultBoxTextContainer>
                        {timePerQues ? (
                          <ResultNumberText>
                            {dispSecondsAsMins(timePerQues?.toFixed(0))}{" "}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}

                        <ResultBoxText>Tid per fråga</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>

                    <ResultBox>
                      <ResultBoxTextContainer>
                        {responseCollection ? (
                          <ResultNumberText>
                            {dispSecondsAsMins(time)}
                          </ResultNumberText>
                        ) : (
                          <LoaderContainer>
                            <CustomLoader />
                          </LoaderContainer>
                        )}

                        <ResultBoxText>Tid kvar</ResultBoxText>
                      </ResultBoxTextContainer>
                    </ResultBox>
                  </ResultBoxContainer>
                )}
              </ResultBody>
            </ResultContainer>

            <QuestionsContainer>
              <ResultTitle>Dina svar</ResultTitle>
              <ResultBody>
                {responseCollection ? (
                  responseCollection?.question?.map((item, index) => {
                    return (
                      <QuestionBox
                        key={index}
                        onClick={() => {
                          let questionIndex =
                            responseCollection.question.findIndex(
                              (element) => element._id === item._id
                            );
                          navigate(
                            `/public/${params?.state?.sectionCategory}/${routeParams?.index}${utm_url}`,
                            {
                              state: {
                                isReviewSection: true,
                                time: params?.state?.time,
                                questionIndex,
                                quiz: params?.state?.quizMQ
                                  ? params?.state?.quizMQ
                                  : responseCollection,
                                answerSubmittedState: params?.state?.quizMQ
                                  ? params?.state?.answerSubmittedState
                                  : null,
                                sectionCategory: params?.state?.sectionCategory,
                                data: {
                                  value: false,
                                  sectionCategory:
                                    params?.state?.sectionCategory,
                                },
                              },
                            }
                          );
                        }}
                      >
                        <FormControlBox>
                          <FormControlLabel
                            control={
                              item.optionId === item.answer.option ? (
                                <StyledCorrectIcon />
                              ) : item.optionId === null ? (
                                <ResultImage
                                  src={UnAttemptedCheckBox}
                                  alt="resultImg"
                                />
                              ) : (
                                <StyledWrongIcon />
                              )
                            }
                          />
                          <UppgiftText>
                            {"Uppgift " +
                              `${index + 1}` +
                              " av " +
                              responseCollection.question.length}
                          </UppgiftText>
                        </FormControlBox>
                        <QuestionTimeContainer>
                          <UppgiftText>
                            {item?.spendtime
                              ? "Tid: " + dispSecondsAsMins(item?.spendtime)
                              : "Tid: 00:00"}
                          </UppgiftText>
                          <FormControlBox>
                            <RightArrowIcon />
                          </FormControlBox>
                        </QuestionTimeContainer>
                      </QuestionBox>
                    );
                  })
                ) : (
                  <div key={"loading-skeleton"}>
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                    <StyledSkeleton animation="wave" />
                  </div>
                )}
              </ResultBody>
            </QuestionsContainer>
            <PublicResultPremiumBanner
              text={bannerText}
              buttonText="Kom igång"
              onClick={navigationHandler}
              isResult={true}
            />
            <StyledButton onClick={navigationHandler}>
              <BannerButtonText>Klar</BannerButtonText>
            </StyledButton>
          </BodyContainer>
        </MainContainer>
      </BackgroundContainer>
    </div>
  );
};

export default PublicResultSummary;
