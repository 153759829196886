import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { graphMetrics } from "../../../utils/Utils";

const LinesChart = (props) => {
  // const progressOfUserAllCategories = []
  const [graphData, setGrapgData] = useState([]);

  const theme = useTheme();
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: theme.palette.appcolors.secondaryColor4,
            border: `1px solid ${theme.palette.appcolors.grey4}`,
            borderRadius: "5px",
            padding: ".65rem .75rem",
          }}
        >
          <p>{`Prognos: ${payload[0].value}`}</p>
        </div>
      );
    }

    // useEffect(()=> {
    //   if(!props.showProgress) {
    //     const weekNames = getWeekNumbers().reverse()
    //     weekNames.forEach((weekName) => {
    //       progressOfUserAllCategories.push({ name: weekName, Prognos: null })
    //     })
    //   }

    // }, [props.showProgress])

    return null;
  };

  useEffect(() => {
    if (props?.weekWiseProgressGraph?.length > 0) {
      const graphDataWeekNameUpdate = props?.weekWiseProgressGraph?.map(
        (weeklyCoreectedGraphData) => {
          const weekNumberDevide = weeklyCoreectedGraphData?.name?.split(".");
          return {
            ...weeklyCoreectedGraphData,
            name: weekNumberDevide[0] + "." + weekNumberDevide[2],
          };
        }
      );

      setGrapgData(graphDataWeekNameUpdate);
    } else if (graphData.length == 0) {
      setGrapgData(graphMetrics);
    }
  }, [props?.weekWiseProgressGraph]);

  const CustomXAxisTick = (props) => {
    const { x, y, payload, index } = props;
    const isLastTick = index === graphData.length - 1;

    if (isLastTick) {
      return (
        <>
          <rect
            x={x - 17}
            y={y - 3}
            width={33}
            height={33}
            rx={100}
            ry={100}
            fill={theme.palette.appcolors.black2}
          />
          <text
            x={x}
            y={y}
            dy={16}
            textAnchor="middle"
            fill={theme.palette.appcolors.tertiaryColor12}
          >
            {payload.value}
          </text>
        </>
      );
    }
    return (
      <>
        <text
          x={x}
          y={y}
          dy={16}
          textAnchor="middle"
          fill={theme.palette.appcolors.black}
        >
          {payload.value}
        </text>
      </>
    );
  };

  const CustomYAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-5}
          y={-13}
          dy={16}
          textAnchor="end"
          fill={theme.palette.appcolors.black}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" minHeight="300px">
      <LineChart
        style={{
          fontSize: "0.56rem",
          fontFamily: "Figtree",
        }}
        data={graphData}
        syncId="anyId"
        margin={{
          top: 10,
          right: 20,
          left: -20,
          bottom: 20,
        }}
      >
        <CartesianGrid vertical="" horizontal="true" />
        <XAxis
          dataKey="name"
          dy={10}
          tickLine={false}
          tick={<CustomXAxisTick />}
          axisLine={{ stroke: theme.palette.appcolors.grey6, strokeWidth: 2 }}
        />
        <YAxis
          ticks={["0", "0.4", "0.8", "1.2", "1.6", "2.0"]}
          type="number"
          dx={-10}
          domain={[0, 2]}
          allowDecimals={true}
          tickLine={false}
          axisLine={false}
          tick={<CustomYAxisTick />}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Line
          type="monotone"
          dataKey="Prognos"
          stroke={theme.palette.appcolors.grey}
          strokeWidth="2"
          isAnimationActive={false}
          activeDot={{
            fill: theme.palette.appcolors.grey,
            stroke: theme.palette.appcolors.grey,
            strokeWidth: 1,
            r: 4.5,
          }}
          align="center"
          dot={{
            fill: theme.palette.appcolors.grey,
            stroke: theme.palette.appcolors.grey,
            strokeWidth: 1,
            r: 4.5,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LinesChart;
