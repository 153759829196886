/** Libraries */
import React, { useMemo, useState } from "react";
import { Typography, DialogContent, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

/** Components */
import { EndPoints, instance2 } from "../../service/Route";

/** Molecules */
import MainCard from "../MainCard/MainCard";

/** Organism */
import TestInformation from "../../organism/CoursesOrg/CoursePages/TestInformation/TestInformation";
import ProvPassInformation from "../../organism/CoursesOrg/CoursePages/ProvPassInformation/ProvPassInformation";

/** Organism */
import useWindowDimensions from "../WindowDimensions/dimension";

/** Atoms */
import {
  WhiteBox,
  MainCardContainer,
} from "../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { Transition } from "../../atom/SlideTransition/SlideTransition";

/** Custom hooks */
import { useMixpanel } from "../../../hooks";

/** Tools */
import { SendSentryError } from "../../../tools";

/** Utils */
import { setDisabledTimer, setExtendTimer } from "../../../redux/slices";

/** Material UI - Custom elements */
import {
  CardTitleText,
  CombinedCardComponents,
  CustomDialog,
  CustomDialogContentText,
  CustomDialogTitle,
  CustomProgressInnerStack,
  CustomProgressStack,
  DialogButton,
  DialogButtonChoiceBox,
  PercentageText,
  StartExamDialog,
  StyledBannerBackgroundContainer,
  StyledChip,
} from "./CoursesCard.styles";

const CoursesCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { extendTimer, disabledTimer } = useSelector(
    (state) => state.timeCorrection
  );
  const mixpanel = useMixpanel();

  const { isTimeExtended } = useSelector((state) => state.timer);
  const isPremium =
    user?.areVerbalFeaturesEnabled && user?.areKvantiativFeaturesEnabled;
  const [openDialog, setOpenDialog] = useState(false);
  const [openDualChoiceDialog, setOpenDualChoiceDialog] = useState(false);
  const [openStartQuizDialog, setOpenStartQuizDialog] = useState(false);
  const [openStartProvpassDialog, setOpenStartProvpassDialog] = useState(false);
  const [provpass, setProvpass] = useState();
  const isFirstQuiz = useMemo(() => props.latestExam, [props.latestExam]);
  const { width } = useWindowDimensions();
  const theme = useTheme();

  /** Buttons */
  const [disableRestarQuizButton, setDisableRestarQuizButton] = useState(false);

  const percentage = () => {
    switch (props?.quizzes?.simuleraQuizResult?.length) {
      case 1:
        return 25;
      case 2:
        return 50;
      case 3:
        return 75;
      case 4:
        return 100;
      default:
        return 0;
    }
  };

  const examText = props?.item?.title;
  const examName = examText.substring(0, examText.length - 4);
  const examDate = examText.substring(examText.length - 4);

  const restartQuiz = () => {
    setDisableRestarQuizButton(true);
    const URL = EndPoints.createNewResultForSeason;
    const data = {
      simuleraSeason: props.item._id,
      user: user._id,
    };
    if (isFirstQuiz || isPremium) {
      instance2
        .post(URL, data)
        .then((response) => {
          console.log("response14", response);
          setProvpass(response.data.simuleraSeasonResult);
          setOpenStartQuizDialog(true);
          setOpenDialog(false);
        })
        .catch((error) => {
          console.error("Error restarting quiz:", error);
          SendSentryError("QUIZ", "Error restarting quiz", {
            details: "Failed to restart quiz",
            error,
          });
        })
        .finally(() => setDisableRestarQuizButton(false));
    } else {
      setOpenDualChoiceDialog(false);
      props?.popup(true);
      setDisableRestarQuizButton(false);
    }
  };

  const startQuiz = () => {
    if (isFirstQuiz || isPremium) {
      if (
        props?.quizzes?.simuleraQuizResult.length < 4 ||
        !props?.quizzes?.simuleraQuizResult
      ) {
        if (
          props?.quizzes?.simuleraQuizResult.length >= 1 &&
          !props?.quizzes?.simuleraQuizResult.length < 4
        ) {
          setOpenStartProvpassDialog(true);
        } else {
          setOpenStartQuizDialog(true);
        }
      } else {
        navigate("/provresultat", {
          state: {
            seasonId: props?.quizzes?.simuleraSeason?._id,
            simuleraQuizResultId: props?.quizzes?._id,
            quizId: props?.quizzes?._id,
            provpassOrder: props?.provpassOrder,
          },
        });
      }
    } else {
      setOpenDualChoiceDialog(false);
      props?.popup(true);
    }
  };

  const handleClosePopup = () => {
    setOpenStartProvpassDialog(false);
    setOpenDualChoiceDialog(false);
    setOpenStartQuizDialog(false);
  };

  return (
    <>
      <StartExamDialog
        open={openStartQuizDialog}
        onClose={() => setOpenStartQuizDialog(false)}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            maxWidth: "862px !important",
            maxHeight: "837px",
            margin: width < 600 ? "0px" : "32px",
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.appcolors.secondaryColor2,
          },
        }}
      >
        <StyledCloseIconRight onClick={() => setOpenStartQuizDialog(false)} />
        <TestInformation
          id={props?.id}
          session={props?.item}
          provpass={provpass ? provpass : props?.quizzes}
          provpassOrder={props?.provpassOrder}
          isTimeExtended={isTimeExtended}
          closepopup={() => handleClosePopup()}
        />
      </StartExamDialog>

      <StartExamDialog
        open={openStartProvpassDialog}
        onClose={() => setOpenStartProvpassDialog(false)}
        TransitionComponent={Transition}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "5px",
            minWidth: width < 900 ? "0px" : "862px",
            maxHeight: "837px",
            margin: width < 600 ? "0px" : "32px",
            width: "100%",
            height: "100%",
          },
        }}
      >
        <StyledCloseIconRight
          onClick={() => setOpenStartProvpassDialog(false)}
        />
        <ProvPassInformation
          id={props?.id}
          session={props?.item}
          provpass={provpass ? provpass : props?.quizzes}
          provpassOrder={props?.provpassOrder}
          provpassTime={
            props?.quizzes?.simuleraQuizResult[0]?.attempts[0]?.totaltime !==
            3300
          }
          isTimeDisabled={props?.quizzes?.simuleraQuizResult[0]?.isTimeDisabled}
          closepopup={() => handleClosePopup()}
        />
      </StartExamDialog>

      <CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        TransitionComponent={Transition}
        PaperProps={{ sx: { borderRadius: "5px" } }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
      >
        <WhiteBox
          sx={{
            borderRadius: "5px",
            minHeight: "260px",
            padding: "30px",
          }}
        >
          <StyledCloseIconRight onClick={() => setOpenDialog(false)} />
          <CustomDialogTitle>Vill du starta om provet?</CustomDialogTitle>
          <DialogContent>
            <CustomDialogContentText>
              Provet kommer att återställas så du kan göra om provet. Ditt
              tidigare provresultat hittar du i <b>historiken.</b>
            </CustomDialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogButton
              disabled={disableRestarQuizButton}
              onClick={() => restartQuiz()}
            >
              Starta prov
            </DialogButton>
          </DialogActions>
        </WhiteBox>
      </CustomDialog>

      <CustomDialog
        open={openDualChoiceDialog}
        onClose={() => setOpenDualChoiceDialog(false)}
        TransitionComponent={Transition}
        PaperProps={{ sx: { borderRadius: "5px" } }}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: theme.palette.appcolors.popupOverlay,
              backdropFilter: "blur(2px)",
            },
          },
        }}
      >
        <WhiteBox
          sx={{
            borderRadius: "5px",
            minHeight: "260px",
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <StyledCloseIconRight
            onClick={() => setOpenDualChoiceDialog(false)}
          />
          <CustomDialogTitle>
            Vill du fortsätta eller starta om provet?
          </CustomDialogTitle>

          <CustomDialogContentText>
            Välj om du vill <b>fortsätta</b> med nästa provpass eller återställa
            provet och <b>börja om.</b>
          </CustomDialogContentText>

          <DialogButtonChoiceBox>
            <DialogButton
              sx={{
                backgroundColor: `transparent !important`,
                color: `${theme.palette.appcolors.primaryColor} !important`,
                border: `2px solid ${theme.palette.appcolors.primaryColor} !important`,
                marginRight: "1rem !important",
              }}
              onClick={() => restartQuiz()}
            >
              Börja om
            </DialogButton>
            <DialogButton
              onClick={() => {
                if (extendTimer) {
                  dispatch(setExtendTimer());
                }

                if (disabledTimer) {
                  dispatch(setDisabledTimer());
                }
                startQuiz();
              }}
            >
              Nästa provpass
            </DialogButton>
          </DialogButtonChoiceBox>
        </WhiteBox>
      </CustomDialog>

      <MainCard
        /* If quiz is in progress show dual dialog, else start the quiz again */
        onClick={() =>
          props.quizzes
            ? percentage() > 0 && percentage() < 100
              ? setOpenDualChoiceDialog(true)
              : setOpenDialog(true)
            : startQuiz()
        }
        data-testid="exam-card"
        isPremiumSticker={!(isFirstQuiz || isPremium)}
        text={
          <>
            {examName}
            <br />
            {examDate}
          </>
        }
        cardSubtitleText={
          <>
            Testa dig på högskoleprovet från&nbsp;
            {props?.item?.month.toLowerCase()}&nbsp;{props?.item?.year}
          </>
        }
        styledBannerBackground={
          <StyledBannerBackgroundContainer>
            {props?.item?.month}
          </StyledBannerBackgroundContainer>
        }
        prognos={
          props.quizzes !== undefined &&
          props?.quizzes?.simuleraQuizResult.length > 3 ? (
            <Typography
              data-testid="poäng"
              variant="h4"
              component="h4"
              sx={{ paddingBottom: "0.5rem" }}
              style={{ fontSize: "38px" }}
            >
              {props?.progress
                ? (props?.progress?.normering).toFixed(1)
                : "0.0"}
            </Typography>
          ) : (
            <Typography
              style={{
                fontSize: "3rem",
                color: theme.palette.appcolors.black,
              }}
            >
              -
            </Typography>
          )
        }
        prognosText="Poäng"
      >
        <CustomProgressStack direction="row" alignItems="center">
          <CustomProgressInnerStack>
            {" "}
            {props?.provpassOrder
              ?.map((year) => year.split("-")[2].replace(/[^0-9]/g, ""))
              .map((item, index) => {
                return (
                  <StyledChip
                    key={item}
                    props={props}
                    index={index}
                    label={"Pass " + item}
                    size="small"
                  />
                );
              })}
          </CustomProgressInnerStack>
          <PercentageText>{percentage()}%</PercentageText>
        </CustomProgressStack>
      </MainCard>
    </>
  );
};

export default CoursesCard;
