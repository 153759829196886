/** Libraries */
import React from "react";
import { Box } from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import { styled, useTheme } from "@mui/material/styles";

/** Material UI - Custom elements */
const CheckboxContainer = styled(Box)(({ theme }) => ({
  width: "fit-content",
  backgroundColor: "transparent",
  fontFamily: "Figtree",
  display: "flex",
  color: theme.palette.appcolors.black,
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  [theme.breakpoints.down(600)]: {
    fontSize: "0.8rem",
  },
}));

const HoverCheckbox = styled(Checkbox)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const Icon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: `1px solid ${theme.palette.appcolors.primaryColor11}`,
}));

const CheckedIcon = styled("span")(({ theme }) => {
  const fillColor = encodeURIComponent(theme.palette.appcolors.secondaryColor4);

  return {
    borderRadius: 3,
    backgroundColor: theme.palette.appcolors.primaryColor11,
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        `url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath` +
        ` fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ` +
        `1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='${fillColor}'/%3E%3C/svg%3E")`,
      content: '""',
    },
  };
});

const Outline_Field = (props) => {
  const theme = useTheme();

  function StyledCheckbox(props) {
    return (
      <HoverCheckbox
        disableRipple
        color="default"
        checkedIcon={<CheckedIcon />}
        icon={<Icon />}
        inputProps={{ "aria-label": "decorative checkbox" }}
        {...props}
      />
    );
  }

  return (
    <div onClick={(e) => props.onClickCheck(e)}>
      <CheckboxContainer>
        <StyledCheckbox
          checked={props.checked}
          size="medium"
          style={{
            margin: "0rem",
            color: theme.palette.appcolors.primaryColor11,
          }}
          m={0}
        />
        {props.title}
      </CheckboxContainer>
    </div>
  );
};

export default Outline_Field;
