/** Libraries */
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@material-ui/core";
import { IconButton, LinearProgress } from "@mui/material";

/** Atoms */
import { InstructionsTitle } from "../../../atom/SharedInfoStyledComponents/SharedInfoStyledComponents";
import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../atom/CustomIcons/ArrowIcons";
import { StyledCloseIcon } from "../../../atom/CustomIcons/StyledCloseIcon";
import { StyledHelpOutlineIcon } from "../../../atom/CustomIcons/StyledHelpOutlineIcon";
import HelpPopup from "../../../atom/HelpPopup/HelpPopup";
import { SwitchIcon } from "../../../atom/CustomIcons/SwitchIcon";

/** Material UI - Custom elements */
const MainContainer = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  left: 0,
  zIndex: 1000,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  width: "100%",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
}));

const TopContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

const ProgressbarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "800px",
}));

const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: "100%",
  margin: "0.5rem",
  height: "13px",
  borderRadius: "5rem",
  backgroundColor: theme.palette.appcolors.grey3,
  "& .MuiLinearProgress-bar": {
    backgroundColor: theme.palette.appcolors.success4,
    borderRadius: "5rem",
    transition: "transform 0.4s linear",
  },
}));

const ItemContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "no-wrap",
  alignItems: "center",
  justifyContent: "center",
}));

export const LectureTopBar = ({
  proptitle,
  propprogress,
  propclose,
  prophelp,
  propback,
  propforward,
  isBackArrowDisabled,
  isForwardArrowDisabled,
}) => {
  const [helpPopup, setHelpPopup] = useState(false);
  const theme = useTheme();

  return (
    <MainContainer>
      <TopContainer>
        <StyledCloseIcon onClick={propclose} />
        <InstructionsTitle>
          {proptitle ? proptitle : "Undefined"}
        </InstructionsTitle>
        <SwitchIcon />
        {/*
          <ItemContainer onClick={() => setHelpPopup(!helpPopup)}>
            <StyledHelpOutlineIcon />
            <Box>{helpPopup && <HelpPopup lectureMode />}</Box>
          </ItemContainer>
       */}
      </TopContainer>

      <ProgressbarContainer>
        <IconButton onClick={propback} disabled={isBackArrowDisabled}>
          <LeftArrowIcon
            sx={{
              color: isBackArrowDisabled
                ? theme.palette.appcolors.grey9
                : theme.palette.appcolors.primaryColor,
            }}
          />
        </IconButton>
        <LinearProgressBar
          variant="determinate"
          value={propprogress ? propprogress : 0}
        />
        <IconButton onClick={propforward} disabled={isForwardArrowDisabled}>
          <RightArrowIcon
            sx={{
              color: isForwardArrowDisabled
                ? theme.palette.appcolors.grey9
                : theme.palette.appcolors.primaryColor,
            }}
          />
        </IconButton>
      </ProgressbarContainer>
    </MainContainer>
  );
};
