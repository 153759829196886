/** Libraries */
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Material UI - Custom elements */
export const MainContainer = styled(Container)(({ theme }) => ({
  padding: "2rem 0rem",
  marginBottom: "1rem",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  color: theme.palette.appcolors.black,
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.appcolors.secondaryColor5,
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
    padding: "2rem 0rem",
  },
}));
