/** Libraries */
import React from "react";
import PropTypes from "prop-types";
import CommonPopup from "../molecule/CommonPopup/CommonPopup";
import { useUiStore } from "../../hooks";

const UiProvider = ({ children }) => {
  const { commonPopup, startUiCloseCommonPopup } = useUiStore();
  return (
    <>
      {children}
      {commonPopup.open && (
        <CommonPopup
          status={commonPopup.open}
          title={commonPopup.title}
          description={commonPopup.description}
          cancelBtnName={commonPopup.cancelBtnName}
          agreeBtnName={commonPopup.agreeBtnName}
          redirect={() => {
            commonPopup.redirect();
            startUiCloseCommonPopup();
          }}
          closePopup={() => {
            if (commonPopup.cancelBtnName) {
              startUiCloseCommonPopup();
            }
          }}
        />
      )}
    </>
  );
};

UiProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UiProvider;
