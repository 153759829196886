import { useLocation } from "react-router-dom";
import {
  AvatarBox,
  InnerBox2,
  InnerUpperBoxDivider,
  InnerUpperLeftBox,
  InnerUpperRightBox,
  NoPremiumBoxText,
  PremiumBox,
  PremiumBox3,
  PremiumBoxSubtitle,
  PremiumBoxTitle,
  PremiumInfoBox,
  StyledBannerBackground,
  MainPremiumContainer,
} from "../../IsPremiumCard/IsPremiumCard.style";

function IsPremiumSecondBanner({
  children,
  premiumImage,
  isPremium,
  premiumBoxTitle,
  premiumBoxSubtitle,
  noPremiumBoxText,
  button,
}) {
  const location = useLocation();

  return isPremium ? (
    <MainPremiumContainer>
      <PremiumBox maxWidth="false" disableGutters>
        {isPremium ? (
          <InnerUpperBoxDivider>
            <PremiumInfoBox>
              <PremiumBoxTitle>{premiumBoxTitle}</PremiumBoxTitle>
              <PremiumBoxSubtitle>{premiumBoxSubtitle}</PremiumBoxSubtitle>
            </PremiumInfoBox>
            <AvatarBox>{children}</AvatarBox>
          </InnerUpperBoxDivider>
        ) : (
          <NoPremiumBoxText>{noPremiumBoxText}</NoPremiumBoxText>
        )}
        {button}
      </PremiumBox>
    </MainPremiumContainer>
  ) : (
    <MainPremiumContainer>
      <PremiumBox3
        maxWidth="false"
        disableGutters
        isresultpage={location.pathname === "/resultsummary"}
      >
        <InnerBox2>
          <StyledBannerBackground>
            <InnerUpperRightBox>{premiumImage}</InnerUpperRightBox>
          </StyledBannerBackground>
          <InnerUpperLeftBox>
            {children}
            {button}
          </InnerUpperLeftBox>
        </InnerBox2>
      </PremiumBox3>
    </MainPremiumContainer>
  );
}

export default IsPremiumSecondBanner;
