/** Libraries */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/** Custom hooks */
import { useAuthStore } from "../../hooks";

/** Atoms */
import Filled_btn from "../../components/atom/FilledBtn/FilledBtn";
import {
  LogoContainer,
  LogoImage,
  MainContainer,
  PageSubtitle,
  PageTitle,
  CustomLabelField,
  AlertText,
  FooterLink,
  FooterBox,
} from "../../components/atom/SharedLoginSignupStyledComponents/SharedLoginSignupStyledComponents";

/** Templates */
import { AuthLayout } from "../../components/template";

const ForgottenPassword = () => {
  const { resetPasswordEmail } = useAuthStore();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  /** Alerts states */
  const [unAuthorized, setUnAuthorized] = useState(false);
  const [sentResetSuccessfully, setSentResetSuccessfully] = useState(false);

  const getVal = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  /** Send email to change password */
  const forgotPassword = (e) => {
    e.preventDefault();
    setUnAuthorized(false);

    resetPasswordEmail(email, setUnAuthorized, setSentResetSuccessfully);
  };

  const reusedImageContainer = () => {
    return (
      <LogoContainer>
        <LogoImage />
      </LogoContainer>
    );
  };

  const reusedEmailLabelField = () => {
    return (
      <CustomLabelField
        type="text"
        color="primary"
        placeholder="namn@mail.com"
        name="email"
        onChange={getVal}
        value={email}
        auth={unAuthorized}
      />
    );
  };

  const reusedTitleAndSubtitle = (reusedTitle, reusedSubtitle) => {
    return (
      <>
        <PageTitle reset>{reusedTitle}</PageTitle>
        <PageSubtitle>{reusedSubtitle}</PageSubtitle>
      </>
    );
  };

  return (
    <AuthLayout>
      {sentResetSuccessfully ? (
        <MainContainer>
          {reusedImageContainer()}
          {reusedTitleAndSubtitle(
            "Email skickat.",
            `Återställningslänk har skickats till ${email}.`
          )}
          <Filled_btn
            className="filled-btn-1"
            type="submit"
            title="Tillbaka till Logga in"
            disabled={email ? false : true}
            onClick={() => navigate("/login")}
          />
        </MainContainer>
      ) : (
        <MainContainer>
          {reusedImageContainer()}
          {reusedTitleAndSubtitle(
            "Återställ lösenord.",
            "Skriv in din email så skickar vi en länk för återställning av lösenordet."
          )}
          {reusedEmailLabelField()}
          {unAuthorized && (
            <AlertText sx={{ marginBottom: "1rem" }}>
              Denna emailadress är inte registrerad sen tidigare.
            </AlertText>
          )}
          <Filled_btn
            className="filled-btn-1"
            type="submit"
            title="Återställ lösenord"
            disabled={email ? false : true}
            onClick={forgotPassword}
            style={{ margin: "1rem 0rem" }}
          />
          <FooterBox>
            <FooterLink reset to="/login">
              Tillbaka
            </FooterLink>
          </FooterBox>
        </MainContainer>
      )}
    </AuthLayout>
  );
};

export default ForgottenPassword;
