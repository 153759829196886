/** Libraries */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Collapse, Fade, Stack, Alert } from "@mui/material";

/** Atoms */
import { PressEnterIcon } from "../../../components/atom/PressEnter/PressEnter";
import { StyledLectureButton } from "../../../components/atom/Button/Button.styles";

/** Molecules */
import AppLoader from "../../../components/molecule/AppLoader";

/** Organisms */
import { LectureTopBar } from "../../../components/organism/TopBars/LectureTopBar/LectureTopBar";
import { SQDescription } from "../../../components/organism/Quiz/descriptions/SQDescription/SQDescription";
import { Answer } from "../../../components/organism/Quiz/Answer/Answer";
import Options from "../../../components/organism/Quiz/Options/Options";
import AiChatBotBar from "../../../components/organism/AiTutor/AiChatBotBar/AiChatBotBar";

/** Custom hooks */
import { useLectureStore, useUiStore, useAiTutorStore } from "../../../hooks";

/** Style */
import {
  MainContainer,
  BodyContainer,
  DescriptionContainer,
  OptionsContainer,
  ButtonsContainer,
  AnswerAndChatbotContainer,
} from "../LectureTheory/Lecture.style";

const Lecture = () => {
  const buttonRef = useRef(null);
  const answerBoxRef = useRef(null);
  const audioRef = useRef(null);

  const { progressBackdrop } = useUiStore();
  const {
    startSettingLecture,
    startBackLectureQuestion,
    startNextLectureQuestion,
    startToggleShowAnswer,
    startAnswerQuestion,
    startTryAgainQuestion,
    currLecture,
    lectureName,
    topBar,
    startHandleCloseButton,
  } = useLectureStore();

  const {
    aiConversation,
    isRequestInProcess,
    isRequestFailed,
    startConsultingAiTutor,
    startAiTutorLogout,
  } = useAiTutorStore();

  const [currentIndex, setCurrentIndex] = useState(2);
  const [isTyping, setIsTyping] = useState(true);
  const [currentContent, setCurrentContent] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [buffer, setBuffer] = useState(null);
  const [isChatbotInputActive, setIsChatbotInputActive] = useState(false);

  const isCorrectAnswer = useMemo(
    () => currLecture?.correctOption === currLecture?.selectedCurrLecture,
    [currLecture]
  );

  useEffect(() => {
    startSettingLecture();
  }, []);

  useEffect(() => {
    if (currLecture?.correctOption === currLecture?.selectedCurrLecture) {
      startToggleShowAnswer();
    }
  }, [currLecture?.correctOption, currLecture?.selectedCurrLecture]);

  useEffect(() => {
    const handleKeypress = (e) => {
      if (e.keyCode === 13) {
        if (currLecture?.answerExist && isChatbotInputActive) {
          buttonRef.current.click();
        }
      }
    };

    document.addEventListener("keydown", handleKeypress);

    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  }, [currLecture?.answerExist, isChatbotInputActive]);
  /*
  useEffect(() => {
    const scrollToBottom = () => {
      const box = answerBoxRef.current;
      if (box) {
        box.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    };
    scrollToBottom();
  }, [isTyping, currentContent, isRequestInProcess]);
*/
  useEffect(() => {
    aiConversation.forEach((item, index) => {
      const { role, content } = item;
      setDisplayedMessages((prev) => [...prev, { role, content }]);
      setDisplayedMessages((prev) => prev.slice(0, index + 1));
      setIsTyping(true);
    });
  }, [aiConversation]);

  useEffect(() => {
    const messages = displayedMessages.map((e) => e.content);

    if (isTyping && currentIndex < messages.length) {
      let message = messages[currentIndex];
      let index = 0;

      if (currentIndex > 0) {
        var intervalId = setInterval(() => {
          setCurrentContent((prevContent) => prevContent + message[index]);
          index++;
          if (index === message.length) {
            clearInterval(intervalId);
            setCurrentIndex((prevIndex) => prevIndex + 1);
            setIsTyping(false);
            setCurrentContent((prevContent) => prevContent.slice(index));
          }
        }, 0.4);
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setIsTyping(false);
        setCurrentContent("");
      }
    }
    return () => clearInterval(intervalId);
  }, [isTyping, currentIndex, displayedMessages]);

  useEffect(() => {
    if (buffer) {
      const blob = new Blob([new Uint8Array(buffer.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);
      audioRef.current = audio;
      audio.play();
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [buffer]);

  const renderAiChatBot = () => (
    <AiChatBotBar
      isTyping={currentContent !== ""}
      sendMessage={(msg, sendMessage, model) => {
        startConsultingAiTutor({
          message: msg,
          isVoiceOn: sendMessage,
          question: currLecture?.questionStatement,
          options: currLecture?.options?.options.map((e) => e.value),
          correctOption: currLecture?.correctOption,
          answerExplanation: currLecture?.solution,
          setBuffer,
          indexMQ: false,
          MQDescription: false,
          model,
        });
      }}
      question={currLecture?.questionStatement}
      options={currLecture?.options?.options.map((e) => e.value)}
      correctOption={currLecture?.correctOption}
      answerExplanation={currLecture?.solution}
      isChatbotInputActive={isChatbotInputActive}
      setIsChatbotInputActive={setIsChatbotInputActive}
    />
  );

  const renderAnswerAndAi = useMemo(() => {
    return (
      currLecture?.answerExist && (
        <>
          {aiConversation.map((e, i) => (
            <Answer
              key={i}
              isAiTutor={e.role === "assistant"}
              isUser={e.role === "user"}
              content={
                isTyping && i === currentIndex && e.role === "assistant"
                  ? currentContent + "⚫"
                  : e.content
              }
              sectionCategory="Lecture"
              questionCategory="Lecture"
              questionId={currLecture?._id}
              takeScreenshot={true}
              isFeedbackDisabled={e.role === "user" || i === 1}
            />
          ))}

          {isRequestInProcess && (
            <Answer
              isAiTutor={true}
              isUser={false}
              isFeedbackDisabled={true}
              content={
                isRequestInProcess
                  ? "⚫"
                  : aiConversation[aiConversation.length - 1]?.content || ""
              }
            />
          )}

          {isRequestFailed && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">
                Tekniskt fel - testa igen om en stund
              </Alert>
            </Stack>
          )}

          <Box
            ref={answerBoxRef}
            sx={{
              width: "100%",
              content: isRequestInProcess && '""',
              pointerEvents: isRequestInProcess ? "none" : "auto",
            }}
          >
            {renderAiChatBot()}
          </Box>
        </>
      )
    );
  }, [
    currLecture?.answerExist,
    aiConversation,
    currentIndex,
    currentContent,
    isTyping,
    isRequestInProcess,
    isRequestFailed,
  ]);

  if (progressBackdrop || currLecture === null)
    return <AppLoader isLoading={true} />;

  return (
    <MainContainer>
      <LectureTopBar
        proptitle={lectureName}
        propback={startBackLectureQuestion}
        propforward={startNextLectureQuestion}
        propclose={startHandleCloseButton}
        propprogress={topBar.progress}
        isBackArrowDisabled={topBar.isBackArrowDisabled}
        isForwardArrowDisabled={
          topBar.isForwardArrowDisabled ||
          !currLecture?.answerExist ||
          !isCorrectAnswer
        }
      />
      <BodyContainer>
        <Fade in={true}>
          <DescriptionContainer>
            <SQDescription
              sectionTitle={""}
              questionDescription={currLecture?.questionStatement}
              questionImage={currLecture?.questionStatement?.includes(
                "hp-appen.s3.eu-north-1.amazonaws.com"
              )}
            />
          </DescriptionContainer>
        </Fade>

        <OptionsContainer>
          <Fade in={true} style={{ height: "auto" }}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              {currLecture?.options && (
                <Options
                  hiddeCorrectChoice={!isCorrectAnswer}
                  options={currLecture?.options?.options}
                  answerId={currLecture?.correctOption}
                  answerExist={currLecture?.answerExist}
                  selectedOption={currLecture?.selectedCurrLecture}
                  handleSelectOption={startAnswerQuestion}
                />
              )}
            </Box>
          </Fade>
          <Fade in={true} style={{ height: "auto" }}>
            <ButtonsContainer>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {isCorrectAnswer ||
                currLecture?.selectedCurrLecture === undefined ? (
                  <StyledLectureButton
                    disabled={!currLecture?.answerExist}
                    ref={buttonRef}
                    onClick={startNextLectureQuestion}
                  >
                    Fortsätt
                  </StyledLectureButton>
                ) : (
                  <StyledLectureButton
                    disabled={!currLecture?.answerExist}
                    ref={buttonRef}
                    onClick={startTryAgainQuestion}
                  >
                    Försök igen
                  </StyledLectureButton>
                )}
                <PressEnterIcon />
              </Box>
              <StyledLectureButton
                mq
                explain
                disabled={!currLecture?.answerExist}
                onClick={startToggleShowAnswer}
              >
                Visa förklaring
              </StyledLectureButton>
            </ButtonsContainer>
          </Fade>
          <Collapse
            orientation="vertical"
            in={currLecture?.isAnswerVisible}
            sx={{ width: "100%" }}
          >
            <AnswerAndChatbotContainer>
              <Answer
                content={
                  currLecture?.isAnswerVisible ? currLecture.solution : ""
                }
                questionId={currLecture?._id}
                sectionCategory={"Lecture"}
                questionCategory={"Lecture"}
              />
              {renderAnswerAndAi}
            </AnswerAndChatbotContainer>
          </Collapse>
        </OptionsContainer>
      </BodyContainer>
    </MainContainer>
  );
};

export default Lecture;
