/** Libraries */
import React, { useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

/** Molecules */

import { useAllCustomExamStore } from "../../../../hooks/customExams/AllCustomExams/useAllCustomExamsStore";

/** Atoms */
import {
  InfoMainContainer,
  InfoHeaderTitle,
  InfoHeaderIconContainer,
  InfoButtonsContainer,
  InfoOtherButton,
  InfoStartButton,
} from "../../../atom/SharedExamInfoStyledComponents/SharedExamInfoStyledComponents";
import { TimerIcon } from "../../../atom/Timer/TimerPrimary/TimerPrimary.styles";
import { BarChartIcon } from "../../../atom/BarChart/BarChartIcon";

/** Custom hooks */
import { useMixpanel } from "../../../../hooks";

/** Utils */
import {
  timeToggle,
  transformSecToMinutes,
} from "../../../../utils/timeManipulation";
import VerbalProvpassInfo from "../../../molecule/VerbalProvpassInfo/VerbalProvpassInfo";
import KvantitativProvpassInfo from "../../../molecule/KvantitativProvpasInfo/KvantitativProvpassInfo";

/** Material UI - Custom elements */
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const HeaderSubtitle = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: "14px",
  paddingTop: "0.5rem",
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

const InfoText = styled("p")(({ theme }) => ({}));

const CuExTestInfo = (props) => {
  const { setOpenExamSessionDialog } = props;
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const { customizedExamSessionQuestions } = useAllCustomExamStore();
  const { extendTimer, disabledTimer } = useSelector(
    (state) => state.timeCorrection
  );
  const DEFAULT_TIME_SEK = timeToggle(extendTimer);
  const DEFAULT_TIME_MINUTES = transformSecToMinutes(DEFAULT_TIME_SEK);
  const { customExam, customExamSessions } = useSelector(
    (state) => state.allCustomExams
  );
  const theme = useTheme();
  const currentIndex = customExamSessions.findIndex(
    (session) => session.isAttempted === false
  );
  const currentExamSession = customExamSessions[currentIndex];
  // #region Start Quiz
  const handleStartQuiz = async () => {
    // Find the custom exam session
    const customExamSession = customExamSessions.find(
      (session) => session.isAttempted === false
    );

    // const examSessionNumber = customExamSession?.examSessionName.split(" ")[1];

    if (!customExamSession) {
      console.error("No custom exam session found");
      return;
    }

    try {
      // Fetch the exam questions
      const response = await customizedExamSessionQuestions(
        customExamSession._id
      );
      if (response) {
        navigate("/simuleraanpassatprov", {
          state: {
            // id: props?.id,
            // session: props?.session,
            // provpass: props?.provpass,
            // provpassOrder: props?.provpassOrder,
            sessionExams: customExam.sessionExams,
            examSessionNumber: currentIndex + 1,
            totalTime: DEFAULT_TIME_SEK,
            isTimeDisabled: disabledTimer,
          },
        });
        setOpenExamSessionDialog();
      }
    } catch (error) {
      console.error("Error fetching exam session questions:", error);
    }
  };

  return (
    <InfoMainContainer>
      <HeaderContainer>
        <InfoHeaderTitle>
          {currentExamSession.sessionType === "Kvantitativt provpass"
            ? "Kvantitativt "
            : "Verbalt "}
        </InfoHeaderTitle>
        <HeaderSubtitle>
          <InfoHeaderIconContainer tabletmargin={"4rem"} phonemargin={"2rem"}>
            <BarChartIcon />
            40 frågor
          </InfoHeaderIconContainer>
          <InfoHeaderIconContainer>
            <TimerIcon
              style={{
                marginRight: "0.3rem",
                color: theme.palette.appcolors.grey,
              }}
            />
            <InfoText>
              {disabledTimer
                ? "Ingen tidsbegränsning"
                : `${DEFAULT_TIME_MINUTES} min per provpass`}
            </InfoText>
          </InfoHeaderIconContainer>
        </HeaderSubtitle>
      </HeaderContainer>

      {currentExamSession.sessionType === "Kvantitativt provpass" ? (
        <KvantitativProvpassInfo />
      ) : (
        <VerbalProvpassInfo />
      )}

      <InfoButtonsContainer>
        <InfoOtherButton onClick={setOpenExamSessionDialog}>
          Fortsätt senare
        </InfoOtherButton>
        <InfoStartButton onClick={handleStartQuiz}>
          Starta provpass
        </InfoStartButton>
      </InfoButtonsContainer>
    </InfoMainContainer>
  );
};

export default CuExTestInfo;
