/** Libraries */
import React, { useEffect, useMemo, useState, useRef } from "react";

/** Molecules */
import OptionsComponent from "../OptionsComponents";

/** Organisms */
import { Answer } from "../../organism/Quiz/Answer/Answer";
import { Box } from "@material-ui/core";
import { Alert, Stack } from "@mui/material";
import AiChatBotBar from "../../organism/AiTutor/AiChatBotBar/AiChatBotBar";
import {
  useAiTutorStore,
  useExercisesMQStore,
  useExercisesSectionCategoriesStore,
} from "../../../hooks";
import config from "../../../config/config";

const MultiAnswer = (props) => {
  const {
    aiMQConversation,
    isRequestInProcess,
    isRequestFailed,
    startConsultingAiTutor,
  } = useAiTutorStore();
  const { currMultiQuestion } = useExercisesMQStore();
  // typewriter
  const [currentIndex, setCurrentIndex] = useState(2);
  const [isTyping, setIsTyping] = useState(true);
  const [currentContent, setCurrentContent] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [buffer, setBuffer] = useState(null);
  const audioRef = useRef(null);
  const category = useExercisesSectionCategoriesStore();
  const question = useMemo(() => props?.question, [props?.question]);
  const aiConversation = useMemo(
    () => aiMQConversation[props?.indexMQ] || [],
    [aiMQConversation, props?.indexMQ]
  );
  const lastConversation = useMemo(() => {
    if (aiConversation.length > 0) {
      return aiConversation[aiConversation.length - 1];
    } else {
      return null;
    }
  }, [aiConversation]);
  const optionsForAi = useMemo(() => {
    const options = question?.option || question?.options;
    return options[0].options.map((e) => e.value);
  }, [question._id]);
  const correctOptionForAi = useMemo(() => {
    const options = question?.option || question?.options;
    return options[0].options.filter((e) => e._id === question?.answer?.option);
  }, [question._id, question?.answer?.option]);
  const sectionCategory = useMemo(() => {
    return (
      category?.title ||
      props?.question?.multipartQuestion?.title ||
      props?.question?.sectionCategories?.title
    );
  }, [question._id]);
  const MQDescription = useMemo(() => {
    if (currMultiQuestion) return currMultiQuestion.description;
    return sectionCategory === "DTK"
      ? question?.multipartQuestion?.description
          ?.split("![](")[1]
          ?.split(")")[0] || ""
      : question?.multipartQuestion?.description;
  }, [question._id, currMultiQuestion?._id, sectionCategory]);

  useEffect(() => {
    aiConversation.forEach((item, index) => {
      const { role, content } = item;

      setDisplayedMessages((prev) => [
        ...prev,
        {
          role,
          content,
        },
      ]);

      setDisplayedMessages((prev) => prev.slice(0, index + 1));
      setIsTyping(true);
    });
  }, [aiConversation]);

  useEffect(() => {
    const messages = displayedMessages.map((e) => e.content);

    if (isTyping && currentIndex < messages.length) {
      let message = messages[currentIndex];
      let index = 0;

      if (currentIndex > 0) {
        var intervalId = setInterval(() => {
          setCurrentContent((prevContent) => prevContent + message[index]);

          index++;

          if (index === message.length) {
            clearInterval(intervalId);
            setCurrentIndex((prevIndex) => prevIndex + 1);
            setIsTyping(false);
            setCurrentContent((prevContent) => prevContent.slice(index));
          }
        }, 0.4); // typing speed
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setIsTyping(false);
        setCurrentContent("");
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isTyping, currentIndex, displayedMessages]);

  const sendMessageWithBuffer = (
    message,
    isVoiceOn,
    model,
    question,
    options,
    correctOption,
    answerExplanation,
    indexMQ,
    questionId
  ) => {
    return startConsultingAiTutor({
      message,
      isVoiceOn,
      question,
      options,
      correctOption,
      answerExplanation,
      setBuffer,
      indexMQ,
      questionId,
      MQDescription,
      model,
    });
  };

  useEffect(() => {
    if (buffer) {
      const blob = new Blob([new Uint8Array(buffer.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);
      audioRef.current = audio;
      audio.play();
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [buffer]);

  const renderAiChatBot = () => (
    <AiChatBotBar
      indexMQ={props?.indexMQ}
      isTyping={currentContent !== ""}
      sendMessage={sendMessageWithBuffer}
      question={question?.questionStatement}
      options={optionsForAi}
      correctOption={correctOptionForAi[0]?.value}
      answerExplanation={question?.answer?.answer}
    />
  );
  const renderAnswerAndAi = useMemo(() => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {props?.question?.answer && (
          <Answer
            isMQ={true}
            isAiTutor={true}
            content={props?.question?.answer?.answer}
            questionId={props?.question?.questionId}
            sectionCategory={sectionCategory}
            questionCategory={sectionCategory}
          />
        )}
        {aiConversation.map((e, i) => (
          <Answer
            key={i}
            isAiTutor={e.role === "assistant"}
            isUser={e.role === "user"}
            content={
              isTyping && i === currentIndex && e.role === "assistant"
                ? currentContent + "⚫"
                : e.content
            }
            sectionCategory={sectionCategory}
            questionCategory={sectionCategory}
            questionId={props?.question?.questionId}
            isFeedbackDisabled={e.role === "user" || i === 1}
          />
        ))}

        {isRequestInProcess && (
          <Answer
            isAiTutor={true}
            isUser={false}
            isFeedbackDisabled={true}
            content={
              isRequestInProcess
                ? "⚫"
                : lastConversation
                ? lastConversation?.content
                : ""
            }
          />
        )}

        {isRequestFailed && (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">
              Tekniskt fel - testa igen om en stund
            </Alert>
          </Stack>
        )}

        <Box
          sx={{
            width: "100%",
            content: isRequestInProcess && '""',
            pointerEvents: isRequestInProcess ? "none" : "auto",
          }}
        >
          {renderAiChatBot()}
        </Box>
      </Box>
    );
  }, [
    aiConversation,
    currentIndex,
    currentContent,
    isTyping,
    isRequestInProcess,
    isRequestFailed,
    lastConversation,
    props.questionTypeTitle,
    question,
  ]);

  return (
    <>
      <OptionsComponent
        question={props?.question}
        resultComponent={props.resultComponent}
      />

      {props?.isAiEnabled &&
      (config.REACT_APP_SERVER_NAME === "DEV" || sectionCategory !== "DTK") ? (
        renderAnswerAndAi
      ) : (
        <Answer
          isMQ={true}
          isAiTutor={false}
          content={props?.question?.answer?.answer}
          questionId={props?.question?.questionId}
          sectionCategory={sectionCategory}
          questionCategory={sectionCategory}
        />
      )}
    </>
  );
};

export default MultiAnswer;
