import { styled } from "@mui/material/styles";

/** Utils */
import { appColors } from "../../../utils/commonService";

/** Material UI - Custom elements */
export const MainPremiumContainer = styled("div")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("1280")]: {
    paddingRight: "0.5rem",
  },
  [theme.breakpoints.down("800")]: {
    paddingRight: "0rem",
  },
}));

export const PremiumButton2 = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.primaryColor,
  textAlign: "center",
  paddingTop: "10px",
  color: theme.palette.appcolors.secondaryColor10,
  fontWeight: 600,
  width: "100%",
  maxWidth: "400px",
  height: "41px",
  borderRadius: 5,
  cursor: "pointer",
  fontSize: "16px",
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  ":hover": {
    color: theme.palette.appcolors.secondaryColor10,
    transform: "scale(1.01)",
  },
  [theme.breakpoints.up(1280)]: {
    width: "90%",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: "0.5rem",
  },
  [theme.breakpoints.down("600")]: {
    maxWidth: "400px",
  },
}));

export const PremiumButton3 = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.primaryColor,
  textAlign: "center",
  paddingTop: "10px",
  color: theme.palette.appcolors.error4,
  fontWeight: 600,
  width: "100%",
  maxWidth: "400px",
  height: "47px",
  borderRadius: 5,
  cursor: "pointer",
  fontSize: "16px",
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  ":hover": {
    color: theme.palette.appcolors.error4,
    fontSize: "17px",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "328px",
    marginTop: "0.5rem",
  },
}));

export const PremiumButton = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.primaryColor,
  textAlign: "center",
  paddingTop: "10px",
  color: theme.palette.appcolors.secondaryColor10,
  fontWeight: 600,
  width: "100%",
  maxWidth: "383px",
  height: "47px",
  borderRadius: 5,
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  cursor: "pointer",
  fontSize: "16px",
  ":hover": {
    color: theme.palette.appcolors.secondaryColor10,
    fontSize: "17px",
  },
}));

export const PremiumBox = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 25px",
  border: `2px solid ${theme.palette.appcolors.primaryColor}`,
  borderRadius: 10,
  minHeight: "175px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  maxWidth: "1275px",
  margin: "1rem 0rem",
  gap: ".5rem",
  [theme.breakpoints.down(800)]: {
    margin: "0rem",
  },
  [theme.breakpoints.down("sm")]: {
    minHeight: "132px",
    minWidth: "0",
  },
  [theme.breakpoints.down(2400)]: {
    maxWidth: "680px",
  },
}));

export const PremiumBox2 = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: "10px",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "20px",
  paddingLeft: "20px",
  paddingBottom: "20px",
  minHeight: "175px",
  backgroundColor: theme.palette.appcolors.primaryColor10,
  maxWidth: "1275px",
  minWidth: "320px",
  margin: "1rem 0rem",
  [theme.breakpoints.down("sm")]: {
    minHeight: "132px",
  },
}));

export const PremiumBox3 = styled("div")(({ theme, isresultpage }) => ({
  zIndex: "2",
  width: "100%",
  borderRadius: "10px",
  display: "flex",
  overflow: "hidden",
  position: "relative",
  flexDirection: "column",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  padding: "25px",
  minHeight: "175px",
  backgroundColor: theme.palette.appcolors.primaryColor10,
  margin: "1rem 0rem",
  [theme.breakpoints.up(1280)]: {
    maxWidth: !isresultpage && "433px",
  },
  [theme.breakpoints.down(800)]: {
    minHeight: "175px",
  },
}));

export const InnerUpperRightBox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));
export const InnerUpperLeftBox = styled("div")(({ theme }) => ({
  width: "70%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "1em",
  [theme.breakpoints.down(1280)]: {
    width: "60%",
  },
}));

export const InnerUpperBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "400px",
}));

export const InnerBox = styled("div")(({ theme }) => ({
  flexDirection: "column",
  display: "flex",
  [theme.breakpoints.down("1280")]: {
    gap: ".5em",
  },
  [theme.breakpoints.down("sm")]: {
    alignItems: "center",
  },
}));

export const InnerBox2 = styled("div")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("1280")]: {
    gap: ".5em",
  },
}));

export const StyledBannerBackground = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  right: "0",
  top: "-35px",
  bottom: "0",
  zIndex: "-1",
  backgroundColor: theme.palette.appcolors.primaryColor5,
  width: "35%",
  height: "135%",
  borderRadius: "50% 0 0 50%",
  transition: "all 0.2s ease",
  [theme.breakpoints.down(1280)]: {
    width: "38%",
  },
}));

export const NoPremiumBoxText = styled("div")(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 600,
  color: appColors.blueColor,
  marginTop: "2rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
  },
}));

export const NoPremiumBoxText2 = styled("div")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  color: appColors.blackColor,
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
    flex: 1.5,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: ".9rem",
  },
  [theme.breakpoints.up("1280")]: {
    fontSize: "1rem",
  },
}));

export const PremiumBoxTitle = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  color: theme.palette.appcolors.inviteFriendHeaderText,
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    maxWidth: "328px",
  },
  [theme.breakpoints.down("1280")]: {
    fontSize: "1.2rem",
    maxWidth: "328px",
  },
}));

export const PremiumBoxTitle2 = styled("div")(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 600,
  color: appColors.blueColor,
  marginTop: "1rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0.5rem",
    fontSize: "18px",
    maxWidth: "328px",
  },
}));

export const PremiumBoxSubtitle = styled("div")(({ theme }) => ({
  fontSize: "15px",
  fontWeight: 400,
  color: theme.palette.appcolors.black,
  maxWidth: "360px",
  marginTop: ".2em",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const PremiumBoxSubtitle2 = styled("span")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  color: appColors.valentine_red,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const PremiumBoxSubtitle3 = styled("span")(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.appcolors.error6,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

export const PremiumBoxTitle3 = styled("div")(({ theme }) => ({
  fontWeight: 600,
  color: appColors.blueColor,
  marginTop: "1rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "0.5rem",
    fontSize: "18px",
    maxWidth: "328px",
  },
}));

export const NoPremiumBoxText3 = styled("p")(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.appcolors.black,
  "& span": {
    color: theme.palette.appcolors.error6,
  },
}));

export const PremiumCrown = styled("img")(({ theme }) => ({
  maxWidth: "100px",
  objectFit: "contain",
  width: "100%",
  [theme.breakpoints.down("1280")]: {
    width: "30%",
  },
}));

export const PremiumDiamond = styled("img")(({ theme }) => ({
  maxWidth: "86px",
  objectFit: "contain",
  width: "100%",

  [theme.breakpoints.down(800)]: {
    right: "24px",
    top: "8px",
  },
  [theme.breakpoints.down(600)]: {
    right: "20px",
    top: "12px",
  },
}));

export const InnerUpperBoxDivider = styled("div")(({ theme }) => ({
  display: "flex",
  maxWidth: "383px",
  justifyContent: "space-between",
  alignItems: "center",
  gap: ".5rem",
}));

export const AvatarBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: ".2rem",
}));

export const AvatarInnerRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: ".2rem",
}));

export const AvatarImage = styled("img")(({ theme }) => ({
  minWidth: "30px",
}));

export const PremiumInfoBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: `${appColors.blackColor} !important`,
}));
