/** Libraries */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

/** Slices */
import {
  publicTestMQLogout,
  setAnswerVisiblePublicTestMQ,
  setNextQuestionInReviewPublicTestMQ,
  setNextQuestionPublicTestMQ,
  setPreviusQuestionInReviewPublicTestMQ,
  setPublicTestMQQuiz,
  setPublicTestMQToShow,
  setSelectedCurrPublicTestMQIndex,
  setSelectedQuestionPublicTestMQ,
  uiOpenCommonPopup,
} from "../../../redux/slices";

/** Custom hooks */
import { useTimerStore } from "../../useTimer/useTimerStore";
import { useMixpanel } from "../../useMixpanel/useMixpanel";

/** Utils */
import { useNavigateUTM } from "../../utils/useNavigateUTM";

/** Assets */
import quizDTK from "../../../assets/Static/PublicDtkQuestions.json";
import quizELF from "../../../assets/Static/PublicElfQuestions.json";
import quizLAS from "../../../assets/Static/PublicLasQuestions.json";
const quices = {
  DTK: quizDTK,
  ELF: quizELF,
  LAS: quizLAS,
  LÄS: quizLAS,
};
const validCategories = ["DTK", "LÄS", "LAS", "ELF"];

export const usePublicTestMQStore = () => {
  const {
    currTime,
    secondsCounter,
    totalTime,
    startTimer,
    startPausingTimer,
    startReactivetingTimer,
    startTimerLogout,
  } = useTimerStore();
  const mixpanel = useMixpanel();
  const navigateUTM = useNavigateUTM();
  const { pathname, state } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    quiz,
    currentQuestion,
    selectedIndex,
    selectedCurrMQIndex,
    areCurrMQSelected,
    totalQuestions,
    isAnswerVisible,
    topBar,
  } = useSelector((state) => state.publicTestMQ);

  const isPublicTestPage = useMemo(
    () => pathname.split("/")[1] === "public",
    [pathname]
  );
  const atLeastOneQuestionSelected = useMemo(
    () => !!quiz[0]?.question[0]?.optionId,
    [quiz[0]?.question[0]?.optionId]
  );
  const categoryTitle = useMemo(() => {
    if (params?.category.toUpperCase() === "LAS") {
      return "LÄS";
    } else {
      return params?.category.toUpperCase();
    }
  }, [pathname?.category]);

  useEffect(() => {
    if (!validCategories.includes(categoryTitle) && isPublicTestPage) {
      return navigateUTM("/");
    }
  }, [params]);

  /** Handle time up popups */
  useEffect(() => {
    if (currTime === 0 && totalTime !== 0 && quiz.length !== 0) {
      if (isPublicTestPage) {
        if (atLeastOneQuestionSelected) {
          dispatch(
            uiOpenCommonPopup({
              title: "Tiden är över.",
              description: "Bra kämpat! Gå vidare och checka ditt resultat.",
              agreeBtnName: "Se resultat",
              redirect: startSubmittingQuizPublicTestMQ,
            })
          );
        } else {
          dispatch(
            uiOpenCommonPopup({
              title: "Tiden är över.",
              description:
                "Ingen fråga är besvarad så du tas direkt tillbaka till övningssidan.",
              agreeBtnName: "Avsluta",
              redirect: () => {
                startTimerLogout();
                navigateUTM("/");
              },
            })
          );
        }
      }
    }
  }, [pathname, quiz, currTime]);

  const startSettingPublicTestMQ = () => {
    if (!state?.isReviewSection) {
      console.log("category: ", categoryTitle);
      let quiz = quices[`${categoryTitle}`] || [];
      if (quiz.length !== 0) {
        if (params?.index) {
          if (params?.index !== "24") return navigateUTM("/");
          quiz = quiz[1] || [];
        } else {
          quiz = quiz[0] || [];
        }
      }
      if (quiz.length === 0) {
        navigateUTM("/");
      }
      console.log("quiz: ", quiz);

      mixpanel.activityStarted("Public Test", categoryTitle);
      startTimer(10 * 60, true);
      dispatch(setPublicTestMQQuiz({ quiz, questionIndex: null }));
    } else {
      // const { quiz, time } = params?.state?.answerSubmittedState;
      startTimer(state?.time);
      startPausingTimer();
      dispatch(
        setPublicTestMQQuiz({
          quiz: state?.quiz,
          questionIndex: state?.questionIndex,
        })
      );
    }
  };

  const startSubmittingQuizPublicTestMQ = () => {
    startPausingTimer();
    let resultAnswer = {
      answer: [],
      question: [],
      attemptedQuestion: 0,
    };

    quiz.forEach((question) => {
      question.question.forEach((item) => {
        let finalQuestion = null;

        if (item?.optionId && item?.totaltime) {
          finalQuestion = item;
        } else if (item?.optionId && !item?.totaltime) {
          finalQuestion = {
            ...item,
            spendtime: secondsCounter,
            timeleft: currTime,
            totaltime: totalTime,
          };
        } else {
          finalQuestion = {
            ...item,
            spendtime: 0,
            timeleft: currTime,
            totaltime: totalTime,
          };
        }

        resultAnswer = {
          answer: [...resultAnswer.answer, item.answerObj],
          question: [
            ...resultAnswer.question,
            { ...finalQuestion, answer: finalQuestion.answerObj },
          ],
          attemptedQuestion: resultAnswer.attemptedQuestion + 1,
        };
      });
    });

    console.log("Formated quiz: ", resultAnswer);

    mixpanel.activityEnded("Public Test", categoryTitle);
    startTimerLogout();
    navigateUTM(`/public/${categoryTitle}/${params?.index}/resultsummary`, {
      state: {
        sectionCategory: categoryTitle,
        time: currTime,
        answerSubmittedState: resultAnswer,
        quizMQ: quiz,
      },
      replace: true,
    });
  };

  const startSettingSelectedAnswerPublicTestMQ = (question_id) => {
    dispatch(setSelectedQuestionPublicTestMQ(question_id));
  };

  const startSettingSelectedCurrPublicTestMQIndex = (index) => {
    dispatch(setSelectedCurrPublicTestMQIndex(index));
  };

  const startSettingAnswerVisiblePublicTestMQ = () => {
    dispatch(
      setAnswerVisiblePublicTestMQ({
        spendtime: secondsCounter,
        timeleft: currTime,
        totaltime: totalTime,
      })
    );
    startPausingTimer();
  };

  const startNextQuestionPublicTestMQ = () => {
    if (topBar.currNumQuestion !== totalQuestions) {
      dispatch(setNextQuestionPublicTestMQ());
      startReactivetingTimer();
    } else {
      startSubmittingQuizPublicTestMQ();
    }
  };

  const startNavigatingResultPageInReview = () => {
    console.log("state in func: ", state);
    console.log("params in func: ", params);
    navigateUTM(`/public/${categoryTitle}/${params?.index}/resultsummary`, {
      state: {
        sectionCategory: categoryTitle,
        time: state?.time,
        answerSubmittedState: state?.answerSubmittedState,
        quizMQ: state?.quiz,
      },
    });
  };

  const startShowingPublicTestMQ = (index) => {
    dispatch(setPublicTestMQToShow(index));
  };

  const startSettingNextQuestionInReviewPublicTestMQ = () => {
    dispatch(setNextQuestionInReviewPublicTestMQ());
  };

  const startSettingPreviusQuestionInReviewPublicTestMQ = () => {
    dispatch(setPreviusQuestionInReviewPublicTestMQ());
  };

  /** Handle close button popups */
  const startClosingQuizPublicTestMQ = () => {
    if (!state?.isReviewSection) {
      if (atLeastOneQuestionSelected) {
        dispatch(
          uiOpenCommonPopup({
            title: "Vill du avsluta?",
            description: "Du tas nu till summeringssidan.",
            cancelBtnName: "Fortsätt öva",
            agreeBtnName: "Avsluta",
            redirect: startSubmittingQuizPublicTestMQ,
          })
        );
      } else {
        dispatch(
          uiOpenCommonPopup({
            title: "Vill du avsluta?",
            description: "Ingen fråga är besvarad.",
            cancelBtnName: "Fortsätt öva",
            agreeBtnName: "Avsluta",
            redirect: () => {
              mixpanel.activityEnded("Public Test", categoryTitle);
              startTimerLogout();
              navigateUTM("/");
            },
          })
        );
      }
    } else {
      startNavigatingResultPageInReview();
    }
  };

  const startLogoutPublicTestMQ = () => {
    dispatch(publicTestMQLogout());
  };

  return {
    /** Values */
    quiz,
    currentQuestion,
    selectedIndex,
    selectedCurrMQIndex,
    areCurrMQSelected,
    totalQuestions,
    isReviewSection: state?.isReviewSection,
    isAnswerVisible: isAnswerVisible || state?.isReviewSection,
    topBar,
    categoryTitle: categoryTitle,

    /** Functions */
    startSettingPublicTestMQ,
    startSettingSelectedAnswerPublicTestMQ,
    startSettingSelectedCurrPublicTestMQIndex,
    startSettingAnswerVisiblePublicTestMQ,
    startNextQuestionPublicTestMQ,
    startClosingQuizPublicTestMQ,
    startNavigatingResultPageInReview,
    startSettingNextQuestionInReviewPublicTestMQ,
    startSettingPreviusQuestionInReviewPublicTestMQ,
    startSubmittingQuizPublicTestMQ,
    startShowingPublicTestMQ,
    startLogoutPublicTestMQ,
  };
};
