/** Libraries */
import { Navigate } from "react-router-dom";

/** Custom hooks */
import { useAuthStore } from "../hooks";

/** Data */
import influencersUTM from "../assets/Static/influencersUTM.json";

export const PrivateRoute = ({ children }) => {
  const { user } = useAuthStore();

  if (user) {
    if (
      window.screen.width < 600 &&
      influencersUTM.includes(user?.utm?.split("-")[0])
    ) {
      window.location.href = "https://www.hpappen.se/mobil";
      return <></>;
    } else {
      return children;
    }
  } else {
    return <Navigate to="/login" />;
  }
};
