/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

export const exercisesSectionCategoriesSlice = createSlice({
  name: "exercisesSectionCategories",
  initialState: {
    sectionCategories: [],
    sectionCategoryId: "",
  },
  reducers: {
    setSectionCategories: (state, action) => {
      state.sectionCategories = action.payload;
    },
    setSectionCategoryId: (state, action) => {
      state.sectionCategoryId = action.payload;
    },
  },
});

// Function Selector to get an item from sectionCategories by its _id
export const selectItemById = (itemId) => (state) =>
  state.exercisesSectionCategories.sectionCategories.find(
    (item) => item._id === itemId
  );

export const { setSectionCategories, setSectionCategoryId } =
  exercisesSectionCategoriesSlice.actions;
