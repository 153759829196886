import React from "react";

const MenuHamburgerIcon = () => {
  return (
    <svg viewBox="0 0 24 24">
      <rect width="7" height="2" x="3" y="5" rx="1">
        {" "}
      </rect>
      <rect width="17" height="2" x="3" y="11" rx="1">
        {" "}
      </rect>
      <rect width="11" height="2" x="3" y="17" rx="1">
        {" "}
      </rect>
    </svg>
  );
};

export default MenuHamburgerIcon;
