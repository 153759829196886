import React from "react";
import PropTypes from "prop-types";
import { MainCardContainer } from "../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";
import {
  CardSubtitleText,
  CardTitleText,
  CardTitleTextContainer,
  CombinedCardComponents,
  DownsideCardComponent,
  IconBox,
  InnerDownsideCardComponent,
  LeftSideCardComponents,
  PremiumLabel,
  PremiumSticker,
  PrognosText,
  RightSideCardComponents,
  StyledBannerBackground,
} from "./MainCard.styles";

function MainCard({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isPremiumSticker,
  text,
  cardSubtitleText,
  prognos,
  styledBannerBackground,
  isPremium,
  isFirstQuiz,
  prognosText,
}) {
  return (
    <MainCardContainer
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isPremiumSticker && (
        <PremiumSticker>
          <PremiumLabel>Premium</PremiumLabel>
        </PremiumSticker>
      )}
      <CombinedCardComponents
        isPremium={isPremium}
        isFirstQuiz={isFirstQuiz}
        sx={{
          padding: 0,
          marginTop: isPremiumSticker ? "-1.5rem" : "-0.3rem",
        }}
      >
        <DownsideCardComponent>
          <CardTitleTextContainer>
            <CardTitleText>{text}</CardTitleText>
            <IconBox>
              <StyledBannerBackground>
                {styledBannerBackground}
              </StyledBannerBackground>
            </IconBox>
          </CardTitleTextContainer>
          <InnerDownsideCardComponent prognos={prognos}>
            <LeftSideCardComponents>
              {/* Title, Subtitle, Progress bar*/}
              <CardSubtitleText>{cardSubtitleText}</CardSubtitleText>
            </LeftSideCardComponents>
            <RightSideCardComponents>
              {/* Score, Prognos */}
              {prognos}
              <PrognosText>{prognosText}</PrognosText>
            </RightSideCardComponents>
          </InnerDownsideCardComponent>
        </DownsideCardComponent>
      </CombinedCardComponents>
      {children}
    </MainCardContainer>
  );
}

MainCard.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default MainCard;
