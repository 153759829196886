import React from "react";
import CoursesMain from "../../components/organism/CoursesOrg/CoursesMain/CoursesMain";

const Courses = () => {
  return (
    <div>
      <CoursesMain />
    </div>
  );
};

export default Courses;
