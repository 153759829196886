/** Libraries */
import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

/** Assets */
import LecturePayIcon1 from "../../../assets/Icons/LecturePayIcon1.svg";
import LecturePayIcon2 from "../../../assets/Icons/LecturePayIcon2.svg";
import LecturePayIcon3 from "../../../assets/Icons/LecturePayIcon3.svg";
import LecturePayIcon4 from "../../../assets/Icons/LecturePayIcon4.svg";
import LecturePayIcon5 from "../../../assets/Icons/LecturePayIcon5.svg";

/** Items data */
const itemsLecture = [
  {
    text: "12,000+ uppgifter med smarta lösningar",
    icon: LecturePayIcon1,
  },
  {
    text: "Livechatt 24/7 för XYZ, NOG, KVA, MEK & ORD",
    icon: LecturePayIcon2,
  },
  {
    text: "Högskoleprov 2011-2023 med automatisk rättning och poäng",
    icon: LecturePayIcon3,
  },
  {
    text: "Få normerad poäng på alla delar",
    icon: LecturePayIcon4,
  },
  {
    text: "Studiematerial för kvantitativ och verbal del",
    icon: LecturePayIcon5,
  },
];

const itemsExam = [
  {
    text: "12,000+ uppgifter med smarta lösningar",
    icon: LecturePayIcon1,
  },
  {
    text: "AI studiecoach: hjälp med uppgifter dygnet runt",
    icon: LecturePayIcon2,
  },
  {
    text: "Normerad poäng på alla provdelar",
    icon: LecturePayIcon4,
  },
  {
    text: "Teori & strategier för högskoleprovet",
    icon: LecturePayIcon5,
  },
  {
    text: "Övningsprov med automatisk rättning och normering",
    icon: LecturePayIcon3,
  },
];

/** Material UI - Custom elements */
const CustomGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  gap: "0px",
}));

const ListContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  [theme.breakpoints.down(1280)]: {
    marginTop: "-10px",
  },
}));

const ListText = styled("span")(({ theme }) => ({
  fontFamily: "Figtree",
  cursor: "default",
  fontSize: "15px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "12px",
  },
}));

const ListImgIcon = styled(CheckCircleOutlineRoundedIcon)(({ theme }) => ({
  fontSize: "1.75rem",
  color: theme.palette.appcolors.primaryColor,
  marginRight: "0.5rem",
}));

const LecturePayIcon = styled("img")(({ theme }) => ({
  marginRight: "0.5rem",
  [theme.breakpoints.down(1280)]: {
    width: "14px",
  },
}));

const ListValues = (props) => {
  const items = props.version === 1 ? itemsLecture : itemsExam;

  return (
    <Grid>
      <List>
        {items.map((item, index) => (
          <CustomGrid key={index}>
            <ListItem disableGutters>
              <ListContentContainer>
                {props.isExtended ? (
                  <LecturePayIcon src={item.icon} />
                ) : (
                  <ListImgIcon />
                )}
                <ListText>{item.text}</ListText>
              </ListContentContainer>
            </ListItem>
          </CustomGrid>
        ))}
      </List>
    </Grid>
  );
};

export default ListValues;
