import { createSlice } from "@reduxjs/toolkit";

export const allCustomExamSlice = createSlice({
  name: "allCustomExam",
  initialState: {
    allCustomExams: [],
    customExam: {},
    customExamSessions: [],
    customExamSessionQuestions: [],
    startExamDialog: false,
    sessionExamDialog: false,
  },
  reducers: {
    setAllCustomExams: (state, action) => {
      state.allCustomExams = action.payload;
    },
    setCustomExam: (state, action) => {
      state.customExam = action.payload;
    },
    setCustomExamSessions: (state, action) => {
      state.customExamSessions = action.payload;
    },
    setCustomExamSessionQuestions: (state, action) => {
      state.customExamSessionQuestions = action.payload;
    },
    setStartExamDialog: (state, action) => {
      state.startExamDialog = action.payload;
    },
    setSessionExamDialog: (state, action) => {
      state.sessionExamDialog = action.payload;
    },
  },
});

export const {
  setAllCustomExams,
  setCustomExam,
  setCustomExamSessions,
  setCustomExamSessionQuestions,
  setStartExamDialog,
  setSessionExamDialog,
} = allCustomExamSlice.actions;

export default allCustomExamSlice.reducer;
