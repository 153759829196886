/** Libraries */
import { Navigate } from "react-router-dom";

/** Custom hooks */
import { useAuthStore } from "../hooks";

export const PublicRoute = ({ children }) => {
  const { user } = useAuthStore();

  return !user ? children : <Navigate to="/home" />;
};
