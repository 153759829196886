import { styled } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

const konfetiURL =
  "https://hp-appen.s3.eu-north-1.amazonaws.com/Assets/Gifs/confetti.gif";

const KonfetiContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  width: "100vw",
  height: "100%",
  zIndex: "1500", // Konfeti is displayed in the foreground above other content
}));

export default function Konfeti({ show }) {
  const [gifLoaded, setGifLoaded] = useState(false);
  const [gifVisible, setGifVisible] = useState(show);
  const gifRef = useRef();

  useEffect(() => {
    if (gifRef.current) {
      gifRef.current.addEventListener("load", () => {
        setGifLoaded(true);
        setTimeout(() => setGifVisible(false), 2000);
      });
    }

    return () => {
      if (gifRef.current) {
        gifRef.current.removeEventListener("load", () => {});
      }
    };
  }, []);

  useEffect(() => {
    setGifVisible(show);
  }, [show]);

  return (
    <>
      {gifVisible && (
        <KonfetiContainer>
          <img
            ref={gifRef}
            src={konfetiURL}
            alt="Konfeti"
            style={{ display: gifLoaded ? "block" : "none" }}
          />
        </KonfetiContainer>
      )}
    </>
  );
}
