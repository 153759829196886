/** Libraries */
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Assets */
import { PressEnterIcon } from "../../atom/PressEnter/PressEnter";

/** Material UI - Custom elements */
const ButtonContainer = styled(Box)(({ theme, disabled }) => ({
  width: "100%",
  display: disabled ? "none" : "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  marginTop: "2.5%",
  padding: "0.5rem 0rem 2rem 0rem",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
}));

const AnswerButton = styled("button")(({ theme, disabled }) => ({
  display: "flex",
  width: "100%",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  borderRadius: "16px",
  cursor: "pointer",
  border: "none",
  background: disabled
    ? theme.palette.appcolors.grey3
    : theme.palette.appcolors.primaryColor,
  borderBottom: `3px solid ${
    disabled ? theme.palette.appcolors.grey8 : theme.palette.appcolors.black3
  }`,
  [theme.breakpoints.down(600)]: {
    borderRadius: "8px",
  },
}));

const TextButton = styled("p")(({ theme, disabled }) => ({
  color: disabled
    ? theme.palette.appcolors.grey7
    : theme.palette.appcolors.secondaryColor10,
  fontWeight: 600,
  [theme.breakpoints.down(600)]: {
    fontSize: "14px",
  },
}));

const EnterIconVoid = styled("div")(({ theme }) => ({
  width: "90px",
  height: "16px",
  marginBottom: "3px",
  [theme.breakpoints.down(900)]: {
    display: "none",
  },
}));

const AnswerButtonSQ = (props) => {
  const [disableAnswerButton, setDisableAnswerButton] = useState(false);

  useEffect(() => {
    if (props?.disabled !== undefined) {
      if (props?.disabled) {
        setDisableAnswerButton(true);
      } else {
        setDisableAnswerButton(false);
      }
    }
  }, [props?.disabled]);

  const handleClick = (e) => {
    setDisableAnswerButton(true);

    props.Next
      ? props.Next(props.question, e.target.innerText)
      : !props.isAnswerVisible
      ? props.startSettingAnswerVisiblePublicTestSQ()
      : props.startNextQuestionPublicTestSQ();

    setDisableAnswerButton(false);
  };

  useEffect(() => {
    const handleEnterClick = (e) => {
      if (e.key === "Enter" && props?.question?.selectedQuestion) {
        e.preventDefault();
        if (!props?.isAnswerVisible) {
          props?.startSettingAnswerVisiblePublicTestSQ();
        } else {
          props?.startNextQuestionPublicTestSQ();
        }
      }
    };

    document.addEventListener("keydown", handleEnterClick);

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [props?.isAnswerVisible, props?.question?.selectedQuestion, props]);

  return (
    <>
      {(props.question.selectedIndex + 1 ||
        props.question.answer ||
        props.question.selectedQuestion) &&
      !disableAnswerButton ? (
        <ButtonContainer>
          <PressEnterIcon />
          <AnswerButton onClick={handleClick}>
            <TextButton>
              {props.question.answer || props.isAnswerVisible
                ? "Nästa"
                : "Svara"}
            </TextButton>
          </AnswerButton>
        </ButtonContainer>
      ) : (
        <ButtonContainer disabled>
          <EnterIconVoid />
          <AnswerButton disabled>
            <TextButton disabled>
              {props.question.answer || props.isAnswerVisible
                ? "Nästa"
                : "Svara"}
            </TextButton>
          </AnswerButton>
        </ButtonContainer>
      )}
    </>
  );
};

export default AnswerButtonSQ;
