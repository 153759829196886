/** Libraries */
import React, { useEffect, useMemo, useRef } from "react";
import ConfettiExplosion from "react-confetti-explosion";

/** Atoms */
import { PressEnterIcon } from "../../../components/atom/PressEnter/PressEnter";
import { StyledButton } from "../../../components/atom/Button/Button.styles";

/** Molecules */
import AppLoader from "../../../components/molecule/AppLoader";
import QuestionStatement from "../../../components/molecule/QuestionStatement/QuestionStatement";

/** Organisms */
import { LectureTopBar } from "../../../components/organism/TopBars/LectureTopBar/LectureTopBar";

/** Assets */
import circleAnimation from "../../../assets/gifs/circleAnimation.gif";
import Trophy1 from "../../../assets/gifs/Trophy1.gif";
import Trophy2 from "../../../assets/gifs/Trophy2.gif";

/** UI */
import {
  BodyContainer,
  ButtonContainer,
  ButtonContainerSM,
  CardContainer,
  ContentContainer,
  FirstContentContainer,
  FontSubTitle,
  FontSubTitleHeader,
  FontTitle,
  ImageContainer,
  ListContainer,
  StyledGif,
  LectureCompletedContainer,
  LectureCompletedAudio,
} from "./LectureCompleted.styles";

/* Redux */
import {
  useExercisesSectionCategoriesStore,
  useLectureDashboardStore,
  useLectureStore,
  useMixpanel,
  useUiStore,
} from "../../../hooks";

export default function LectureCompleted() {
  const mixpanel = useMixpanel();
  const { progressBackdrop } = useUiStore();
  const currentSectionCategory = useExercisesSectionCategoriesStore();
  const {
    currLecture,
    topBar,
    lectureName,
    lectureEnding,
    completedPageAanimationIndex,
    startSettingLecture,
    startBackLectureQuestion,
    startNextLectureQuestion,
    startToggleShowAnswer,
    startHandleCloseButton,
    startLecturePreLogout,
  } = useLectureStore();
  const {
    lectureQuicez,
    nextLectureName,
    selectedLectureQuizIndex,
    startNextLecture,
  } = useLectureDashboardStore();
  const gifArrays = [circleAnimation, Trophy1, Trophy2];
  const selectedGif = gifArrays[completedPageAanimationIndex];

  const buttonRef = useRef(null);
  const audioRef = useRef();

  const isLastLecture = useMemo(
    () =>
      selectedLectureQuizIndex >=
      lectureQuicez[currentSectionCategory.title].length - 1,
    [selectedLectureQuizIndex, lectureQuicez]
  );

  useEffect(() => {
    if (audioRef?.current) {
      mixpanel.activityEnded(
        "Lecture",
        currentSectionCategory?.title,
        lectureName
      );
      audioRef.current.play();
    }
  }, []);

  useEffect(() => {
    startSettingLecture();
    startLecturePreLogout();
  }, []);

  useEffect(() => {
    const handleKeypress = (e) => {
      if (e.keyCode === 13) {
        buttonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeypress);

    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  }, []);

  if (progressBackdrop) return <AppLoader isLoading={true} />;

  return (
    <LectureCompletedContainer>
      <LectureCompletedAudio
        ref={audioRef}
        src="https://hp-appen.s3.eu-north-1.amazonaws.com/sounds/FINISHED_Lecture.wav"
        autoPlay
      />
      <LectureTopBar
        proptitle={lectureName}
        propback={startBackLectureQuestion}
        propforward={startNextLectureQuestion}
        propclose={startHandleCloseButton}
        propprogress={topBar.progress}
        isBackArrowDisabled={topBar.isBackArrowDisabled}
        isForwardArrowDisabled={topBar.isForwardArrowDisabled}
      />
      <BodyContainer>
        <CardContainer>
          <FirstContentContainer>
            <ConfettiExplosion style={{ position: "absolute", top: "33%" }} />
            <ContentContainer>
              <FontTitle>Grattis, du har slutfört lektionen!</FontTitle>
              <FontSubTitle>
                🎉 Fantastiskt jobbat! Dina ansträngningar och engagemang har
                tagit dig ett steg närmare ditt mål. Vi hoppas att du har funnit
                materialet både informativt och inspirerande.
              </FontSubTitle>
              {!isLastLecture && (
                <FontSubTitle>
                  Ta nästa steg i din inlärningsresa nu!
                </FontSubTitle>
              )}
              <FontSubTitleHeader>
                {isLastLecture
                  ? "Detta var sista lektionen!"
                  : `Nästa Lektion: ${nextLectureName}`}
              </FontSubTitleHeader>
              {!isLastLecture && (
                <ListContainer>
                  <QuestionStatement description={lectureEnding} />
                </ListContainer>
              )}
              <ButtonContainer>
                <StyledButton
                  lecturecomplete
                  ref={buttonRef}
                  onClick={startNextLecture}
                >
                  {isLastLecture ? "Avsluta Lektion" : "Starta Nästa Lektion"}
                </StyledButton>
                <PressEnterIcon />
              </ButtonContainer>
            </ContentContainer>
            <ImageContainer>
              <StyledGif src={selectedGif} alt="celebration_gif" />
            </ImageContainer>
          </FirstContentContainer>
        </CardContainer>
        <ButtonContainerSM>
          <StyledButton
            lecturecomplete
            ref={buttonRef}
            onClick={startNextLecture}
          >
            {isLastLecture ? "Avsluta Lektion" : "Starta Nästa Lektion"}
          </StyledButton>
          <PressEnterIcon />
        </ButtonContainerSM>
      </BodyContainer>
    </LectureCompletedContainer>
  );
}
