/** Libraries */
import React from "react";
import { useNavigate } from "react-router-dom";

/** Atoms */
import {
  DtkIconImage,
  ElfIconImage,
  KvaIconImage,
  LasIconImage,
  MekIconImage,
  NogIconsImage,
  OrdIconImage,
  XyzIconImage,
} from "../../../atom/CustomIcons/ExerciseIcons";

/** Custom hooks */
import { useMixpanel } from "../../../../hooks";

/** Assets */
import LectureDashboardCardImg from "../../../../assets/Icons/LectureDashboardCardImg.svg";

/** Styles */
import {
  ItemsContainer,
  LectureImage,
  LectureTitleFont,
  LinearProgressBar,
  MainContainer,
  PremiumImage,
  PremiumTitle,
  ProgressBarContainer,
  ProgressPercentageFont,
  StyledSkeleton,
  SubItemsContainer,
} from "./LectureDashboardCard.styles";

const LectureDashboardCard = ({
  isLoading,
  title,
  percentage,
  isPremium,
  sectionCategory,
  lecture,
  image,
  popup,
}) => {
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const handleNavigate = () => {
    mixpanel.cardClicked("Lectures", title);
    if (isPremium) {
      navigate("/lecture/theory", {
        state: {
          sectionCategory: sectionCategory,
          lecture: lecture,
        },
      });
    } else {
      popup(true);
    }
  };

  const renderPremiumSignal = () => {
    return (
      <PremiumImage isPremium={isPremium}>
        <PremiumTitle>Premium</PremiumTitle>
      </PremiumImage>
    );
  };

  const renderLectureContent = () => {
    return (
      <SubItemsContainer>
        <LectureTitleFont>{title}</LectureTitleFont>
        <ProgressBarContainer>
          <LinearProgressBar variant="determinate" value={Number(percentage)} />
          <ProgressPercentageFont>{percentage}%</ProgressPercentageFont>
        </ProgressBarContainer>
      </SubItemsContainer>
    );
  };

  const renderImageIcon = () => {
    switch (image) {
      case "DTK":
        return <DtkIconImage />;
      case "ELF":
        return <ElfIconImage />;
      case "KVA":
        return <KvaIconImage />;
      case "LÄS":
        return <LasIconImage />;
      case "MEK":
        return <MekIconImage />;
      case "NOG":
        return <NogIconsImage />;
      case "ORD":
        return <OrdIconImage />;
      case "XYZ":
        return <XyzIconImage />;
      default:
        return (
          <LectureImage src={LectureDashboardCardImg} alt="Lecture image" />
        );
    }
  };

  return !isLoading ? (
    <MainContainer onClick={handleNavigate}>
      {renderPremiumSignal()}
      <ItemsContainer>
        {renderImageIcon()}
        {renderLectureContent()}
      </ItemsContainer>
    </MainContainer>
  ) : (
    <StyledSkeleton />
  );
};

export default LectureDashboardCard;
