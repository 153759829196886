/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

export const discountSlice = createSlice({
  name: "discount",
  initialState: {
    discount: {},
    isCodeValid: null,
  },
  reducers: {
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    validateDiscountCode: (state, action) => {
      state.isCodeValid = action.payload;
    },
  },
});

export const { setDiscount, validateDiscountCode } = discountSlice.actions;
