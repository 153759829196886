/** Libraries */
import React from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Link } from "react-router-dom";
import { useUiStore } from "../../../hooks";
import { Box } from "@mui/material";

/** Material UI - Custom elements */
const ListContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const ListText = styled("p")(({ theme }) => ({
  fontSize: "15px",
  fontFamily: "Figtree",
  "& span": {
    fontWeight: "700",
  },
  "& a": {
    fontWeight: "400",
  },
}));

const ListImgIcon = styled(CheckCircleOutlineRoundedIcon)(({ theme }) => ({
  top: -2,
  position: "absolute",
  fontSize: "2rem",
  color: theme.palette.appcolors.success4,
}));

const GuaranteeImgIcon = styled(CheckCircleRoundedIcon)(({ theme }) => ({
  top: -2,
  position: "absolute",
  fontSize: "2rem",
  color: theme.palette.appcolors.success4,
}));

const ImgBackground = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  borderRadius: "50%",
  marginRight: "10px",
}));

const InfoLabel = styled("span")(({ theme }) => ({
  alignSelf: "flex-end",
  fontSize: "12px",
  fontFamily: "Figtree",
  textDecorationLine: "underline",
  color: theme.palette.appcolors.primaryColor,
  "a:visited": {
    color: theme.palette.appcolors.primaryColor,
  },
}));

const CommentTextContainer = styled(Box)(({ theme }) => ({
  minWidth: "100%",
}));
const CommentText = styled("p")(({ theme }) => ({
  minWidth: "300px",
  height: "16px",
  color: theme.palette.appcolors.black,
  fontFamily: "Figtree",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16.8px",
  textAlign: "right",
  marginTop: "2rem",
  marginBottom: "1rem",
  marginRight: "1rem",
  [theme.breakpoints.down("sm")]: {
    minWidth: "100%",
  },
}));

const RepeatedListItem = (text, guaranteelink, guarantee) => {
  const { startGuaranteePopup } = useUiStore();
  return (
    <ListItem disableGutters alignItems="flex-start">
      <ListContentContainer>
        <ImgBackground>
          {guarantee ? <GuaranteeImgIcon /> : <ListImgIcon />}
          <span
            style={{
              marginTop: "1px",
              backgroundColor: "#fff",
              height: "24px",
              width: "24px",
              borderRadius: "50%",
            }}
          ></span>
        </ImgBackground>
        {guaranteelink ? (
          <ListText>
            <span>Resultatgaranti:</span> Nå ditt mål eller få pengarna
            tillbaka!{" "}
            <InfoLabel>
              <Link onClick={() => startGuaranteePopup()}>(Läs mer)</Link>
            </InfoLabel>
          </ListText>
        ) : (
          <ListText>{text}</ListText>
        )}
      </ListContentContainer>
    </ListItem>
  );
};

export const GratisListValues = () => {
  return (
    <Grid>
      <List dense>
        {RepeatedListItem("Testa utvalda frågor")}
        {RepeatedListItem("Normerad poäng på varje kategori")}
        {RepeatedListItem("1 övningsprov med automatisk rättning")}
        {RepeatedListItem("AI-coach som hjälper dig med uppgifter dygnet runt")}
      </List>
    </Grid>
  );
};

export const FullListValues = () => {
  return (
    <Grid>
      <List dense>
        {RepeatedListItem("Tillgång till hela HP-appen")}
        {RepeatedListItem("12 000+ uppgifter med lösningar")}
        {RepeatedListItem("Övningsprov")}
        {RepeatedListItem("Teori & strategier för hela provet")}
        {RepeatedListItem("Normerad poäng på alla provdelar")}
        {RepeatedListItem("AI-coach som hjälper dig med uppgifter dygnet runt")}
      </List>
    </Grid>
  );
};

export const ShortPlanListValues = () => {
  return (
    <Grid>
      <List dense>
        {RepeatedListItem("Du behöver inte välja: tillgång till hela HP-appen")}
        {RepeatedListItem("12 000+ uppgifter med lösningar")}
        {RepeatedListItem("Övningsprov med automatisk rättning")}
        {RepeatedListItem("Teori & strategier för högskoleprovet")}
        {RepeatedListItem("Normerad poäng på alla provdelar")}
        {RepeatedListItem("AI studiecoach: hjälp med uppgifter dygnet runt")}
      </List>
    </Grid>
  );
};

export const PartialListValues = () => {
  return (
    <Grid>
      <List dense>
        {RepeatedListItem("Välj kvantitativ eller verbal del")}
        {RepeatedListItem("2500+ uppgifter med lösningar")}
        {RepeatedListItem("Övningsprovspass")}
        {RepeatedListItem("Teori & strategier för vald del")}
        {RepeatedListItem("Normerad poäng på alla provdelar")}
        {RepeatedListItem("AI-coach som hjälper dig med uppgifter dygnet runt")}
      </List>
      <CommentTextContainer>
        <CommentText>
          *Vald delplan (verbal eller kvantitativ) kan inte ändras, endast
          uppgraderas till Full Premium.
        </CommentText>
      </CommentTextContainer>
    </Grid>
  );
};
