/** Libraries */
import React, { useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

/** Molecules */
import KvantitativProvpassInfo from "../../../../molecule/KvantitativProvpasInfo/KvantitativProvpassInfo";
import VerbalProvpassInfo from "../../../../molecule/VerbalProvpassInfo/VerbalProvpassInfo";

/** Atoms */
import {
  InfoMainContainer,
  InfoHeaderTitle,
  InfoHeaderIconContainer,
  InfoButtonsContainer,
  InfoOtherButton,
  InfoStartButton,
} from "../../../../atom/SharedExamInfoStyledComponents/SharedExamInfoStyledComponents";
import { TimerIcon } from "../../../../atom/Timer/TimerPrimary/TimerPrimary.styles";
import { BarChartIcon } from "../../../../atom/BarChart/BarChartIcon";

/** Custom hooks */
import { useMixpanel } from "../../../../../hooks";

/** Utils */
import {
  timeToggle,
  transformSecToMinutes,
} from "../../../../../utils/timeManipulation";

/** Material UI - Custom elements */
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const HeaderSubtitle = styled("div")(({ theme }) => ({
  display: "flex",
  fontSize: "14px",
  paddingTop: "0.5rem",
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

const ProvPassInformation = (props) => {
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const { extendTimer, disabledTimer } = useSelector(
    (state) => state.timeCorrection
  );
  const theme = useTheme();

  const DEFAULT_TIME_SEK = timeToggle(
    props?.provpassTime ? props?.provpassTime : extendTimer
  );
  const DEFAULT_TIME_MINUTES = transformSecToMinutes(DEFAULT_TIME_SEK);

  const provpassNumber = useMemo(
    () =>
      props?.provpassOrder[props?.provpass?.simuleraQuizResult?.length || 0]
        ?.split("-")[2]
        ?.replace(/[^0-9]/g, ""),
    [props]
  );

  const isTimeDisabled = useMemo(
    () => (props?.isTimeDisabled ? props?.isTimeDisabled : disabledTimer),
    [props, disabledTimer]
  );

  const handleStartQuiz = () => {
    const mode = extendTimer
      ? "extended"
      : isTimeDisabled
      ? "disabled"
      : "normal";
    mixpanel.activityStarted("Exam", null, null, mode);

    navigate("/simuleraprov", {
      state: {
        id: props?.id,
        session: props?.session,
        provpass: props?.provpass,
        provpassOrder: props?.provpassOrder,
        totalTime: DEFAULT_TIME_SEK,
        isTimeDisabled: isTimeDisabled,
      },
    });
  };

  return (
    <InfoMainContainer>
      <HeaderContainer>
        <InfoHeaderTitle>
          {props?.provpassOrder[
            props?.provpass?.simuleraQuizResult?.length || 0
          ]?.includes("KVA")
            ? "Kvantitativt "
            : "Verbalt "}
          provpass - Provpass {provpassNumber}
        </InfoHeaderTitle>
        <HeaderSubtitle>
          <InfoHeaderIconContainer tabletmargin={"4rem"} phonemargin={"2rem"}>
            <BarChartIcon />
            40 frågor
          </InfoHeaderIconContainer>
          <InfoHeaderIconContainer>
            <TimerIcon
              style={{
                marginRight: "0.3rem",
                color: theme.palette.appcolors.grey,
              }}
            />
            {isTimeDisabled ? "Utan tid" : `${DEFAULT_TIME_MINUTES} min`}
          </InfoHeaderIconContainer>
        </HeaderSubtitle>
      </HeaderContainer>

      {props?.provpassOrder[
        props?.provpass?.simuleraQuizResult.length || 0
      ]?.includes("KVA") ? (
        <KvantitativProvpassInfo />
      ) : (
        <VerbalProvpassInfo />
      )}

      <InfoButtonsContainer>
        <InfoOtherButton onClick={props?.closepopup}>
          Fortsätt senare
        </InfoOtherButton>
        <InfoStartButton onClick={handleStartQuiz}>
          Starta provpass
        </InfoStartButton>
      </InfoButtonsContainer>
    </InfoMainContainer>
  );
};

export default ProvPassInformation;
