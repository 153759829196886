/** Libraries */
import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";
import ProgressBar from "@ramonak/react-progress-bar";
import Tooltip from "@mui/material/Tooltip";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";

/** Atoms */
import Timer from "../../../atom/Timer/timer";
import { SwitchIcon } from "../../../atom/CustomIcons/SwitchIcon";
import { StyledCloseIcon } from "../../../atom/CustomIcons/StyledCloseIcon";

/** Molecules */
import useWindowDimensions from "../../../molecule/WindowDimensions/dimension";

/** Material UI - Custom elements */
const MainContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  left: 0,
  right: 0,
  top: 0,
  width: "100%",
  height: "108px",
  zIndex: 1000,
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  margin: "auto",
  padding: "1rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "2rem",
  [theme.breakpoints.down(1080)]: {
    height: "86px",
  },
  [theme.breakpoints.down(600)]: {
    gap: "1rem",
    height: "52px",
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1440px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const NumbersContainer = styled(Box)(({ theme, timer }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "18px",
  fontWeight: "600",
  color: theme.palette.appcolors.primaryColor,
  minWidth: "max-content",
  paddingRight: !timer && "1rem",
  paddingLeft: timer && "1rem",
  cursor: timer ? "pointer" : "default",
  [theme.breakpoints.down(1080)]: {
    display: !timer && "none",
  },
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

const NotesIcon = styled(BookmarkBorderRoundedIcon)(({ theme }) => ({
  display: "none", // Hide the notes icon until it is implemented
  cursor: "pointer",
  fontSize: "28px",
  color: theme.palette.appcolors.grey7,
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

function Header(props) {
  const { width } = useWindowDimensions();
  const [quiz, setQuiz] = useState();
  const [barPerccentage, setBarPercentage] = useState(0);
  const [hideTimer, setHideTimer] = useState(false);
  const [tooltipTimer, setTooltipTimer] = useState(true);
  const theme = useTheme();

  let totalPAragraphQuestions = 0;

  useEffect(() => {
    let correctAnswers = 0;
    let totalQuestions = 0;
    setQuiz(props?.quiz);
    totalPAragraphQuestions = totalPAragraphQuestions + 1;

    props.quiz &&
      props?.quiz?.map((item) => {
        if (item.type == "multiple") {
          totalQuestions = totalQuestions + item.question.length;
          item.question.map((question) => {
            if (question.optionId) {
              correctAnswers = correctAnswers + 1;
            }
          });
        } else {
          totalQuestions = totalQuestions + 1;
          if (item.answer) {
            correctAnswers = correctAnswers + 1;
          }
        }
      });
    setBarPercentage((correctAnswers / totalQuestions) * 100);
  }, [props?.quiz]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTooltipTimer(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const CustomizedTimer = () => {
    return (
      <>
        {tooltipTimer && (
          <Tooltip
            onMouseEnter={() => setTooltipTimer(false)}
            title={"Klicka för att visa eller dölja timer"}
            placement="bottom"
            arrow
            open={props.quiz && tooltipTimer}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              },
            }}
          >
            <NumbersContainer timer onClick={() => setHideTimer(!hideTimer)}>
              <Timer
                continueStatus={props.status}
                hideTimer={hideTimer}
                time={props.time}
                timeleft={(timer) => {
                  if (!props.status) {
                    props.timeLeft(timer);
                    props.nextPress();
                  }
                }}
                callBackForTimer={(value) => props.callBackForTimer(value)}
                onCloseTimer={() => props.onCloseTimer()}
              />
            </NumbersContainer>
          </Tooltip>
        )}
        {!tooltipTimer && (
          <Tooltip
            title={!hideTimer ? "Dölj timer" : "Visa timer"}
            placement="bottom"
            arrow
            followCursor
          >
            <NumbersContainer timer onClick={() => setHideTimer(!hideTimer)}>
              <Timer
                continueStatus={props.status}
                hideTimer={hideTimer}
                time={props.time}
                timeleft={(timer) => {
                  if (!props.status) {
                    props.timeLeft(timer);
                    props.nextPress();
                  }
                }}
                callBackForTimer={(value) => props.callBackForTimer(value)}
                onCloseTimer={() => props.onCloseTimer()}
              />
            </NumbersContainer>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <MainContainer bgprop={props?.resultSummary?.toString()}>
      <StyledCloseIcon onClick={props.goBack} />
      <HeaderContainer>
        <NumbersContainer>
          {props.selectedIndex}/{props.totalQuestions}
        </NumbersContainer>
        <ProgressBarContainer>
          <ProgressBar
            completed={
              quiz
                ? Math.floor(barPerccentage)
                : Math.floor((props.selectedIndex / props.totalQuestions) * 100)
            }
            bgColor={theme.palette.appcolors.success4}
            baseBgColor={theme.palette.appcolors.grey3}
            borderRadius="50px"
            height="13px"
            isLabelVisible={false}
            transitionDuration="0.6s"
            labelSize="12px"
          />
        </ProgressBarContainer>
        {props.params && props.params.value ? CustomizedTimer() : <div />}
        {width < 1080 && <NotesIcon />}
      </HeaderContainer>
      {width > 600 && <SwitchIcon exercise />}
    </MainContainer>
  );
}

export default Header;
