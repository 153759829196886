import { createSlice } from "@reduxjs/toolkit";

export const feedbackQuestionSlice = createSlice({
  name: "feedbackQuestion",
  initialState: {
    isFeedbackPopupOpened: false,
    count: null,
    anchorEl: null,
    questionId: null,
    sectionCategory: null,
    questionCategory: null,
  },
  reducers: {
    setFeedbackQuestionData: (state, action) => {
      state.questionId = action.payload.questionId;
      state.sectionCategory = action.payload.sectionCategory;
      state.questionCategory = action.payload.questionCategory;
    },
    setFeedbackValues: (state, action) => {
      state.isFeedbackPopupOpened = true;
      state.count = action.payload.count;
      state.anchorEl = action.payload.anchorEl;
    },
    feedbackQuestionLogout: (state) => {
      state.isFeedbackPopupOpened = false;
      state.count = null;
      state.anchorEl = null;
    },
  },
});
export const {
  setFeedbackQuestionData,
  setFeedbackValues,
  feedbackQuestionLogout,
} = feedbackQuestionSlice.actions;
