/** Libraries */
import { styled } from "@mui/material/styles";

/** Styled Components */
export const PlanBox = styled("div")(({ theme, planid }) => ({
  height: "723px",
  maxWidth: "338px",
  padding: "40px 20px",
  backgroundColor:
    planid === "planTwo"
      ? theme.palette.appcolors.primaryColor18
      : "transparent",
  border:
    planid === "planTwo"
      ? `2px solid ${theme.palette.appcolors.primaryColor}`
      : `1px solid ${theme.palette.appcolors.grey3}`,
  borderRadius: "8px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  alignItems: "center",
  [theme.breakpoints.down(1280)]: {
    padding: "20px",
    maxWidth: "546px",
    height: "100%",
  },
}));

export const PopularityTab = styled("div")(({ theme, planid }) => ({
  fontSize: "16px",
  fontWeight: "600",
  position: "absolute",
  top: planid === "planTwo" ? -11 : -10,
  left: "50%",
  transform: "translate(-50%, 0)",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  color: theme.palette.appcolors.primaryColor,
  padding: "2px 24px",
  borderWidth: planid === "planTwo" ? 2 : 1,
  borderStyle: "solid",
  borderRadius: "20px",
  zIndex: 1,
  [theme.breakpoints.down(1280)]: {
    fontSize: "9px",
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "14px",
  },
}));

export const DiscountLabel = styled("p")(({ theme, bb }) => ({
  color: bb
    ? theme.palette.appcolors.black
    : theme.palette.appcolors.tertiaryColor11,
  fontWeight: "400",
  fontSize: "15px",
  fontFamily: "Figtree",
  position: "absolute",
  top: 90,
  [theme.breakpoints.down(1280)]: {
    top: 70,
  },
}));

export const FullPriceLabel = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.grey,
  fontWeight: "600",
  fontSize: "18px",
  position: "absolute",
  top: 90,
  textDecoration: "none",
  "&::after": {
    content: '""',
    position: "absolute",
    left: -10,
    right: -10,
    top: "50%",
    height: "2px",
    backgroundColor: theme.palette.appcolors.grey,
  },
  [theme.breakpoints.down(1280)]: {
    top: 70,
  },
}));

export const PriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "2rem",
}));

export const PaySectionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  height: "120px",
  position: "relative",
  paddingTop: "2.5rem",
}));

export const PayButtonContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const ListContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  maxHeight: "100%",
  margin: "auto",
  marginTop: "0px",
}));

export const RadioContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 10,
  fontSize: "12px",
  fontFamily: "Figtree",
}));

export const PriceText = styled("h2")(({ theme, premium }) => ({
  fontSize: "48px",
  fontWeight: "700",
  color: premium
    ? theme.palette.appcolors.primaryColor4
    : theme.palette.appcolors.primaryColor,
}));

export const MonthsTitle = styled("h1")(({ theme, planid, gratistitle }) => ({
  color:
    planid === "planTwo" || gratistitle
      ? theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.black,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  height: gratistitle && "40px",
}));

export const ManadText = styled("h1")(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  marginBottom: "-5px",
}));

export const HorizontalLine = styled("hr")(({ theme, planid }) => ({
  color:
    planid === "planTwo"
      ? theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.black,
  border: "none",
  borderTop: `1px solid ${
    planid === "planTwo"
      ? theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.black
  }`,
  width: "100%",
  padding: 0,
  margin: "0px 0px 30px 0px",
}));

export const PriceSubtitle = styled("p")(({ theme, toptext, bottomtext }) => ({
  position: "absolute",
  fontFamily: "Figtree",
  top: toptext && -10,
  bottom: bottomtext && 0,
  fontSize: toptext ? "12px" : "10px",
}));

export const FullPlanSwitchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  marginTop: "-4rem",
}));

export const PaymentTermText = styled("span")(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "21px",
  color: theme.palette.appcolors.primaryColor,
  whiteSpace: "nowrap",
  padding: 0,
  marginTop: "-10px",
}));

export const PriceTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 0,
}));
