/** Libraries */
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

/** Molecules */
import QuestionWrapper from "../../../../molecule/QuestionContainers/QuestionWrapper";
import QuestionStatement from "../../../../molecule/QuestionStatement/QuestionStatement";
import { SparaButton } from "../../../../molecule/Buttons/SparaButton/SparaButton";
import useWindowDimensions from "../../../../molecule/WindowDimensions/dimension";

/**
 * @docs https://www.notion.so/Atomic-design-c511ecc580614e919d052ecee7b6a8e1?pvs=4#1613d3b6a80d41d6b5f2958d2ce91628
 */
export const SQDescription = ({
  yScrollEnabled,
  doesSparaButtonExists,
  isQuestionFlagged,
  flagQuestion,
  sparaSaveButtonRef,
  sectionTitle,
  title,
  questionDescription,
  questionIndications,
  questionImage,
  ...props
}) => {
  const { width } = useWindowDimensions();
  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
      }}
      {...props}
    >
      {doesSparaButtonExists && (
        <SparaButton
          isExam={true}
          isQuestionFlagged={isQuestionFlagged}
          sparaSaveButtonRef={sparaSaveButtonRef}
          onClick={flagQuestion}
        />
      )}
      <QuestionWrapper
        yScrollEnabled={yScrollEnabled}
        questionTypeTitle={sectionTitle}
        ord={sectionTitle === "ORD"}
        sparaButtonExists={doesSparaButtonExists}
        {...props}
      >
        <QuestionStatement
          description={questionDescription}
          indications={questionIndications}
          type={sectionTitle}
          image={questionImage}
          title={title}
          width={width}
        />
      </QuestionWrapper>
    </Box>
  );
};

SQDescription.propTypes = {
  doesSparaButtonExists: PropTypes.bool,
  isQuestionFlagged: PropTypes.bool,
  flagQuestion: PropTypes.func,
  sparaSaveButtonRef: PropTypes.object,
  sectionTitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  questionDescription: PropTypes.string.isRequired,
  questionIndications: PropTypes.array,
  questionImage: PropTypes.bool,
};

SQDescription.defaultProps = {
  yScrollEnabled: false,
  doesSparaButtonExists: false,
  isQuestionFlagged: false,
  flagQuestion: () => {},
  sparaSaveButtonRef: null,
  questionIndications: null,
  questionImage: "",
  title: "",
};
