import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const PremiumSticker = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  width: "100%",
  zIndex: "3",
  position: "absolute",
  top: "0",
}));

export const PremiumLabel = styled("span")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "2px",
  borderRadius: "10px 0",
  textAlign: "center",
  width: "77px",
  height: "23px",
  fontSize: "12px",
  backgroundColor: theme.palette.appcolors.primaryColor,
  color: theme.palette.appcolors.tertiaryColor10,
}));

export const CombinedCardComponents = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "100%",
}));

export const LeftSideCardComponents = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingRight: "5%",
  width: "100%",
}));

export const RightSideCardComponents = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.appcolors.black,
}));

export const DownsideCardComponent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
}));

export const InnerDownsideCardComponent = styled("div")(
  ({ theme, prognos }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: `${prognos === "Prognos" ? ".5" : "1"}`,
    padding: "14px 24px 12px",
    width: "100%",
  })
);

export const CardTitleTextContainer = styled("div")(({ theme }) => ({
  fontSize: "23px",
  fontWeight: "600",
  paddingLeft: "24px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  alignSelf: "flex-start",
  flex: "1.6",
  minHeight: "130px",
  backgroundColor: theme.palette.appcolors.tertiaryColor,
  borderRadius: "10px 10px 0px 0px",
  width: "100%",
  overflow: "hidden",
  zIndex: "2",
}));

export const CardTitleText = styled(Box)(({ theme }) => ({
  color: theme.palette.appcolors.black4,
  zIndex: "3",
}));

export const StyledBannerBackground = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "absolute",
  zIndex: "1",
  right: "-60px",
  backgroundColor: theme.palette.appcolors.tertiaryColor6,
  width: "211px",
  height: "211px",
  borderRadius: "211px",
  transition: "all 0.2s ease",
}));
export const CardSubtitleText = styled("div")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: "16px",
  fontFamily: "Figtree",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  lineHeight: "21px",
  WebkitLineClamp: "3",
  WebkitBoxOrient: "vertical",
}));

export const IconBox = styled(Box)(({ theme }) => ({
  maxWidth: "160px",
  width: "100%",
  height: "100%",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const PrognosText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: "12px",
}));
