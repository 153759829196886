/** Libraries */
import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

/** Organisms */
import ExerciseTopBar from "../../../components/organism/TopBars/ExerciseTopBar/ExerciseTopBar";
import ExamTopBar from "../../../components/organism/TopBars/ExamTopBar/ExamTopBar";
import { MQDescription } from "../../../components/organism/Quiz";
import MQQuestionReview from "../../../components/organism/Quiz/MQQuestion/MQQuestionReview/MQQuestionReview";

/** Templates */
import { MQTemplate } from "../../../components/template";

/** Custom hooks */
import { useQuestionViewerStore } from "../../../hooks/questionViewer/useQuestionViewerStore";

const QuestionViewerMQ = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { question, startSettingToggleQuestionViewerAnswerVisibility } =
    useQuestionViewerStore();

  return (
    <MQTemplate
      sx={{
        paddingTop: "0px",
      }}
      topBar={
        pathname.split("/")[1] === "exercise" ? (
          <ExerciseTopBar
            selectedIndex={1}
            totalQuestions={1}
            params={{ value: true }}
            status={false}
            time={false}
            nextPress={() => {}}
            onCloseTimer={() => {}}
            quiz={[question]}
            timeLeft={(timer) => {}}
            callBackForTimer={(value) => {}}
            goBack={() => navigate("/home")}
            resultSummary
          />
        ) : (
          <ExamTopBar
            time={undefined}
            setShouldNavigate={() => {}}
            currentIndex={0}
            quiz={{ question: [question?.multipartQuestion?.question[0]] }}
            setBackPressPopup={() => {}}
            setTestSubmitPopUp={() => {}}
            provpassNumber={question?.year?.title?.charAt(
              question?.year?.title?.length - 1
            )}
            handleOpenOversikt={() => {}}
            handleLamnaIn={() => {}}
            isResultSection={true}
            handleNavigationResultPage={() => navigate("/home")}
          />
        )
      }
      dialogBox={
        <MQDescription
          description={question?.multipartQuestion.description}
          sectionTitle={question?.sectionCategories?.title}
          currNumberOfQuestions={question?.question?.length}
          sx={{
            marginTop: pathname.split("/")[1] === "exercise" ? 0 : "4rem",
          }}
        />
      }
      questions={
        <MQQuestionReview
          isMQSection={pathname.split("/")[1] === "exam"}
          isResultPage={false}
          isNastaButtonDisabled={true}
          isForaendeButtonDisabled={true}
          isNastaExamButtonDisabled={true}
          sectionTitle={question?.sectionCategories?.title}
          questions={question?.multipartQuestion?.question}
          selectedIndex={0}
          totalQuestions={question?.multipartQuestion?.question?.length}
          handleResultButton={() => {}}
          handleLeftButton={() => {}}
          handleRightButton={() => {}}
          handleShowAnswer={startSettingToggleQuestionViewerAnswerVisibility}
          handleOnClickNasta={() => {}}
          sx={{
            marginTop: pathname.split("/")[1] === "exercise" ? 0 : "10rem",
          }}
        />
      }
    />
  );
};

export default QuestionViewerMQ;
