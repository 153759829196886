import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { instance2, EndPoints } from "../../../../service/Route";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CommonPopup from "../../../../molecule/CommonPopup/CommonPopup";
import ExamTopBar from "../../../../organism/TopBars/ExamTopBar/ExamTopBar";
import OversiktModal from "../OverBlick/OversiktModal";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import {
  ExamBodyMQ,
  ExamBodyReviewMQ,
  ExamBodySQ,
  ExamBodyReviewSQ,
} from "../ExamBody";
import { SendSentryError } from "../../../../../tools";
import {
  useExamStore,
  useMixpanel,
  useTimerStore,
  useUiStore,
} from "../../../../../hooks";
import { BackgroundContainer } from "../../../../atom/SharedExamExerciseStyledComponents/BackgroundContainer";
import { ExamStartupDialog } from "../../../StartInfoPopup/StartInfoPopup";
import { useCustomExamsSQStore } from "../../../../../hooks/customExams/SingleQuestions/useCustomExamsSQStore";

const StandardViewXyz = () => {
  const navigate = useNavigate();
  const params = useLocation();
  const theme = useTheme();
  const mixpanel = useMixpanel();
  const { startLoadingExamsData } = useExamStore();
  const {
    totalTime,
    currTime,
    isPaused,
    startTimer,
    startPausingTimer,
    startReactivetingTimer,
    startTimerLogout,
  } = useTimerStore();
  const { currentQuestion } = useCustomExamsSQStore();

  const sectionCategory = currentQuestion?.sectionCategories?.title;

  const { user } = useSelector((state) => state.user);
  const { disabledTimer } = useSelector((state) => state.timeCorrection);
  const isTimeDisabled = useMemo(
    () =>
      params?.state?.isTimeDisabled
        ? params?.state?.isTimeDisabled
        : disabledTimer,
    [params, disabledTimer]
  );

  const [quiz, setQuiz] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [SubmitedQuestions, setSubmitedQuestions] = useState([]);
  const [backPressPopup, setBackPressPopup] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [open, setOpen] = useState(true);
  const [oversiktModalStatus, setOversiktModalStatus] = useState(false);
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();

  /** Popups */
  const [testSubmitPopUp, setTestSubmitPopUp] = useState(false);
  const [timeOverPopUp, setTimeOverPopUp] = useState(false);
  const [lamnaInhandIn, setLamnaInhandIn] = useState(false);

  const [isProvpassSubmited, setIsProvpassSubmited] = useState(false);
  const saveButtonRef = useRef(null);
  const sparaButtonExists = true;

  useEffect(() => {
    if (params?.state?.questionIndex != undefined) {
      if (!isTimeDisabled && !params?.state?.isReview) {
        startTimer(params?.state.totalTime);
      }
      setQuiz(params?.state?.quiz);
      setCurrentIndex(params?.state?.questionIndex);
      setSubmitedQuestions(params?.state?.SubmittedQuestions || []);
      setOpen(false);
    } else {
      const URL = EndPoints.getSimuleraQuiz + params?.state?.id;

      instance2
        .get(URL)
        .then((response) => {
          setQuiz(response.data.simuleraQuiz);
          if (!isTimeDisabled && !params?.state?.isReview) {
            startTimer(params?.state.totalTime);
          }
          setOpen(false);
        })
        .catch((error) => {
          console.error("Error getting simulera quiz:", error);
          SendSentryError(
            "SIMULERA-QUIZ",
            "Error starting an exam Timer",
            error
          );
          Sentry.captureException(error, { extra: { userId: user?._id } }); // Log user ID
          startErrorPopup();
          startErrorRedirectionTrue();
        });
    }
  }, []);

  useEffect(() => {
    if (currTime === 0 && totalTime !== 0) {
      setShouldNavigate(true);
      Sentry.captureMessage("Timer reached zero");
    }
  }, [currTime]);

  useEffect(() => {
    if (!isProvpassSubmited && shouldNavigate) {
      setTimeOverPopUp(true);
    } else {
      setTimeOverPopUp(false);
    }
  }, [isProvpassSubmited, shouldNavigate, timeOverPopUp]);

  // #region Submit Quiz

  const submitQuiz = () => {
    startPausingTimer(true);
    setIsProvpassSubmited(true);
    setTimeOverPopUp(false);
    setTestSubmitPopUp(false);
    setOpen(true);

    const data = {
      simuleraQuiz: quiz?._id,
      isTimeDisabled: disabledTimer,
      simuleraSeason: quiz?.season,
      quiz: fillInMissingQuestions(SubmitedQuestions, quiz?.question),
    };

    const URL = EndPoints.submitSimuleraTest;
    instance2
      .post(URL, data)
      .then((response) => {
        if (response.status === 200) {
          const updatePreviosExam = EndPoints.updatePreviousExam;
          const examData = {
            simuleraSeason: quiz?.season,
            user: user._id,
            simuleraQuizResult: response?.data?._id,
          };
          const provpassNumber =
            params.state.provpass?.simuleraQuizResult?.length || 0;
          instance2
            .post(updatePreviosExam, examData)
            .then((res) => {
              if (provpassNumber < 3) {
                const currentSeason = quiz?.season;
                navigate("/provpassinfo", {
                  state: {
                    id: currentSeason,
                    session: params?.state?.session,
                    provpass: res.data.simuleraSeasonResult,
                    provpassOrder: params?.state?.provpassOrder,
                    totalTime: params?.state.totalTime,
                    isTimeDisabled: isTimeDisabled,
                  },
                });
              } else if (provpassNumber === 3) {
                mixpanel.activityEnded("Exam");
                navigate("/provresultat", {
                  state: {
                    seasonId: quiz?.season,
                    quizId: params?.state?.provpass?._id,
                    provpassOrder: params?.state?.provpassOrder,
                    isTimeDisabled: isTimeDisabled,
                  },
                });
              }
            })
            .catch((error) => {
              console.error("Error updating simulera test:", error);
              startErrorPopup();
              SendSentryError("SIMULERA-TEST", "Error updating simulera test", {
                details: "Failed to update simulera test",
                error,
              });
            })
            .finally(() => {
              startLoadingExamsData();
              setTimeout(() => {
                startTimerLogout();
              }, 500);
              setOpen(false);
            });
        } else {
          startErrorPopup();
        }
      })
      .catch((error) => {
        console.error("Error submitting simulera test:", error);
        startErrorPopup();
        SendSentryError("SIMULERA-TEST", "Error submitting simulera test", {
          details: "Failed to submit simulera test",
          error,
        });
      })
      .finally(() => {
        setTimeout(() => {
          startTimerLogout();
        }, 500);
      });
  };

  const isReadingComprehension = useMemo(
    () =>
      quiz?.question[currentIndex]?.sectionCategories?.title === "ELF" ||
      quiz?.question[currentIndex]?.sectionCategories?.title === "LÄS" ||
      quiz?.question[currentIndex]?.sectionCategories?.title === "DTK",
    [currentIndex, quiz?.question]
  );

  const provpassNumber = useMemo(() => {
    const provpassOrder = params?.state?.provpassOrder;
    return provpassOrder[
      params?.state?.provpass?.simuleraQuizResult?.length || 0
    ]
      .split("-")[2]
      .replace(/[^0-9]/g, "");
  }, [params]);

  const handleNavigationResultPage = () => {
    const { examResultData, isCustomExam } = params.state || {};
    if (examResultData && examResultData.seasonId && !isCustomExam) {
      navigate("/provresultat", {
        state: {
          seasonId: params.state?.examResultData?.seasonId,
          quizId: params.state?.examResultData?.quizId,
          provpassOrder: params.state?.examResultData?.provpassOrder,
          isTimeDisabled: isTimeDisabled,
        },
      });
    } else {
      navigate("/cuexresult", {
        state: {
          seasonId: params.state?.examResultData?.seasonId,
          quizId: params.state?.examResultData?.quizId,
          // provpassOrder: params.state?.examResultData?.provpassOrder,
          isTimeDisabled: isTimeDisabled,
        },
      });
    }
  };

  const flagQuestion = () => {
    let allQuiz = { ...quiz };
    const qz = allQuiz?.question ?? [];
    let question = qz[currentIndex];

    if (question.isFlaged) {
      question.isFlaged = false;
      setQuiz(allQuiz);
    } else if (question.optionId) {
      question.isFlaged = true;
      setQuiz(allQuiz);
    } else {
      question.isFlaged = true;
      setQuiz(allQuiz);
    }
    saveButtonRef?.current.blur();
  };

  const SelectFunc = (optionId, optionIndex) => {
    let allQuiz = { ...quiz };
    const qz = allQuiz?.question;
    let question = qz[currentIndex];
    question.selectedOptionIndex = optionIndex;
    question.optionId = optionId;
    allQuiz.question = qz;
    setQuiz(allQuiz);

    if (question.optionId && question.isFlaged) {
      question.isFlaged = false;
      flagQuestion();
    }
    let data = {
      questionId: question._id,
      optionId: optionId,
      sectionCategory: question?.sectionCategories._id,
      questionCategory: question?.questionCategory[0],
      timeleft: currTime,
      totaltime: isTimeDisabled ? 0 : totalTime,
      spendtime: isTimeDisabled ? 0 : totalTime - currTime,
      isTimeDisabled: isTimeDisabled,
    };

    console.log("question?.sectionCategories", question?.sectionCategories);

    let questions = [...SubmitedQuestions];

    const exist = questions.some((obj) => obj.questionId === question._id);
    if (exist) {
      const ind = questions.findIndex((obj) => obj.questionId === question._id);
      questions.splice(ind, 1, data);
      setSubmitedQuestions(questions);
    } else {
      questions.push(data);
      setSubmitedQuestions(questions);
    }
  };

  const fillInMissingQuestions = (submittedQuestions, allQuestions) => {
    let questions = [...submittedQuestions];
    allQuestions.forEach((question) => {
      const exist = questions.some((obj) => obj.questionId === question._id);
      if (!exist) {
        let data = {
          questionId: question._id,
          optionId: null,
          sectionCategory: question?.sectionCategories._id,
          questionCategory: question?.questionCategory[0],
          timeleft: currTime,
          totaltime: isTimeDisabled ? 0 : totalTime,
          spendtime: 0,
          isTimeDisabled: isTimeDisabled,
        };
        questions.push(data);
      }
    });
    return questions;
  };

  const ifAnswerExist = quiz?.question.some(
    (question) => question.questionAnswer
  );

  const isCurrentQuestion = quiz?.question[currentIndex];
  const questionNewStructure = {
    answer: isCurrentQuestion?.questionAnswer,
    ...isCurrentQuestion,
  };
  questionNewStructure.options = [isCurrentQuestion?.options];

  const isLastQuestion = currentIndex === quiz?.question.length - 1;

  const currentProvPassNumber =
    params.state.provpass?.simuleraQuizResult?.length || 0;

  const RenderBodySq = () => {
    return ifAnswerExist ? (
      <ExamBodyReviewSQ
        quiz={quiz}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        isReadingComprehension={isReadingComprehension}
        currentQuestion={isCurrentQuestion}
        sparaButtonExists={sparaButtonExists}
        questionNewStructure={questionNewStructure}
        saveButtonRef={saveButtonRef}
        isLastQuestion={isLastQuestion}
        open={open}
        ifAnswerExist={ifAnswerExist}
        setBackPressPopup={setBackPressPopup}
        SelectFunc={SelectFunc}
        flagQuestion={flagQuestion}
        setShouldNavigate={setShouldNavigate}
        handleNavigationResultPage={handleNavigationResultPage}
      />
    ) : (
      <ExamBodySQ
        quiz={quiz}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        isReadingComprehension={isReadingComprehension}
        currentQuestion={isCurrentQuestion}
        sparaButtonExists={sparaButtonExists}
        questionNewStructure={questionNewStructure}
        saveButtonRef={saveButtonRef}
        isLastQuestion={isLastQuestion}
        open={open}
        ifAnswerExist={ifAnswerExist}
        setBackPressPopup={setBackPressPopup}
        SelectFunc={SelectFunc}
        flagQuestion={flagQuestion}
        setShouldNavigate={setShouldNavigate}
        handleLamnaIn={() => setLamnaInhandIn(true)}
      ></ExamBodySQ>
    );
  };

  const RenderBodyMq = () => {
    return ifAnswerExist ? (
      <ExamBodyReviewMQ
        quiz={quiz}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        isReadingComprehension={isReadingComprehension}
        currentQuestion={isCurrentQuestion}
        sparaButtonExists={sparaButtonExists}
        questionNewStructure={questionNewStructure}
        saveButtonRef={saveButtonRef}
        isLastQuestion={isLastQuestion}
        open={open}
        ifAnswerExist={ifAnswerExist}
        setBackPressPopup={setBackPressPopup}
        SelectFunc={SelectFunc}
        flagQuestion={flagQuestion}
        setShouldNavigate={setShouldNavigate}
        handleNavigationResultPage={handleNavigationResultPage}
      />
    ) : (
      <ExamBodyMQ
        quiz={quiz}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        isReadingComprehension={isReadingComprehension}
        currentQuestion={isCurrentQuestion}
        sparaButtonExists={sparaButtonExists}
        questionNewStructure={questionNewStructure}
        saveButtonRef={saveButtonRef}
        isLastQuestion={isLastQuestion}
        open={open}
        ifAnswerExist={ifAnswerExist}
        setBackPressPopup={setBackPressPopup}
        SelectFunc={SelectFunc}
        flagQuestion={flagQuestion}
        setShouldNavigate={setShouldNavigate}
        handleLamnaIn={() => setLamnaInhandIn(true)}
      />
    );
  };
  return (
    <div>
      <ExamStartupDialog />
      <OversiktModal
        quiz={quiz}
        setCurrentIndex={setCurrentIndex}
        timeLeft={currTime}
        params={params}
        open={oversiktModalStatus}
        handleStatus={setOversiktModalStatus}
        handleLamnaIn={() => setLamnaInhandIn(true)}
        isResultSection={
          quiz?.question[currentIndex]?.questionAnswer ? true : false
        }
        handleNavigationResultPage={handleNavigationResultPage}
      />

      <Backdrop
        sx={{
          color: theme.palette.appcolors.popupOverlay,
          zIndex: (theme) => theme.zIndex.drawer + 1300,
          width: "100%",
        }}
        open={open}
      >
        <CircularProgress color="inherit" size="5rem" />
      </Backdrop>
      <BackgroundContainer>
        <CommonPopup
          status={backPressPopup}
          closePopup={() => setBackPressPopup(false)}
          title="Vill du avsluta provpasset?"
          description="Du måste göra klart provpasset för att få din poäng. Om du trycker
          på avsluta, sparas inte dina svar."
          cancelBtnName="Gör klart provpass"
          agreeBtnName="Avsluta prov"
          redirect={() => {
            mixpanel.activityEnded("Exam");
            navigate("/courses");
          }}
        />
        <CommonPopup
          status={timeOverPopUp}
          closePopup={() => setTimeOverPopUp(false)}
          redirect={submitQuiz}
          title="Provpasset är klart."
          description={`${
            currentProvPassNumber === 3
              ? "Efter att du har lämnat in detta provpass är provet klart."
              : "Efter att du har lämnat in kan du ta en paus innan du  påbörjar nästa provpass! Ditt resultat kommer sparas."
          }`}
          oneButtonPopup
          agreeBtnName="Lämna in provpasset"
        />
        <CommonPopup
          status={lamnaInhandIn}
          closePopup={() => setLamnaInhandIn(false)}
          redirect={submitQuiz}
          title="Vill du lämna in?"
          description={`${
            currentProvPassNumber === 3
              ? "Efter att du har lämnat in detta provpass är provet klart."
              : "Efter att du har lämnat in kan du ta en paus innan du  påbörjar nästa provpass! Ditt resultat kommer sparas."
          }`}
          oneButtonPopup
          agreeBtnName="Lämna in provpasset"
        />
        {!open && (
          <ExamTopBar
            time={isTimeDisabled ? undefined : currTime}
            setShouldNavigate={setShouldNavigate}
            currentIndex={currentIndex}
            quiz={quiz}
            setBackPressPopup={setBackPressPopup}
            setTestSubmitPopUp={setTestSubmitPopUp}
            provpassNumber={
              !quiz?.question[currentIndex].year.title
                ? provpassNumber
                : quiz?.question[currentIndex].year.title.charAt(
                    quiz?.question[currentIndex].year.title.length - 1
                  )
            }
            isCustomExam={params.state.isCustomExam}
            isReview={params.state.isReview}
            handleOpenOversikt={() => setOversiktModalStatus(true)}
            handleLamnaIn={() => setLamnaInhandIn(true)}
            examNumber={params.state?.examNumber}
            sectionCategory={sectionCategory}
            isResultSection={
              quiz?.question[currentIndex]?.questionAnswer ? true : false
            }
            handleNavigationResultPage={handleNavigationResultPage}
          />
        )}
        {!open && (
          <>
            {isReadingComprehension ? (
              <>{RenderBodyMq()}</>
            ) : (
              <>{RenderBodySq()}</>
            )}
          </>
        )}
      </BackgroundContainer>
    </div>
  );
};

export default StandardViewXyz;
