/** Tools */
import { SendSentryError } from "../tools";

export const handleErrors = (error) => {
  const { response } = error;
  const message = response?.data?.error?.message;
  const status = response?.status;
  const statusText = response?.statusText;

  if (status === 500) {
    SendSentryError("ALLERRORS", `${statusText}`, {
      details: "Refresh session failed with status 500 [server error]",
      serverMessage: message,
    });
    console.log("errors");
  } else if (error.request) {
    SendSentryError("ALLERRORS", "Refresh session failed", {
      details: "Refresh session failed due to a network error [network error]",
      serverMessage: error?.message,
    });
  } else {
    SendSentryError("ALLERRORS", `${error.message}`, {
      details: "An error occurred. Please try again later.",
      serverMessage: error?.message,
    });
  }
};
