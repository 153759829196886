/** Libraries */
import { styled } from "@mui/material";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";

/** MUI - Custom elements */
export const MainContainer = styled("div")(() => ({
  display: "flex",
  width: "130px",
  justifyContent: "flex-end",
}));

export const TimerContainer = styled("div")(({ hidetimer, theme }) => ({
  display: "flex",
  width: "75px",
  alignItems: "center",
  justifyContent: "center",
  opacity: hidetimer ? "0" : "1",
  transition: "all 300ms",
  color: theme.palette.appcolors.black,
}));

export const TimerText = styled("p")(({ isPaused, theme }) => ({
  color: isPaused
    ? theme.palette.appcolors.error6
    : theme.palette.appcolors.grey,
  textAlign: "right",
  fontSize: "0.875rem",
}));

export const TimerIcon = styled(AccessTimeRoundedIcon)(
  ({ hidetimer, theme }) => ({
    fontSize: "1.5rem",
    cursor: "pointer",
    color:
      hidetimer === "false"
        ? theme.palette.appcolors.grey
        : theme.palette.appcolors.grey3,
  })
);
