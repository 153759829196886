/** Libraries */
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

/** Molecules */
import AppLoader from "../../components/molecule/AppLoader";

/** Pages */
import QuestionViewerSQ from "./QuestionViewerSQ";
import QuestionViewerMQ from "./QuestionViewerMQ";

/** Custom hooks */
import { useQuestionViewerStore } from "../../hooks/questionViewer/useQuestionViewerStore";

const QuestionViewer = () => {
  const params = useParams();
  const { question, startLoadingQuestion } = useQuestionViewerStore();

  useEffect(() => {
    startLoadingQuestion(params?.id);
  }, [params]);

  if (question === null) return <AppLoader isLoading={true} />;

  return question.multipartQuestion === null ? (
    <QuestionViewerSQ />
  ) : (
    <QuestionViewerMQ />
  );
};

export default QuestionViewer;
