/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import {
  multiQuestionLogout,
  setNavigationLeft,
  setNavigationRight,
  setQuizReview,
  setShowResultItem,
} from "../../../redux/slices/exercise/MultiQuestions/exercisesMQReviewSlice";
import { useAiTutorStore } from "../../aiTutor/useAiTutorStore";

export const useExercisesMQReviewStore = () => {
  const dispatch = useDispatch();
  const {
    quiz,
    sectionTitle,
    currMultiQuestion,
    currIndexMultiQuestion,
    selectedIndex,
    currQuestion,
    questionText,
    totalQuestions,
  } = useSelector((state) => state.exercisesMQReview);

  const { startAiTutorLogout } = useAiTutorStore();

  const startSettingQuiz = (data) => {
    dispatch(setQuizReview(data));
  };

  const startShowingQuestion = (index) => {
    dispatch(setShowResultItem(index));
  };

  const startNavigationRight = () => {
    startAiTutorLogout();
    dispatch(setNavigationRight());
  };

  const startNavigationLeft = () => {
    startAiTutorLogout();
    dispatch(setNavigationLeft());
  };

  const startMQReviewLogout = () => {
    dispatch(multiQuestionLogout());
  };

  return {
    /** Values */
    quiz,
    sectionTitle,
    currMultiQuestion,
    currIndexMultiQuestion,
    selectedIndex,
    currQuestion,
    questionText,
    totalQuestions,

    /** Functions */
    startSettingQuiz,
    startShowingQuestion,
    startNavigationRight,
    startNavigationLeft,
    startMQReviewLogout,
  };
};
