/** Libraries */
import { Tooltip } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@material-ui/core/TextField";
import Fab from "@mui/material/Fab";
import FormControl from "@mui/material/FormControl";

/** Mui Custom elements */
export const StyledTextInputField = styled("form")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  gap: "8px",
  width: "100%",
  overflow: "hidden",
  minHeight: "60px",
  height: "100%",
  padding: "0px 16px",
  [theme.breakpoints.down(600)]: {
    gap: "0.5em",
  },
}));

export const StyledTextArea = styled(TextField)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  resize: "none",
  width: "100%",
  margin: "10px 0px",
  outline: "none",
  "& .MuiInputBase-input": {
    fontSize: "16px",
    padding: "10px",
    color: theme.palette.appcolors.black,
  },
  "& .MuiInputBase-input::placeholder": {
    color: theme.palette.appcolors.grey6,
    fontFamily: "Figtree",
    fontSize: "15px",
    opacity: 1,
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "13px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "10px",
  },
}));

export const SendInstance = styled(Fab)(({ text, theme }) => ({
  borderRadius: "50% !important",
  color: `${theme.palette.appcolors.secondaryColor10} !important`,
  height: "35px !important",
  width: "35px !important",
  boxShadow: "none !important",
  backgroundColor: `${
    text ? theme.palette.appcolors.primaryColor : theme.palette.appcolors.grey3
  } !important`,
  "&:hover": {
    cursor: "pointer",
  },
}));

export const IconAndSwitchContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  minWidth: "90px",
}));

export const ArrowButtonContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5em",
  cursor: "pointer",
}));

export const AiChatBarContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  backgroundColor: theme.palette.appcolors.grey15,
  borderRadius: "16px",
}));

export const CustomFormControl = styled(FormControl)(({ theme, menu }) => ({
  width: "100%",
  backgroundColor: theme.palette.appcolors.grey3,
  borderRadius: menu ? "10px 10px 0px 0px" : "10px",

  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
  "& .MuiInputBase-root": {
    color: theme.palette.appcolors.grey,
  },
  "& .MuiInputBase-input": {
    display: "flex",
    padding: "8px",
    alignItems: "center",
  },
  "& .MuiSelect-select": {
    backgroundColor: theme.palette.appcolors.grey3,
    borderRadius: "10px",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: theme.palette.appcolors.grey3,
    borderRadius: "10px",
  },
}));

export const ToolTip = ({ className, ...props }) => {
  const theme = useTheme();

  return (
    <Tooltip
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            color: theme.palette.appcolors.secondaryColor10,
            backgroundColor: theme.palette.appcolors.primaryColor,
            height: "30px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "2.5px",
            fontFamily: "Noto Sans",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "10px",
          },
        },
        arrow: {
          sx: {
            color: theme.palette.appcolors.primaryColor,
          },
        },
      }}
    />
  );
};
