import React from "react";

/* MUI */
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

/* UI */
import { StyledActivateButton, useStyles } from "./DiscountForm.style";

/* Redux Hooks */
import { useDiscountStore } from "../../../hooks";

/* Form Hook */
import { useForm } from "react-hook-form";

const DiscountForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  const { validateCode, isCodeValid } = useDiscountStore();

  const onSubmit = (data) => {
    validateCode(data.code);
  };

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.innerForm}>
          <div className={classes.inputContainer}>
            <LocalOfferOutlinedIcon fontSize="small" className={classes.icon} />
            <input
              className={`${classes.input} ${
                isCodeValid !== null &&
                (isCodeValid ? classes.successBorder : classes.errorBorder)
              }`}
              type="text"
              placeholder="Rabattkod"
              {...register("code", {
                required: "Please enter your discount code.",
              })}
              required
            />
          </div>
          <StyledActivateButton type="submit" disabled={isCodeValid === true}>
            Aktivera
          </StyledActivateButton>
        </div>
      </form>
      <div className={classes.info}>
        {isCodeValid !== null && isCodeValid && (
          <p className={classes.successText}>Din rabatt har tillämpats</p>
        )}
        {isCodeValid !== null && !isCodeValid && (
          <p className={classes.errorText}>
            Tyvärr, den här rabattkoden verkar inte vara korrekt.
          </p>
        )}
      </div>
    </div>
  );
};

export default DiscountForm;
