import {
  InstructionsContainer,
  InstructionsTitle,
  SectionTitle,
  SectionSubtitle,
} from "../../atom/SharedInfoStyledComponents/SharedInfoStyledComponents";

const ExamStartInformation = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Instruktioner</InstructionsTitle>
      <SectionTitle>Fyra provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Högskoleprovet är indelat i fem provpass, men eftersom ett av dessa är
        utprövningspass har vi här exkluderat detta provpass. Du kommer alltså
        att få göra fyra provpass. Varje pass är 55 minuter långt med normal
        provtid, alternativt 80 minuter med förlängd provtid. Du kommer att
        skriva två kvantitativa provpass, två verbala provpass.
      </SectionSubtitle>

      <SectionTitle>Kvantitativt provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Varje kvantitativt provpass består av uppgifter från fyra olika delprov:
      </SectionSubtitle>
      <SectionSubtitle>
        XYZ, matematisk problemlösning: 12 uppgifter
      </SectionSubtitle>
      <SectionSubtitle>
        KVA, kvantitativa jämförelser: 10 uppgifter
      </SectionSubtitle>
      <SectionSubtitle>
        NOG, kvantitativa resonemang: 6 uppgifter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        DTK, diagram, tabeller och kartor: 12 uppgifter
      </SectionSubtitle>

      <SectionTitle>Verbalt provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Varje verbalt provpass består av uppgifter från fyra olika delprov:
      </SectionSubtitle>

      <SectionSubtitle>ORD, ordförståelse: 10 uppgifter</SectionSubtitle>
      <SectionSubtitle>LÄS, svensk läsförståelse: 10 uppgifter</SectionSubtitle>
      <SectionSubtitle>MEK, meningskomplettering: 10 uppgifter</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        ELF, engelsk läsförståelse: 10 uppgifter
      </SectionSubtitle>

      <SectionTitle>Lämna in provpass efter 55 minuter</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        När provpasset är slut avbryts provpasset automatiskt och ditt resultat
        registreras. Poängen för provet redovisas först efter att du gjort
        provets samtliga provpass. Mellan provpassen har du möjlighet att ta
        paus. Om du avbryter provpasset utan att lämna in får du ingen poäng,
        men du kan göra om provpasset senare.
      </SectionSubtitle>
      <SectionTitle>Hjälpmedel</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Du får inte använda miniräknare eller några andra digitala hjälpmedel på
        provet. Kladdpapper får du däremot använda!
      </SectionSubtitle>
      <SectionTitle>Rekommendationer</SectionTitle>
      <SectionSubtitle>
        Stressa inte men stanna inte heller länge på en uppgift du fastnat på.
        Tidsbegränsningen är en utmaning på Högskoleprovet, men det är precis
        därför det är så bra att träna på! Lycka till!
      </SectionSubtitle>
    </InstructionsContainer>
  );
};

export default ExamStartInformation;
