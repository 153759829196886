/** Libraries */
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Radio } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Atoms */
import HelpPopup from "../../../../../atom/HelpPopup/HelpPopup";
import QuestionBody from "../../../../../atom/QuestionBody/questionBody";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
  StyledRadiusCheckedIcon,
  StyledRadiusUnCheckedIcon,
  StyledRadiusOnHoverIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";
import { BackgroundContainer } from "../../../../../atom/SharedExamExerciseStyledComponents/BackgroundContainer";

/** Molecules */
import ResultFooter from "../../../../../molecule/ResultFooter/ResultFooter";
import CommonPopup from "../../../../../molecule/CommonPopup/CommonPopup";
import AnswerButtonSQ from "../../../../../molecule/AnswerButton/AnswerButtonSQ";

/** Organisms */
import ExerciseTopBar from "../../../../TopBars/ExerciseTopBar/ExerciseTopBar";
import ExerciseBottomBar from "../../../../BottomBars/ExerciseBottomBar";

/** Tools */
import { SendSentryError } from "../../../../../../tools";

/** Utils */
import { scrollTop } from "../../../../../../utils/commonService";

/** Axios instances */
import { EndPoints, instance2 } from "../../../../../service/Route";

/** Custom hooks */
import {
  useExercisesMQReviewStore,
  useExercisesMQStore,
  useExercisesSectionCategoriesStore,
  useUiStore,
} from "../../../../../../hooks";

import { useAiTutorStore } from "../../../../../../hooks";
import { ExerciseStartupDialog } from "../../../../StartInfoPopup/StartInfoPopup";

const TransitionContainer = styled("div")(({ theme, transition }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: transition ? 0 : -100,
  width: "100%",
  zIndex: 1060,
  transition: "bottom 0.3s ease-in-out",
}));

const QuestionViewXyzOrg = () => {
  const {
    quiz: MqQuiz,
    currQuestion,
    totalQuestions: totalMQQuestions,
  } = useExercisesMQStore();
  const {
    quiz: MqResultQuiz,
    questionText,
    currMultiQuestion,
  } = useExercisesMQReviewStore();
  const { user, token } = useSelector((state) => state.user);

  const {
    isActive: isAIActive,
    switchAIKeyPress,
    startAiTutorLogout,
    isRequestInProcess,
  } = useAiTutorStore();

  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [quiz, setQuiz] = useState();
  const params = useLocation();
  const [status, setStatus] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [time, setTime] = useState(0);
  const [open, setOpen] = useState(false);
  const [timeEnd, setTimeEnd] = useState(false);
  const [nextPress, setNextPress] = useState(undefined);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [loading, setLoading] = useState(true);
  const [helpPopup, setHelpPopup] = useState(false);
  const [onHover, setOnhover] = useState();
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [answerSubmittedState, setAnsSubmittedState] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [paragraphResponse, setParagraphResponse] = useState(false);
  const [startTimer, setStartTimer] = useState(true);
  const [darkmode, setDarkMode] = useState(false);
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();
  const [transition, setTransition] = useState(false);

  const currentSectionCategory = useExercisesSectionCategoriesStore();

  const myRef = useRef(null);

  const dyslexiaTimeMap = {
    XYZ: 80, //mins converted into secs
    KVA: 84,
    NOG: 150,
    DTK: 175,
    ORD: 24,
    MEK: 72,
    LÄS: 192,
    ELF: 192,
  };

  useEffect(() => {
    quiz !== undefined &&
      quiz[selectedIndex].selectedIndex !== undefined &&
      setTransition(true);
  }, [quiz]);

  useEffect(() => {
    const questionToShow = params?.state?.questionIndex;
    if (questionToShow != undefined) {
      setSelectedIndex(questionToShow);
      setCurrentQuestion(questionToShow);
      setCurrentQuestion(questionToShow + 1);
      setQuiz(params?.state?.quiz.question);
      setTotalQuestions(params?.state?.quiz?.question?.length);
      setLoading(false);
    } else {
      if (localStorage.getItem("quiz")) {
        let refreshQuiz = JSON.parse(localStorage.getItem("quiz"));
        let totalQ = 0;
        setLoading(false);
        refreshQuiz &&
          refreshQuiz.map((item) => {
            if (item?.answer) {
              setSelectedIndex((selectedIndex) => selectedIndex + 1);
              setCurrentQuestion((currentQuestion) => currentQuestion + 1);
            }
            if (item.type == "multiple") {
              setTotalQuestions((totalQ) => totalQ + item?.question?.length);
              totalQ = totalQ + item?.question?.length;
            } else {
              totalQ = totalQ + 1;
              setTotalQuestions((totalQ) => totalQ + 1);
            }
          });
        params.state.isDyslexiaTime
          ? setTime(
              Number(
                (
                  dyslexiaTimeMap[currentSectionCategory?.title] * totalQ
                ).toFixed(0)
              )
            )
          : setTime(
              Number((currentSectionCategory?.time * totalQ * 60).toFixed(0))
            );

        setStatus(true);
        if (localStorage.getItem("quiz")) {
          setQuiz(JSON.parse(localStorage.getItem("quiz")));
        } else {
          setQuiz(params?.state?.data?.quiz);
        }
        // }
      } else {
        let totalQ = 0;
        setLoading(false);
        params?.state?.data?.quiz &&
          params?.state?.data?.quiz?.map((item) => {
            if (item?.answer) {
              setSelectedIndex((selectedIndex) => selectedIndex + 1);
              setCurrentQuestion((currentQuestion) => currentQuestion + 1);
            }
            if (item.type == "multiple") {
              setTotalQuestions((totalQ) => totalQ + item?.question?.length);
              totalQ = totalQ + item?.question?.length;
            } else {
              totalQ = totalQ + 1;
              setTotalQuestions((totalQ) => totalQ + 1);
            }
          });
        params.state.isDyslexiaTime
          ? setTime(
              Number(
                (
                  dyslexiaTimeMap[currentSectionCategory?.title] * totalQ
                ).toFixed(0)
              )
            )
          : setTime(
              Number((currentSectionCategory?.time * totalQ * 60).toFixed(0))
            );
        setStatus(true);
        if (localStorage.getItem("quiz")) {
          setQuiz(JSON.parse(localStorage.getItem("quiz")));
        } else {
          setQuiz(params?.state?.data?.quiz);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      if (JSON.parse(localStorage.getItem("quiz"))) {
        setQuiz(JSON.parse(localStorage.getItem("quiz")));
      }
    }
  }, []);

  useEffect(() => {
    if (
      nextPress &&
      quiz?.length > 0 &&
      (time || (!params?.state?.data.value && !time)) &&
      !["ELF", "DTK", "LÄS"].includes(currentSectionCategory.title)
    ) {
      const questions = [...quiz];
      let question = questions[selectedIndex];

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const data = {
        quiz: params?.state?.quizId,
        user: user?._id,
        optionId: question?.optionId,
        questionId: question?._id,
        questionCategory: question?.questionCategory[0],
        sectionCategory: currentSectionCategory?._id,
        timeleft: timeLeft ? timeLeft : 0,
        totaltime: time ? time : 0,
        spendtime: params.state.time ? time - timeLeft : seconds,
        MultipartQuestion: null,
        isTimeRestricted: params?.state?.time,
      };

      const URL = EndPoints.submitAnswer;
      instance2
        .post(URL, data, { headers })
        .then((response) => {
          setAnsSubmittedState(response.data);
          setTime(Number(timeLeft));
          setNextPress(undefined);
        })
        .catch((error) => {
          console.error("Error submitting answer:", error);
          SendSentryError("QUESTION-VIEW", "Error submitting answer", {
            details: "Failed to submit answer",
            error,
          });
          startErrorPopup();
          startErrorRedirectionTrue();
        });
    } else {
      return;
    }
  }, [nextPress, timeLeft && !quiz[0]?.type == "multiple"]);

  useEffect(() => {
    let timer;

    if (!params.state.time && startTimer) {
      timer = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [params.state.time, startTimer]);

  const scrollBottom = () => {
    if (myRef?.current) {
      setTimeout(() => {
        myRef?.current?.scrollIntoView();
      }, 100);
    }
  };

  /** Function onClick for svara/nasta in the answerButton SQ*/
  const Next = (question, buttonText) => {
    setStartTimer(false);
    if (question.answer) {
      if (selectedIndex + 1 == quiz?.length) {
        if (
          answerSubmittedState.attempts?.length ===
          answerSubmittedState.attemptedQuestion
        ) {
          // trackEndTest();
          navigate("/resultsummary", {
            state: {
              sectionCategory: currentSectionCategory,
              quizId: params?.state?.quizId,
              spendtime: answerSubmittedState,
              time: params?.state?.time,
            },
          });
        }
        localStorage.removeItem("time");
        localStorage.removeItem("quiz");
      } else {
        setSeconds(0);
        setStatus(true);
        setStartTimer(true);
        setSelectedIndex((previousIndex) => previousIndex + 1);
        setCurrentQuestion((previousIndex) => previousIndex + 1);
        scrollTop();
        setTransition(false);
      }
    } else {
      if (question.selectedIndex + 1) {
        const questions = [...quiz];
        let ques = questions[selectedIndex];
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const URL = EndPoints.getAnswerByQuestionId + ques?._id;
        instance2
          .get(URL, { headers })
          .then((response) => {
            ques.answer = response?.data;
            ques.answerSubmited = true;
            !params?.state?.data?.value && setNextPress(!nextPress);
            localStorage.setItem("quiz", JSON.stringify(questions));
            setQuiz(questions);
            scrollBottom();
            setStatus(false);
          })
          .catch((error) => {
            console.error("Error getting answer by question id:", error);
            SendSentryError(
              "QUESTION-VIEW",
              "Error getting answer by question id",
              {
                details: "Failed to getting answer by question id",
                error,
              }
            );
          });
      }
    }
  };

  const AnswerArrayPayloadForCloseTimerFunc = () => {
    let singleQuestionArray = [];
    quiz?.map((item) => {
      if (
        item.hasOwnProperty("multipartQuestion") &&
        item?.multipartQuestion === null
      ) {
        if (!item.answer) {
          if (singleQuestionArray?.length < 1) {
            singleQuestionArray.push({
              questionId: item?._id,
              sectionCategory: item?.sectionCategories,
              questionCategory: item?.questionCategory[0],
              optionId: null,
              timeleft: 0,
              spendtime: timeLeft,
              totaltime: time ? time : 0,
            });
          } else {
            // console.log("else case");
            singleQuestionArray.push({
              questionId: item?._id,
              sectionCategory: item?.sectionCategories,
              questionCategory: item?.questionCategory[0],
              optionId: null,
              timeleft: 0,
              totaltime: time ? time : 0,
              spendtime: 0,
            });
          }
        }
      } else {
        item?.question?.map((multipartQuestion) => {
          if (!multipartQuestion.answer) {
            if (singleQuestionArray?.length < 1) {
              singleQuestionArray.push({
                questionId: multipartQuestion?._id,
                sectionCategory: multipartQuestion?.sectionCategories,
                questionCategory: multipartQuestion?.questionCategory[0],
                optionId: null,
                timeleft: 0,
                spendtime: timeLeft,
                totaltime: time ? time : 0,
              });
            } else {
              singleQuestionArray.push({
                questionId: multipartQuestion?._id,
                sectionCategory: multipartQuestion?.sectionCategories,
                questionCategory: multipartQuestion?.questionCategory[0],
                optionId: null,
                timeleft: 0,
                totaltime: time ? time : 0,
                spendtime: 0,
              });
            }
          }
          return multipartQuestion;
        });
      }
      return item;
    });

    return singleQuestionArray;
  };

  const CloseTimerFunc = async () => {
    setTimeEnd(true);
    try {
      if (localStorage.getItem("quiz")) {
        const payload = {
          quiz: params?.state?.quizId,
          user: user?._id,
          sectionCategory: currentSectionCategory?._id,
          answer: AnswerArrayPayloadForCloseTimerFunc(),
        };
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const URL = EndPoints.submitMultiquestionParagragh;
        instance2
          .post(URL, payload, { headers })
          .then((response) => {
            console.log("response7", response);

            setParagraphResponse(true);
          })
          .catch((error) => {
            console.error("Error submitting mq paragraph:", error);
            SendSentryError("QUESTION-VIEW", "Error submitting mq paragraph", {
              details: "Failed to submit mq paragraph",
              error,
            });
            startErrorPopup();
            startErrorRedirectionTrue();
          });
      } else {
        console.log("no need to call api ");
      }
    } catch (error) {
      console.log("in catch block: ", error);
    }
  };

  const SelectFunc = (item, optionIndex) => {
    const questions = [...quiz];
    let question = questions[selectedIndex];
    question.selectedIndex = optionIndex;
    question.optionId = item?._id;
    setQuiz(questions);
  };

  /** Returns the options radius buttons for SQ*/
  const Options = (question, curentOption, optionIndex) => {
    if (question.answer && question.answer.option === curentOption?._id) {
      return <Radio disabled checked={false} icon={<StyledCorrectIcon />} />;
    } else if (question.answer && curentOption._id === question?.optionId) {
      return <Radio disabled checked={false} icon={<StyledWrongIcon />} />;
    } else if (question.answer && curentOption._id !== question?.optionId) {
      return (
        <Radio disabled checked={false} icon={<StyledRadiusUnCheckedIcon />} />
      );
    }

    if (optionIndex === question.selectedIndex) {
      return (
        <Radio
          checked={true}
          icon={<StyledRadiusUnCheckedIcon />}
          checkedIcon={<StyledRadiusCheckedIcon />}
        />
      );
    } else {
      return (
        <Radio
          checked={false}
          icon={
            onHover && curentOption._id === onHover ? (
              <StyledRadiusOnHoverIcon />
            ) : (
              <StyledRadiusUnCheckedIcon />
            )
          }
        />
      );
    }
  };

  /** Returns the button for SQ*/
  const getSubmitButton = (question, isRequestInProcess) => {
    if (params?.state?.questionIndex !== undefined) {
      return (
        <ResultFooter
          questionLength={quiz?.length}
          questionIndex={selectedIndex}
          onResultHandler={() => {
            navigate("/resultsummary", {
              state: {
                sectionCategory: currentSectionCategory,
                quizId: params?.state?.quizId,
                time: params?.state?.time,
              },
            });
          }}
          onLeftClick={() => {
            startAiTutorLogout();
            setSelectedIndex((previousIndex) => previousIndex - 1);
            setCurrentQuestion((previousIndex) => previousIndex - 1);
          }}
          onRightClick={() => {
            startAiTutorLogout();
            setSelectedIndex((previousIndex) => previousIndex + 1);
            setCurrentQuestion((previousIndex) => previousIndex + 1);
          }}
        />
      );
    } else {
      return null;
      /*  <AnswerButtonSQ
          disabled={isRequestInProcess || false}
          Next={() => !isRequestInProcess && Next(question)}
          question={question}
        />*/
    }
  };

  /** Functions to handle the popups/modals */
  const PopupHandler = () => {
    const checkPopup = params?.state?.questionIndex;
    if (checkPopup !== undefined && currMultiQuestion === null) {
      navigate("/category", {
        state: {
          item: currentSectionCategory,
        },
      });
    } else
      quiz?.[0]?.answer ||
        quiz?.[0]?.question?.[0].answer ||
        MqQuiz?.[0]?.isQuestionAnswered;
    {
      setOpen(true);
    }
  };

  const handleAlertDialogPopup = () => {
    const isSingleQuestionAndAnswered =
      !!quiz?.[0]?.answer && quiz?.[0]?.multipartQuestion === null;

    const isMultiQuestionAndAnswered =
      !!MqQuiz?.[0]?.isQuestionAnswered &&
      MqQuiz?.[0]?.question?.[0]?.multipartQuestion !== null;

    const isReviewSection = MqResultQuiz?.length !== 0;

    if (
      isSingleQuestionAndAnswered ||
      isMultiQuestionAndAnswered ||
      isReviewSection
    ) {
      // trackEndTest();
      navigate("/resultsummary", {
        state: {
          sectionCategory: currentSectionCategory,
          quizId: params?.state?.quizId,
          time: params?.state?.time,
        },
      });
      localStorage.removeItem("quiz");
      localStorage.removeItem("time");
    } else {
      navigate("/category", {
        state: {
          item: currentSectionCategory,
        },
      });
      localStorage.removeItem("quiz");
    }
  };
  return (
    <>
      {helpPopup && <HelpPopup />}
      <ExerciseStartupDialog />
      {/** This is the header or top bar where we can see the time, title of the section, etc*/}
      {(time || (!time && !params?.state?.data?.value)) && (
        <ExerciseTopBar
          selectedIndex={
            MqResultQuiz?.length !== 0
              ? questionText
              : MqQuiz?.length !== 0
              ? currQuestion
              : currentQuestion
          }
          totalQuestions={
            MqQuiz?.length !== 0 ? totalMQQuestions : totalQuestions
          }
          params={params?.state?.data}
          status={time && status}
          time={time && time}
          nextPress={() => setNextPress(true)}
          onCloseTimer={() => CloseTimerFunc()}
          quiz={MqQuiz?.length !== 0 ? MqQuiz : quiz}
          timeLeft={(timer) => {
            setTimeLeft(timer);
          }}
          callBackForTimer={(value) => setTimeLeft(value)}
          goBack={PopupHandler}
          setDarkmode={(value) => setDarkMode(value)}
          darkmode={darkmode}
        />
      )}
      <BackgroundContainer ref={myRef}>
        {/** Some pupups/modals*/}
        {quiz?.[0]?.answer ||
        quiz?.[0]?.question?.[0]?.answer ||
        MqQuiz?.[0]?.isQuestionAnswered ? (
          <CommonPopup
            title={"Vill du avsluta?"}
            description={"Du tas nu till summeringssidan."}
            cancelBtnName={"Fortsätt öva"}
            agreeBtnName={"Avsluta"}
            status={open}
            closePopup={() => setOpen(false)}
            redirect={() => handleAlertDialogPopup()}
            questionPopup
          />
        ) : (
          <CommonPopup
            title={"Vill du avsluta?"}
            description={"Ingen fråga är besvarad."}
            cancelBtnName={"Fortsätt öva"}
            agreeBtnName={"Avsluta"}
            status={open}
            closePopup={() => setOpen(false)}
            redirect={() => handleAlertDialogPopup()}
          />
        )}

        {(quiz && quiz?.[0]?.answer && quiz?.[0]?.multipartQuestion === null) ||
        (MqQuiz &&
          MqQuiz?.[0]?.isQuestionAnswered &&
          MqQuiz?.[0]?.question[0]?.multipartQuestion !== null) ? (
          <CommonPopup
            title={"Tiden är över."}
            description={"Bra kämpat! Gå vidare och checka ditt resultat."}
            agreeBtnName={"Se resultat"}
            status={timeEnd}
            oneButtonPopup
            closePopup={() => setOpen(false)}
            redirect={() => {
              localStorage.removeItem("quiz");
              localStorage.removeItem("time");

              navigate("/resultsummary", {
                state: {
                  sectionCategory: currentSectionCategory,
                  quizId: params?.state?.quizId,
                  time: params?.state?.time,
                },
              });
            }}
          />
        ) : !quiz?.[0]?.answer || !quiz?.[0]?.question?.[0]?.answer ? (
          <CommonPopup
            title={"Tiden är över."}
            description={
              "Ingen fråga är besvarad så du tas direkt tillbaka till övningssidan."
            }
            agreeBtnName={"Avsluta"}
            status={timeEnd}
            oneButtonPopup
            closePopup={() => {
              navigate("/category", {
                state: {
                  item: currentSectionCategory,
                },
              });
              localStorage.removeItem("quiz");
            }}
            redirect={() => {
              navigate("/category", {
                state: {
                  item: currentSectionCategory,
                },
              });
              localStorage.removeItem("quiz");
            }}
          />
        ) : null}

        {/** The next component send the props to the right page (either MQ or SQ) */}
        {quiz &&
          quiz?.map((item, index) => {
            if (index === selectedIndex) {
              return (
                <Container maxWidth={false} id="singleQuestionViewOrgContainer">
                  <QuestionBody
                    key={item?._id}
                    onScrollBottom={myRef}
                    isTimeRestricted={params?.state?.time}
                    question={quiz[selectedIndex]}
                    totalQuestions={totalQuestions}
                    selectedOption={params.state.selectedOption}
                    paragraphIndex={params?.state?.paragraphIndex}
                    questionIndex={params?.state?.questionIndex}
                    questionTypeTitle={currentSectionCategory.title}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={(value) => setSelectedIndex(value)}
                    setCurrentQuestion={(index) => {
                      let answeredQuestions = 0;
                      quiz?.map((item) => {
                        if (item.type == "multiple") {
                          if (
                            item.question.every((question) => question.optionId)
                          ) {
                            answeredQuestions += item.question?.length;
                          }
                        } else {
                          if (item.answer) {
                            answeredQuestions = answeredQuestions + 1;
                          }
                        }
                      });
                      setCurrentQuestion(index + answeredQuestions);
                    }}
                    onLeftClick={() => {
                      setCurrentQuestion((previousIndex) => previousIndex - 1);
                      setSelectedIndex((previousIndex) => previousIndex - 1);
                    }}
                    onRightClick={() => {
                      setCurrentQuestion((previousIndex) => previousIndex + 1);
                      setSelectedIndex((previousIndex) => previousIndex + 1);
                    }}
                    stopTime={() => setStatus(false)}
                    SelectOption={(e, index) => SelectFunc(e, index)}
                    callBackForTimer={(value) => setTimeLeft(value)}
                    totalTime={time}
                    quiz={quiz}
                    onHover={onHover}
                    onhover={(optionId) => setOnhover(optionId)}
                    onHoverLeave={() => setOnhover(null)}
                    sectionCategory={currentSectionCategory}
                    onResultHandler={() => {
                      navigate("/resultsummary", {
                        state: {
                          sectionCategory: currentSectionCategory,
                          quizId: params?.state?.quizId,
                          time: params?.state?.time,
                        },
                      });
                      localStorage.removeItem("quiz");
                      localStorage.removeItem("time");
                    }}
                    startTime={() => setStatus(true)}
                    showOptions={(question, item, optionIndex) =>
                      Options(question, item, optionIndex)
                    }
                    updateQuiz={(value) => setQuiz(value)}
                    changeIndex={() => setCurrentQuestion(currentQuestion + 1)}
                    previosQuestion={() =>
                      setCurrentQuestion(currentQuestion - 1)
                    }
                    submitButton={getSubmitButton}
                    quizId={params?.state?.quizId}
                    timeLeft={timeLeft}
                    PopupTimeEnd={timeEnd}
                    updateCompleteQuiz={(quiz) => setQuiz(quiz)}
                    nextQuestion={() => {
                      if (selectedIndex + 1 < quiz?.length) {
                        setSelectedIndex(selectedIndex + 1);
                        setCurrentQuestion(currentQuestion + 1);
                        localStorage.setItem("quiz", JSON.stringify(quiz));
                      } else {
                        navigate("/resultsummary", {
                          state: {
                            sectionCategory: currentSectionCategory,
                            quizId: params?.state?.quizId,
                            time: params?.state?.time,
                          },
                        });
                        localStorage.removeItem("quiz");
                        localStorage.removeItem("time");
                      }
                    }}
                  />
                </Container>
              );
            }
          })}
      </BackgroundContainer>
      <TransitionContainer transition={transition}>
        {transition && (
          <ExerciseBottomBar
            Next={() => Next(quiz[selectedIndex])}
            question={quiz[selectedIndex]}
          />
        )}
      </TransitionContainer>
    </>
  );
};

export default QuestionViewXyzOrg;
