import { ReactComponent as BarChart } from "../../../assets/Icons/BarChart.svg";
import { useTheme } from "@mui/material/styles";

export const BarChartIcon = () => {
  const theme = useTheme();

  return (
    <BarChart
      stroke={theme.palette.appcolors.grey}
      style={{ marginRight: ".5rem" }}
    />
  );
};
