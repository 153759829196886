import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const LineDemo = (props) => {
  const [rangeOfGraph, setRangeOfGraph] = useState(4);
  const [graphData, setGraphData] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const graphDataWeekNameUpdate = props?.weeklyCoreectedGraph.map(
      (weeklyCoreectedGraphData) => {
        const weekNumberDevide = weeklyCoreectedGraphData?.name?.split(".");
        return {
          ...weeklyCoreectedGraphData,
          name: weekNumberDevide[0] + "." + weekNumberDevide[2],
        };
      }
    );

    setGraphData(graphDataWeekNameUpdate);
    const weekWiseCorrectedAnswers = props?.weeklyCoreectedGraph.map(
      (correctedInWeek) => correctedInWeek?.correct
    );
    const maxNumberOfCorrected = Math.max(...weekWiseCorrectedAnswers);
    if (maxNumberOfCorrected > 4) {
      let checkMaxNumber = maxNumberOfCorrected % 4;
      if (checkMaxNumber > 0) {
        const noToAdd = 4 - checkMaxNumber;
        setRangeOfGraph(noToAdd + maxNumberOfCorrected);
      }
    }
  }, []);

  const CustomXAxisTick = (props) => {
    const { x, y, payload, index } = props;
    const isLastTick = index === graphData.length - 1;

    if (isLastTick) {
      return (
        <>
          <rect
            x={x - 17}
            y={y - 3}
            width={33}
            height={33}
            rx={100}
            ry={100}
            fill={theme.palette.appcolors.black2}
          />
          <text
            x={x}
            y={y}
            dy={16}
            textAnchor="middle"
            fill={theme.palette.appcolors.tertiaryColor12}
          >
            {payload.value}
          </text>
        </>
      );
    }
    return (
      <>
        <text
          x={x}
          y={y}
          dy={16}
          textAnchor="middle"
          fill={theme.palette.appcolors.black}
        >
          {payload.value}
        </text>
      </>
    );
  };

  const CustomYAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-5}
          y={-13}
          dy={16}
          textAnchor="end"
          fill={theme.palette.appcolors.black}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: theme.palette.appcolors.secondaryColor4,
            border: `1px solid ${theme.palette.appcolors.grey4}`,
            borderRadius: "5px",
            padding: ".65rem .75rem",
          }}
        >
          <p>{`Antal rätt: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" minWidth="300px" minHeight="200px">
      {graphData.length > 0 && (
        <BarChart
          style={{
            fontSize: "10px",
            fontFamily: "Figtree",
          }}
          data={graphData}
          syncId="snycId"
          margin={{
            top: 10,
            right: 10,
            left: -25,
            bottom: 8,
          }}
        >
          <CartesianGrid strokeDasharray="0 0" vertical="" horizontal="true" />
          <XAxis
            dataKey="name"
            dy={10}
            tickLine={false}
            tick={<CustomXAxisTick />}
            axisLine={{ stroke: theme.palette.appcolors.grey6, strokeWidth: 2 }}
          />
          <YAxis
            type="number"
            dx={-10}
            domain={[0, rangeOfGraph]}
            allowDecimals={false}
            axisLine={false}
            tickCount={5}
            tickLine={false}
            tick={<CustomYAxisTick />}
          />
          <Tooltip content={<CustomTooltip />} cursor={false} />
          <Bar
            dataKey="correct"
            fill={theme.palette.appcolors.success4}
            barSize={8}
            radius={[10, 10, 0, 0]}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default LineDemo;
