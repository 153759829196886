/** Libraries */
import React, { useEffect, useState } from "react";
import { CircularProgress, LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

/** Molecules */
import LineDemo from "../../../../../molecule/Charts/BarChart";
import LinesChart from "../../../../../molecule/Charts/LinesChart";

import {
  useCategoryDetailsStore,
  useExercisesSectionCategoriesStore,
} from "../../../../../../hooks";

/** Components */
import { handleErrors } from "../../../../../../utils/Errors";
import { SecondaryIsPremiumCard } from "../../../../../molecule/IsPremiumCard/IsPremiumCard";

/** Material UI - Custom elements */
const MainContainer = styled("div")(({ theme }) => ({
  padding: { xs: "0 !important", md: "0 3rem" },
  paddingBottom: "3rem",
}));

const PremiumBoxDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(1280)]: {
    display: "none",
  },
}));

const TitleBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  margin: "2rem 0rem 0.5rem 0.1rem",
}));

const PageTitle = styled("h3")(({ theme }) => ({
  fontSize: "23px",
  fontWeight: "600",
  color: theme.palette.appcolors.black,
  marginBottom: "0rem",
}));

const PageSubTitle = styled("p")(({ theme }) => ({
  fontSize: "15px",
  fontFamily: "Figtree",
  color: theme.palette.appcolors.black,
}));

const ProgressBox = styled("div")(({ theme }) => ({
  width: "100%",
  height: "68px",
  marginTop: "1rem",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  borderRadius: "10px",
  padding: "24px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
}));

const LinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: "20px",
  borderRadius: "5rem",
  backgroundColor: theme.palette.appcolors.primaryColor10,
  "& .MuiLinearProgress-bar": {
    backgroundColor: theme.palette.appcolors.success4,
    borderRadius: "5rem",
  },
}));

const ScoreBox = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "2rem",
  marginBottom: "1rem",
}));

const WeeklyGraphBox = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: 10,
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  border: `2px solid ${theme.palette.appcolors.grey5}`,
  padding: "34px 20px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  [theme.breakpoints.up(1280)]: {
    minWidth: "433px",
  },
  [theme.breakpoints.down(400)]: {
    padding: "0.75rem",
  },
}));

const WeeklyGraphTitle = styled("h3")(({ theme, pl }) => ({
  fontSize: "16px",
  fontWeight: "600",
  paddingBottom: "1rem",
  paddingLeft: pl,
  color: theme.palette.appcolors.black,
}));

const NormeringScoreBox = styled("div")(({ theme }) => ({
  marginTop: "2rem",
  marginBottom: "1rem",
}));

const CategoryPagesRightBar = () => {
  const { user } = useSelector((state) => state.user);
  const currentSectionCategory = useExercisesSectionCategoriesStore();
  const title = currentSectionCategory?.title;
  const totalQuestions = currentSectionCategory?.totalQuestions;
  const isPremium = user.isPremium;

  const { lastSevenWeeksData, lastSevenWeeksCorrected } =
    useCategoryDetailsStore();

  const {
    lastWeeksCorrectForGraph,
    correctedQuestionPerCategory,
    currentWeekCorrect,
  } = lastSevenWeeksCorrected;

  const { lastWeeksDataForGraph, prognos } = lastSevenWeeksData;

  console.log(prognos, "prognos");

  return (
    <MainContainer>
      <PremiumBoxDisplay>
        <SecondaryIsPremiumCard isPremium={isPremium} />
      </PremiumBoxDisplay>

      <TitleBox>
        <PageTitle>Statistik - {title}</PageTitle>
        <PageSubTitle>
          Du har fått rätt på{" "}
          {`${
            correctedQuestionPerCategory
              ? Math.min(correctedQuestionPerCategory, totalQuestions)
              : 0
          }`}{" "}
          av {` ${totalQuestions ?? 0} `}
          uppgifter
        </PageSubTitle>
      </TitleBox>

      <ProgressBox>
        <LinearProgressBar
          variant="determinate"
          value={
            correctedQuestionPerCategory
              ? Math.min(
                  (correctedQuestionPerCategory / totalQuestions) * 100,
                  100 // Ensure the progress bar value does not exceed 100
                )
              : 0
          }
        />
      </ProgressBox>

      <ScoreBox>
        <Box sx={{ marginRight: "8rem" }}>
          <PageTitle>
            {!currentWeekCorrect ? "0" : currentWeekCorrect}
          </PageTitle>
          <PageSubTitle>Rätt denna vecka</PageSubTitle>
        </Box>

        <Box>
          <PageTitle>
            {correctedQuestionPerCategory > totalQuestions
              ? totalQuestions
              : correctedQuestionPerCategory || 0}
          </PageTitle>
          <PageSubTitle>Rätt totalt</PageSubTitle>
        </Box>
      </ScoreBox>

      <WeeklyGraphBox>
        <WeeklyGraphTitle pl={"0.35rem"}>Uppgifter</WeeklyGraphTitle>
        {lastWeeksCorrectForGraph?.length > 0 && (
          <LineDemo weeklyCoreectedGraph={lastWeeksCorrectForGraph} />
        )}
      </WeeklyGraphBox>

      <NormeringScoreBox>
        {prognos === null || prognos === undefined ? (
          <CircularProgress />
        ) : (
          <PageTitle>{prognos}</PageTitle>
        )}
        <PageSubTitle>Prognostiserad normerad poäng {title}</PageSubTitle>
      </NormeringScoreBox>

      <WeeklyGraphBox>
        <WeeklyGraphTitle>Poäng</WeeklyGraphTitle>
        <LinesChart
          syncId="anyId"
          weekWiseProgressGraph={lastWeeksDataForGraph && lastWeeksDataForGraph}
          CategoryPagesRightBar="categoryPagesRightBar"
        />
      </WeeklyGraphBox>
    </MainContainer>
  );
};

export default CategoryPagesRightBar;
