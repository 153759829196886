/** Libraries */
import { styled } from "@mui/material/styles";
import { Box } from "@material-ui/core";

/** Icons */
import DtkIcon from "../../../assets/Icons/DtkIcon.svg";
import ElfIcon from "../../../assets/Icons/ElfIcon.svg";
import KvaIcon from "../../../assets/Icons/KvaIcon.svg";
import LasIcon from "../../../assets/Icons/LasIcon.svg";
import MekIcon from "../../../assets/Icons/MekIcon.svg";
import NogIcons from "../../../assets/Icons/NogIcon.svg";
import OrdIcon from "../../../assets/Icons/OrdIcon.svg";
import ProvIcon from "../../../assets/Icons/ProvIcon.svg";
import XyzIcon from "../../../assets/Icons/XyzIcon.svg";

/** Material UI - Custom elements */
const ImageContainer = styled(Box)(() => ({
  position: "relative",
  width: "100%",
  height: "100%",
  maxWidth: "65px",
  maxHeight: "100%",
}));

const OrangeCircle = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "-50%",
  top: "-10%",
  width: "150%",
  height: "120%",
  borderRadius: "0 50% 50% 0",
  backgroundColor: theme.palette.appcolors.tertiaryColor6,
}));

const ImageIcon = styled("img")(() => ({
  padding: "25px",
  marginLeft: "15px",
  width: "100%",
  height: "100%",
}));

export const DtkIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={DtkIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const ElfIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={ElfIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const KvaIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={KvaIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const LasIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={LasIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const MekIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={MekIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const NogIconsImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={NogIcons} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const OrdIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={OrdIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const XyzIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={XyzIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};

export const ProvIconImage = () => {
  return (
    <ImageContainer>
      <OrangeCircle>
        <ImageIcon src={ProvIcon} />
      </OrangeCircle>
    </ImageContainer>
  );
};
