import React from "react";
import {
  HeltProvInfo,
  KvantitativtProvInfo,
  SvårtKvantitativtProvInfo,
  VerbaltProvInfo,
  SvårtVerbaltProvInfo,
} from "./CustomExamStartInfo";

const CustomExamInfo = ({ name }) => {
  switch (name) {
    case "Helt prov":
      return <HeltProvInfo />;
    case "Kvantitativt provpass":
      return <KvantitativtProvInfo />;
    case "Svårt kvantitativt provpass":
      return <SvårtKvantitativtProvInfo />;
    case "Verbalt provpass":
      return <VerbaltProvInfo />;
    default:
      return <SvårtVerbaltProvInfo />;
  }
};

export default CustomExamInfo;
