/** Libraries */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

/** Atoms */
import { QuestionImage } from "../../../atom/QuestionImage/QuestionImage";
import RadioButton from "../../../atom/RadioButton/RadioButton";

/** Styles */
import { ImageContainer, ImageOptionItem } from "./OptionImageCard.styles";

/** Assets */
const audioRef = new Audio(
  "https://hp-appen.s3.eu-north-1.amazonaws.com/sounds/Exercise_correct_answer.wav"
);
import { usePersistedStore } from "../../../../hooks";

const OptionImageCard = ({
  areBorderColorsVisible,
  optionId,
  content,
  optionIndex,
  isCorrect,
  isIncorrect,
  isSelected,
  answerExist,
  handleSelectOption,
  hiddeCorrectChoice,
}) => {
  const { darkmode } = usePersistedStore();
  const theme = useTheme();
  const [isOnHover, setIsOnHover] = useState(null);
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);

  const changeOptionsBorderColor = () => {
    if (answerExist && areBorderColorsVisible) {
      if (isCorrect && !hiddeCorrectChoice) {
        return `3px solid ${theme.palette.appcolors.success4}`;
      } else if (isIncorrect) {
        return `3px solid ${theme.palette.appcolors.error6}`;
      } else {
        return `1px solid ${theme.palette.appcolors.grey3}`;
      }
    }

    return `1px solid ${theme.palette.appcolors.grey4}; border-bottom: 3px solid ${theme.palette.appcolors.grey4};`;
  };

  const changeOptionsColor = () => {
    if (answerExist) {
      if (isCorrect && !hiddeCorrectChoice) {
        return theme.palette.appcolors.success4;
      } else if (isIncorrect) {
        return theme.palette.appcolors.error6;
      }
    }

    return theme.palette.appcolors.grey3;
  };

  const handleOnclick = (optionId, optionIndex) => {
    if (!answerExist) {
      handleSelectOption({ optionId, optionIndex });
      setIsSoundEnabled(true);
    }
  };

  useEffect(() => {
    const playCorrectOptionAudio = () => {
      if (audioRef) {
        audioRef.play();
      }
    };

    if (isCorrect && isSoundEnabled) {
      playCorrectOptionAudio();
    }

    return () => {
      if (audioRef) {
        audioRef.pause();
        audioRef.currentTime = 0;
      }
    };
  }, [isCorrect, isSoundEnabled]);

  return (
    <ImageOptionItem
      sx={{
        border: changeOptionsBorderColor,
        color: changeOptionsColor,
        "&:hover": {
          cursor: !answerExist && "pointer",
          color: !answerExist && theme.palette.appcolors.primaryColor,
          border:
            !answerExist && `1px solid ${theme.palette.appcolors.primaryColor}`,
        },
      }}
      onMouseOver={() => setIsOnHover(optionId)}
      onMouseLeave={() => setIsOnHover(null)}
      onClick={() => handleOnclick(optionId, optionIndex)}
    >
      <Box
        sx={{
          position: "absolute",
        }}
      >
        <RadioButton
          isCorrect={!hiddeCorrectChoice && isCorrect}
          isIncorrect={isIncorrect}
          isSelected={isSelected}
          isHover={optionId === isOnHover}
          indexLetter={optionIndex}
          disabled={answerExist}
        />
      </Box>

      <ImageContainer>
        <QuestionImage content={content} />
      </ImageContainer>
    </ImageOptionItem>
  );
};

OptionImageCard.propTypes = {
  optionId: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  optionIndex: PropTypes.number.isRequired,
  isCorrect: PropTypes.bool,
  isIncorrect: PropTypes.bool,
  isSelected: PropTypes.bool,
  answerExist: PropTypes.bool,
  handleSelectOption: PropTypes.func.isRequired,
  hiddeCorrectChoice: PropTypes.bool,
  areBorderColorsVisible: PropTypes.bool,
};

OptionImageCard.defaultProps = {
  areBorderColorsVisible: true,
};

export default OptionImageCard;
