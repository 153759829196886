import React from "react";
import ReactDOM from "react-dom";
import { Dialog } from "@mui/material";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { Transition } from "../../atom/SlideTransition/SlideTransition";
import useWindowDimensions from "../WindowDimensions/dimension";

function PopUpDialog({ isOpen, onClose, children, maxWidth, dashboard }) {
  const { width } = useWindowDimensions();

  const getMaxWidth = () => {
    if (dashboard && window.innerWidth < 600) {
      return "100vw";
    } else if (dashboard && window.innerWidth < 800) {
      return "600px";
    } else if (dashboard) {
      return "690px";
    }
    return "862px"; // Default Material-UI value is "sm"
  };

  const setMargin = () => {
    if (dashboard && window.innerWidth < 600) {
      return "0";
    }
    return "32px"; // Default Material-UI value is "32px"
  };

  // Define a custom theme with overrides
  const theme = createTheme({
    overrides: {
      MuiDialog: {
        paperWidthSm: {
          maxWidth: getMaxWidth() + " !important",
          margin: setMargin() + "!important",
          width: getMaxWidth() + " !important",
          backgroundColor: "transparent",
          backgroundImage: "none",
        },
      },
    },
  });

  // Render the dialog outside of the main content area
  return ReactDOM.createPortal(
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            "&.MuiDialog-paperWidthSm": {
              margin: "0 !important",
              width: "100% !important",
              borderRadius: width > 600 ? "10px !important" : "0px !important",
            },
            "&.MuiPaper-root": {
              backgroundColor: "transparent",
              backgroundImage: "none",
            },
          },
        }}
      >
        {children}
      </Dialog>
    </ThemeProvider>,
    document.body
  );
}

export default PopUpDialog;
