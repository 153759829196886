export const formatText = (initialText) => {
  //Change to $ signs on delimiters. extremely important for rendering LaTex correctly on our component, it pre-process the response from the API.
  const latexDelimiters = initialText
    ?.replace(/\\\(.*?\\\)/gs, (match) => `$${match.slice(2, -2).trim()}$`)
    ?.replace(/\\\[.*?\\\]/gs, (match) => `$$${match.slice(2, -2).trim()}$$`);

  const addLineBreaker = latexDelimiters
    ? latexDelimiters?.replace(/\n\s*\n/g, "\n \n&nbsp;\n\n")
    : null;

  // const addLineBreaker = latexDelimiters
  //   ?.split("\n")
  //   ?.map((line, index) => {
  //     if (line.startsWith(">")) {
  //       return index === 0 ? line + "\n>" : ">\n" + line;
  //     } else if (line.trim() === "") {
  //       return "&#160;";
  //     } else {
  //       return line;
  //     }
  //   })
  //   ?.join("\n");

  const contentImproved = addLineBreaker?.replace(/^\s+(?=\$)/gm, "");
  return contentImproved;
};
