/** Libraries */
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Rating, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

/** Molecules */
import FeedbackPopup from "../../../molecule/FeedbackPopup/FeedbackPopup";
import MobileMenu from "../../../molecule/MobileMenu/MobileMenu";
import useWindowDimensions from "../../../molecule/WindowDimensions/dimension";

/** Atoms */
import { mainCard, babyCard } from "../../../atom/ReviewCards/MainReviewCard";

/** Routes */
import { EndPoints, instance2 } from "../../../service/Route";

/** Tools */
import { SendSentryError } from "../../../../tools";

/** Styled Components */
const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "0rem 2rem",
  margin: "3rem 0rem",
  gap: "1rem",
  [theme.breakpoints.down(600)]: {
    margin: "1rem 0rem",
    padding: "0rem 1rem",
  },
}));

const FeedbackTitle = styled("p")(({ theme }) => ({
  fontSize: "32px",
  fontWeight: 600,
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "23px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "18px",
  },
}));

const FeedbackSubitle = styled("p")(({ theme }) => ({
  fontSize: "24px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "16px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "14px",
  },
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  fontSize: "2rem",
  display: "flex",
  WebkitInitialLetter: "2rem",
  gap: "1rem",
  "& .MuiRating-iconFilled": {
    color: theme.palette.appcolors.tertiaryColor11,
  },
  "& .MuiRating-iconHover": {
    color: theme.palette.appcolors.tertiaryColor11,
  },
  "& .MuiRating-iconEmpty": {
    color: theme.palette.appcolors.grey7,
  },
}));

const StyledTextAreaContainer = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "1rem",
  marginTop: "2rem",
  width: "100%",
  maxWidth: "728px",
}));

const StyledTextArea = styled("textarea")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  minHeight: "217px",
  backgroundColor: theme.palette.appcolors.grey15,
  color: theme.palette.appcolors.black,
  border: "none",
  padding: "2rem 2rem",
  borderRadius: "10px",
  outline: "none",
  resize: "none",
  fontFamily: "Figtree",
  "&::placeholder": {
    color: theme.palette.appcolors.grey7,
    [theme.breakpoints.down(600)]: {
      fontSize: "11px",
    },
  },
}));

const CharLimitText = styled("p")(({ theme }) => ({
  position: "absolute",
  bottom: "5px",
  right: "16px",
  fontSize: "14px",
  color: theme.palette.appcolors.grey14,
  [theme.breakpoints.down(600)]: {
    fontSize: "11px",
  },
}));

const StyledButton = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: !disabled
    ? theme.palette.appcolors.primaryColor
    : theme.palette.appcolors.grey3,
  color: !disabled
    ? theme.palette.appcolors.secondaryColor10
    : theme.palette.appcolors.grey7,
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  textTransform: "capitalize",
  fontWeight: "regular",
  width: "100%",
  maxWidth: "105px",
  borderRadius: "5px",
  "&:hover": {
    transform: "scale(1.05)",
    transition: "all 0.3s ease",
    cursor: "pointer",
    backgroundColor: theme.palette.appcolors.primaryColor,
  },
}));

const ReviewTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  marginTop: "2rem",
  [theme.breakpoints.down(600)]: {
    marginTop: "1rem",
  },
}));

const ReviewTitle = styled("p")(({ theme }) => ({
  fontSize: "32px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "23px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down(400)]: {
    fontSize: "14px",
  },
}));

const DoubleArrowIcon = styled("svg")(({ theme }) => ({
  width: "24px",
  height: "24px",
  [theme.breakpoints.down(600)]: {
    width: "18px",
    height: "18px",
  },
}));

const ReviewMainCardsContainer = styled(Box)(({ theme, baby }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "1.5rem 1rem",
  width: "100%",
  maxWidth: "1080px",
  marginBottom: "2rem",
  justifyContent: "center",
  alignItems: "flex-start",
  [theme.breakpoints.up(2400)]: {
    maxWidth: "1400px",
    gap: "1.5rem 1.5rem",
  },
  [theme.breakpoints.down(800)]: {
    gridTemplateColumns: "repeat(1, 1fr)",
    marginBottom: "1rem",
    gap: "1rem 0.5rem",
    padding: baby && "0rem 2rem",
  },
}));

const MessageFeedContent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [reviews, setReviews] = useState([]);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const { user, token } = useSelector((state) => state.user);
  const [showMore, setShowMore] = useState({});
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const [linebreak, setLinebreak] = useState(false);

  const changeHandler = (e) => {
    setFeedback(e.target.value);
    setWordCount(e.target.value.length);
  };

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const URL = EndPoints.feedbackList;
    instance2
      .get(URL, { headers })
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error("Error retrieving feedback list", error);
        SendSentryError("FEEDBACK", "Error retrieving feedback list", {
          details: "Failed to retrieve feedback list",
          error,
        });
      });
  }, []);

  const clickHandler = () => {
    if (isSubmitting) return;
    const URL = EndPoints.feedbackSubmit;
    const payLoad = {
      userId: user._id,
      rating: value,
      statement: feedback,
    };
    instance2
      .post(URL, payLoad)
      .then(setIsSubmitting(true))
      .then((response) => {
        if (response.status === 200) {
          setFeedbackPopup(true);
          setFeedback("");
          setWordCount(0);
        }
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
        SendSentryError("FEEDBACK", "Error submitting feedback", {
          details: "Failed to submit feedback",
          error,
        });
      })
      .finally(setIsSubmitting(false));
  };

  const handleClose = () => {
    setFeedbackPopup(false);
    setValue(0);
  };

  return (
    <>
      <MobileMenu />
      <FeedbackPopup show={feedbackPopup} onClose={() => handleClose()} />
      <MainContainer>
        <FeedbackTitle>Berätta för oss vad du tycker!</FeedbackTitle>
        <FeedbackSubitle>Hur nöjd är du med HP-appen?</FeedbackSubitle>
        <StyledRating
          size="large"
          name="simple-controlled"
          value={value}
          precision={1}
          onChange={(e) => {
            setValue(Number(e.target.value));
          }}
        />
        <StyledTextAreaContainer>
          <StyledTextArea
            aria-label="empty textarea"
            placeholder="Ditt omdöme är värdefullt för oss! Dina synpunkter är viktiga för oss och vi kommer inte att publicera ditt omdöme nedan utan att fråga dig först. Du kan lämna feedback hur många gånger du vill!"
            onChange={changeHandler}
            value={feedback}
            maxLength={2000}
          />
          <CharLimitText>{`${wordCount}/2000`}</CharLimitText>
        </StyledTextAreaContainer>
        <StyledButton disabled={!value || isSubmitting} onClick={clickHandler}>
          Skicka in
        </StyledButton>
        <ReviewTitleContainer>
          <ReviewTitle>Vad våra studenter tycker om oss</ReviewTitle>
          <DoubleArrowIcon
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill={theme.palette.appcolors.primaryColor}
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
            />
            <path
              fillRule="evenodd"
              d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
            />
          </DoubleArrowIcon>
        </ReviewTitleContainer>
        <ReviewMainCardsContainer>
          {reviews.slice(0, 3).map((object, index) => {
            let bgColor;
            let borderColor;
            switch (index) {
              case 0:
                bgColor = theme.palette.appcolors.tertiaryColor19;
                borderColor = theme.palette.appcolors.tertiaryColor10;
                break;
              case 1:
                bgColor = theme.palette.appcolors.primaryColor23;
                borderColor = theme.palette.appcolors.primaryColor25;
                break;
              case 2:
                bgColor = theme.palette.appcolors.primaryColor24;
                borderColor = theme.palette.appcolors.primaryColor19;
                break;
              default:
                bgColor = theme.palette.appcolors.tertiaryColor19;
                borderColor = theme.palette.appcolors.tertiaryColor10;
            }
            return mainCard(
              object._id,
              object.userImage,
              object.userName,
              object.userScore,
              object.rating,
              object.statement,
              theme,
              width,
              bgColor,
              borderColor,
              showMore,
              setShowMore,
              linebreak,
              setLinebreak
            );
          })}
        </ReviewMainCardsContainer>
        <ReviewMainCardsContainer baby>
          {reviews
            .slice(3)
            .map((object) =>
              babyCard(
                object._id,
                object.userImage,
                object.userName,
                object.userScore,
                object.rating,
                object.statement,
                theme
              )
            )}
        </ReviewMainCardsContainer>
      </MainContainer>
    </>
  );
};

ReviewMainCardsContainer.propTypes = {
  baby: PropTypes.bool,
};

export default MessageFeedContent;
