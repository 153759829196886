/** Atoms */
import {
  InstructionsContainer,
  InstructionsTitle,
  SectionTitle,
  SectionSubtitle,
  Notes,
} from "../../atom/SharedInfoStyledComponents/SharedInfoStyledComponents";

const VerbalProvpassInfo = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Verbalt provpass</InstructionsTitle>
      <SectionTitle>Förberedande information</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Här följer anvisningar till de verbala delproven ORD, LÄS, MEK och ELF.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Provpasset innehåller 40 uppgifter och den totala provtiden är 55
        minuter långt med normal provtid, alternativt 80 minuter med förlängd
        provtid.
      </SectionSubtitle>

      <SectionTitle>Ord - Ordförståelse</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>10 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 3 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        I delprovet ORD inleds varje uppgift med ett ord i fetstil. Under detta
        finns fem svarsförslag. Välj det svarsförslag som bäst motsvarar
        innebörden av det fetstilta ordet.
      </SectionSubtitle>

      <SectionTitle>LÄS - Svenska läsförståelse</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>10 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 22 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        Delprovet LÄS består av svenska texter från olika ämnesområden och av
        varierande längd. Varje uppgift består av en fråga med fyra
        svarsförslag, varav ett är rätt.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Ibland kan du klart se att ett av svarsförslagen är rätt och att de
        andra är fel. Ibland verkar först flera svar mer eller mindre rimliga.
        Då måste du, genom att jämföra de olika svarsförslagen, välja ut det som
        bäst besvarar frågan. Observera att du ska lösa uppgifterna med ledning
        av den information som ges i texten.
      </SectionSubtitle>

      <SectionTitle>MEK - Meningskomplettering</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>10 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 8 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        Uppgifterna i delprovet MEK består av korta textstycken där ett eller
        flera ord ersatts av en lucka markerad med _____. En uppgift kan
        innehålla en, två eller tre luckor.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Efter varje textstycke följer fyra svarsförslag. Välj det svarsförslag
        som innehållsligt och språkligt passar bäst in i textstycket som helhet.
      </SectionSubtitle>

      <SectionTitle>ELF - Engelsk läsförståelse</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>10 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 22 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        Delprovet ELF består av engelska texter från olika ämnesområden och av
        varierande längd. Varje uppgift består av en fråga med fyra
        svarsförslag, varav ett är rätt.
      </SectionSubtitle>

      <SectionSubtitle>
        Ibland kan du klart se att ett av svarsförslagen är rätt och att de
        andra är fel. Ibland verkar först flera svar mer eller mindre rimliga.
        Då måste du, genom att jämföra de olika svarsförslagen, välja ut det som
        bäst besvarar frågan. Observera att du ska lösa uppgifterna med ledning
        av den information som ges i respektive text.
      </SectionSubtitle>
    </InstructionsContainer>
  );
};

export default VerbalProvpassInfo;
