import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const InstructionsContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.secondaryColor5,
  width: "100%",
  overflow: "auto",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  padding: "3rem",
  marginTop: "2rem",
  ".MuiTypography-root": {
    maxWidth: "450px",
    lineHeight: "1.6",
  },
  [theme.breakpoints.down(600)]: {
    padding: "1.5rem",
    ".MuiTypography-root": {
      lineHeight: "1.4",
      paddingTop: "0.3rem",
    },
  },
}));

export const InstructionsTitle = styled("h3")(({ theme }) => ({
  fontWeight: "400",
  fontSize: "1.75rem",
  marginBottom: "1rem",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontSize: "1.3rem",
    marginLeft: "25px",
  },
}));

export const SectionTitle = styled("p")(({ theme }) => ({
  fontSize: ".9rem",
  fontWeight: "600",
  color: theme.palette.appcolors.black,
}));

export const SectionSubtitle = styled("p")(({ theme, mb }) => ({
  fontSize: ".875rem",
  fontWeight: "400",
  marginBottom: mb,
  maxWidth: "450px",
  lineHeight: "1.6",
  color: theme.palette.appcolors.black,
}));

export const Notes = styled(Typography)(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: ".875rem",
  fontWeight: "400",
}));
