import { createSlice } from "@reduxjs/toolkit";

export const timeCorrectionSlice = createSlice({
  name: "timeCorrection",
  initialState: {
    extendTimer: false,
    disabledTimer: false,
  },
  reducers: {
    setExtendTimer: (state) => {
      state.extendTimer = !state.extendTimer;
    },
    setDisabledTimer: (state) => {
      state.disabledTimer = !state.disabledTimer;
    },
  },
});

export const { setExtendTimer, setDisabledTimer } = timeCorrectionSlice.actions;
