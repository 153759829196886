import { createSlice } from "@reduxjs/toolkit";
import allProvPassDates from "../../../assets/Static/allProvPassDates.json";

export const pdfDownloadSlice = createSlice({
  name: "pdfDownload",
  initialState: {
    allProvPassDates,
    links: {},
    provpassOrder: [],
    title: "",
    isDownloadable: false,
    isLoading: false,
    year: null,
    baseLink: "",
    zipLink: "",
  },
  reducers: {
    generateLinks: (state, action) => {
      const { session_title, session_monthRaw, provpassOrder } =
        action.payload ?? {
          session_title: undefined,
          session_monthRaw: undefined,
          provpassOrder: undefined,
        };

      const session_month = session_monthRaw.toLowerCase().slice(0, 3);
      const session = state.allProvPassDates?.[session_title] ?? false;
      const current_session = session[session_month];
      const session_facit = session["facit"];

      if (!session) return;

      state.isDownloadable = current_session !== false;

      // set title
      state.title = session_title + "-" + session_monthRaw;
      // Generate provpassOrder
      state.provpassOrder = session ? provpassOrder : [];

      // Generate links
      const generatedLinks = {};

      for (let i = 0; i < current_session.length; i++) {
        const provpassOrderName = provpassOrder[i];
        const provpassNumber = provpassOrderName[provpassOrderName.length - 1];
        const provpassSeasonName = provpassOrderName
          .split("-")
          .pop()
          .slice(0, -1);

        const provpassName = `provpass ${provpassNumber} - ${provpassSeasonName}`;

        // get year
        const match = session_title.match(/\d{4}$/);
        // set year
        if (match) {
          state.year = parseInt(match[0], 10);
        }
        // baseLink  => "https://www.studera.nu/globalassets/hogskoleprovet/";
        state.baseLink =
          "https://hp-appen.s3.eu-north-1.amazonaws.com/DownloadExams/";

        generatedLinks[provpassName] = state.baseLink + current_session[i];
      }

      // add facit to links
      generatedLinks["facit"] =
        typeof session_facit === "object" && session_facit !== null
          ? state.baseLink + session_facit[session_month]
          : state.baseLink + session_facit;

      state.links = session ? generatedLinks : {};
    },
    fetchZipLink: (state, action) => {
      const { session_title, session_monthRaw } = action.payload ?? {
        session_title: undefined,
        session_monthRaw: undefined,
        provpassOrder: undefined,
      };
      const session_month = session_monthRaw.toLowerCase().slice(0, 3);
      const session = state.allProvPassDates?.[session_title] ?? false;
      const current_session = session[session_month];

      if (!session) return;

      state.isDownloadable = current_session !== false;

      // set title
      const getSeasonName = /^[^\s]+/;
      const getSeasonYear = /\b\d{4}\b/;
      const replacedSeasonName = session_title
        .replace(/ö/g, "o")
        .replace(/å/g, "%C3%A5");

      const seasonName = replacedSeasonName.match(getSeasonName);
      const seasonYear = session_title.match(getSeasonYear);
      state.title = seasonName + "+" + seasonYear + "-" + session_monthRaw;
      state.baseLink =
        "https://hp-appen.s3.eu-north-1.amazonaws.com/DownloadExams/onlyZIP/";
      state.zipLink = state.baseLink + state.title + ".zip";
    },
    switchDownloadable: (state) => {
      state.isDownloadable = !state.isDownloadable;
    },
    switchIsLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
  },
});

export const {
  generateLinks,
  switchDownloadable,
  switchIsLoading,
  fetchZipLink,
} = pdfDownloadSlice.actions;
