/** Libraries */
import { Box, Radio } from "@material-ui/core";
import { DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";

/** Components */
import { EndPoints, instance2 } from "../../../../../service/Route";

/** Utils */
import { appColors, scrollTop } from "../../../../../../utils/commonService";
import { SendSentryError } from "../../../../../../tools";

/** Organisms */
import ResultQuestionViewDtkOrg from "./ResultQuestionViewDTKOrg";
import ExerciseBottomBar from "../../../../../organism/BottomBars/ExerciseBottomBar";

/** Molecules */
import QuestionStatement from "../../../../../molecule/QuestionStatement/QuestionStatement";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";
import { AnswerButtonMQ } from "../../../../../molecule/AnswerButton/AnswerButtonMQ";
import OptionsMQ from "../../../../../molecule/OptionsComponents/OptionsMQ/OptionsMQ";

/** Atoms */
import { StyledQuestionInfo } from "../../../../../atom/SharedExamExerciseStyledComponents/MQDoubleColumnText";
import { StyledQuestionContainer } from "../../../../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";
import {
  StyledRadiusCheckedIcon,
  StyledRadiusOnHoverIcon,
  StyledRadiusUnCheckedIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";
import { StyledContainer } from "./SharedDtkOrg.styles";

/** Hooks */
import {
  useAuthStore,
  useAiTutorStore,
  useExercisesMQStore,
  useUiStore,
} from "../../../../../../hooks";

/** Material UI - Custom elements */
const DescriptionQuestionContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
  padding: "22px 16px 22px 25px",
  gap: "10px",

  [theme.breakpoints.down(1200)]: {
    minHeight: "104px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "15px",
    gap: "0px",
    minHeight: "119px",
  },
}));

const TransitionContainer = styled("div")(({ theme, transition }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: transition ? 0 : -100,
  width: "100%",
  zIndex: 1060,
  transition: "bottom 0.3s ease-in-out",
}));

const QuestionViewDTKOrg = (props) => {
  const { user, token } = useAuthStore();
  const {
    currNumberOfQuestions,
    currMultiQuestion,
    isCurrQuestionAnswered,
    areCurrMQSelected,
    selectedCurrMQIndex,
    startSettingQuiz,
    startNextQuestion,
    startSelectingQuestion,
    startSettingCurrQuestionAsAnswered,
    startMQNavigation,
  } = useExercisesMQStore();
  const { switchAIKeyPress } = useAiTutorStore();

  /** Seconds timer */
  const [seconds, setSeconds] = useState(0);

  /** Ui */
  const [onHover, setOnHover] = useState();
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();
  const [transition, setTransition] = useState(false);
  const [renderTrigger, setRenderTrigger] = useState(false);

  /** Buttons */
  const [enterSubmitted, setEnterSubmitted] = useState(false);
  const [disableAnswerButton, setDisableAnswerButton] = useState(false);
  const { width } = useWindowDimensions();

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    areCurrMQSelected && !isCurrQuestionAnswered && setTransition(true);
    !areCurrMQSelected && setRenderTrigger((prev) => !prev);
  }, [areCurrMQSelected, isCurrQuestionAnswered]);

  useEffect(() => {
    scrollTop();
    startSettingQuiz(props?.quiz);
  }, []);

  useEffect(() => {
    const handleEnterClick = (e) => {
      if (
        e.keyCode === 13 &&
        areCurrMQSelected &&
        !isCurrQuestionAnswered &&
        !enterSubmitted &&
        !switchAIKeyPress
      ) {
        submitAnswer();
      }
    };
    document.addEventListener("keydown", handleEnterClick);
    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [areCurrMQSelected, isCurrQuestionAnswered, enterSubmitted]);

  var tymer;
  useEffect(() => {
    tymer = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);

    return () => clearInterval(tymer);
  }, []);

  const Options = (question, option, optionIndex) => {
    if (optionIndex === question?.answerToSubmit?.selectedOptionIndex) {
      return (
        <Radio
          checked={true}
          icon={<StyledRadiusUnCheckedIcon />}
          checkedIcon={<StyledRadiusCheckedIcon />}
        />
      );
    } else {
      return (
        <Radio
          checked={false}
          icon={
            onHover && option._id === onHover ? (
              <StyledRadiusOnHoverIcon />
            ) : (
              <StyledRadiusUnCheckedIcon />
            )
          }
        />
      );
    }
  };

  const submitAnswer = async () => {
    setDisableAnswerButton(true);
    setEnterSubmitted(true);

    let dataToSubmit = [];
    currMultiQuestion.question.forEach((e) => {
      dataToSubmit.push({
        ...e.answerToSubmit,
      });
    });
    const data = {
      questionId: currMultiQuestion.question[selectedCurrMQIndex]._id,
      questionCategory:
        currMultiQuestion.question[selectedCurrMQIndex].questionCategory[0],
      sectionCategory:
        currMultiQuestion.question[selectedCurrMQIndex].sectionCategories,
      optionId: dataToSubmit[selectedCurrMQIndex]?.optionId,
      MultipartQuestion: currMultiQuestion._id,
      timeleft: props?.timeLeft ? props?.timeLeft : 0,
      totaltime: props?.totalTime ? props?.totalTime : null,
      spendtime: dataToSubmit[selectedCurrMQIndex]?.spendtime
        ? dataToSubmit[selectedCurrMQIndex]?.spendtime + seconds
        : seconds,
      spendtimevtwo: props?.totalTime
        ? props?.totalTime - props?.timeLeft
        : seconds,
    };
    dataToSubmit.splice(selectedCurrMQIndex, 1, data);

    // props.updateQuiz(quiz);

    try {
      props.stopTimer();
      const obj = {
        quiz: props.quizId,
        user: user._id,
        sectionCategory: currMultiQuestion.sectionCategory,
        answer: dataToSubmit,
        isTimeRestricted: props.isTimeRestricted,
      };

      const URL = EndPoints.submitMultiquestionParagragh;
      await instance2
        .post(URL, obj, { headers })
        .then((response) => {
          startSettingCurrQuestionAsAnswered();
          setDisableAnswerButton(false);
        })
        .finally(() => {
          setEnterSubmitted(false);
          setTransition(false);
        });
    } catch (error) {
      console.log("in catch block: ", error);
      setDisableAnswerButton(false);
      setEnterSubmitted(false);
      const { response } = error;
      const message = response?.data?.error?.message;
      const status = response?.status;
      const statusText = response?.statusText;

      if (status === 500) {
        SendSentryError("PARAQUES", `${statusText}`, {
          details: "Refresh session failed with status 500 [server error]",
          serverMessage: message,
        });
        console.log("errors");
      } else if (error.request) {
        SendSentryError("PARAQUES", "Refresh session failed", {
          details:
            "Refresh session failed due to a network error [network error]",
          serverMessage: error?.message,
        });
      } else {
        SendSentryError("PARAQUES", `${error.message}`, {
          details: "An error occurred. Please try again later.",
          serverMessage: error?.message,
        });
      }
      startErrorPopup();
      startErrorRedirectionTrue();
    }
  };

  return (
    currMultiQuestion && (
      <>
        <StyledContainer
          first={
            props?.quiz[0].question[0]._id === currMultiQuestion.question[0]._id
          }
          key={renderTrigger}
          exercise
        >
          {
            // #region Dialog-start
          }
          <StyledQuestionInfo
            isanswered={isCurrQuestionAnswered}
            paddingY={2}
            description={currMultiQuestion?.description}
            width={"100%"}
            dtk={currMultiQuestion?.title === "DTK"}
          >
            {currMultiQuestion && (
              <QuestionStatement
                description={currMultiQuestion?.description}
                title={
                  currMultiQuestion?.title !== "DTK" && currMultiQuestion?.title
                }
                width={width}
                isMQ={true}
              />
            )}
            {currMultiQuestion?.image && (
              <DialogTitle
                style={{
                  padding: "0 5rem 2rem",
                  flex: "1",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {currMultiQuestion && (
                  <QuestionStatement
                    numberOfQuestions={currNumberOfQuestions}
                    title={
                      currMultiQuestion?.title === "DTK" &&
                      currMultiQuestion?.title
                    }
                    description={currMultiQuestion?.description}
                    isMQ={true}
                  />
                )}
              </DialogTitle>
            )}
          </StyledQuestionInfo>
          {
            // #region Dialog-end
          }

          {isCurrQuestionAnswered ? (
            <ResultQuestionViewDtkOrg
              isAnswerExist={isCurrQuestionAnswered}
              paragraphIndex={props?.paragraphIndex}
              onRightClick={() => props.onRightClick()}
              onLeftClick={() => props.onLeftClick()}
              onResultHandler={() => props.onResultHandler()}
              questionIndex={props.questionIndex}
              paragraph={currMultiQuestion}
              quizId={props.quizId}
              stopTimer={() => props.stopTimer()}
              startTimer={() => props.startTimer()}
              selectedIndex={props.selectedIndex}
              nextQuestion={startNextQuestion}
            />
          ) : (
            currMultiQuestion &&
            currMultiQuestion?.question?.map((question, index) => {
              const isELFgaps = [
                "gap 31",
                "gap 32",
                "gap 33",
                "gap 34",
                "gap 35",
              ].some((substring) =>
                question?.questionStatement.includes(substring)
              );
              if (index === selectedCurrMQIndex) {
                return (
                  <StyledQuestionContainer key={question._id} exercise={true}>
                    <DescriptionQuestionContainer
                      sx={{
                        flexDirection: "column",
                      }}
                    >
                      <QuestionStatement
                        description={question?.questionStatement}
                        iself={isELFgaps}
                        indications={[
                          question?.information1,
                          question?.information2,
                        ]}
                        type={currMultiQuestion?.title}
                        isMQ={true}
                        isOptionText={true}
                      />
                      {question.image && (
                        <img
                          src={question.image[0]}
                          style={{ height: "10rem", marginBottom: ".4rem" }}
                          alt=""
                        />
                      )}
                    </DescriptionQuestionContainer>

                    <OptionsMQ
                      options={question.options[0].options}
                      selectedopindex={
                        question?.answerToSubmit?.selectedOptionIndex
                      }
                      startSelectingQuestion={(item, optionIndex) =>
                        !question.answerSubmited &&
                        startSelectingQuestion(
                          {
                            item,
                            optionIndex: optionIndex,
                            timeleft: props?.timeLeft,
                            totaltime: props?.totalTime,
                            seconds,
                          },
                          setSeconds
                        )
                      }
                      Options={(item, index) => Options(question, item, index)}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        margin: "auto",
                      }}
                    >
                      {currMultiQuestion.question.map(
                        (question, questionIndex) => {
                          return (
                            <Avatar
                              key={questionIndex}
                              sx={{
                                textAlign: "center",
                                fontFamily: "Noto Sans",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "30px",

                                marginTop: "21px",
                                marginBottom: "21px",
                                padding: "10px",
                                gap: "10px",
                                width: "36px",
                                height: "36px",
                                cursor: "pointer",
                                backgroundColor:
                                  questionIndex === selectedCurrMQIndex
                                    ? appColors.blueColor
                                    : "transparent",
                                color:
                                  questionIndex === selectedCurrMQIndex
                                    ? appColors.whiteColor
                                    : appColors.grey35,
                              }}
                              onClick={() => {
                                if (selectedCurrMQIndex !== questionIndex) {
                                  startMQNavigation(questionIndex);
                                }
                              }}
                            >
                              {questionIndex + 1}
                            </Avatar>
                          );
                        }
                      )}
                    </Box>

                    {/** Answer button 
                    <AnswerButtonMQ
                      submitAnswer={() => submitAnswer(question)}
                      question={question}
                      areCurrMQSelected={areCurrMQSelected}
                      disableAnswerButton={disableAnswerButton}
                    />*/}
                  </StyledQuestionContainer>
                );
              }
            })
          )}
        </StyledContainer>
        <TransitionContainer transition={transition}>
          {transition && (
            <ExerciseBottomBar
              mq
              question={currMultiQuestion}
              submitAnswer={() => submitAnswer(currMultiQuestion)}
            />
          )}
        </TransitionContainer>
      </>
    )
  );
};

export default QuestionViewDTKOrg;
