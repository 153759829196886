/** Libraries */
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Dialog,
  DialogActions,
  Box,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { useAuthStore } from "../../../hooks/auth/useAuthStore.js";
import { useSelector } from "react-redux";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PasswordStrengthBar from "react-password-strength-bar";

/** Atoms */
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon.js";
import InputField from "../../atom/InputField/InputField";

/** Utils */
import { isValidPassword } from "../../../utils/validations.js";
import { validateChangePassword } from "../../../utils/validations.js";

/** Custom Components */
const StyledDialog = styled(Dialog)(({ theme }) => ({
  width: "100%",
  "& .MuiDialogContent-root": {
    padding: 0,
    overflowY: "hidden",
  },
  "& .MuiPaper-root": {
    width: "100%",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundColor: theme.palette.appcolors.secondaryColor4,
    backgroundImage: "none",
  },
}));

const StyledInputField = styled(InputField)(({ theme }) => ({
  marginBottom: "15px",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderRadius: "10px",
  height: "60px",
  width: "100%",
  paddingLeft: "20px",
  overflow: "hidden",
  "& .MuiInputBase-input": {
    height: "100%",
    paddingLeft: "20px",
    backgroundColor: theme.palette.appcolors.secondaryColor3,
    fontSize: "18px",
  },
  "& .MuiFormLabel-root.MuiInputLabel-root": {
    position: "absolute",
    left: "0px",
    color: theme.palette.appcolors.grey7,
    fontSize: "17px",
  },
}));

const StyledTypography = styled(Typography)`
  && {
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    margin-top: 60px;
    margin-bottom: 10px;
  }
`;

const StyledSmallTypography = styled(Typography)`
  && {
    text-align: center;
    font-family: Figtree;
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const StyledFormBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  width: "70%",
  margin: "0 auto",
}));

const StyledConfirmButton = styled(Box)(({ theme, disabled }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: disabled
    ? theme.palette.appcolors.grey7
    : theme.palette.appcolors.primaryColor,
  borderRadius: "8px",
  color: theme.palette.appcolors.secondaryColor10,
  textTransform: "initial",
  width: "150px",
  textDecoration: "none",
  fontWeight: "400",
  cursor: "pointer",
  height: "50px",
  fontSize: "20px",
  "&:hover": {
    cursor: disabled && "default",
    transform: !disabled && "scale(1.03)",
    transition: "all 0.3s ease-in-out",
  },
}));

export default function PasswordUpdationDialog(props) {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const { startUpdatePassword } = useAuthStore();

  const [updatePassword, setUpdatePassword] = useState({
    oldPassword: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setUpdatePassword({ ...updatePassword, [name]: value });
  };

  const clickHandler = () => {
    const { currentPassword: oldPassword, updatedPassword: password } =
      updatePassword;
    const isPasswordValid = validateChangePassword(password);
    isPasswordValid &&
      startUpdatePassword(
        password,
        oldPassword,
        user._id,
        props.setIsUpdatedPassword
      );
  };

  return (
    <Box>
      {!props.isUpdatedPassword ? (
        <StyledDialog
          onClose={props.hidePopup}
          aria-labelledby="customized-dialog-title"
          open={props.showPopup}
        >
          <StyledCloseIconRight onClick={props.hidePopup} />
          <DialogContent>
            <StyledTypography>Ändra lösenord</StyledTypography>
            <StyledSmallTypography>
              Skriv in ditt nya lösenord nedan.
            </StyledSmallTypography>
            <StyledFormBox>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <StyledInputField
                  placeholder="Nuvarande lösenord"
                  name="currentPassword"
                  onChange={changeHandler}
                  type={!showPassword ? "password" : "text"}
                  value={updatePassword.currentPassword}
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span style={{ position: "relative", top: -54, right: 10 }}>
                    <IconButton
                      disableRipple
                      style={{
                        height: "0px",
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? (
                        <VisibilityOffOutlinedIcon
                          style={{
                            color: theme.palette.appcolors.black,
                          }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          style={{
                            color: theme.palette.appcolors.black,
                          }}
                        />
                      )}
                    </IconButton>
                  </span>
                </Box>
              </Box>
              <StyledInputField
                placeholder="Nytt lösenord"
                name="updatedPassword"
                onChange={changeHandler}
                type={!showPassword ? "password" : "text"}
                value={updatePassword.updatedPassword}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <span style={{ position: "relative", top: -54, right: 10 }}>
                  <IconButton
                    disableRipple
                    style={{
                      height: "0px",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <VisibilityOffOutlinedIcon
                        style={{
                          color: theme.palette.appcolors.black,
                        }}
                      />
                    ) : (
                      <VisibilityOutlinedIcon
                        style={{
                          color: theme.palette.appcolors.black,
                        }}
                      />
                    )}
                  </IconButton>
                </span>
              </Box>
              <PasswordStrengthBar
                style={{ marginTop: "-35px" }}
                className="passwordStrengthBar"
                minLength={0}
                scoreWords={["", "", "", "", ""]}
                barColors={
                  isValidPassword.test(updatePassword.updatedPassword)
                    ? [
                        `${theme.palette.appcolors.grey9}`,
                        `${theme.palette.appcolors.error6}`,
                        `${theme.palette.appcolors.tertiaryColor15}`,
                        `${theme.palette.appcolors.success5}`,
                        `${theme.palette.appcolors.success5}`,
                      ]
                    : [
                        `${theme.palette.appcolors.grey9}`,
                        `${theme.palette.appcolors.error6}`,
                        `${theme.palette.appcolors.tertiaryColor15}`,
                        `${theme.palette.appcolors.tertiaryColor15}`,
                        `${theme.palette.appcolors.tertiaryColor15}`,
                      ]
                }
                password={updatePassword.updatedPassword}
              />
            </StyledFormBox>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1rem",
            }}
          >
            <Box marginBottom="50px" marginTop="50px">
              <StyledConfirmButton
                disabled={!updatePassword.updatedPassword}
                onClick={clickHandler}
              >
                Spara
              </StyledConfirmButton>
            </Box>
          </DialogActions>
        </StyledDialog>
      ) : (
        <StyledDialog
          onClose={props.hidePopup}
          aria-labelledby="customized-dialog-title"
          open={props.showPopup}
        >
          <StyledCloseIconRight onClick={props.hidePopup} />

          <StyledTypography>Lösenord uppdaterat</StyledTypography>
          <StyledSmallTypography>
            Kom ihåg att spara det på ett säkert ställe :)
          </StyledSmallTypography>

          <Box marginBottom="50px">
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <StyledConfirmButton onClick={props.hidePopup}>
                Stäng
              </StyledConfirmButton>
            </DialogActions>
          </Box>
        </StyledDialog>
      )}
    </Box>
  );
}
