/** Libraries */
import { createSlice, current } from "@reduxjs/toolkit";

export const exercisesMQSlice = createSlice({
  name: "exercisesMQ",
  initialState: {
    quiz: [],
    sectionTitle: "",
    currMultiQuestion: null,
    currNumberOfQuestions: 0,
    areCurrMQSelected: false,
    isCurrQuestionAnswered: false,
    selectedCurrMQIndex: 0,
    selectedIndex: 0,
    currQuestion: 1,
    totalQuestions: 0,
  },
  reducers: {
    setQuiz: (state, action) => {
      if (state.quiz.length === 0) {
        state.quiz = action.payload;
        state.sectionTitle = action.payload[0].title;
        state.currMultiQuestion = action.payload[0];
        state.currNumberOfQuestions = action.payload[0].question.length;
        state.selectedCurrMQIndex = 0;
        state.areCurrMQSelected = false;
        state.isCurrQuestionAnswered = false;

        /** Set total questions*/
        let totalIndexAcc = 0;
        for (let i = 0; i <= action.payload.length - 1; i++) {
          totalIndexAcc += action.payload[i].question.length;
        }
        state.totalQuestions = totalIndexAcc;
      }
    },
    setSelectedQuestion: (state, action) => {
      const { item, optionIndex, timeleft, totaltime, seconds } =
        action.payload;

      let quiz = { ...state.currMultiQuestion };
      const questions = quiz?.question;
      let question = questions[state.selectedCurrMQIndex];

      question.optionId = item._id;
      question.answerToSubmit = {
        questionId: question._id,
        questionCategory: question.questionCategory[0],
        sectionCategory: question.sectionCategories,
        MultipartQuestion: quiz._id,
        selectedOptionIndex: optionIndex,
        optionId: item._id,
        timeleft: timeleft,
        totaltime: totaltime,
        spendtime: question?.answerToSubmit?.spendtime
          ? question?.answerToSubmit?.spendtime + seconds
          : seconds,
      };

      quiz.question = questions;
      state.quiz[state.selectedIndex].question = questions;

      state.currMultiQuestion = quiz;

      /** Enable svara button */
      const answerLenght = state.currMultiQuestion.question.filter(
        (item) => item.answerToSubmit
      ).length;
      if (answerLenght === state.currMultiQuestion.question.length) {
        state.areCurrMQSelected = true;
      }
    },
    setSelectedCurrMultiQuestionIndex: (state, action) => {
      state.selectedCurrMQIndex = action.payload;
    },
    setCurrQuestionAsAnswered: (state) => {
      state.isCurrQuestionAnswered = true;
      state.quiz[state.selectedIndex].isQuestionAnswered = true;
    },
    setNextQuestion: (state) => {
      const nextIndex = state.selectedIndex + 1;

      state.selectedIndex = nextIndex;
      state.currMultiQuestion = state.quiz[nextIndex];
      state.currNumberOfQuestions = state.quiz[nextIndex].question.length;
      state.selectedCurrMQIndex = 0;
      state.areCurrMQSelected = false;
      state.isCurrQuestionAnswered = false;
      state.currQuestion += 1;
    },
    setCurrQuestion: (state, action) => {
      let { selectedIndex, quiz } = state;
      const { questionIndex } = action.payload;

      /** Set selected index and currQuestion*/
      let totalIndexAcc = 0;
      for (let i = 0; i < selectedIndex; i++) {
        totalIndexAcc += quiz[i].question.length;
      }

      totalIndexAcc = totalIndexAcc + questionIndex + 1;
      state.currQuestion = totalIndexAcc;
    },
    exerciseMultiQuestionLogout: (state) => {
      state.quiz = [];
      state.sectionTitle = "";
      state.currMultiQuestion = null;
      state.currNumberOfQuestions = 0;
      state.areCurrMQSelected = false;
      state.isCurrQuestionAnswered = false;
      state.selectedCurrMQIndex = 0;
      state.selectedIndex = 0;
      state.currQuestion = 1;
      state.totalQuestions = 0;
    },
  },
});
export const {
  setQuiz,
  setSelectedQuestion,
  setSelectedCurrMultiQuestionIndex,
  setCurrQuestionAsAnswered,
  setNextQuestion,
  setCurrQuestion,
  exerciseMultiQuestionLogout,
} = exercisesMQSlice.actions;
