/** Libraries */
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const LectureCompletedContainer = styled("div")(({ theme }) => ({
  height: "100vh",
}));

export const BodyContainer = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  gap: "10px",
  padding: "1rem",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
  [theme.breakpoints.down(600)]: {
    padding: "0rem",
  },
}));

export const CardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: "1241px",
  height: "100%",
  borderRadius: "5px",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  background: theme.palette.appcolors.secondaryColor4,
  padding: "5rem",
  marginTop: "25px",
  [theme.breakpoints.down(600)]: {
    border: "0px",
    padding: "2rem",
  },
  [theme.breakpoints.down(400)]: {
    padding: "1rem",
  },
}));

export const FirstContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  gap: "50px",
  [theme.breakpoints.down(1200)]: {
    flexDirection: "column",
    gap: "0px",
  },
}));

export const ContentContainer = styled("div")(({ theme }) => ({
  width: "70%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",

  [theme.breakpoints.down(1200)]: {
    width: "100%",
  },
}));

export const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40%",
  height: "100%",
}));

export const StyledGif = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  height: "auto",
  maxHeight: "500px",
}));

/** Texts */
export const FontTitle = styled("h3")(({ theme }) => ({
  color: theme.palette.appcolors.secondaryColor10,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Poppins",
  fontSize: "34px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "28.192px",

  [theme.breakpoints.down(834)]: {
    fontSize: "24px",
  },
}));

export const FontSubTitleHeader = styled("h2")(({ theme }) => ({
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "28.192px",
  color: theme.palette.appcolors.black,
}));

export const FontSubTitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  textAlign: "justify",
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
}));

export const ListContainer = styled("ul")(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

export const NotificationContainer = styled(Box)(({ theme }) => ({
  width: "78px",
  height: "16px",
  flexShrink: 0,
  display: "flex",
  alignSelf: "flex-end",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down(900) && theme.breakpoints.up(3840)]: {
    display: "none",
  },
}));

export const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8.5px",
  width: "100%",

  [theme.breakpoints.down(1200)]: {
    display: "none",
  },
}));

export const ButtonContainerSM = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  marginBottom: "3rem",
  height: "100%",
  [theme.breakpoints.up(1200)]: {
    display: "none",
  },
  [theme.breakpoints.up(600)]: {
    marginTop: "2rem",
  },
}));

export const LectureCompletedAudio = styled("audio")(({ theme }) => ({}));
