/** Libraries */
import { Box, Button, Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";

/** Molecules */
import CirculerLoader from "../../../../../molecule/CircularLoader";

/** Utils */
import { appColors } from "../../../../../../utils/commonService";

/** Material UI - Custom elements */
export const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
  minHeight: "100vh",
  padding: "0rem",
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1034px",
  margin: "2rem auto",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  borderRadius: "5px",
  padding: "3rem 1rem",
  [theme.breakpoints.down(600)]: {
    width: "100%",
    backgroundColor: "transparent",
    border: "none",
    padding: "16px",
  },
}));

export const BodyContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "740px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "25px",
}));

export const ResultContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

export const QuestionsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const ResultTitle = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    alignSelf: "stretch",
    color: theme.palette.appcolors.black,
    fontSize: "1.75rem",
  },
  [theme.breakpoints.down(600)]: {
    "&.MuiTypography-root": {
      fontSize: "1.3125rem",
    },
  },
}));

export const ResultBody = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down(600)]: {
    gap: "0px",
  },
}));

export const ResultBoxContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexWrap: "no-wrap",
  gap: "10px",
  [theme.breakpoints.down(600)]: {
    flexWrap: "wrap",
    flexDirection: "column",
    gap: "0px",
  },
}));

export const ResultBox = styled(Box)(({ theme }) => ({
  width: "50%",
  maxWidth: "364px",
  maxHeight: "119px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  border: `1px solid ${theme.palette.appcolors.grey3}`,
  display: "flex",
  alignItems: "center",
  borderRadius: "10px",
  padding: "16px 16px 16px 24px",
  boxShadow: "0px 4px 10px 0px rgba(37, 37, 37, 0.05)",
  [theme.breakpoints.down(600)]: {
    width: "100%",
    maxWidth: "600px",
    height: "50px",
    maxHeight: "50px",
    border: "none",
    borderRadius: "0px",
    borderBottom: `1px solid ${theme.palette.appcolors.grey3}`,
    boxShadow: "none",
    padding: "4px 16px",
  },
}));

export const ResultBoxTextContainer = styled(Box)(({ theme }) => ({
  width: "85%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  gap: "7.5px",
  [theme.breakpoints.down(600)]: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    gap: "0px",
    paddingBottom: "0px",
  },
}));

export const ResultNumberText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    color: theme.palette.appcolors.black,
    fontSize: "3rem",
  },
  [theme.breakpoints.down(600)]: {
    "&.MuiTypography-root": {
      fontSize: "1rem",
    },
  },
}));

export const ResultBoxText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    color: theme.palette.appcolors.grey,
    fontSize: "14px",
    marginBottom: "12px",
  },
  [theme.breakpoints.down(600)]: {
    "&.MuiTypography-root": {
      fontSize: "1rem",
      marginBottom: "0px",
    },
  },
}));

export const CustomLoader = styled(CirculerLoader)(({ theme }) => ({
  [theme.breakpoints.down(600)]: {
    "&.MuiCircularProgress-root": {
      width: "25px !important",
      height: "25px !important",
    },
  },
}));

export const LoaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
}));

export const QuestionBox = styled(Box)(({ theme }) => ({
  maxHeight: "70px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 16px 16px 48px",
  cursor: "pointer",
  backgroundColor: theme.palette.appcolors.secondaryColor5,
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  borderRadius: "5px",
  [theme.breakpoints.up(834)]: {
    "&:hover": {
      transition: "all 0.3s ease",
      transform: "scale(1.02)",
      MozTransform: "scale(1.02)",
      msTransform: "scale(1.02)",
      WebkitTransform: "scale(1.02)",
      OTransform: "scale(1.02)",
      "& $size": {
        display: "none",
      },
      "& $sizeBlue": {
        display: "block",
      },
      "& svg": {
        color: theme.palette.appcolors.primaryColor,
      },
    },
  },
  [theme.breakpoints.down(600)]: {
    minHeight: "70px",
    border: "none",
    borderRadius: "0px",
    borderBottom: `1px solid ${theme.palette.appcolors.grey5}`,
    padding: "8px 8px 8px 24px",
  },
}));

export const FormControlBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ResultImage = styled("img")(({ theme }) => ({
  height: "28px",
  width: "28px",
  flexShrink: 0,
}));

export const UppgiftText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    color: theme.palette.appcolors.black,
    fontSize: "1rem",
  },
}));

export const QuestionTimeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.down(375)]: {
    gap: "6px",
  },
}));

export const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  height: "70px",
  width: "100%",
  padding: "16px 16px 16px 48px",
  borderRadius: "5px",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    border: `1px solid ${theme.palette.appcolors.primaryColor}`,
    backgroundColor: theme.palette.appcolors.primaryColor,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    height: "46px",
    width: "100%",
    color: theme.palette.appcolors.secondaryColor10,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    "&:hover": {
      transform: "scale(1.01)",
      transition: "all 0.3s ease",
      backgroundColor: theme.palette.appcolors.primaryColor,
    },
  },
}));

export const ResultPremiumBannerContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: "1",
  display: "flex",
  height: "140px",
  width: "100%",
  padding: "0rem 3rem",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: appColors.brandBlue10,
  borderRadius: "10px",
  overflow: "hidden",
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
    height: "220px",
    padding: "3rem 0rem",
  },
}));

export const BannerTextBox = styled(Box)(({ theme }) => ({
  maxWidth: "290px",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
}));

export const BannerButtonBox = styled(Box)(({ theme }) => ({
  maxWidth: "290px",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
}));

export const StyledBannerBackground = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "0",
  bottom: "0",
  top: "-50%",
  zIndex: "-1",
  backgroundColor: appColors.brandBlue45,
  width: "58%",
  height: "200%",
  borderRadius: "100% 0 0 0",
  transition: "all 0.2s ease",
  [theme.breakpoints.down(700)]: {
    top: "40%",
    width: "80%",
  },
  [theme.breakpoints.down(600)]: { top: "41.5%" },
  [theme.breakpoints.down(500)]: { top: "43%" },
  [theme.breakpoints.down(400)]: {
    top: "44.5%",
  },
}));

export const BannerText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    cursor: "default",
    textAlign: "left",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.05px",
  },
}));

export const BannerButton = styled(Button)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    display: "flex",
    width: "210px",
    maxHeight: "40px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    border: `1px solid ${appColors.blueColor}`,
    backgroundColor: appColors.blueColor,
    textTransform: "none",
    "&:hover": {
      backgroundColor: appColors.blueColor,
      border: `1px solid ${appColors.blueColor}`,
      transition: "transform 0.3s ease",
      transform: "scale(1.03)",
      MozTransform: "scale(1.03)",
      msTransform: "scale(1.03)",
      WebkitTransform: "scale(1.03)",
      OTransform: "scale(1.03)",
    },
  },
}));

export const BannerButtonText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    color: appColors.whiteColor,
    fontSize: "0.8rem",
    fontWeight: 500,
    lineHeight: "23.4px",
  },
}));
