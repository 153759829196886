import { useDispatch, useSelector } from "react-redux";
import {
  generateLinks as generateLinksAction,
  switchDownloadable as switchDownloadableAction,
  switchIsLoading as switchIsLoadingAction,
  fetchZipLink as fetchZipLinkAction,
} from "../../redux/slices";

const usePdfDownload = () => {
  const dispatch = useDispatch();
  const { allProvPassDates, links, isDownloadable, isLoading, title, zipLink } =
    useSelector((state) => state.pdfDownload);

  const fetchZipLink = (session_title, session_monthRaw) => {
    dispatch(
      fetchZipLinkAction({
        session_title,
        session_monthRaw,
      })
    );
  };

  const generateLinks = ({
    session_title,
    session_monthRaw,
    provpassOrder,
  }) => {
    dispatch(
      generateLinksAction({ session_title, session_monthRaw, provpassOrder })
    );
  };

  const switchDownloadable = () => {
    dispatch(switchDownloadableAction);
  };

  const switchIsLoading = () => {
    dispatch(switchIsLoadingAction);
  };

  return {
    allProvPassDates,
    links,
    isDownloadable,
    isLoading,
    generateLinks,
    fetchZipLink,
    switchDownloadable,
    switchIsLoading,
    title,
    zipLink,
  };
};

export default usePdfDownload;
