/** Libraries */
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  setExtendTimer,
  setDisabledTimer,
} from "../../../../../redux/slices/timeCorrection/timeCorrectionSlice";

/** Atoms */
import {
  InfoMainContainer,
  InfoHeaderTitle,
  InfoHeaderIconContainer,
  InfoButtonsContainer,
  InfoOtherButton,
  InfoStartButton,
} from "../../../../atom/SharedExamInfoStyledComponents/SharedExamInfoStyledComponents";
import { TimerIcon } from "../../../../atom/Timer/TimerPrimary/TimerPrimary.styles";
import { BarChartIcon } from "../../../../atom/BarChart/BarChartIcon";

/** Molecules */
import ExamStartInformation from "../../../../molecule/ExamStartInformation/ExamStartInformation";
import ProvPassInformation from "../ProvPassInformation/ProvPassInformation";

/** Utils */
import { appColors } from "../../../../../utils/commonService";
import {
  timeToggle,
  transformSecToMinutes,
} from "../../../../../utils/timeManipulation";

/** Hooks */
import useZipAndDownload from "../../../../../hooks/pdfDownload/useZipAndDownload";

/** Redux Hooks */
import usePdfDownload from "../../../../../hooks/pdfDownload/usePdfDownload";

/** Material UI - Custom elements */
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down(1280)]: {
    flexDirection: "column",
  },
}));

const LeftHeaderSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(1280)]: {
    marginBottom: "1rem",
  },
}));

const RightHeaderSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

const RightHeaderFormGroup = styled(FormGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "right",
  width: "100%",
  [theme.breakpoints.down(1280)]: {
    justifyContent: "left",
  },
}));

const HeaderSubtitle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontSize: "14px",
  paddingTop: "0.5rem",
  [theme.breakpoints.down(1280)]: {
    justifyContent: "left",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

const CheckboxSection = styled("div")(
  ({ theme, tabletmargin, phonemargin, pcmargin }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(1280)]: {
      marginRight: pcmargin,
    },
    [theme.breakpoints.down(1280)]: {
      marginRight: tabletmargin,
    },
    [theme.breakpoints.down(600)]: {
      marginRight: phonemargin,
    },
  })
);

const CheckboxText = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.appcolors.black,
  marginBottom: "0.3rem",
}));

const CheckboxDesign = styled(FormControlLabel)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Figtree",
  fontSize: "14px",
  borderRadius: "8px",
  width: "138px",
  height: "35px",
  marginLeft: "0px",
  marginRight: "0px",
  [theme.breakpoints.down(600)]: {
    maxWidth: "140px !important",
    height: "30px !important",
    "& .MuiSvgIcon-root": {
      fontSize: "24px !important",
    },
  },
}));

const InfoText = styled("p")(({ theme }) => ({}));

const TestInformation = (props) => {
  const [openProvpass, setOpenProvpass] = useState(false);
  const theme = useTheme();
  const { isDownloadable, isLoading, fetchZipLink } = usePdfDownload();
  const { downloadZip } = useZipAndDownload();

  useEffect(() => {
    const generatePdfLinks = () => {
      const { title: session_title, month: session_monthRaw } =
        props.session || {};
      const { provpassOrder } = props;
      // generate zip link
      fetchZipLink(session_title, session_monthRaw);
    };
    generatePdfLinks();
  }, []);

  // Generate the zip file and download
  const handlePDFBtn = () => {
    // zipAndDownload();
    downloadZip();
  };

  const dispatch = useDispatch();
  const { extendTimer, disabledTimer } = useSelector(
    (state) => state.timeCorrection
  );

  const DEFAULT_TIME_SEK = timeToggle(extendTimer);
  const DEFAULT_TIME_MINUTES = transformSecToMinutes(DEFAULT_TIME_SEK);

  const startQuiz = () => {
    setOpenProvpass(true);
  };

  const handleDisableTimer = () => {
    extendTimer
      ? (dispatch(setExtendTimer()), dispatch(setDisabledTimer()))
      : dispatch(setDisabledTimer());
  };

  const handleExtendTimer = () => {
    disabledTimer
      ? (dispatch(setDisabledTimer()), dispatch(setExtendTimer()))
      : dispatch(setExtendTimer());
  };

  return (
    <>
      {openProvpass ? (
        <ProvPassInformation
          id={props.id}
          session={props?.session}
          provpass={props?.provpass}
          provpassOrder={props?.provpassOrder}
          closepopup={props?.closepopup}
        />
      ) : (
        <InfoMainContainer>
          <HeaderContainer>
            <LeftHeaderSection>
              <InfoHeaderTitle>
                Högskoleprov {props?.session.title} {props?.session.month}
              </InfoHeaderTitle>
              <HeaderSubtitle>
                <InfoHeaderIconContainer
                  tabletmargin={"4rem"}
                  phonemargin={"2rem"}
                >
                  <BarChartIcon />
                  <InfoText>160 frågor</InfoText>
                </InfoHeaderIconContainer>
                <InfoHeaderIconContainer>
                  <TimerIcon
                    style={{
                      marginRight: "0.3rem",
                      color: theme.palette.appcolors.grey,
                    }}
                  />
                  <InfoText>
                    {disabledTimer
                      ? "Ingen tidsbegränsning"
                      : `${DEFAULT_TIME_MINUTES} min per provpass`}
                  </InfoText>
                </InfoHeaderIconContainer>
              </HeaderSubtitle>
            </LeftHeaderSection>

            <RightHeaderSection>
              <RightHeaderFormGroup>
                <CheckboxSection
                  pcmargin={"1rem"}
                  tabletmargin={"2.5rem"}
                  phonemargin={"1rem"}
                >
                  <CheckboxText>Tidsbegränsning</CheckboxText>
                  <CheckboxDesign
                    control={
                      <Checkbox
                        checkedIcon={<CheckBoxRoundedIcon />}
                        checked={disabledTimer}
                        onChange={handleDisableTimer}
                        disableRipple
                      />
                    }
                    label="Utan tid"
                    sx={{
                      color:
                        disabledTimer || (!disabledTimer && !extendTimer)
                          ? theme.palette.appcolors.primaryColor
                          : theme.palette.appcolors.grey3,
                      border: `1px solid ${
                        disabledTimer || (!disabledTimer && !extendTimer)
                          ? theme.palette.appcolors.primaryColor
                          : theme.palette.appcolors.grey3
                      }`,
                      "& .MuiSvgIcon-root": {
                        fontSize: "30px",
                        strokeWidth: extendTimer
                          ? "1"
                          : !disabledTimer && !extendTimer
                          ? "1"
                          : "0",
                        stroke: theme.palette.appcolors.secondaryColor2,
                        color:
                          disabledTimer || (!disabledTimer && !extendTimer)
                            ? theme.palette.appcolors.primaryColor
                            : theme.palette.appcolors.grey3,
                      },
                    }}
                  />
                </CheckboxSection>

                <CheckboxSection>
                  <CheckboxText>Anpassat för dyslexi</CheckboxText>
                  <CheckboxDesign
                    control={
                      <Checkbox
                        checkedIcon={<CheckBoxRoundedIcon />}
                        checked={extendTimer}
                        onChange={handleExtendTimer}
                        disableRipple
                      />
                    }
                    label="Förlängd tid"
                    sx={{
                      width: "163px",
                      color:
                        extendTimer || (!disabledTimer && !extendTimer)
                          ? theme.palette.appcolors.primaryColor
                          : theme.palette.appcolors.grey3,
                      border: `1px solid ${
                        extendTimer || (!disabledTimer && !extendTimer)
                          ? theme.palette.appcolors.primaryColor
                          : theme.palette.appcolors.grey3
                      }`,
                      "& .MuiSvgIcon-root": {
                        fontSize: "30px",
                        strokeWidth: disabledTimer
                          ? "1"
                          : !disabledTimer && !extendTimer
                          ? "1"
                          : "0",
                        stroke: theme.palette.appcolors.secondaryColor2,
                        padding: "0px",
                        color:
                          extendTimer || (!disabledTimer && !extendTimer)
                            ? theme.palette.appcolors.primaryColor
                            : theme.palette.appcolors.grey3,
                      },
                    }}
                  />
                </CheckboxSection>
              </RightHeaderFormGroup>
            </RightHeaderSection>
          </HeaderContainer>

          <ExamStartInformation />

          <InfoButtonsContainer>
            {/** PDF Download Button **/}

            <InfoOtherButton
              disabled={isLoading || !isDownloadable}
              onClick={handlePDFBtn}
            >
              {isLoading ? "Loading..." : "Skriv ut PDF"}
            </InfoOtherButton>
            <InfoStartButton onClick={() => startQuiz()}>
              Starta prov
            </InfoStartButton>
          </InfoButtonsContainer>
        </InfoMainContainer>
      )}
    </>
  );
};

export default TestInformation;
