/** Libraries */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

/** Slices */
import {
  setLecture,
  setBackLectureQuestion,
  setNextLectureQuestion,
  setBackArrowStatus,
  setForwardArrowStatus,
  setToggleAnswerVisibility,
  setProgressBar,
  setSelectedCurrLecture,
  lectureLogout,
  setIsLoading,
  lecturePreLogout,
  uiCloseProgressBackdrop,
  setSelectedLectureQuizIndex,
  setDiselectedCurrLecture,
} from "../../redux/slices";

/** Axios Instances */
import { EndPoints, instance2 } from "../../components/service/Route";

/** Tools */
import { SendSentryError } from "../../tools";

/** Utils */
import mockLectureData from "../../assets/Static/mockLectureData.json";
import { useExercisesSectionCategoriesStore } from "../exercise/useExercisesSectionCategoriesStore";
import { useMixpanel } from "../useMixpanel/useMixpanel";
import { scrollTop } from "../../utils/commonService";
import { useUiStore } from "../ui/useUiStore";
import { useAiTutorStore } from "../aiTutor/useAiTutorStore";

export const useLectureStore = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();
  const mixpanel = useMixpanel();

  const {
    isLoading,
    currType,
    quiz,
    currLecture,
    lectureName,
    lectureEnding,
    selectedIndex,
    totalNumberQuestions,
    topBar,
    completedPageAanimationIndex,
  } = useSelector((state) => state.lecture);

  const currentSectionCategory = useExercisesSectionCategoriesStore();
  const { startAiTutorLogout } = useAiTutorStore();

  /** Memorized values */
  const isAtTheoryPage = useMemo(
    () => params?.pathname === "/lecture/theory",
    [params?.pathname]
  );
  const isAtLecturePage = useMemo(
    () => params?.pathname === "/lecture",
    [params?.pathname]
  );
  const isAtCompletedPage = useMemo(
    () => params?.pathname === "/lecture/completed",
    [params?.pathname]
  );
  const isAtCategoryPage = useMemo(
    () => params?.pathname === "/category",
    [params?.pathname]
  );

  useEffect(() => {
    /** If a user tries to navigate to any of the lecture pages without
     * coming from the category dashboard we redirect them to the /home.
     */
    if (!currentSectionCategory && !isAtCategoryPage) {
      navigate("/home");
    }

    /** If user uses browser navigation buttons we kick him out of the lectures */
    const handleKeyPress = () => {
      if ((!isAtCategoryPage && currentSectionCategory) || isAtCompletedPage) {
        startHandleCloseButton();
      }
    };

    window.addEventListener("popstate", handleKeyPress);

    return () => {
      window.removeEventListener("popstate", handleKeyPress);
    };
  }, [params, isAtCompletedPage, isAtCategoryPage]);

  useEffect(() => {
    if (!params.state?.lecture) {
      navigate("/category");
      return;
    }
    if (currType && !isAtCategoryPage) {
      switch (currType) {
        case "Question":
          if (!isAtLecturePage) {
            navigate("/lecture", {
              state: {
                sectionCategory: currentSectionCategory,
                lecture: params.state.lecture,
              },
            });
          }
          break;
        case "Theory":
          if (!isAtTheoryPage) {
            navigate("/lecture/theory", {
              state: {
                sectionCategory: currentSectionCategory,
                lecture: params.state.lecture,
              },
            });
          }
          break;
        case "Completed":
          if (!isAtCompletedPage) {
            navigate("/lecture/completed", {
              state: {
                sectionCategory: currentSectionCategory,
                lecture: params.state.lecture,
              },
            });
          }
          break;

        default:
          navigate("/lecture/theory", {
            state: {
              sectionCategory: currentSectionCategory,
              lecture: params.state.lecture,
            },
          });
          break;
      }
    }
  }, [currType]);

  /** Handle Arrows of the topbar enable/disable */
  const shouldEnableBackArrow =
    (selectedIndex === 0 || isAtCompletedPage) && !topBar.isBackArrowDisabled;

  const progressPercentage = Math.round((selectedIndex / quiz.length) * 100);

  useEffect(() => {
    if (isAtCompletedPage) {
      dispatch(setProgressBar(100));
    } else {
      dispatch(setProgressBar(progressPercentage));
    }

    /** BackArrow enable or disable */
    if (shouldEnableBackArrow) {
      dispatch(setBackArrowStatus(true));
    }
    if (selectedIndex > 0 && topBar.isBackArrowDisabled) {
      dispatch(setBackArrowStatus(false));
    }
    /** ForwardArrow enable or disable */
    if (selectedIndex === quiz.length && !topBar.isForwardArrowDisabled) {
      dispatch(setForwardArrowStatus(true));
    }
    if (selectedIndex < quiz.length && topBar.isForwardArrowDisabled) {
      dispatch(setForwardArrowStatus(false));
    }
    if (isAtCompletedPage) {
      dispatch(setForwardArrowStatus(true));
    }
  }, [selectedIndex]);

  const startSettingLecture = async () => {
    const lectureId = params?.state?.lecture?._id;
    // console.log("lectureId: ", lectureId);
    if (params.state?.bypass) {
      dispatch(setIsLoading(true));
    }

    if (
      params.state?.lecture?.lectureIndex &&
      params.state?.lecture?.lectureIndex !== 0
    ) {
      dispatch(
        setSelectedLectureQuizIndex({
          index: params.state.lecture.lectureIndex,
          category: currentSectionCategory.title,
        })
      );
    }

    if (lectureId && (quiz.length === 0 || params.state?.bypass)) {
      const getLectureUrl = `${EndPoints.getLecture}/${lectureId}`;
      try {
        const data = await instance2.get(getLectureUrl);

        if (data?.data.length !== 0) {
          if (quiz.length === 0) {
            mixpanel.activityStarted(
              "Lecture",
              currentSectionCategory.title,
              params.state?.lecture?.lectureName
            );
          }
          dispatch(
            setLecture({
              data: data.data,
              lecture: params.state?.lecture,
              bypass: params.state?.bypass,
            })
          );
        } else {
          startHandleCloseButton();
        }
      } catch (error) {
        console.log("error: ", error);
        SendSentryError("LECTURE", "Error getting the lecture quicez", {
          details: "Something went wrong from the backend.",
        });
        startErrorPopup();
        startErrorRedirectionTrue();
      } finally {
        dispatch(setIsLoading(false));
        dispatch(uiCloseProgressBackdrop());
      }
    }
  };

  /** Navigation through questions */
  const startBackLectureQuestion = () => {
    startAiTutorLogout();
    scrollTop();
    if (selectedIndex > 0) {
      dispatch(setBackLectureQuestion());
      if (isAtCompletedPage) {
        const lastQuestionType = quiz[selectedIndex - 1].type;

        if (lastQuestionType === "Question") {
          return navigate("/lecture", {
            state: {
              sectionCategory: currentSectionCategory,
              lecture: params.state.lecture,
            },
          });
        } else {
          return navigate("/lecture/theory", {
            state: {
              sectionCategory: currentSectionCategory,
              lecture: params.state.lecture,
            },
          });
        }
      }

      const previusQuestionType = quiz[selectedIndex - 1].type;
      if (previusQuestionType === "Question") {
        if (!isAtLecturePage) {
          navigate("/lecture", {
            state: {
              sectionCategory: currentSectionCategory,
              lecture: params.state.lecture,
            },
          });
        }
      } else if (previusQuestionType === "Theory") {
        if (!isAtTheoryPage) {
          navigate("/lecture/theory", {
            state: {
              sectionCategory: currentSectionCategory,
              lecture: params.state.lecture,
            },
          });
        }
      }
    }
  };

  const startNextLectureQuestion = () => {
    startAiTutorLogout();
    const totalQuestionsIndex = quiz.length - 1;
    scrollTop();
    if (selectedIndex < totalQuestionsIndex - 1) {
      dispatch(setNextLectureQuestion());
      const nextQuestionType = quiz[selectedIndex + 1].type;
      // console.log("nextQuestionType: ", nextQuestionType);

      if (nextQuestionType === "Question") {
        if (!isAtLecturePage) {
          navigate("/lecture", {
            state: {
              sectionCategory: currentSectionCategory,
              lecture: params.state.lecture,
            },
          });
        }
      } else if (nextQuestionType === "Theory") {
        if (!isAtTheoryPage) {
          navigate("/lecture/theory", {
            state: {
              sectionCategory: currentSectionCategory,
              lecture: params.state.lecture,
            },
          });
        }
      }
    } else {
      dispatch(setNextLectureQuestion());
      if (!isAtCompletedPage) {
        navigate("/lecture/completed", {
          state: {
            sectionCategory: currentSectionCategory,
            lecture: params.state.lecture,
          },
        });
      }
    }
  };

  const startToggleShowAnswer = () => {
    dispatch(setToggleAnswerVisibility());
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 200);
  };

  const startAnswerQuestion = async (optionId) => {
    dispatch(setSelectedCurrLecture(optionId));

    const lectureId = params?.state?.lecture?._id;
    // console.log("lectureId: ", lectureId);

    const numQuestionsDone = quiz.reduce(
      (acc, e) =>
        e.type === "Question" && e.selectedCurrLecture ? acc + 1 : acc,
      0
    );

    const score = ((numQuestionsDone + 1) / totalNumberQuestions) * 100;

    // console.log("totalNumberQuestions: ", totalNumberQuestions);
    // console.log("numQuestionsDone: ", numQuestionsDone + 1);
    // console.log("score: ", score);

    if (lectureId) {
      try {
        await instance2.post(EndPoints.updateLectureScore, {
          lecture: lectureId,
          score: score,
        });
      } catch (error) {
        console.log("error: ", error);
        SendSentryError("LECTURE", "Error updating score in lecture", {
          details: "Something went wrong from the backend.",
        });
      }
    }
  };

  const startTryAgainQuestion = () => {
    dispatch(setDiselectedCurrLecture());
  };

  const startLectureLogout = () => {
    dispatch(lectureLogout());
  };

  const startLecturePreLogout = () => {
    dispatch(lecturePreLogout());
  };

  const startHandleCloseButton = () => {
    startAiTutorLogout();
    navigate("/category", {
      state: {
        item: currentSectionCategory,
      },
    });
  };

  return {
    /** Values */
    isLoading,
    quiz,
    currLecture,
    lectureName,
    lectureEnding,
    selectedIndex,
    topBar,
    completedPageAanimationIndex,

    /** Functions */
    startSettingLecture,
    startBackLectureQuestion,
    startNextLectureQuestion,
    startToggleShowAnswer,
    startAnswerQuestion,
    startTryAgainQuestion,
    startHandleCloseButton,
    startLecturePreLogout,
    startLectureLogout,
  };
};
