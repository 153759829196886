import { Container, Grid, makeStyles } from "@material-ui/core";
import LeftBar from "../../LeftBarOrg/LeftBar";
import ProfileFeedContent from "../ProfileFeedContent/ProfileFeedContent";
import React from "react";

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down("1200")]: {
      display: "none",
    },
  },
  main: {
    minHeight: "100vh",
    gap: "3rem",
    [theme.breakpoints.down("960")]: {
      gap: "0rem",
    },
  },
  leftBarHide: {
    [theme.breakpoints.down("600")]: {
      display: "none",
    },
  },
}));

const ProfileMain = () => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container wrap="nowrap" className={classes.main}>
        <Grid
          className={classes.leftBarHide}
          item
          style={{ maxWidth: "13rem" }}
        >
          <LeftBar currentPage="profile" />
        </Grid>
        <ProfileFeedContent />
      </Grid>
    </Container>
  );
};

export default ProfileMain;
