/** Libraries */
import { useDispatch, useSelector } from "react-redux";
import { uiCheckoutPopupOpen, uiCheckoutPopupClose } from "../../redux/slices";

export const useSubsPopups = () => {
  const dispatch = useDispatch();
  const { checkoutPopup } = useSelector((state) => state.ui);

  const handleCloseCheckoutPopup = () => {
    dispatch(uiCheckoutPopupClose());
  };

  const handleOpenCheckoutPopup = (planId) => {
    dispatch(uiCheckoutPopupOpen(planId));
  };

  return {
    isOpen: checkoutPopup.status,
    planId: checkoutPopup.planId,
    handleOpenCheckoutPopup,
    handleCloseCheckoutPopup,
  };
};
