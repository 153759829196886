/** Libraries */
import { useMemo } from "react";
import { Dialog, Box, Grid } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

/** Atoms */
import { StarIcon } from "../../../../atom/CustomIcons/StarIcon";
import { Transition } from "../../../../atom/SlideTransition/SlideTransition";

/** Utils */
import { StyledCloseIconRight } from "../../../../atom/CustomIcons/StyledCloseIcon";

/** Material UI custom elements */
const ModalContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  maxWidth: "775px",
  width: "100%",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  borderRadius: "5px",
  padding: "1rem",
  overflowY: "auto",
  scrollbarWidth: "none",
  [theme.breakpoints.down(900)]: {
    maxWidth: "605px",
  },
  [theme.breakpoints.down(600)]: {
    maxWidth: "350px",
  },
}));

const ContentContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "start",
  justifyContent: "center",
  flexDirection: "column",
}));

const QuestionsTextContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "5px",
}));

const QuestionsTitle = styled("p")(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "29px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontSize: "23px",
  },
}));

const SectionTitle = styled("p")(({ theme }) => ({
  fontSize: "23px",
  fontWeight: 600,
  lineHeight: "30px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
}));

const QuestionsText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: "18px",
  fontWeight: 400,
  lineHeight: "26px",
  paddingLeft: "5px",
  [theme.breakpoints.down(600)]: {
    fontSize: "16px",
    paddingLeft: "3px",
    lineHeight: "20px",
  },
  [theme.breakpoints.down(450)]: {
    fontSize: "14px",
  },
}));

const QuestionsTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "10px",
  [theme.breakpoints.down(600)]: {
    marginTop: "0px",
  },
}));

const IconTextBox = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "0 10px",
  alignItems: "center",
  [theme.breakpoints.down(600)]: {
    padding: "0 5px",
  },
}));

const QuestionCardsContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "0.6rem",
  padding: "1rem 4rem 0.6rem 4rem",
  justifyContent: "center",
  flexDirection: "column",
  [theme.breakpoints.down(900)]: {
    padding: "0.4rem 1.5rem 0.6rem 2.5rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: "0rem 1rem 0.5rem 1rem",
  },
}));

const QuestionCard = styled("div")(({ theme }) => ({
  display: "flex",
  height: "32px",
  maxWidth: "97px",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  flexShrink: 0,
  borderRadius: "6px",
  border: `1.2px solid ${theme.palette.appcolors.grey3}`,
  backgroundColor: theme.palette.appcolors.secondaryColor5,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.appcolors.secondaryColor4,
    transform: "scale(1.02)",
  },
  [theme.breakpoints.down(600)]: {
    maxWidth: "109px",
  },
}));

const CardNumber = styled("p")(({ theme }) => ({
  minWidth: "23px",
  color: theme.palette.appcolors.black,
  textAlign: "right",
  leadingTrim: "both",
  textEdge: "cap",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "20px",
}));

const SubmitButtonContainer = styled("div")(({ theme }) => ({
  display: "none",
  width: "100%",
  marginTop: "25px",
  marginBottom: "25px",
  [theme.breakpoints.down(600)]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.5rem",
    marginBottom: "1rem",
  },
}));

const SubmitButton = styled("button")(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: "159px",
  height: "40px",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  borderRadius: "5px",
  background: theme.palette.appcolors.primaryColor,
  border: "none",
  "&:hover": {
    transform: "scale(1.02)",
    transition: "all 0.3s ease-in-out",
  },
}));

const SubmitButtonText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.secondaryColor10,
  textAlign: "center",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "normal",
}));

const CorrectIcon = styled(TaskAltRoundedIcon)(({ theme }) => ({
  color: theme.palette.appcolors.success4,
  fontSize: "16px",
}));

const FavoritedIcon = styled(StarIcon)(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
  fontSize: "18px",
  marginRight: "0rem",
}));

const WarningIcon = styled(WarningAmberRoundedIcon)(({ theme }) => ({
  color: theme.palette.appcolors.tertiaryColor13,
  fontSize: "18px",
}));

const ShowImage = (item) => {
  if (item.isFlaged) {
    return <FavoritedIcon />;
  } else if (item.optionId) {
    return <CorrectIcon />;
  } else {
    return <WarningIcon />;
  }
};
const OversiktModal = ({
  quiz,
  params,
  timeLeft,
  open,
  setCurrentIndex,
  handleStatus,
  handleLamnaIn,
  isResultSection,
  handleNavigationResultPage,
}) => {
  const isKvantitativt = useMemo(
    () =>
      isResultSection
        ? params.state.quiz.question[0].year.title.includes("KVA")
        : params.state?.provpassOrder[
            params.state?.provpass?.simuleraQuizResult?.length || 0
          ]?.includes("KVA"),
    [params.state, isResultSection]
  );
  const theme = useTheme();

  const handleNavigateToQuestion = (index) => {
    setCurrentIndex(index);
    handleStatus(false);
  };

  const handleSubmitProvpass = () => {
    handleLamnaIn();
    handleStatus(false);
  };

  const repeatedCard = (item, index, key) => {
    return (
      <Grid item key={key} xs={4} sm={2.3} md={2}>
        <QuestionCard
          key={index + 1}
          onClick={() => handleNavigateToQuestion(index)}
        >
          {ShowImage(item)}
          <CardNumber>{index + 1}</CardNumber>
        </QuestionCard>
      </Grid>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleStatus(false)}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          boxShadow: "none",
          width: "100%",
          height: "100%",
          maxWidth: "none",
          backgroundImage: "none",
          backgroundColor: "transparent",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
          },
        },
      }}
      sx={{
        zIndex: 1401,
        margin: "auto",
      }}
    >
      <ModalContainer>
        <StyledCloseIconRight onClick={() => handleStatus(false)} />
        <ContentContainer>
          <QuestionsTextContainer>
            <QuestionsTitle>Översikt</QuestionsTitle>
            <SubmitButtonContainer>
              {isResultSection ? (
                <SubmitButton onClick={handleNavigationResultPage}>
                  <SubmitButtonText>Rättningsöversikt</SubmitButtonText>
                </SubmitButton>
              ) : (
                <SubmitButton onClick={handleSubmitProvpass}>
                  <SubmitButtonText>Lämna in</SubmitButtonText>
                </SubmitButton>
              )}
            </SubmitButtonContainer>
            <QuestionsTextBox>
              <IconTextBox>
                <CorrectIcon />
                <QuestionsText>Besvarad</QuestionsText>
              </IconTextBox>
              <IconTextBox>
                <WarningIcon />
                <QuestionsText>Ej besvarad</QuestionsText>
              </IconTextBox>
              <IconTextBox>
                <FavoritedIcon />
                <QuestionsText>Sparad fråga</QuestionsText>
              </IconTextBox>
            </QuestionsTextBox>
          </QuestionsTextContainer>

          <QuestionCardsContainer>
            <SectionTitle>{isKvantitativt ? "XYZ" : "ORD"}</SectionTitle>
            <Grid container spacing={1.5} direction="row">
              {quiz?.question?.map(
                (item, index) =>
                  index <= (isKvantitativt ? 11 : 9) &&
                  repeatedCard(item, index, item.id)
              )}
            </Grid>
          </QuestionCardsContainer>
          <QuestionCardsContainer>
            <SectionTitle>{isKvantitativt ? "KVA" : "LÄS"}</SectionTitle>
            <Grid container spacing={1.5} direction="row">
              {quiz?.question?.map(
                (item, index) =>
                  index >= (isKvantitativt ? 12 : 10) &&
                  index <= (isKvantitativt ? 21 : 19) &&
                  repeatedCard(item, index, item.id)
              )}
            </Grid>
          </QuestionCardsContainer>
          <QuestionCardsContainer>
            <SectionTitle>{isKvantitativt ? "NOG" : "MEK"}</SectionTitle>
            <Grid container spacing={1.5} direction="row">
              {quiz?.question?.map(
                (item, index) =>
                  index >= (isKvantitativt ? 22 : 20) &&
                  index <= (isKvantitativt ? 27 : 29) &&
                  repeatedCard(item, index, item.id)
              )}
            </Grid>
          </QuestionCardsContainer>
          <QuestionCardsContainer>
            <SectionTitle>{isKvantitativt ? "DTK" : "ELF"}</SectionTitle>
            <Grid container spacing={1.5} direction="row">
              {quiz?.question?.map(
                (item, index) =>
                  index >= (isKvantitativt ? 28 : 30) &&
                  index <= 40 &&
                  repeatedCard(item, index, item.id)
              )}
            </Grid>
          </QuestionCardsContainer>
        </ContentContainer>
      </ModalContainer>
    </Dialog>
  );
};

export default OversiktModal;
