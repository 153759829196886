/** Libraries */
import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

/** Atoms */
import { StyledQuestionInfo } from "../../../../atom/SharedExamExerciseStyledComponents/MQDoubleColumnText";

/** Molecules */
import QuestionStatement from "../../../../molecule/QuestionStatement/QuestionStatement";
import useWindowDimensions from "../../../../molecule/WindowDimensions/dimension";

/** MUI styled elements */
const DescriptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  width: "100%",
  maxWidth: "900px",
  [theme.breakpoints.down(1200)]: {
    maxWidth: "100%",
  },
}));

const MQDescription = ({
  description,
  sectionTitle,
  questionTitle,
  currNumberOfQuestions,
  ...props
}) => {
  const { width } = useWindowDimensions();

  return (
    <DescriptionContainer {...props}>
      <StyledQuestionInfo
        description={description}
        width={width}
        dtk={sectionTitle === "DTK"}
      >
        {description && (
          <QuestionStatement
            key={description}
            description={description}
            title={questionTitle !== "DTK" && questionTitle}
            width={width}
            isMQ={true}
          />
        )}
      </StyledQuestionInfo>
    </DescriptionContainer>
  );
};

MQDescription.propTypes = {
  description: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  currNumberOfQuestions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

MQDescription.defaultProps = {
  currNumberOfQuestions: false,
};

export default MQDescription;
