/** Material UI - Custom elements */
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    alignItems: "flex-end",
  },
  "& .MuiDialog-paper": {
    backgroundImage: "none",
    [theme.breakpoints.down(400)]: {
      margin: "16px 4px",
      borderRadius: 0,
    },
  },
}));

export const ModalContainer = styled(Box)(({ theme, isClosing }) => ({
  display: "inline-flex",
  padding: "10px 36px 36px",
  maxWidth: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "5px",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  background: theme.palette.appcolors.secondaryColor4,
  overflow: "hidden",
  boxShadow: "0px 4px 10px 0px rgba(37, 37, 37, 0.05)",
  transition: isClosing ? "opacity 0.3s ease-out 0.3s" : "none",
  [theme.breakpoints.down(600)]: {
    padding: "10px 24px 24px",
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "380px",
  width: "100%",
  height: "240px",
  paddingTop: "28px",
  paddingBottom: "16px",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  zIndex: 101,
  [theme.breakpoints.down(400)]: {
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

export const TextFeedback = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "start",
  "&.MuiTypography-root": {
    color: theme.palette.appcolors.black,
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
}));

export const CustomTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  maxWidth: "100%",
  border: "none",
  alignSelf: "stretch",
  resize: "none",
  backgroundColor: theme.palette.appcolors.secondaryColor2,
  color: theme.palette.appcolors.black,
  "&:focus-visible": {
    outline: "none",
  },
}));

export const TextSubmitButton = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    textAlign: "center",
    fontFamily: "Poppins !important",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
}));

export const SubmitButton = styled("button")(({ theme }) => ({
  display: "flex",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  alignSelf: "stretch",
  borderRadius: "5px",
  border: "none",
  transition: "background-color 0.3s ease",
  "&:hover": {
    cursor: "pointer",
  },
}));
