/** Libraries */
import React, { useState } from "react";
import { Box, Button, Switch } from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

/** Atoms */
import HelpPopup from "../../../atom/HelpPopup/HelpPopup";
import { LeftArrowIcon } from "../../../atom/CustomIcons/ArrowIcons";
import { BarChartIcon } from "../../../atom/BarChart/BarChartIcon";
import { StyledHelpOutlineIcon } from "../../../atom/CustomIcons/StyledHelpOutlineIcon";
import { StyledCloseIcon } from "../../../atom/CustomIcons/StyledCloseIcon";
import { SwitchIcon } from "../../../atom/CustomIcons/SwitchIcon";

/** Utils */
import TimerPrimary from "../../../atom/Timer/TimerPrimary/TimerPrimary";

/** MUI - Custom elements */
const MainContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  left: 0,
  right: 0,
  top: 0,
  zIndex: 1000,
  display: "flex",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.appcolors.secondaryColor3,
}));

const TopBarMainContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "113px",
  padding: "28px 39px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  marginTop: 0,
  [theme.breakpoints.down(1200)]: {
    padding: "8px 16px",
    height: "75px",
  },
  [theme.breakpoints.down(400)]: {
    padding: "8px 16px 16px 16px",
  },
}));

const TopBarSecondaryContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingBottom: "1rem",
  [theme.breakpoints.down(600)]: {
    padding: "8px 16px 28px 16px",
    justifyContent: "space-around",
  },
}));

const TopBarTertiaryContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "30px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down(600)]: {
    padding: "8px 16px 28px 16px",
    justifyContent: "space-around",
  },
}));

const MainItemsGroupContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  width: "100%",
  minWidth: "fit-content",
}));

const ItemsGroupContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "70%",
  [theme.breakpoints.up(2400)]: {
    width: "auto",
  },
  [theme.breakpoints.down(1200)]: {
    width: "auto",
    padding: "1px",
    gap: "10px",
  },
  [theme.breakpoints.down(600)]: {
    gap: "0px",
  },
}));

const ItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "no-wrap",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledButton = styled(Button)(({ theme, background, mobile }) => ({
  "&:hover": {
    transform: "scale(1.05)",
    transition: "all 0.3s ease",
    backgroundColor:
      background === "primary"
        ? `${theme.palette.appcolors.primaryColor} !important`
        : `transparent !important`,
  },
  width: mobile ? "120px" : "175px",
  height: "30px",
  backgroundColor:
    background === "primary"
      ? `${theme.palette.appcolors.primaryColor} !important`
      : `transparent !important`,
  textTransform: "none !important",
  color:
    background === "primary"
      ? `${theme.palette.appcolors.secondaryColor10} !important`
      : `${theme.palette.appcolors.primaryColor} !important`,
  border: `1px solid ${theme.palette.appcolors.primaryColor} !important`,
}));

/** Texts */
const NumberQuestionText = styled("p")(({ theme }) => ({
  textWrap: "nowrap",
  color: theme.palette.appcolors.grey,
  fontSize: "0.875rem",
  marginLeft: ".4rem",
}));

const OversiktText = styled("p")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: 600,
  [theme.breakpoints.down(1200)]: {
    fontSize: "0.875rem",
  },
}));

const ProvpassText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  textAlign: "center",
  fontSize: "1.75rem",
  [theme.breakpoints.down(1200)]: {
    fontSize: "1.3125rem",
  },
  [theme.breakpoints.down(300)]: {
    fontSize: "1.1rem",
  },
}));

const LamnaInText = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  fontSize: "1rem",
  fontWeight: 500,
  [theme.breakpoints.down(1200)]: {
    fontSize: "0.875rem",
  },
}));

const ExamTopBar = ({
  currentIndex,
  quiz,
  time,
  setShouldNavigate,
  setBackPressPopup,
  setTestSubmitPopUp,
  provpassNumber,
  handleOpenOversikt,
  handleLamnaIn,
  isResultSection,
  handleNavigationResultPage,
  isCustomExam,
  examNumber,
  sectionCategory,
  isReview,
  isTimeDisabled,
}) => {
  const theme = useTheme();
  const isSmallMobile = useMediaQuery(theme.breakpoints.down(400));
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isIpad = useMediaQuery(theme.breakpoints.down(1200));
  const is4k = useMediaQuery(theme.breakpoints.up(2400));

  const [helpPopup, setHelpPopup] = useState(false);

  /** Render components */
  const RenderCloseButton = () => {
    return isResultSection ? (
      <ItemContainer onClick={handleNavigationResultPage}>
        <LeftArrowIcon />
      </ItemContainer>
    ) : (
      <ItemContainer onClick={() => setBackPressPopup(true)}>
        <StyledCloseIcon />
      </ItemContainer>
    );
  };

  const RenderExamQuestions = () => {
    return (
      <ItemContainer
        sx={{
          justifyContent: isMobile ? "flex-start" : "center",
          width: "130px",
        }}
      >
        <BarChartIcon />
        <NumberQuestionText>
          {currentIndex + 1} av {quiz?.question.length || 40}
        </NumberQuestionText>
      </ItemContainer>
    );
  };

  const RenderExamTimer = () => {
    // Check if the timer is disabled
    if (isTimeDisabled) {
      return null; // Do not render the timer if disabled
    }
    return time ? (
      <ItemContainer
        sx={{
          justifyContent: "flex-end",
        }}
      >
        <TimerPrimary
          firstQuestion={currentIndex + 1 === 1}
          isResultSection={isResultSection}
        />
      </ItemContainer>
    ) : (
      <ItemContainer style={{ width: "130px" }} />
    );
  };

  const RenderOversiktButton = () => {
    return (
      <ItemContainer>
        <StyledButton
          mobile={isMobile ? "true" : "false"}
          background={!isMobile ? "secondary" : "primary"}
          sx={{
            "& .MuiButton-label p": {
              color: !isMobile
                ? theme.palette.appcolors.primaryColor
                : theme.palette.appcolors.secondaryColor10,
              fontFamily: "Figtree",
            },
          }}
          onClick={handleOpenOversikt}
        >
          <OversiktText>Översikt</OversiktText>
        </StyledButton>
      </ItemContainer>
    );
  };

  const RenderRattningsOversiktButton = () => {
    return (
      <ItemContainer>
        <StyledButton
          background="secondary"
          sx={{
            "& .MuiButton-label p": {
              color: theme.palette.appcolors.primaryColor,
              fontFamily: "Figtree",
            },
          }}
          onClick={handleOpenOversikt}
        >
          <OversiktText>Rättningsöversikt</OversiktText>
        </StyledButton>
      </ItemContainer>
    );
  };

  const RenderLamnaInButton = () => {
    return (
      <ItemContainer
        sx={{
          visibility: isResultSection ? "hidden" : "visible",
        }}
      >
        <StyledButton
          background="primary"
          onClick={handleLamnaIn}
          mobile={isMobile ? "true" : "false"}
        >
          <LamnaInText>Lämna in</LamnaInText>
        </StyledButton>
      </ItemContainer>
    );
  };

  return (
    <MainContainer>
      <TopBarMainContainer>
        <ItemsGroupContainer
          sx={{ justifyContent: isIpad ? "flex-start" : "space-between" }}
        >
          {RenderCloseButton()}
          {!is4k && (
            <>
              {!isMobile && RenderExamQuestions()}
              {!isIpad && !isResultSection && RenderOversiktButton()}
              {!isIpad && isResultSection && RenderRattningsOversiktButton()}
            </>
          )}
        </ItemsGroupContainer>

        <MainItemsGroupContainer>
          {is4k && (
            <ItemsGroupContainer sx={{ gap: "68px" }}>
              {RenderExamQuestions()}
              {RenderOversiktButton()}
            </ItemsGroupContainer>
          )}
          <ItemContainer>
            {isCustomExam ? (
              <ProvpassText>
                {`Provpass ${examNumber} | ${
                  isReview
                    ? quiz?.question[currentIndex].sectionCategories.title
                    : sectionCategory
                }`}
              </ProvpassText>
            ) : (
              <ProvpassText>
                {`Provpass ${provpassNumber} | ${quiz?.question[currentIndex].sectionCategories.title}`}
              </ProvpassText>
            )}
          </ItemContainer>
          {is4k && (
            <ItemsGroupContainer sx={{ gap: "68px" }}>
              {RenderLamnaInButton()}
              {RenderExamTimer()}
            </ItemsGroupContainer>
          )}
        </MainItemsGroupContainer>

        <ItemsGroupContainer
          sx={{
            justifyContent: isIpad ? "flex-end" : "space-between",
          }}
        >
          {!is4k && (
            <>
              {!isIpad && RenderLamnaInButton()}
              {!isMobile && RenderExamTimer()}
            </>
          )}
          <SwitchIcon />
          {/*
            <ItemContainer onClick={() => setHelpPopup(!helpPopup)}>
              <StyledHelpOutlineIcon />
              <Box>{helpPopup && <HelpPopup examMode />}</Box>
            </ItemContainer>
        */}
        </ItemsGroupContainer>
      </TopBarMainContainer>
      {isIpad && (
        <TopBarSecondaryContainer>
          <ItemsGroupContainer
            style={{
              width: isMobile ? "100%" : "",
              justifyContent: isMobile ? "space-between" : "center",
            }}
          >
            {isMobile && RenderExamQuestions()}
            {!isMobile && !isResultSection && RenderLamnaInButton()}
            {!isSmallMobile &&
              isResultSection &&
              RenderRattningsOversiktButton()}
            {!isSmallMobile && !isResultSection && RenderOversiktButton()}
            {isMobile && RenderExamTimer()}
          </ItemsGroupContainer>
        </TopBarSecondaryContainer>
      )}
      {isSmallMobile && (
        <TopBarTertiaryContainer>
          {!isResultSection && RenderOversiktButton()}
          {isResultSection && RenderRattningsOversiktButton()}
        </TopBarTertiaryContainer>
      )}
    </MainContainer>
  );
};

export default ExamTopBar;
