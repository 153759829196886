import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100%",
  padding: "70px 140px",
  fontFamily: "Poppins",
  gap: "1.2em",
  textAlign: "center",
  backgroundColor: theme.palette.appcolors.secondaryColor,
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  [theme.breakpoints.down(800)]: {
    padding: "3rem 70px",
  },
  [theme.breakpoints.down(430)]: {
    padding: "3rem",
  },
}));

const InputContainer = styled("div")(({ theme }) => ({
  position: "relative",
}));

const Icon = styled(LocalOfferOutlinedIcon)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: theme.spacing(2),
  transform: "translateY(-50%)",
  color: theme.palette.appcolors.black,
}));

const Input = styled("input")(({ theme, clicked }) => ({
  color: theme.palette.appcolors.black,
  pointerEvents: "none",
  padding: "12px 40px",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  borderColor: clicked ? theme.palette.appcolors.success4 : "unset",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  "&:focus": {
    outline: "none",
  },
  [theme.breakpoints.down(800)]: {
    width: "180px",
  },
}));

const Info = styled("div")(({ theme }) => ({
  marginTop: "-12px",
  height: "12px",
}));

const PopupTitle = styled("p")(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "600",
  color: theme.palette.appcolors.black,
}));

const PopupSubtitle = styled("p")(({ theme }) => ({
  fontSize: "18px",
  color: theme.palette.appcolors.grey,
}));

const SuccessText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.success4,
  fontFamily: "Figtree",
  fontSize: "12px",
  fontWeight: "600",
}));

const StyledButton = styled(Button)(({ theme, disabled }) => ({
  "&.MuiButtonBase-root": {
    width: "143px",
    height: "40px",
    fontSize: "16px",
    fontWeight: "400",
    textTransform: "none",
    backgroundColor: disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor,
    color: disabled
      ? theme.palette.appcolors.grey7
      : theme.palette.appcolors.secondaryColor10,
    borderBottom: `3px solid ${
      disabled ? theme.palette.appcolors.grey8 : theme.palette.appcolors.black3
    }`,
    "&:hover": {
      backgroundColor: disabled
        ? theme.palette.appcolors.grey3
        : theme.palette.appcolors.primaryColor,
    },
  },
}));

function DiscountBox({ onClose }) {
  const inputRef = useRef(null);
  const [isClicked, setIsClicked] = useState(false);

  const copyToClipboard = async () => {
    const textToCopy = inputRef.current.value;
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsClicked(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
    inputRef.current.setSelectionRange(0, 0);
    inputRef.current.blur();
  };

  return (
    <Container>
      <StyledCloseIconRight onClick={onClose} />
      <PopupTitle>Bjud in vänner!</PopupTitle>
      <PopupSubtitle>
        Dela denna rabattkod med dina vänner, så får de 100kr rabatt när de
        uppgraderar till premium.
      </PopupSubtitle>
      <InputContainer>
        <Icon fontSize="small" />
        <Input
          clicked={isClicked}
          ref={inputRef}
          id="discount-code"
          label="Discount Code"
          type="text"
          value="HPKOMPIS"
          required
        />
      </InputContainer>
      <Info>
        {isClicked && <SuccessText>Du har kopierat koden!</SuccessText>}
      </Info>
      <StyledButton onClick={copyToClipboard} disabled={isClicked}>
        {isClicked ? "Kopierad" : "Kopiera"}
      </StyledButton>
    </Container>
  );
}

export default DiscountBox;
