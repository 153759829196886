import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "@mui/material/styles/styled";
import { ReactComponent as Home } from "../../../assets/Icons/Home.svg";
import { ReactComponent as Course } from "../../../assets/Icons/Course.svg";
import { ReactComponent as Profile } from "../../../assets/Icons/Profile.svg";
import { ReactComponent as Feedback } from "../../../assets/Icons/Feedback.svg";
import ConstructionIcon from "@mui/icons-material/Construction";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";
import { Box } from "@material-ui/core";
import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import config from "../../../config/config";

/** Custom hooks */
import { useMixpanel } from "../../../hooks";

require("dotenv").config();

/** Material UI - Custom elements */
const StyledNavlink = styled(NavLink)(({ theme, active }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "3rem",
  justifyContent: "center",
  borderRadius: "5px",
  textDecoration: "none",
  color: active
    ? theme.palette.appcolors.primaryColor2
    : theme.palette.appcolors.black,
  backgroundColor: active ? theme.palette.appcolors.primaryColor10 : "none",
  marginBottom: "1rem",
  marginTop: "0.5rem",
  "&:hover": {
    backgroundColor: theme.palette.appcolors.primaryColor10,
  },
  [theme.breakpoints.up(960)]: {
    cursor: "pointer",
  },
}));

const LinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  marginLeft: "1.5rem",
  [theme.breakpoints.down(960)]: {
    justifyContent: "center",
    marginLeft: "0rem",
  },
  [theme.breakpoints.down(600)]: {
    justifyContent: "left",
    marginLeft: "1.5rem",
  },
}));

const LinkText = styled("p")(({ theme, active }) => ({
  fontSize: "14px",
  fontWeight: "500",
  textAlign: "center",
  marginLeft: "0.8rem",
  color: active
    ? theme.palette.appcolors.primaryColor2
    : theme.palette.appcolors.black,
  [theme.breakpoints.down(960)]: {
    display: "none",
  },
  [theme.breakpoints.down(600)]: {
    display: "flex",
  },
}));

const Menus = () => {
  const location = useLocation();
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const { height } = useWindowDimensions();

  const navigationLinks = [
    {
      to: "/home",
      icon: <Home stroke={theme.palette.appcolors.black} />,
      activeIcon: <Home stroke={theme.palette.appcolors.primaryColor2} />,
      label: "Övningar",
    },
    {
      to: "/courses",
      icon: <Course stroke={theme.palette.appcolors.black} />,
      activeIcon: <Course stroke={theme.palette.appcolors.primaryColor2} />,
      label: "Prov",
    },
    {
      to: "/profile",
      icon: <Profile stroke={theme.palette.appcolors.black} />,
      activeIcon: <Profile stroke={theme.palette.appcolors.primaryColor2} />,
      label: "Profil",
    },
    {
      to: "/feedback",
      icon: <Feedback fill={theme.palette.appcolors.black} />,
      activeIcon: <Feedback fill={theme.palette.appcolors.primaryColor2} />,
      label: "Referenser",
    },
  ];

  return (
    <Container sx={{ marginBottom: height >= 750 ? "2rem" : "1rem" }}>
      {navigationLinks.map(({ to, icon, activeIcon, label }, index) => (
        <StyledNavlink
          active={location.pathname === to}
          key={index}
          to={to}
          onClick={() => {
            mixpanel.buttonClicked(`Navigation-${label}`);
          }}
          sx={{ marginBottom: height >= 750 ? "1rem" : "0.5rem" }}
        >
          <LinkBox>
            {location.pathname === to ? activeIcon : icon}
            <LinkText active={location.pathname === to}>{label}</LinkText>
          </LinkBox>
        </StyledNavlink>
      ))}

      {config.REACT_APP_SERVER_NAME === "DEV" && (
        <>
          <StyledNavlink to="/studyplan">
            <LinkBox>
              <MenuBookIcon
                alt="Studyplan"
                fill="theme.palette.appcolors.black"
              />
              <LinkText>Studyplan</LinkText>
            </LinkBox>
          </StyledNavlink>
          <StyledNavlink to="/admin">
            <LinkBox>
              <ConstructionIcon
                alt="Admin"
                fill="theme.palette.appcolors.black"
              />
              <LinkText>Admin Tab</LinkText>
            </LinkBox>
          </StyledNavlink>
        </>
      )}
    </Container>
  );
};

export default Menus;
