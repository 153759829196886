import React, { useEffect, useRef, useState } from "react";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { useAiTutorStore } from "../../../../hooks";
import { HPIcon } from "../../../atom/HP/HPIcon";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Select } from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import { useTheme } from "@mui/material/";

import {
  StyledTextInputField,
  StyledTextArea,
  SendInstance,
  ArrowButtonContainer,
  ToolTip,
  AiChatBarContainer,
  IconAndSwitchContainer,
  CustomFormControl,
} from "./AiChatBotBar.style";
import config from "../../../../config/config";

export default function AiChatBotBar({
  sendMessage,
  question,
  options,
  correctOption,
  answerExplanation,
  isTyping,
  indexMQ,
  onAudioRecorded, // Callback to notify parent component about audio recording
}) {
  const placeholder = "Ställ en fråga";
  const [model, setModel] = useState("OPENAI");
  const [inputText, setInputText] = useState("");
  const textAreaRef = useRef(null);
  const [isVoiceOn, setIsVoiceOn] = useState(false);
  const { switchAIKeyPress, isRequestInProcess, isActive } = useAiTutorStore();
  const [isRecording, setIsRecording] = useState(false);
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isRequestInProcess) {
      setInputText("");
    }
  }, [isRequestInProcess]);

  const recognitionRef = useRef(null);

  useEffect(() => {
    if (inputText) {
      switchAIKeyPress(true);
    } else {
      switchAIKeyPress(false);
    }
  }, [inputText]);

  const handleInput = (e) => {
    setInputText(e.target.value);
  };

  const handleClick = async () => {
    if (inputText) {
      await sendMessage(
        inputText,
        isVoiceOn,
        model,
        question,
        options,
        correctOption,
        answerExplanation,
        indexMQ,
        null // Pass null for audioBlob when sending text message
      );
    }
  };

  const handleChange = (event) => {
    setModel(event.target.value);
  };

  const handleTextAreaEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (inputText) {
        sendMessage(
          inputText,
          isVoiceOn,
          model,
          question,
          options,
          correctOption,
          answerExplanation,
          indexMQ,
          null // Pass null for audioBlob when sending text message
        );
      }
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    setIsVoiceOn(true);

    const recognition = new window.webkitSpeechRecognition();
    recognitionRef.current = recognition;
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";

    recognition.onstart = () => {
      // console.log("Speech recognition started");
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInputText(transcript);
      // console.log("Recognized Text:", transcript);
    };

    recognition.onend = () => {
      setIsRecording(false);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error occurred:", event.error);
      setIsRecording(false);
    };

    recognition.start();
  };

  const stopRecording = () => {
    setIsRecording(false);
    const recognition = recognitionRef.current;
    if (recognition) {
      recognition.stop();
    }
  };

  return (
    <AiChatBarContainer>
      <StyledTextInputField>
        {config.REACT_APP_SERVER_NAME === "DEV" && (
          <IconAndSwitchContainer>
            <CustomFormControl menu={menuOpen} variant="standard">
              <Select
                value={model}
                onChange={handleChange}
                label="Model"
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                sx={{
                  "& .MuiSelect-select": {
                    backgroundColor: theme.palette.appcolors.grey3,
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.appcolors.grey3,
                      backgroundImage: "none",
                      borderRadius: "0px 0px 10px 10px",
                      "& .MuiMenuItem-root": {
                        display: "flex",
                        marginBottom: "8px",
                        "&:last-child": {
                          marginBottom: 0,
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value={"CLAUDE"}>Claude</MenuItem>
                <MenuItem value={"OPENAI"}>OpenAI</MenuItem>
              </Select>
            </CustomFormControl>
          </IconAndSwitchContainer>
        )}
        <StyledTextArea
          disabled={isTyping || isRequestInProcess}
          ref={textAreaRef}
          role="textbox"
          value={inputText}
          placeholder={placeholder}
          className="text-input"
          onChange={handleInput}
          onKeyDown={handleTextAreaEnter}
          multiline
          maxRows={4}
          InputProps={{
            disableUnderline: true,
          }}
        />
        <ArrowButtonContainer>
          <ToolTip
            disabled={!inputText}
            title="Skicka meddelande"
            placement="top"
            arrow
          >
            <span>
              <SendInstance
                disabled={!inputText}
                text={inputText}
                onClick={handleClick}
                className="send-instance"
                alt="Send Button"
              >
                <ArrowUpwardRoundedIcon />
              </SendInstance>
            </span>
          </ToolTip>
          {/** This talk feature 1.0 will be hidden for now */}
          {/* {config.REACT_APP_SERVER_NAME === "DEV" && (
            <ToolTip
              title={isRecording ? "Stop Recording" : "Start Recording"}
              className="record-instance"
              alt="Record Button"
            >
              <span>
                <SendInstance
                  onClick={isRecording ? stopRecording : startRecording}
                  className="record-instance"
                  alt="Record Button"
                >
                  <MicIcon color={isRecording ? "secondary" : "primary"} />
                </SendInstance>
              </span>
            </ToolTip>
          )} */}
        </ArrowButtonContainer>
      </StyledTextInputField>
    </AiChatBarContainer>
  );
}
