import {
  Chip,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const CombinedCardComponents = styled("div")(
  ({ theme, isPremium, isFirstQuiz }) => ({
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    padding:
      isFirstQuiz || isPremium ? "28px 14px 28px 20px" : "8px 16px 28px 20px",
  })
);

export const CardTitleText = styled("h5")(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "400",
  margin: "0",
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
}));

export const PercentageText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
  fontSize: "0.8rem",
  fontWeight: 500,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const DialogButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.appcolors.primaryColor}`,
  borderRadius: "8px",
  textTransform: "initial !important",
  backgroundColor: `${theme.palette.appcolors.primaryColor} !important`,
  color: `${theme.palette.appcolors.secondaryColor10} !important`,
  fontWeight: "400 !important",
  width: "12rem",
  height: "2.3rem",
  margin: "0 auto",
  ":hover": {
    transform: "scale(1.03)",
    transition: "transform 300ms",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "1rem !important",
  },
}));

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1401,
  maxWidth: "738px",
  margin: "auto",
}));

export const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.appcolors.black,
  textAlign: "center",
  marginBottom: "1rem",
  fontSize: "25px !important",
  fontWeight: "400 !important",
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px !important",
  },
}));

export const CustomDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    textAlign: "center",
    color: theme.palette.appcolors.grey6,
    fontSize: "16px !important",
    padding: "0rem 6rem 1rem 6rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  })
);

export const CustomProgressStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  padding: "12px 22px 20px",
  [theme.breakpoints.down(430)]: {
    flexWrap: "wrap",
  },
}));

export const DialogButtonChoiceBox = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: "1rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const StartExamDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1401,
  margin: "auto",
}));

export const CustomProgressInnerStack = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: ".5rem",
}));

export const StyledBannerBackgroundContainer = styled("div")(({ theme }) => ({
  fontSize: "23px",
  fontWeight: "600",
  lineHeight: "130%",
  position: "relative",
  left: "35px",
  top: "5px",
  minWidth: "90px",
  textAlign: "center",
  color: theme.palette.appcolors.grey11,
}));

export const StyledChip = styled(Chip)(({ theme, props, index }) => ({
  width: "22%",
  height: "20px",
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Figtree",
  backgroundColor:
    props.quizzes && index + 1 <= props?.quizzes.simuleraQuizResult?.length
      ? theme.palette.appcolors.success4
      : theme.palette.appcolors.grey3,
  color: theme.palette.appcolors.grey,
  [theme.breakpoints.down(2400)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(2160)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1460)]: {
    fontSize: "9px",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1000)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "12px",
  },
}));

export const StyledChipCE = styled(Chip)(({ theme, props, index }) => ({
  width: "22%",
  height: "20px",
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Figtree",
  backgroundColor:
    props?.customExamData && index < props.customExamData.totalAttempted
      ? theme.palette.appcolors.success4
      : theme.palette.appcolors.grey3,
  color: theme.palette.appcolors.grey,
  [theme.breakpoints.down(2400)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(2160)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1460)]: {
    fontSize: "9px",
  },
  [theme.breakpoints.down(1400)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1000)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(800)]: {
    fontSize: "12px",
  },
}));
