/** Libraries */
import React, { useMemo, useState } from "react";
import { Typography, Box, Fade } from "@material-ui/core";
import { Collapse, DialogTitle } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

/** Atoms */
import MarkLatex from "../../../../../atom/Marklatex/MarkLatex";
import {
  DownArrowIcon,
  UpArrowIcon,
} from "../../../../../atom/CustomIcons/ArrowIcons";
import { StyledQuestionInfo } from "../../../../../atom/SharedExamExerciseStyledComponents/MQDoubleColumnText";
import { StyledQuestionContainer } from "../../../../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";
import { StyledContainer } from "../../../../HomeOrg/HomePages/QuestionPages/QuestionViewDtkOrg/SharedDtkOrg.styles";

/** Molecules */
import QuestionStatement from "../../../../../molecule/QuestionStatement/QuestionStatement";
import MultiAnswer from "../../../../../molecule/MultiAnswer/MultiAnswer";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";
import NoneAnsweredAlert from "../../../../../molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";

/** Organisms */
import { ExamBottomBar } from "../../../../BottomBars/ExamBottomBar";

/** Utils */
import { formatText } from "../../../../../../tools";

/** MUI styled elements */
const DescriptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  width: "100%",
  maxWidth: "900px",
  [theme.breakpoints.down(1200)]: {
    maxWidth: "100%",
  },
}));

const AnswerAndMultiQuestionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

const StyledAnswersReviewContainer = styled(Box)(({ theme }) => ({
  "&&": {
    backgroundColor: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    gap: "18px",
  },
}));

const AnswerCard = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  padding: "20px 20px 0px 20px",
  alignSelf: "strech",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  borderRadius: "5px",
  cursor: "pointer",
}));

const AnswerCardText = styled(MarkLatex)(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontFamily: "Noto Sans",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "30px",
  [theme.breakpoints.down(900)]: {
    lineHeight: "25px",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
    lineHeight: "25px",
  },
}));

const AnswerAndImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "80px",
  display: "flex",
  paddingBottom: "0px",
  alignItems: "center",
  gap: "16px",
  flex: "1 0 0",
}));

const TextAndButtonImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  paddingBottom: "0px",
}));

const TextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "6px",
  flex: "1 0 0",
}));

const ButtonImageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const ExamBodyReviewMQ = (props) => {
  const [answerCardStatus, setAnswerCardStatus] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { width } = useWindowDimensions();
  const theme = useTheme();

  const currentQuestion = useMemo(
    () => props?.currentQuestion,
    [props?.currentQuestion]
  );

  const sectionTitle = useMemo(
    () => props?.currentQuestion?.multipartQuestion.title,
    [props?.currentQuestion]
  );

  const description = useMemo(
    () => props?.currentQuestion?.multipartQuestion.description,
    [props?.currentQuestion]
  );

  const handleShowQuestion = () => {
    if (answerCardStatus) {
      setAnswerCardStatus(false);
    } else {
      setAnswerCardStatus(true);
    }
  };

  const handleAnswerBorder = (isCorrect) => {
    if (isCorrect) {
      return `3px solid ${theme.palette.appcolors.success4}`;
    } else {
      return `3px solid ${theme.palette.appcolors.error6}`;
    }
  };

  return (
    <>
      <StyledContainer>
        {/** Description info section - START */}
        <Fade in={true} style={{ transitionDelay: "50ms" }}>
          <DescriptionContainer>
            {!props?.currentQuestion?.optionId && <NoneAnsweredAlert />}
            <StyledQuestionInfo
              description={description}
              width={width}
              dtk={sectionTitle === "DTK"}
            >
              {description && (
                <QuestionStatement
                  description={description}
                  title={sectionTitle !== "DTK" && sectionTitle}
                  width={width}
                  isMQ={true}
                  isMQReview={true}
                  answeropen={answerCardStatus}
                />
              )}
              {props?.currentQuestion?.image && (
                <>
                  <DialogTitle
                    style={{
                      padding: "0 5rem 2rem",
                      flex: "1",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    {props?.currentQuestion && (
                      <QuestionStatement
                        numberOfQuestions={quiz?.props?.currentQuestion.length}
                        title={quiz?.title === "DTK" && quiz?.title}
                        description={quiz?.description}
                      />
                    )}
                  </DialogTitle>
                </>
              )}
            </StyledQuestionInfo>
          </DescriptionContainer>
        </Fade>
        {/** Description info section - END */}

        <StyledQuestionContainer mt>
          {/** Answer card section - START */}
          <StyledAnswersReviewContainer>
            <AnswerAndMultiQuestionsContainer>
              <AnswerCard
                sx={{
                  border: handleAnswerBorder(
                    currentQuestion.optionId ===
                      currentQuestion?.questionAnswer?.option
                  ),
                }}
                onClick={handleShowQuestion}
              >
                <TextAndButtonImageContainer>
                  <AnswerAndImageContainer>
                    {width > 600 && (
                      <>
                        {currentQuestion.optionId ===
                        currentQuestion?.questionAnswer?.option ? (
                          <StyledCorrectIcon />
                        ) : (
                          <StyledWrongIcon />
                        )}
                      </>
                    )}
                    <TextContainer>
                      {/* <UppgiftText>
                      {"Uppgift " +
                        `${index + 1}` +
                        " av " +
                        currMultiQuestion.currTotalMultiQuestions}
                    </UppgiftText> */}
                      {currentQuestion?.questionStatement?.includes(
                        "hp-appen.s3.eu-north-1.amazonaws.com"
                      ) ? (
                        <Typography
                          variant="h6"
                          component="h6"
                          className="questionImage"
                          style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                          }}
                        >
                          <MarkLatex
                            content={formatText(
                              currentQuestion.questionStatement
                            )}
                            sx={{ "& img": { minHeight: "0" } }}
                          />
                        </Typography>
                      ) : (
                        <AnswerCardText
                          content={formatText(
                            currentQuestion.questionStatement
                          )}
                        />
                      )}
                    </TextContainer>
                  </AnswerAndImageContainer>
                  <ButtonImageContainer>
                    {answerCardStatus ? <UpArrowIcon /> : <DownArrowIcon />}
                  </ButtonImageContainer>
                </TextAndButtonImageContainer>
              </AnswerCard>

              <Collapse
                style={{
                  width: "100%",
                  "& .MuiCollapseWrapper": { maxWidth: "100%" },
                }}
                orientation="vertical"
                in={answerCardStatus}
              >
                <MultiAnswer
                  question={props?.questionNewStructure}
                  selectOption={props?.questionNewStructure.optionId}
                  resultComponent={true}
                  feedbackPopup={feedbackPopup}
                  setFeedbackPopup={setFeedbackPopup}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              </Collapse>
            </AnswerAndMultiQuestionsContainer>
          </StyledAnswersReviewContainer>
          {/** Answer card section - END */}
        </StyledQuestionContainer>
      </StyledContainer>
      {!props?.open && (
        <ExamBottomBar
          review={true}
          mq={true}
          isLastQuestion={props?.isLastQuestion}
          handleLamnaIn={props?.handleNavigationResultPage}
          setCurrentIndex={props?.setCurrentIndex}
          currentIndex={props?.currentIndex}
          quiz={props?.quiz}
        />
      )}
    </>
  );
};

export default ExamBodyReviewMQ;
