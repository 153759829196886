import { createSlice } from "@reduxjs/toolkit";

export const timerSlice = createSlice({
  name: "timer",
  initialState: {
    extendTimer: false,
    isTimeExtended: false,
    totalTime: 0,
    totalTimeTextMin: "",
    currTime: 0,
    currTextTime: "",
    secondsCounter: 0,
    isPaused: false,
  },
  reducers: {
    setExtendedTimeTimer: (state, action) => {
      state.isTimeExtended = action.payload;
    },
    setTotalTime: (state, action) => {
      state.totalTime = action.payload;

      const mins = Math.floor(action.payload / 60);
      const seconds = Math.floor(action.payload % 60);
      state.totalTimeTextMin =
        (mins.toString().length === 1
          ? "0" + mins.toString()
          : mins.toString()) +
        ":" +
        (seconds == 0
          ? "00"
          : seconds.toString().length == 1
          ? "0" + seconds.toString()
          : seconds.toString()) +
        " min";
    },
    setTime: (state, action) => {
      state.currTime = action.payload;

      const mins = Math.floor(action.payload / 60);
      const seconds = Math.floor(action.payload % 60);

      state.currTextTime =
        (mins.toString().length === 1
          ? "0" + mins.toString()
          : mins.toString()) +
        ":" +
        (seconds == 0
          ? "00"
          : seconds.toString().length == 1
          ? "0" + seconds.toString()
          : seconds.toString()) +
        " min";
    },
    setTimePaused: (state, action) => {
      state.isPaused = action.payload;
    },
    setTimeText: (state, action) => {
      state.currTextTime = action.payload;
    },
    setSecondsCounter: (state, action) => {
      state.secondsCounter = action.payload;
    },
    timerLogout: (state) => {
      state.isTimeExtended = false;
      state.totalTime = 0;
      state.totalTimeTextMin = "";
      state.currTime = 0;
      state.currTextTime = "";
      state.secondsCounter = 0;
      state.isPaused = false;
    },
  },
});
export const {
  setExtendedTimeTimer,
  setTotalTime,
  setTime,
  setTimeText,
  setTimePaused,
  setSecondsCounter,
  timerLogout,
} = timerSlice.actions;
