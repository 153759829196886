/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import {
  feedbackQuestionLogout,
  setFeedbackQuestionData,
  setFeedbackValues,
} from "../../redux/slices";

/** Custom hooks */
import { useMixpanel } from "../useMixpanel/useMixpanel";

export const useFeedbackQuestionsStore = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const {
    isFeedbackPopupOpened,
    count,
    anchorEl,
    questionId,
    sectionCategory,
    questionCategory,
  } = useSelector((state) => state.feedbackQuestion);

  const startLoadingFeedbackQuestionData = (data) => {
    dispatch(setFeedbackQuestionData(data));
  };

  const startPlusPoint = (anchorEl) => {
    mixpanel.feedbackButtonClicked(
      sectionCategory,
      questionCategory,
      questionId,
      "positive"
    );
    dispatch(setFeedbackValues({ count: 1, anchorEl }));
  };

  const startMinusPoint = (anchorEl) => {
    mixpanel.feedbackButtonClicked(
      sectionCategory,
      questionCategory,
      questionId,
      "negative"
    );
    dispatch(setFeedbackValues({ count: 0, anchorEl }));
  };

  const startFeedbackQuestionLogout = () => {
    dispatch(feedbackQuestionLogout());
  };

  return {
    /** Values */
    isFeedbackPopupOpened,
    count,
    anchorEl,

    /** Functions */
    startLoadingFeedbackQuestionData,
    startPlusPoint,
    startMinusPoint,
    startFeedbackQuestionLogout,
  };
};
