/** Libraries */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

/** Atoms */
import { StyledQuestionContainer } from "../../../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";

/** Molecules */
import Pagination from "../../../../molecule/Pagination/Pagination";
import QuestionStatement from "../../../../molecule/QuestionStatement/QuestionStatement";
import { AnswerButtonMQ } from "../../../../molecule/AnswerButton/AnswerButtonMQ";

/** Organisms */
import Options from "../../Options/Options";

/** Styles */
import {
  DescriptionQuestionContainer,
  MainContainer,
  StyledOptionsContainer,
} from "./MQQuestion.styles";

export const MQQuestion = ({
  questions,
  selectedCurrMQIndex,
  sectionCategory,
  enableSubmitButton,
  isAnswerVisible,
  handleSelectOption,
  handlePagination,
  handleSubmitQuestions,
  ...props
}) => {
  const question = useMemo(
    () => questions[selectedCurrMQIndex],
    [questions, selectedCurrMQIndex]
  );
  const isELFgaps = useMemo(
    () =>
      ["gap 31", "gap 32", "gap 33", "gap 34", "gap 35"].some((substring) =>
        question?.questionStatement.includes(substring)
      ),
    [question]
  );

  useEffect(() => {
    const handleEnterClick = (e) => {
      e.preventDefault();
      if (e.keyCode === 13 && enableSubmitButton) {
        handleSubmitQuestions();
      }
    };
    document.addEventListener("keydown", handleEnterClick);

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [handleSubmitQuestions]);

  if (!question) return null;

  return (
    <MainContainer {...props}>
      <StyledQuestionContainer>
        <DescriptionQuestionContainer>
          <QuestionStatement
            description={question?.questionStatement}
            iself={isELFgaps}
            indications={[question?.information1, question?.information2]}
            type={sectionCategory}
            isMQ={true}
            isMQQuestion={true}
            isOptionText={true}
          />
        </DescriptionQuestionContainer>

        <StyledOptionsContainer disableGutters>
          <Options
            options={question?.options[0].options}
            answerId={question?.answerObj?.option}
            answerExist={isAnswerVisible}
            selectedOption={question?.optionId}
            handleSelectOption={handleSelectOption}
            areBorderColorsVisible={false}
          />
        </StyledOptionsContainer>

        <Pagination
          count={questions.length}
          selectedIndex={selectedCurrMQIndex}
          handlePagination={handlePagination}
        />

        <AnswerButtonMQ
          enableSubmitButton={enableSubmitButton}
          handleSubmitQuestions={handleSubmitQuestions}
          question={question}
        />
      </StyledQuestionContainer>
    </MainContainer>
  );
};

const optionShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  image: PropTypes.string,
  type: PropTypes.string,
});

const questionShape = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  questionStatement: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(optionShape).isRequired,
    })
  ).isRequired,
});

MQQuestion.propTypes = {
  questions: PropTypes.arrayOf(questionShape).isRequired,
  selectedCurrMQIndex: PropTypes.number.isRequired,
  sectionCategory: PropTypes.string.isRequired,
  enableSubmitButton: PropTypes.bool,
  isAnswerVisible: PropTypes.bool,
  handleSelectOption: PropTypes.func.isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSubmitQuestions: PropTypes.func.isRequired,
};

MQQuestion.defaultProps = {
  enableSubmitButton: true,
  isAnswerVisible: false,
};
