/** Libraries */
import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  setExtendTimer,
  setDisabledTimer,
} from "../../../../redux/slices/timeCorrection/timeCorrectionSlice";

/** Atoms */
import {
  InfoMainContainer,
  InfoHeaderTitle,
  InfoHeaderIconContainer,
  CustomExamInfoButtonsContainer,
  InfoStartButton,
} from "../../../atom/SharedExamInfoStyledComponents/SharedExamInfoStyledComponents";
import { TimerIcon } from "../../../atom/Timer/TimerPrimary/TimerPrimary.styles";
import { BarChartIcon } from "../../../atom/BarChart/BarChartIcon";

/** Molecules */

/** Utils */
import {
  timeToggle,
  transformSecToMinutes,
} from "../../../../utils/timeManipulation";
import { useAllCustomExamStore } from "../../../../hooks/customExams/AllCustomExams/useAllCustomExamsStore";
import { useNavigate } from "react-router-dom";
import CustomExamInfo from "../../../molecule/CustomExamStartInfo/CustomExamInfo";
import AppLoader from "../../../molecule/AppLoader";

/** Material UI - Custom elements */
const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down(1280)]: {
    flexDirection: "column",
  },
}));

const LeftHeaderSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down(1280)]: {
    marginBottom: "1rem",
  },
}));

const RightHeaderSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

const RightHeaderFormGroup = styled(FormGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "right",
  width: "100%",
  [theme.breakpoints.down(1280)]: {
    justifyContent: "left",
  },
}));

const HeaderSubtitle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontSize: "14px",
  paddingTop: "0.5rem",
  [theme.breakpoints.down(1280)]: {
    justifyContent: "left",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "12px",
  },
}));

const CheckboxSection = styled("div")(
  ({ theme, tabletmargin, phonemargin, pcmargin }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(1280)]: {
      marginRight: pcmargin,
    },
    [theme.breakpoints.down(1280)]: {
      marginRight: tabletmargin,
    },
    [theme.breakpoints.down(600)]: {
      marginRight: phonemargin,
    },
  })
);

const CheckboxText = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.appcolors.black,
  marginBottom: "0.3rem",
}));

const CheckboxDesign = styled(FormControlLabel)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Figtree",
  fontSize: "14px",
  borderRadius: "8px",
  width: "138px",
  height: "35px",
  marginLeft: "0px",
  marginRight: "0px",
  [theme.breakpoints.down(600)]: {
    maxWidth: "140px !important",
    height: "30px !important",
    "& .MuiSvgIcon-root": {
      fontSize: "24px !important",
    },
  },
}));

const InfoText = styled("p")(({ theme }) => ({}));

const CuExTestsInfo = (props) => {
  const { setOpenExamSessionDialog, setOpenExamDialog } = props;
  const { customExam, customExamSessions, customizedExamSessionQuestions } =
    useAllCustomExamStore();
  const noOfQuestions = customExam.examSession.length * 40;
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { extendTimer, disabledTimer } = useSelector(
    (state) => state.timeCorrection
  );

  const DEFAULT_TIME_SEK = timeToggle(extendTimer);
  const DEFAULT_TIME_MINUTES = transformSecToMinutes(DEFAULT_TIME_SEK);
  const [isLoading, setIsLoading] = useState(false);

  const startQuiz = async () => {
    setIsLoading(true);

    try {
      if (customExam.name !== "Helt prov") {
        const examSession = customExamSessions.find(
          (exam) => exam.isAttempted === false
        );

        if (!examSession) {
          console.error("No custom exam session found");
          setIsLoading(false);
          return;
        }

        try {
          const response = await customizedExamSessionQuestions(
            examSession._id
          );
          if (response) {
            navigate("/simuleraanpassatprov", {
              state: {
                sessionExams: customExam.sessionExams,
                examSessionNumber: 1,
                totalTime: DEFAULT_TIME_SEK,
                isTimeDisabled: disabledTimer,
              },
            });
            setOpenExamDialog();
          }
        } catch (error) {
          console.error("Error fetching exam session questions:", error);
        }
      } else {
        setOpenExamSessionDialog();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisableTimer = () => {
    extendTimer
      ? (dispatch(setExtendTimer()), dispatch(setDisabledTimer()))
      : dispatch(setDisabledTimer());
    // console.log("ksljfalkdjfadlksdf", disabledTimer);
  };

  const handleExtendTimer = () => {
    disabledTimer
      ? (dispatch(setDisabledTimer()), dispatch(setExtendTimer()))
      : dispatch(setExtendTimer());
  };

  return (
    <>
      <InfoMainContainer>
        <HeaderContainer>
          <LeftHeaderSection>
            <InfoHeaderTitle>{customExam.name}</InfoHeaderTitle>
            <HeaderSubtitle>
              <InfoHeaderIconContainer
                tabletmargin={"4rem"}
                phonemargin={"2rem"}
              >
                <BarChartIcon />
                <InfoText>{noOfQuestions} frågor</InfoText>
              </InfoHeaderIconContainer>
              <InfoHeaderIconContainer>
                <TimerIcon
                  style={{
                    marginRight: "0.3rem",
                    color: theme.palette.appcolors.grey,
                  }}
                />
                <InfoText>
                  {disabledTimer
                    ? "Ingen tidsbegränsning"
                    : `${DEFAULT_TIME_MINUTES} min per provpass`}
                </InfoText>
              </InfoHeaderIconContainer>
            </HeaderSubtitle>
          </LeftHeaderSection>

          <RightHeaderSection>
            <RightHeaderFormGroup>
              <CheckboxSection
                pcmargin={"1rem"}
                tabletmargin={"2.5rem"}
                phonemargin={"1rem"}
              >
                <CheckboxText>Tidsbegränsning</CheckboxText>
                <CheckboxDesign
                  control={
                    <Checkbox
                      checkedIcon={<CheckBoxRoundedIcon />}
                      checked={disabledTimer}
                      onChange={handleDisableTimer}
                      disableRipple
                    />
                  }
                  label="Utan tid"
                  sx={{
                    color:
                      disabledTimer || (!disabledTimer && !extendTimer)
                        ? theme.palette.appcolors.primaryColor
                        : theme.palette.appcolors.grey3,
                    border: `1px solid ${
                      disabledTimer || (!disabledTimer && !extendTimer)
                        ? theme.palette.appcolors.primaryColor
                        : theme.palette.appcolors.grey3
                    }`,
                    "& .MuiSvgIcon-root": {
                      fontSize: "30px",
                      strokeWidth: extendTimer
                        ? "1"
                        : !disabledTimer && !extendTimer
                        ? "1"
                        : "0",
                      stroke: theme.palette.appcolors.secondaryColor2,
                      color:
                        disabledTimer || (!disabledTimer && !extendTimer)
                          ? theme.palette.appcolors.primaryColor
                          : theme.palette.appcolors.grey3,
                    },
                  }}
                />
              </CheckboxSection>

              <CheckboxSection>
                <CheckboxText>Anpassat för dyslexi</CheckboxText>
                <CheckboxDesign
                  control={
                    <Checkbox
                      checkedIcon={<CheckBoxRoundedIcon />}
                      checked={extendTimer}
                      onChange={handleExtendTimer}
                      disableRipple
                    />
                  }
                  label="Förlängd tid"
                  sx={{
                    width: "163px",
                    color:
                      extendTimer || (!disabledTimer && !extendTimer)
                        ? theme.palette.appcolors.primaryColor
                        : theme.palette.appcolors.grey3,
                    border: `1px solid ${
                      extendTimer || (!disabledTimer && !extendTimer)
                        ? theme.palette.appcolors.primaryColor
                        : theme.palette.appcolors.grey3
                    }`,
                    "& .MuiSvgIcon-root": {
                      fontSize: "30px",
                      strokeWidth: disabledTimer
                        ? "1"
                        : !disabledTimer && !extendTimer
                        ? "1"
                        : "0",
                      stroke: theme.palette.appcolors.secondaryColor2,
                      padding: "0px",
                      color:
                        extendTimer || (!disabledTimer && !extendTimer)
                          ? theme.palette.appcolors.primaryColor
                          : theme.palette.appcolors.grey3,
                    },
                  }}
                />
              </CheckboxSection>
            </RightHeaderFormGroup>
          </RightHeaderSection>
        </HeaderContainer>
        <CustomExamInfo name={customExam.name} />
        <CustomExamInfoButtonsContainer>
          {isLoading ? (
            <AppLoader isLoading={isLoading} /> // Show loader while loading
          ) : (
            <InfoStartButton onClick={startQuiz}>Starta prov</InfoStartButton>
          )}
        </CustomExamInfoButtonsContainer>
      </InfoMainContainer>
    </>
  );
};

export default CuExTestsInfo;
