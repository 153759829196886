/** Libraries */
import { alpha, createTheme } from "@mui/material/styles";
import { appColors } from "../utils/commonService";

const darkGlobalTheme = createTheme({
  palette: {
    mode: "dark",
    appcolors: {
      //Primary Colors
      primaryColor: "#1097DA",
      primaryColor2: "#FFFFFF",
      primaryColor3: "#22303C",
      primaryColor4: "#3E5366",
      primaryColor5: "#1097DA",
      primaryColor6: "#3E5366",
      primaryColor7: "#FFFFFF",
      primaryColor8: "#5E709E",
      primaryColor9: "#15202B",
      primaryColor10: "#22303C",
      primaryColor11: "#888899",
      primaryColor12: "#15202B",
      primaryColor13: "#15202B",
      primaryColor14: "#15202B",
      primaryColor15: "#54A4DE",
      primaryColor16: "#83A2EF",
      primaryColor17: "#EDF3F8",
      primaryColor18: "#22303C",
      primaryColor19: "#15202B",
      primaryColor20: "#1097DA",
      primaryColor21: "#1097DA",
      primaryColor22: "#517BE7",
      primaryColor23: "#22303C",
      primaryColor24: "#22303C",
      primaryColor25: "#15202B",
      //Secondary Colors
      secondaryColor: "#15202B",
      secondaryColor2: "#15202B",
      secondaryColor3: "#15202B",
      secondaryColor4: "#192734",
      secondaryColor5: "#22303C",
      secondaryColor6: alpha("#15202B", 0.42),
      secondaryColor7: "#15202B",
      secondaryColor8: "#15202B",
      secondaryColor9: alpha("#15202B", 0.8),
      secondaryColor10: "#FFFFFF",
      secondaryColor11: "#FAFAFA",
      secondaryColor12: "#22303C",
      secondaryColor13: "#22303C",
      secondaryColor14: "#22303C",
      secondaryColor15: "#192734",
      secondaryColor16: "#192734",
      //Tertiary Colors
      tertiaryColor: "#D8B27C",
      tertiaryColor2: alpha("#C4C4D8", 0.8),
      tertiaryColor3: "#C4C4D8",
      tertiaryColor4: "#E0A85A",
      tertiaryColor5: "#15202B",
      tertiaryColor6: "#D2A159",
      tertiaryColor7: "#192734",
      tertiaryColor8: "#888899",
      tertiaryColor9: "#FFFFFF",
      tertiaryColor10: "#192734",
      tertiaryColor11: "#FF8B00",
      tertiaryColor12: "#FFE5BF",
      tertiaryColor13: "#FFAB35",
      tertiaryColor14: "#FFBA59",
      tertiaryColor15: "#FFA840",
      tertiaryColor16: "#FFCA80",
      tertiaryColor17: "#FF8B00",
      tertiaryColor18: "#15202B",
      tertiaryColor19: "#22303C",
      //Error Colors
      error: "#192734",
      error2: "#AA514A",
      error3: "#15202B",
      error4: "#FFEAE8",
      error5: "#FFFFFF",
      error6: "#EB5757",
      error7: "#CC5A51",
      error8: "#FF0000",
      //Success Colors
      success: "#FFFFFF",
      success2: "#378959",
      success3: "#15202B",
      success4: "#6FCF97",
      success5: "#1BCF66",
      //Warning Colors
      alertMessage: "#22303C",
      //Grayscale Colors
      black: "#FFFFFF",
      black2: "#22303C",
      black3: alpha("#252525", 0.3),
      black4: "#252525",
      grey: "#FFFFFF",
      grey2: "#888899",
      grey3: "#3E5366",
      grey4: "#22303C",
      grey5: "#15202B",
      grey6: "#FFFFFF",
      grey7: "#A6A6A6",
      grey8: "#22303C",
      grey9: "#C2C2C2",
      grey10: "#E1E1E1",
      grey11: "#525252",
      grey12: alpha("#D9D9D9", 0.0),
      grey13: "#8B8B8B",
      grey14: "#3E5366",
      grey15: "#22303C",
      //Other Colors
      discord: "#15202B",
      popupOverlay: alpha("#3E5366", 0.5),
      inviteFriendHeaderText: "#D8675E",
      purple: "#BBB8F9",
      pink: "#FEDAF6",
    },
    primary: {
      main: appColors.blueColor,
      dark: appColors.blueColor,
      contrastText: appColors.whiteColor,
    },
    secondary: {
      main: appColors.valentine_red,
      contrastText: appColors.blackColor,
    },
    valentine_1: {
      main: appColors.valentine_red,
      dark: appColors.grey10,
      contrastText: appColors.grey25,
    },
  },
  typography: {
    Typography: {
      Poppins_1: {
        color: `${appColors.whiteColor} !important`,
        textAlign: "center !important",
        fontFamily: "Poppins !important",
        fontSize: "14px !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        lineHeight: "normal !important",
        textTransform: "none !important",
      },
    },
  },
});

const darkTheme = createTheme(
  {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor:
              darkGlobalTheme.palette["appcolors"]["secondaryColor3"],
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            backgroundColor: darkGlobalTheme.palette["primary"]["main"],
            width: "100%",
            height: "40px",
            borderRadius: "8px",
            "&:hover": {
              boxShadow: `0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px rgba(0, 0, 0, 0.2)`,
            },
            "&:disabled": {
              color: `${alpha(appColors.whiteColor, 0.42)}`,
            },
            ...darkGlobalTheme.typography.Typography["Poppins_1"],
          },
        },
      },
    },
  },
  darkGlobalTheme
);

export default darkTheme;
