import { styled } from "@mui/material/styles";
import { ReactComponent as Correct } from "../../../assets/Icons/CorrectCircle.svg";
import { ReactComponent as Wrong } from "../../../assets/Icons/WrongCircle.svg";
import { ReactComponent as RadiusCheckedIcon } from "../../../assets/Icons/RadiusCheckedIcon.svg";
import { ReactComponent as RadiusUnCheckedIcon } from "../../../assets/Icons/RadiusUnCheckedIcon.svg";
import { ReactComponent as RadiusOnHoverIcon } from "../../../assets/Icons/RadiusOnHoverIcon.svg";

export const StyledCorrectIcon = styled(Correct)(({ theme }) => ({
  width: "28px",
  height: "28px",
  fill: theme.palette.appcolors.success4,
}));

export const StyledWrongIcon = styled(Wrong)(({ theme }) => ({
  width: "28px",
  height: "28px",
  fill: theme.palette.appcolors.error6,
}));

export const StyledRadiusCheckedIcon = styled(RadiusCheckedIcon)(
  ({ theme }) => ({
    width: "28px",
    height: "28px",
    fill: theme.palette.appcolors.primaryColor,
  })
);

export const StyledRadiusUnCheckedIcon = styled(RadiusUnCheckedIcon)(
  ({ theme }) => ({
    width: "28px",
    height: "28px",
    stroke: theme.palette.appcolors.grey3,
  })
);

export const StyledRadiusOnHoverIcon = styled(RadiusOnHoverIcon)(
  ({ theme }) => ({
    width: "28px",
    height: "28px",
    stroke: theme.palette.appcolors.primaryColor,
  })
);
