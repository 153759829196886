import React from "react";
import MessageMain from "../../components/organism/MessageOrg/MessageMain/MessageMain";

const Message = () => {
  return (
    <div>
      <MessageMain />
    </div>
  );
};

export default Message;
