/** Libraries */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Styles */
export const ResultPremiumWaveContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: "1",
  display: "flex",
  width: "100%",
  height: "40px",
  padding: "0rem 1rem",
  marginBottom: "1rem",
  justifyContent: "space-between",
  alignItems: "center",
  background: `linear-gradient(89.98deg, ${theme.palette.appcolors.primaryColor18} 38.41%, ${theme.palette.appcolors.primaryColor5} 99.98%)`,
  overflow: "hidden",
  [theme.breakpoints.down(900)]: {
    height: "84px",
  },
  [theme.breakpoints.up(2400)]: {
    height: "84px",
  },
}));

export const BannerTextPremium = styled("p")(({ theme }) => ({
  cursor: "default",
  textAlign: "left",
  fontWeight: 600,
  lineHeight: "20px",
  letterSpacing: "0.05px",
  color: theme.palette.appcolors.black,
  fontFamily: "Figtree",
  fontSize: "18px",
  [theme.breakpoints.down(600)]: {
    fontSize: "16px",
  },
}));

export const MaxWidthContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "auto",
  maxWidth: "1440px",
  width: "100%",
}));

export const BannerButton = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "210px",
  height: "27px",
  justifyContent: "center",
  alignItems: "center",
  margin: "0rem 1rem",
  borderRadius: "5px",
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  backgroundColor: theme.palette.appcolors.primaryColor,
  textTransform: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.appcolors.primaryColor,
    borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
    transition: "transform 0.3s ease",
    transform: "scale(1.03)",
  },
  [theme.breakpoints.down(900)]: {
    height: "40px",
  },
  [theme.breakpoints.up(2400)]: {
    height: "40px",
  },
}));

export const BannerButtonText = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.secondaryColor10,
  fontSize: "0.8rem",
  fontWeight: 500,
  lineHeight: "23.4px",
}));
