/** Libraries */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";

/** Slices */
import {
  authCheckingFinish,
  authLogin,
  authLogout,
  examsLogout,
  setStudentPreference,
  uiLogout,
  setTableHistory,
  setLastSevenWeeksData,
  setLastSevenWeeksCorrected,
  setSectionCategories,
} from "../../redux/slices";

/** Axios Instances */
import { EndPoints, instance, instance2 } from "../../components/service/Route";

/** Tools */
import { SendSentryError } from "../../tools";

/** Utils */
import { setInitialUserState } from "../../utils/commonService";
import { verbalQuantitativePerWeekNormring } from "../../utils/Utils";

/** Custom hooks */
import { useExamStore } from "../exam/useExamStore";
import { useMixpanel } from "../useMixpanel/useMixpanel";
import { useUiStore } from "../ui/useUiStore";

export const useAuthStore = () => {
  const mixpanel = useMixpanel();
  const { startLoadingExamsData } = useExamStore();
  const { startErrorPopup } = useUiStore();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const fullUTM = `${params.get("utm_source")}-${params.get(
    "utm_medium"
  )}-${params.get("utm_campaign")}`;
  const { pathname } = useLocation();

  const { user, checking, token } = useSelector((state) => state.user);
  const { rememberMe } = useSelector((state) => state.persisted);

  /** This function renews the token and gets the user data */
  const sessionRefresh = async () => {
    if (!token) {
      return dispatch(authCheckingFinish());
    }
    if (!rememberMe || pathname.includes("public")) {
      startLogout();
      return dispatch(authCheckingFinish());
    }

    instance2
      .get(EndPoints.getUser)
      .then((response) => {
        const { user, token } = response.data;
        if (token) {
          setInitialUserState({ user, token });
          dispatch(authLogin({ user, token }));

          localStorage.setItem("token", token);
          localStorage.setItem("userId", user._id);
          localStorage.setItem("role", user.role);
          localStorage.setItem("fullName", user.fullName);
          localStorage.setItem("email", user.email);

          mixpanel.sessionRefresh({
            status: "Success",
            method: "Refresh",
          });
          startLoadingExamsData(user._id, token);
          dispatch(authCheckingFinish());
        }
      })
      .catch((error) => {
        const { response } = error;

        const message =
          response?.data?.message || response?.data?.error?.message;
        const status = response?.status;

        console.log("error: ", message);

        if (status === 500) {
          SendSentryError("AUTH", "Refresh session failed", {
            details: "Refresh session failed with status 500 [server error]",
            serverMessage: message,
          });
          startErrorPopup();
        }

        startLogout();

        dispatch(authCheckingFinish());
      });
  };

  const startLogin = async (data, setUnAuthorized, setUnVerified) => {
    const URL = EndPoints.Login;
    instance
      .post(URL, data)
      .then((response) => {
        const { user, token } = response.data;
        if (token) {
          setInitialUserState({ user, token });
          dispatch(authLogin({ user, token }));

          localStorage.setItem("token", token);
          localStorage.setItem("userId", user._id);
          localStorage.setItem("role", user.role);
          localStorage.setItem("fullName", user.fullName);
          localStorage.setItem("email", user.email);

          mixpanel.login({
            status: "Success",
            userId: user._id,
            method: "Email",
          });

          fetchAllSections();
          startLoadingExamsData(user._id, token);
          navigate("/home");
        } else {
          setUnAuthorized(true);
        }
      })
      .catch((error) => {
        const { response } = error;

        const message =
          response?.data?.message || response?.data?.error?.message;
        const status = response?.status;

        console.log("error: ", message);

        if (status === 400 || status === 401) {
          setUnAuthorized(true);
        } else if (status === 403) {
          setUnVerified(true);
        } else if (status === 500) {
          SendSentryError("AUTH", "Login failed", {
            details: "Login request failed with status 500 [server error]",
            serverMessage: message,
          });
          console.log("Server error");
          startErrorPopup();
        } else {
          startErrorPopup();
        }
      });
  };

  const startSignUp = async (data, setEmailError) => {
    const URL = EndPoints.SignUp;
    instance
      .post(URL, data)
      .then((response) => {
        if (response?.data?.token) {
          const { user, token } = response?.data;
          setInitialUserState({ user, token });
          dispatch(authLogin({ user, token }));

          // console.log("data: ", data);
          // console.log("user: ", user);
          // console.log("token: ", token);

          localStorage.setItem("token", token);
          localStorage.setItem("userId", user._id);
          localStorage.setItem("role", user.role);
          localStorage.setItem("fullName", user.fullName);
          localStorage.setItem("email", user.email);

          if (fullUTM?.split("-")[0] !== params.get("utm_source")) {
            SendSentryError("UTM", "Utm possible failed in the signup", {
              details: `mixpanel: ${params.get("utm_source")} | database: ${
                fullUTM?.split("-")[0]
              } | userId: ${user._id}`,
            });
          }

          mixpanel.signUp(
            "Success",
            response?.data?.user?._id,
            response?.data?.user?.fullName,
            response?.data?.user?.email,
            "Free",
            "Email",
            null,
            params.get("utm_medium"),
            params.get("utm_source"),
            params.get("utm_campaign"),
            "Verified"
          );

          fetchAllSections();
          startLoadingExamsData(user._id, token);
          navigate("/home");
        }
      })
      .catch((error) => {
        const { response } = error;

        const message =
          response?.data?.message || response?.data?.error?.message;
        const status = response?.status;

        console.log("error: ", message);

        if (status === 403) {
          setEmailError(true);
          console.log("email already exists");
        } else if (status === 422) {
          console.log("email invalid");
        } else if (status === 500) {
          console.log("Server error");
          SendSentryError("AUTH", "SignUp failed", {
            details:
              "Request to signUp the user failed with status 500 [server error]",
            serverMessage: message,
          });
          startErrorPopup();
          mixpanel.signUp("Fail", null, null, null, "Free", "Email", message);
        } else {
          startErrorPopup();
          mixpanel.signUp("Fail", null, null, null, "Free", "Email", message);
        }
      });
  };

  const startGoogleLogin = async (id_token, type) => {
    const URL = EndPoints.googleLogin;
    instance
      .post(URL, { token: id_token, type, utm: fullUTM })
      .then((response) => {
        console.log(response);
        const { user, token, type } = response.data;
        if (token) {
          dispatch(authLogin({ user, token }));
          startLoadingExamsData(user._id, token);

          localStorage.setItem("token", token);
          localStorage.setItem("userId", user._id);
          localStorage.setItem("role", user.role);
          localStorage.setItem("fullName", user.fullName);
          localStorage.setItem("email", user.email);
          setInitialUserState({ user, token });
          fetchAllSections();
          // Fetch Google profile info
          fetchProfileImage(id_token, user, type);

          if (type === "register") {
            mixpanel.signUp(
              "Success",
              user._id,
              user.fullName,
              user.email,
              "Free",
              "Google",
              null,
              params.get("utm_medium"),
              params.get("utm_source"),
              params.get("utm_campaign"),
              "Verified"
            );
          } else {
            mixpanel.login({
              status: "Success",
              userId: user._id,
              method: "Google",
            });
          }
        }
      })
      .catch((error) => {
        const { response } = error;

        const message =
          response?.data?.message || response?.data?.error?.message;
        const status = response?.status;

        console.log("Server error");
        console.log("error: ", message);

        if (status === 500) {
          SendSentryError("AUTH", "Google oauth failed", {
            details:
              "Google oauth request failed with status 500 [server error]",
            serverMessage: message,
          });
        }
        startErrorPopup();
      });
  };

  const fetchProfileImage = (id_token, user, type) => {
    fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
      headers: {
        Authorization: `Bearer ${id_token}`,
      },
    })
      .then((response) => response.json())
      .then((profileData) => {
        const profileImage = profileData?.picture;
        console.log(profileData);
        localStorage.setItem("profileImage", profileImage);
      })
      .catch((error) => {
        console.error("Error fetching profile image:", error);
        SendSentryError("FETCH", "Error fetching profile image", {
          details: "Failed to fetch profile image",
          error,
        });
        startErrorPopup();
      });
  };

  /** This function sends an email for restoring the user password */
  const resetPasswordEmail = async (
    email,
    setUnAuthorized,
    setSentResetSuccessfully
  ) => {
    const URL = EndPoints.resetPassword;

    instance2
      .post(URL, { email })
      .then((response) => {
        if (response.status === 200) {
          setSentResetSuccessfully(true);
        }
      })
      .catch((error) => {
        const { response } = error;

        const message = response?.data?.error?.message || response?.data;
        const status = response?.status;

        console.log("error: ", message);

        if (status === 400) {
          setUnAuthorized(true);
        } else if (status === 500) {
          SendSentryError("AUTH", "Send reset password email failed", {
            details:
              "Sending email for changing password failed with status 500 [server error]",
            serverMessage: message,
          });

          console.log("Server error");
          startErrorPopup();
        } else {
          startErrorPopup();
        }
      });
  };

  /** This function sends a password to change the current one */
  const startChangePassword = async (
    newPassword,
    token,
    userId,
    setUpdateThePassword
  ) => {
    const endpointURL = `${EndPoints.resetPassword}/${userId}/${token}`;

    try {
      const response = await instance2.post(endpointURL, {
        password: newPassword,
      });
      setUpdateThePassword(false);
    } catch (error) {
      const { response } = error;
      const message = response?.data?.message || response?.data?.error?.message;
      const status = response?.status;

      console.error("Error:", message);
      startErrorPopup();

      if (status === 500) {
        SendSentryError("PASSWORD", "Changing password failed", {
          details:
            "Request to attempt changing password failed with status 500 [server error]",
          serverMessage: message,
        });
      }
    }
  };

  const startUpdatePassword = async (
    newPassword,
    oldPassword,
    userId,
    setUpdateThePassword
  ) => {
    const endpointURL = `${EndPoints.updatePassword}/${userId}`;
    try {
      await instance2.put(endpointURL, {
        password: oldPassword,
        newPassword: newPassword,
      });
      setUpdateThePassword(true);
    } catch (error) {
      const { response } = error;
      const message = response?.data?.message || response?.data?.error?.message;
      const status = response?.status;

      console.error("Error:", message);
      startErrorPopup();

      if (status === 500) {
        SendSentryError("PASSWORD", "Changing password failed", {
          details:
            "Request to attempt changing password failed with status 500 [server error]",
          serverMessage: message,
        });
      }
    }
  };

  const resendVerifyEmail = async (email, setEmailToSend, setSmailSent) => {
    const URL = EndPoints.resendVerifyEmail;
    instance2
      .post(URL, { email })
      .then((response) => {
        if (response.status === 200) {
          setEmailToSend(email);
          setSmailSent(true);
        }
      })
      .catch((error) => {
        const { response } = error;

        const message =
          response?.data?.message || response?.data?.error?.message;
        const status = response?.status;

        console.log("Server error");
        console.log("error: ", message);

        if (status === 500) {
          SendSentryError("AUTH", "Resend verification email failed", {
            details:
              "Request to resend verification failed with status 500 [server error]",
            serverMessage: message,
          });
        }
        startErrorPopup();
      });
  };

  const startVerifyEmail = async (token, setVerifiedSucced) => {
    const URL = EndPoints.emailVerified + token;
    instance2
      .get(URL)
      .then((response) => {
        console.log(response);
        setVerifiedSucced(true);
        mixpanel.updateEmailUserToVerified(response?.data?.userId);
      })
      .catch((error) => {
        const { response } = error;

        const message =
          response?.data?.message || response?.data?.error?.message;
        const status = response?.status;

        console.log("Server error");
        console.log("error: ", response.data);

        if (status === 500) {
          SendSentryError("AUTH", "Token/email verification failed", {
            details:
              "Request to verificate the token of a unverified user failed with status 500 [server error]",
            serverMessage: message,
          });
        }
        startErrorPopup();
      });
  };

  const startLogout = () => {
    dispatch(authLogout());
    dispatch(examsLogout());
    dispatch(uiLogout());

    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("fullName");
    localStorage.removeItem("isPremium");
    localStorage.removeItem("premiumExpiryDate");
    localStorage.removeItem("profileImage");

    mixpanel.reset();

    googleLogout();
  };

  const fetchNormeringValueOfBothMainCategories = async (userId, signal) => {
    try {
      const NormeringValueOfBothMainCategoriesURL =
        EndPoints.sevenWeeksMetricsOfCategoryPerWeek;
      const response = await instance2.get(
        NormeringValueOfBothMainCategoriesURL,
        { signal }
      );
      const homeGraphMetrics = verbalQuantitativePerWeekNormring(response.data);
      return homeGraphMetrics;
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
        return null;
      } else {
        if (error.name !== "CanceledError") {
          console.error("Error fetching normering value:", error);
          SendSentryError("FETCH", "Error fetching normering value", {
            details: "Failed to fetch normering value",
            error,
          });
          return null;
        }
      }
    }
  };

  const fetchNormeringValueForHomeCards = async (signal) => {
    try {
      const NormeringValueOfBothMainCategoriesURL =
        EndPoints.currentWeekMetrics;
      const response = await instance2.get(
        NormeringValueOfBothMainCategoriesURL,
        { signal }
      );
      return response.data;
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
        return null;
      } else {
        if (error.name !== "CanceledError") {
          console.error("Error fetching normering value:", error);
          SendSentryError("FETCH", "Error fetching normering value", {
            details: "Failed to fetch normering value",
            error,
          });
          return null;
        }
      }
    }
  };
  const fetchAllSections = async () => {
    try {
      const url = EndPoints.getAllSections;
      const response = await instance2.get(url);
      const sectionCategories = response.data.data;

      let sectionCategoriesWithTime = [];
      sectionCategories?.forEach((item) => {
        switch (item.title) {
          case "XYZ":
          case "KVA":
            item.time = 1;
            break;
          case "NOG":
            item.time = 10 / 6;
            break;
          case "DTK":
            item.time = 23 / 12;
            break;
          case "ELF":
          case "LÄS":
            item.time = 2.2;
            break;
          case "MEK":
            item.time = 0.8;
            break;
          case "ORD":
            item.time = 0.3;
            break;
          default:
            break;
        }
        sectionCategoriesWithTime.push(item);
      });
      dispatch(setSectionCategories(sectionCategoriesWithTime));
      return sectionCategories;
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
        return null;
      } else {
        if (error.name !== "CanceledError") {
          console.error("Error fetching all sections:", error);
          SendSentryError("FETCH", "Error fetching all sections", {
            details: "Failed to fetch all sections",
            error,
          });
        }
      }
      return null;
    }
  };

  const fetchUser = async (signal) => {
    try {
      const url = EndPoints.getUser;
      const response = await instance2.get(url, { signal });
      return response;
    } catch (error) {
      if (error.name !== "CanceledError") {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
          return null;
        } else {
          SendSentryError("FETCH", "Error fetching user data", {
            details: "Failed to fetch user data",
            error,
          });
          throw error;
        }
      }
    }
  };

  const fetchStudentPreference = async (userId, signal) => {
    try {
      const URL = EndPoints.getStudentPreference + userId;
      const response = await instance2.get(URL, { signal });
      dispatch(setStudentPreference(response?.data?.StudentPreference || null));
      return response?.data?.StudentPreference || null;
    } catch (error) {
      if (error.name !== "CanceledError") {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
          return null;
        } else {
          SendSentryError("FETCH", "Error fetching user's preference", {
            details: "Failed to fetch user preference data",
            error,
          });
          return null;
        }
      }
    }
  };

  const fetchLastWeekURL = async (userId, signal) => {
    try {
      const URL = EndPoints.lastWeekTasks + userId;
      const response = await instance2.get(URL, { signal });
      return response;
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
        return null;
      } else {
        if (error.name !== "CanceledError") {
          console.error("Error fetching last week tasks:", error);
          SendSentryError("FETCH", "Error fetching last week tasks", {
            details: "Failed to fetch last week tasks",
            error,
          });
          return null;
        }
      }
    }
  };

  return {
    /** Values */
    user,
    checking,

    /** Functions */
    sessionRefresh,
    startLogin,
    startSignUp,
    resetPasswordEmail,
    startChangePassword,
    startUpdatePassword,
    resendVerifyEmail,
    startVerifyEmail,
    startGoogleLogin,
    startLogout,
    fetchNormeringValueOfBothMainCategories,
    fetchAllSections,
    fetchNormeringValueForHomeCards,
    fetchUser,
    fetchStudentPreference,
    fetchLastWeekURL,
  };
};
