/** Libraries */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { Box } from "@material-ui/core";
import { Alert, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Molecules */
import QuestionStatement from "../../../../../molecule/QuestionStatement/QuestionStatement";
import OptionsSQ from "../../../../../molecule/OptionsComponents/OptionsSQ/OptionsSQ";
import NoneAnsweredAlert from "../../../../../molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";

/** Atoms */
import {
  appearFromMiddle,
  slideInFromRight,
} from "../../../../../atom/SlideTransition/SlideTransition";

/** Styles */
import "../../../../../../styles/QuestionBody.css";
import QuestionWrapper from "../../../../../molecule/QuestionContainers/QuestionWrapper";

import { useAiTutorStore } from "../../../../../../hooks";
import AiChatBotBar from "../../../../AiTutor/AiChatBotBar/AiChatBotBar";
import { Answer } from "../../../../Quiz/Answer/Answer";
import { useExercisesSectionCategoriesStore } from "../../../../../../hooks";
import { useLocation } from "react-router-dom";
import config from "../../../../../../config/config";

const MainContainer = styled(Box)(({ theme, review, first }) => ({
  position: "relative",
  display: "flex",
  minHeight: "712px",
  maxWidth: "738px",
  width: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  marginBottom: "5rem",
  padding: "2rem 1rem 4rem 1rem",
  gap: "1rem",
  animation:
    !review && `${first ? appearFromMiddle : slideInFromRight} 0.3s ease-out`,
  [theme.breakpoints.down(600)]: {
    padding: "2rem 0rem",
    marginBottom: "4rem",
  },
}));

const SingleQuestionViewOrg = (props) => {
  const answerBoxRef = useRef(null);
  const location = useLocation();

  const currentSectionCategory = useExercisesSectionCategoriesStore();
  const {
    aiConversation,
    isRequestInProcess,
    isRequestFailed,
    startConsultingAiTutor,
    startAiTutorLogout,
  } = useAiTutorStore();

  const lastConversation = aiConversation[aiConversation.length - 1];

  // typewriter
  const [currentIndex, setCurrentIndex] = useState(2);
  const [isTyping, setIsTyping] = useState(true);
  const [currentContent, setCurrentContent] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [buffer, setBuffer] = useState(null);
  const audioRef = useRef(null);

  const question = useMemo(() => props?.question, [props?.question]);
  const optionsForAi = useMemo(
    () => question.options[0].options.map((e) => e.value),
    [question._id]
  );
  const correctOptionForAi = useMemo(
    () =>
      question.options[0].options.filter(
        (e) => e._id === question?.answer?.option
      ),
    [question._id, question?.answer?.option]
  );

  const isAnswerVisible = useMemo(
    () => question?.answer && question?.optionId,
    [question?.answer, question?.optionId]
  );

  const isAnswerVisibleInReview = useMemo(
    () =>
      question?.answer && typeof location?.state?.questionIndex === "number",
    [question?.answer, location?.state?.questionIndex]
  );

  const isChatbotHidden = useMemo(
    () =>
      config.REACT_APP_SERVER_NAME === "PROD" &&
      (["NOG", "KVA"].includes(currentSectionCategory?.title?.toUpperCase()) ||
        ["NOG", "KVA"].includes(props?.questionTypeTitle?.toUpperCase())),
    [
      config.REACT_APP_SERVER_NAME,
      currentSectionCategory?.title,
      props?.questionTypeTitle,
    ]
  );

  const scrollToBottom = () => {
    const box = answerBoxRef.current;
    if (box) {
      box.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  /*
  useEffect(() => {
    scrollToBottom();
  }, [isTyping, currentContent, isRequestInProcess]);
*/
  useEffect(() => {
    if (!isAnswerVisible) {
      startAiTutorLogout();
    }
  }, [isAnswerVisible]);

  useEffect(() => {
    aiConversation.forEach((item, index) => {
      const { role, content } = item;

      setDisplayedMessages((prev) => [
        ...prev,
        {
          role,
          content,
        },
      ]);

      setDisplayedMessages((prev) => prev.slice(0, index + 1));
      setIsTyping(true);
    });
  }, [aiConversation]);

  useEffect(() => {
    const messages = displayedMessages.map((e) => e.content);

    if (isTyping && currentIndex < messages.length) {
      let message = messages[currentIndex];
      let index = 0;

      if (currentIndex > 0) {
        var intervalId = setInterval(() => {
          setCurrentContent((prevContent) => prevContent + message[index]);

          index++;

          if (index === message.length) {
            clearInterval(intervalId);
            setCurrentIndex((prevIndex) => prevIndex + 1);
            setIsTyping(false);
            setCurrentContent((prevContent) => prevContent.slice(index));
          }
        }, 0.4); // typing speed
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
        setIsTyping(false);
        setCurrentContent("");
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isTyping, currentIndex, displayedMessages]);

  useEffect(() => {
    if (buffer) {
      const blob = new Blob([new Uint8Array(buffer.data)], {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(blob);
      const audio = new Audio(url);
      audioRef.current = audio;
      audio.play();
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [buffer]);

  const isPublicPath = location.pathname.startsWith("/public");

  // message,
  // isVoiceOn,
  // question,
  // options,
  // correctOption,
  // answerExplanation,
  // setBuffer,
  // indexMQ,
  // DTKImage,
  // model,

  const renderAiChatBot = () => (
    <AiChatBotBar
      isTyping={currentContent !== ""}
      sendMessage={(msg, sendMessage, model) => {
        startConsultingAiTutor({
          message: msg,
          isVoiceOn: sendMessage,
          question: question?.questionStatement,
          options: optionsForAi,
          correctOption: correctOptionForAi[0]?.value,
          answerExplanation: question?.answer?.answer,
          setBuffer,
          indexMQ: false,
          MQDescription: false,
          model,
        });
      }}
      question={question?.questionStatement}
      options={optionsForAi}
      correctOption={correctOptionForAi[0]?.value}
      answerExplanation={question?.answer?.answer}
    />
  );

  const renderAnswerAndAi = useMemo(() => {
    return (
      (isAnswerVisible || isAnswerVisibleInReview) && (
        <>
          {question.answer && (
            <Answer
              isAiTutor={true}
              content={question.answer.answer}
              questionId={question.questionId}
              takeScreenshot={true}
              sectionCategory={
                currentSectionCategory?.title || props?.questionTypeTitle
              }
              questionCategory={
                currentSectionCategory?.title || props?.questionTypeTitle
              }
            />
          )}
          {aiConversation.map((e, i) => (
            <Answer
              key={i}
              isAiTutor={e.role === "assistant"}
              isUser={e.role === "user"}
              content={
                isTyping && i === currentIndex && e.role === "assistant"
                  ? currentContent + "⚫"
                  : e.content
              }
              sectionCategory={
                currentSectionCategory?.title || props?.questionTypeTitle
              }
              questionCategory={
                currentSectionCategory?.title || props?.questionTypeTitle
              }
              questionId={question.questionId}
              takeScreenshot={true}
              isFeedbackDisabled={e.role === "user" || i === 1}
            />
          ))}

          {isRequestInProcess && (
            <Answer
              isAiTutor={true}
              isUser={false}
              isFeedbackDisabled={true}
              content={
                isRequestInProcess
                  ? "⚫"
                  : lastConversation
                  ? lastConversation.content
                  : ""
              }
            />
          )}

          {isRequestFailed && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">
                Tekniskt fel - testa igen om en stund
              </Alert>
            </Stack>
          )}

          <Box
            ref={answerBoxRef}
            sx={{
              width: "100%",
              content: isRequestInProcess && '""',
              pointerEvents: isRequestInProcess ? "none" : "auto",
            }}
          >
            {!isPublicPath && !isChatbotHidden && renderAiChatBot()}
          </Box>
        </>
      )
    );
  }, [
    isAnswerVisible,
    aiConversation,
    currentIndex,
    currentContent,
    isTyping,
    isRequestInProcess,
    isRequestFailed,
    lastConversation,
    props.questionTypeTitle,
    isPublicPath,
    question,
  ]);

  return (
    <MainContainer review={isAnswerVisible} first={props?.selectedIndex === 0}>
      {/* unAttempted question warning */}
      {!question.optionId && question.answer && <NoneAnsweredAlert />}
      <QuestionWrapper
        questionTypeTitle={currentSectionCategory?.title}
        ord={currentSectionCategory.title === "ORD"}
      >
        <QuestionStatement
          description={question?.questionStatement}
          descriptionClassName={true}
          indications={[question?.information1, question?.information2]}
          type={currentSectionCategory?.title}
        />
      </QuestionWrapper>

      <OptionsSQ {...props} />

      {renderAnswerAndAi}

      {props.submitButton(question, isRequestInProcess)}
    </MainContainer>
  );
};

export default SingleQuestionViewOrg;
