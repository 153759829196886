import GridLayout from "../../GridOrg/GridLayout";
import LeftBar from "../../LeftBarOrg/LeftBar";
import MessageFeedContent from "../MessageFeedContent/MessageFeedContent";
import React from "react";

const MessageMain = () => {
  return <GridLayout leftBar={<LeftBar />} middle={<MessageFeedContent />} />;
};

export default MessageMain;
