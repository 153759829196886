/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

const initialCategoryState = {
  timer: true,
  extendedTime: false,
  partialTest: 12,
  checkboxState: {
    selectedFive: false,
    selectedTen: false,
    selectedFifteen: false,
    selectedTwenty: false,
    selectedDefault: true,
  },
  mediumDifficulty: true,
  hardDifficulty: true,
  easyDifficulty: true,
  wrongAnswered: true,
  unAnswered: true,
  correctAnswered: false,
  selectAll: true,
  questionType: [],
  questionFormat: [],
};

export const saveChoicesSlice = createSlice({
  name: "saveChoices",
  initialState: {
    XYZ: { ...initialCategoryState },
    KVA: { ...initialCategoryState },
    NOG: { ...initialCategoryState },
    DTK: { ...initialCategoryState },
    ORD: { ...initialCategoryState },
    ELF: { ...initialCategoryState },
    LÄS: { ...initialCategoryState },
    MEK: { ...initialCategoryState },
  },
  reducers: {
    setPracticeMode: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].practiceMode = value;
    },
    setPartialTest: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].partialTest = value;
    },
    setFilter: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].filter = value;
    },
    setTimer: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].timer = value;
    },
    setExtendedTime: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].extendedTime = value;
    },
    setCheckboxState: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].checkboxState = value;
    },
    setMediumDifficulty: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].mediumDifficulty = value;
    },
    setHardDifficulty: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].hardDifficulty = value;
    },
    setEasyDifficulty: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].easyDifficulty = value;
    },
    setWrongAnswered: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].wrongAnswered = value;
    },
    setUnAnswered: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].unAnswered = value;
    },
    setCorrectAnswered: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].correctAnswered = value;
    },
    setSelectAll: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].selectAll = value;
    },
    setQuestionType: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].questionType = value;
    },
    setQuestionFormat: (state, action) => {
      const { categoryName, value } = action.payload;
      state[categoryName].questionFormat = value;
    },
  },
});

export const {
  setPracticeMode,
  setPartialTest,
  setFilter,
  setTimer,
  setExtendedTime,
  setMediumDifficulty,
  setHardDifficulty,
  setEasyDifficulty,
  setWrongAnswered,
  setUnAnswered,
  setCorrectAnswered,
  setCheckboxState,
  setQuestionType,
  setQuestionFormat,
  setSelectAll,
} = saveChoicesSlice.actions;
