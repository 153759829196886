/** Libraries */
import React from "react";
import { useNavigate } from "react-router-dom";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { Dialog } from "@mui/material";

/** Organisms */
import ListValues from "../PaymentOrg/ListValues";

/** Molecules */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";

/** Atoms */
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { Transition } from "../../atom/SlideTransition/SlideTransition";

/** Hooks */
import { useMixpanel } from "../../../hooks";
import { usePersistedStore } from "../../../hooks/usePersisted/usePersistedStore";

/** Material UI - Custom elements */
const ModalContainer = styled("div")(({ theme, version }) => ({
  position: "relative",
  zIndex: version < 3 && 1,
  backgroundColor: theme.palette.appcolors.secondaryColor,
  background:
    version < 3 &&
    `linear-gradient(90deg, ${alpha(
      theme.palette.appcolors.secondaryColor9,
      1.0
    )} 21%, ${alpha(theme.palette.appcolors.secondaryColor6, 1.0)} 100%)`,
  borderRadius: "10px",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  padding: version < 3 ? "6rem 0rem 0rem 0rem" : "4rem 2rem",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "900px",
  margin: "auto",
  "&:focus": {
    outline: "none",
  },
  [theme.breakpoints.down(1280)]: {
    maxWidth: "724px",
    padding: version < 3 ? "4rem 0rem 0rem 0rem" : "3rem 2rem",
  },
  [theme.breakpoints.down(800)]: {
    flexDirection: "column-reverse",
    width: "346px",
    padding: "2rem 1rem",
    paddingBottom: version < 3 && "0rem",
    background:
      version < 3 &&
      `linear-gradient(90deg, ${alpha(
        theme.palette.appcolors.secondaryColor,
        1.0
      )} 21%, ${alpha(theme.palette.appcolors.secondaryColor6, 1.0)} 100%)`,
  },
}));

const StyledModal = styled(Dialog)(({ theme }) => ({
  zIndex: 1401,
  margin: "auto",
}));

const ContentAndButtonContainer = styled("div")(({ theme, version }) => ({
  width: "50%",
  marginTop: version < 3 && "-4rem",
  display: "flex",
  direction: "column",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingLeft: 50,
  [theme.breakpoints.down(1280)]: {
    padding: "10px 0px 10px 30px",
  },
  [theme.breakpoints.down(800)]: {
    width: "100%",
    padding: "10px",
    marginTop: version < 3 && "0rem",
    justifyContent: version < 3 && "center",
  },
}));

const ContentContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  direction: "column",
  flexWrap: "wrap",
  paddingRight: "2rem",
  [theme.breakpoints.down(1280)]: {
    paddingRight: "1rem",
  },
  [theme.breakpoints.down(800)]: {
    paddingRight: "0rem",
  },
}));

const FontTitle = styled("p")(({ theme }) => ({
  cursor: "default",
  marginBottom: "1rem",
  fontSize: "18px",
  fontWeight: 600,
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "13px",
  },
  [theme.breakpoints.down(800)]: {
    textAlign: "center",
  },
}));

const FontContent = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1280)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(800)]: {
    textAlign: "center",
    marginBottom: "1rem",
    padding: "0rem 1.5rem",
  },
}));

const CheckoutButton = styled("div")(({ theme, premium }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.appcolors.secondaryColor10,
  backgroundColor: theme.palette.appcolors.primaryColor,
  width: "100%",
  maxWidth: "169px",
  height: "2.3rem",
  border: 0,
  borderRadius: "6.62px",
  fontSize: "15px",
  marginTop: "1rem",
  fontWeight: 600,
  "&:hover": {
    transform: "scale(1.05)",
    cursor: "pointer",
  },
  [theme.breakpoints.down(1280)]: {
    marginTop: "0.2rem",
    maxWidth: "100px",
    height: "1.375rem",
    fontSize: "10px",
  },
  [theme.breakpoints.down(800)]: {
    marginRight: "0px",
    marginTop: "1rem",
    fontSize: premium ? "13px" : "15px",
    height: premium ? "30px" : "24px",
    maxWidth: premium ? "129px" : "109px",
  },
}));

const ImgContainer = styled("div")(({ theme, version }) => ({
  width: version < 3 ? "100%" : "50%",
  maxWidth: "27.5rem",
  maxHeight: "25.3rem",
  padding: "1rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down(1280)]: {
    paddingBottom: version < 3 && "0rem",
  },
  [theme.breakpoints.down(800)]: {
    width: "100%",
  },
}));

const CustomPayImage = styled("img")(({ theme, darkmode }) => ({
  maxWidth: "100%",
  filter: darkmode && "hue-rotate(333deg)",
}));

const GradientCircle = styled("div")(({ theme, two, three }) => ({
  position: "absolute",
  zIndex: -1,
  top: !three && "-10%",
  bottom: three && "-40%",
  right: (!two && "4%") || (!three && "4%"),
  left: (two && "10%") || (three && "10%"),
  width: "350px",
  height: "350px",
  borderRadius: "50%",
  background: two
    ? `linear-gradient(180deg, ${alpha(
        theme.palette.appcolors.black,
        0.0
      )} 50%, ${alpha(theme.palette.appcolors.primaryColor6, 0.35)} 100%)`
    : three
    ? `linear-gradient(0deg, ${alpha(
        theme.palette.appcolors.black,
        0.0
      )} 50%, ${alpha(theme.palette.appcolors.primaryColor6, 0.35)} 100%)`
    : `linear-gradient(0deg, ${alpha(
        theme.palette.appcolors.black,
        0.0
      )} 21%, ${alpha(theme.palette.appcolors.primaryColor6, 0.35)} 100%)`,
  [theme.breakpoints.down(1280)]: {
    width: "300px",
    height: "300px",
    left: (two && "5%") || (three && "5%"),
    top: two && "-20%",
  },
  [theme.breakpoints.down(800)]: {
    display: !two && "none" && !three && "none",
    width: "350px",
    height: "350px",
    top: two && "-10%",
    bottom: three && "-40%",
    left: 0,
  },
}));

const MobileView = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  [theme.breakpoints.up(800)]: {
    display: "none",
  },
}));

const TabletView = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down(800)]: {
    display: "none",
  },
}));

export default function PaymentModal({ open, handleClose, img, version = 3 }) {
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const { darkmode } = usePersistedStore();

  const navigateCheckout = () => {
    navigate("/checkout");
    mixpanel.bannerClicked("Banner-02");
  };

  const RepeatedImgContainer = () => {
    return (
      <ImgContainer version={version}>
        <CustomPayImage src={img} alt="" />
      </ImgContainer>
    );
  };

  const repeatedContent = () => {
    if (version === 1) {
      return (
        <ModalContainer version={version}>
          <StyledCloseIconRight onClick={handleClose} />
          <MobileView>{RepeatedImgContainer()}</MobileView>
          <ContentAndButtonContainer version={version}>
            <ContentContainer>
              <FontTitle>
                Plugga{" "}
                <span style={{ color: theme.palette.appcolors.error6 }}>
                  effektivare
                </span>
                ! Skaffa Premium och få tillgång till alla dessa fördelar:
              </FontTitle>
              <ListValues version={version} isExtended={true} />
            </ContentContainer>
            <CheckoutButton onClick={() => navigateCheckout()}>
              Läs mer
            </CheckoutButton>
          </ContentAndButtonContainer>
          <TabletView>{RepeatedImgContainer()}</TabletView>
          <GradientCircle />
          <GradientCircle two />
          <GradientCircle three />
        </ModalContainer>
      );
    } else if (version === 2) {
      return (
        <ModalContainer version={version}>
          <StyledCloseIconRight onClick={handleClose} />
          <MobileView>{RepeatedImgContainer()}</MobileView>
          <ContentAndButtonContainer version={version}>
            <ContentContainer>
              <FontTitle>
                Plugga{" "}
                <span style={{ color: theme.palette.appcolors.error6 }}>
                  effektivare
                </span>{" "}
                med HP-appens premium-funktioner
              </FontTitle>
              <ListValues version={version} isExtended={true} />
            </ContentContainer>
            <CheckoutButton onClick={() => navigateCheckout()}>
              Läs mer
            </CheckoutButton>
          </ContentAndButtonContainer>
          <TabletView>{RepeatedImgContainer()}</TabletView>
          <GradientCircle />
          <GradientCircle two />
          <GradientCircle three />
        </ModalContainer>
      );
    } else {
      return (
        <ModalContainer version={version}>
          <StyledCloseIconRight onClick={handleClose} />
          <ContentAndButtonContainer version={version}>
            <ContentContainer>
              <FontTitle>
                Denna kategori är endast öppen för premiumanvändare.
              </FontTitle>
              <FontContent>
                Som premiumanvändare får du tillgång till:
              </FontContent>
              <ListValues />
            </ContentContainer>
            <CheckoutButton premium onClick={() => navigateCheckout()}>
              Skaffa premium
            </CheckoutButton>
          </ContentAndButtonContainer>
          <ImgContainer version={version}>
            <CustomPayImage darkmode={darkmode} src={img} alt="" />
          </ImgContainer>
        </ModalContainer>
      );
    }
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          boxShadow: "none",
          "&.MuiDialog-paperWidthSm": {
            margin: "0px !important",
            maxWidth: "fit-content",
            transform: width > 2400 && "scale(1.4)",
          },
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      {repeatedContent()}
    </StyledModal>
  );
}
