/** Libraries */
import React from "react";
import { Box, FormControlLabel } from "@material-ui/core";
import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

/** Atoms */
import MarkLatex from "../../../atom/Marklatex/MarkLatex";
import { NogOptionsInfo } from "../../../atom/NogInfoContainer/NogInfoContainer";

/** Utils */
import { optionsCharacters } from "../../../../utils/commonService";

/** Styles */
import "../../../../styles/QuestionBody.css";

/** Hooks */
import useExercisesSectionCategoriesStore from "../../../../hooks/exercise/useExercisesSectionCategoriesStore";
import { usePersistedStore } from "../../../../hooks";
import { formatText } from "../../../../tools";

/** Image options */
const ImageOptionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  minHeight: "352px",
  flexWrap: "wrap",
  alignItems: "center",
  flexShrink: 0,
  boxSizing: "border-box",
  gap: "0.5rem",
  paddingBottom: "75px",
  [theme.breakpoints.down("md")]: {
    padding: 0,
    paddingBottom: "75px",
  },
  [theme.breakpoints.down(600)]: {
    flexWrap: "no-wrap",
    flexDirection: "column",
    width: "100%",
    heigth: "176px",
    flexShrink: 0,
  },
}));

const ImageOptionItem = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "24px 12px",
  alignItems: "flex-start",
  gap: "32px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "16px",
  background: theme.palette.appcolors.secondaryColor4,
  boxSizing: "border-box",
  maxWidth: "50%",
  [theme.breakpoints.down(710)]: {
    maxWidth: "49.2%",
  },
  [theme.breakpoints.down(600)]: {
    maxWidth: "100%",
  },
}));

const ImageRadiusButtonFirstContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "flex-start",
}));

const ImageRadiusButtonSecondContainer = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "space-between",
}));

const ImageRadius = styled(FormControlLabel)(({ theme }) => ({
  width: "16px",
  height: "16px",
  margin: 0,
  marginRight: "5px",
  padding: 0,
  "&.MuiFormControlLabel-root": {
    margin: 0,
  },
}));

const ImageRadiusIndexContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "45px",
  height: "32.5px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "end",
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  margin: "auto",
  paddingLeft: "1rem",
  width: "305px",
  height: "128px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down(600)]: {
    flexShrink: 0,
  },
}));

const Image = styled("img")(({ theme }) => ({
  padding: "10px",
  minWidth: "160px",
  height: "143px",
}));

/** Row options */
const RowOptionsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  flexShrink: 0,
  boxSizing: "border-box",
  height: "auto",
  paddingBottom: "75px",
  gap: "1rem",
}));

const RowOptionItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: "73px",
  width: "100%",
  borderRadius: "16px",
  background: theme.palette.appcolors.secondaryColor4,
  boxSizing: "border-box",
  [theme.breakpoints.down("md")]: {
    strokeWidth: "1px",
  },
}));

const RowRadiusButtonFirstContainer = styled(Box)(({ theme }) => ({
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "space-between",
  marginTop: 0,
  marginLeft: "2rem",
  marginRight: "0.75rem",
  [theme.breakpoints.down("md")]: {
    marginRight: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.75rem",
    marginRight: "2rem",
  },
}));

const RowRadiusButtonSecondContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const RowRadius = styled(FormControlLabel)(({ theme }) => ({
  width: "16px",
  height: "16px",
  margin: 0,
  marginRight: "5px",
  padding: 0,
  "&.MuiFormControlLabel-root": {
    margin: 0,
  },
}));

const RowRadiusIndexContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "45px",
  height: "32.5px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "end",
}));

const RowTextContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: "0.75rem",
  width: "600px",
  justifyContent: "flex-start",
  alignItems: "center",
}));

const RowOptionText = styled(MarkLatex)(({ theme }) => ({
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
}));

/** Both */
const RadiusIndexText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
}));

const OptionsSQ = ({ question, ...props }) => {
  const currentSectionCategory = useExercisesSectionCategoriesStore();
  const theme = useTheme();
  const isImageOption = question?.options[0]?.options[0]?.value.includes(
    "hp-appen.s3.eu-north-1.amazonaws.com"
  );
  const { darkmode } = usePersistedStore();

  const changeImageItemBorderColor = (item, optionIndex) => {
    if (question.answer) {
      if (question.answer.option === item._id) {
        return `2px solid ${theme.palette.appcolors.success5}; border-bottom: 4px solid ${theme.palette.appcolors.success5};`;
      } else if (item._id === question?.optionId) {
        return `2px solid ${theme.palette.appcolors.error6}; border-bottom: 4px solid ${theme.palette.appcolors.error6};`;
      }
    } else {
      if (
        optionIndex == question.selectedIndex ||
        optionIndex == question.selectedOptionIndex
      ) {
        return `2px solid ${theme.palette.appcolors.primaryColor}; border-bottom: 4px solid ${theme.palette.appcolors.primaryColor};`;
      }

      if (props.onHover === item._id) {
        return `1px solid ${theme.palette.appcolors.primaryColor}; border-bottom: 3px solid ${theme.palette.appcolors.primaryColor};`;
      }
    }

    return `1px solid ${theme.palette.appcolors.grey4}; border-bottom: 3px solid ${theme.palette.appcolors.grey4};`;
  };

  const changeTextColor = (item, optionIndex) => {
    if (question.answer) {
      if (question.answer.option === item._id) {
        return theme.palette.appcolors.success4;
      } else if (item._id === question?.optionId) {
        return theme.palette.appcolors.error6;
      }
    } else {
      if (
        optionIndex == question?.selectedIndex ||
        optionIndex == question?.selectedOptionIndex
      ) {
        return theme.palette.appcolors.primaryColor;
      }

      if (props.onHover === item._id) {
        return theme.palette.appcolors.primaryColor;
      }
    }

    return theme.palette.appcolors.grey;
  };

  const changeOptionsColor = (item, optionIndex) => {
    if (question.answer) {
      if (question.answer.option == item._id && question.optionId) {
        return theme.palette.appcolors.success4;
      } else if (item._id === question?.optionId) {
        return theme.palette.appcolors.error6;
      } else if (item._id !== question?.optionId) {
        return theme.palette.appcolors.grey3;
      }
    } else {
      if (
        optionIndex == question?.selectedIndex ||
        optionIndex == question?.selectedOptionIndex
      ) {
        return theme.palette.appcolors.primaryColor;
      }

      if (props.onHover === item._id) {
        return theme.palette.appcolors.primaryColor;
      }
    }

    return theme.palette.appcolors.grey3;
  };

  return (
    <>
      {isImageOption ? (
        <ImageOptionsContainer ref={props.onScrollBottom}>
          {question?.options[0]?.options?.map((item, optionIndex) => {
            if (item?.value) {
              return (
                <ImageOptionItem
                  key={optionIndex}
                  sx={{
                    border: changeImageItemBorderColor(item, optionIndex),
                    color:
                      !question.answer && optionIndex == question.selectedIndex
                        ? theme.palette.appcolors.primaryColor
                        : "",
                    "&:hover": {
                      cursor: !question.answer && "pointer",
                      color:
                        !question.answer &&
                        theme.palette.appcolors.primaryColor,
                      border:
                        !question.answer &&
                        optionIndex == question.selectedIndex &&
                        `1px solid ${theme.palette.appcolors.primaryColor}`,
                    },
                  }}
                  onMouseOver={() => props.onhover(item._id)}
                  onMouseLeave={() => props.onHoverLeave()}
                  onClick={(e) => {
                    !question?.answer && props.SelectOption(item, optionIndex);
                  }}
                >
                  <ImageRadiusButtonFirstContainer>
                    <ImageRadiusButtonSecondContainer>
                      <ImageRadius
                        value={item?._id}
                        control={props.showOptions(question, item, optionIndex)}
                      />
                      <ImageRadiusIndexContainer>
                        <RadiusIndexText
                          style={{
                            color: changeOptionsColor(item, optionIndex),
                          }}
                        >
                          {optionsCharacters(optionIndex)}
                        </RadiusIndexText>
                      </ImageRadiusIndexContainer>
                    </ImageRadiusButtonSecondContainer>
                  </ImageRadiusButtonFirstContainer>

                  <ImageContainer>
                    <Image
                      sx={{ filter: darkmode && "invert(0.9)" }}
                      src={item.value.split("(")[1].split(")")[0]}
                      alt={`option ${optionsCharacters(optionIndex)}`}
                    />
                  </ImageContainer>
                </ImageOptionItem>
              );
            }
          })}
        </ImageOptionsContainer>
      ) : (
        <RowOptionsContainer ref={props.onScrollBottom}>
          {currentSectionCategory &&
            currentSectionCategory?.title === "NOG" && <NogOptionsInfo />}
          {question?.options[0]?.options?.map((item, optionIndex) => {
            if (item?.value) {
              return (
                <RowOptionItem
                  key={optionIndex}
                  sx={{
                    border: changeImageItemBorderColor(item, optionIndex),
                    color:
                      !question.answer && optionIndex == question.selectedIndex
                        ? theme.palette.appcolors.primaryColor
                        : "",
                    "&:hover": {
                      cursor: !question.answer && "pointer",
                      color:
                        !question.answer &&
                        theme.palette.appcolors.primaryColor,
                      transform: "scaleX(1.02)",
                      transformOrigin: "0% 50%",
                      transition: "transform 0.2s ease-in-out",
                    },
                  }}
                  onMouseOver={() => props.onhover(item._id)}
                  onMouseLeave={() => props.onHoverLeave()}
                  onClick={(e) => {
                    !question?.answer && props.SelectOption(item, optionIndex);
                  }}
                >
                  <RowRadiusButtonFirstContainer>
                    <RowRadiusButtonSecondContainer>
                      <RowRadius
                        value={item?._id}
                        control={props.showOptions(question, item, optionIndex)}
                      />
                    </RowRadiusButtonSecondContainer>
                    <RowRadiusIndexContainer>
                      <RadiusIndexText
                        style={{
                          color: changeOptionsColor(item, optionIndex),
                        }}
                      >
                        {optionsCharacters(optionIndex)}
                      </RadiusIndexText>
                    </RowRadiusIndexContainer>
                  </RowRadiusButtonFirstContainer>

                  <RowTextContainer
                    style={{ color: changeTextColor(item, optionIndex) }}
                  >
                    <RowOptionText content={formatText(item.value)} />
                  </RowTextContainer>
                </RowOptionItem>
              );
            }
          })}
        </RowOptionsContainer>
      )}
    </>
  );
};

export default OptionsSQ;
