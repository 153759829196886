/** Libraries */
import { React, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

/** Custom hooks */
import { useTimerStore } from "../../../../hooks";

/** Styles */
import {
  MainContainer,
  TimerContainer,
  TimerText,
  TimerIcon,
} from "./TimerPrimary.styles";

const TimerPrimary = ({ sx, isResultSection, firstQuestion }) => {
  const { currTextTime, isPaused } = useTimerStore();
  const [hideTimer, setHideTimer] = useState(false);
  const [tooltipTimer, setTooltipTimer] = useState(
    firstQuestion ? true : false
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTooltipTimer(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const reusedTimerIcon = (hidetimer) => {
    return (
      <TimerIcon
        hidetimer={hidetimer ? "true" : "false"}
        onClick={() => setHideTimer(!hidetimer)}
      />
    );
  };

  return (
    <MainContainer sx={sx}>
      {tooltipTimer && (
        <Tooltip
          onMouseEnter={() => setTooltipTimer(false)}
          title={"Klicka för att visa eller dölja timer"}
          placement="top"
          arrow
          open={!isResultSection && tooltipTimer}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
        >
          {reusedTimerIcon(hideTimer)}
        </Tooltip>
      )}
      {!tooltipTimer && (
        <Tooltip
          title={!hideTimer ? "Dölj timer" : "Visa timer"}
          placement="top"
          arrow
          followCursor
        >
          {reusedTimerIcon(hideTimer)}
        </Tooltip>
      )}
      <TimerContainer hidetimer={hideTimer}>
        {isResultSection ? (
          "Avslutat"
        ) : (
          <TimerText isPaused={isPaused}>{currTextTime}</TimerText>
        )}
      </TimerContainer>
    </MainContainer>
  );
};

export default TimerPrimary;
