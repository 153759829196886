/** Redux */
import { useSelector, useDispatch } from "react-redux";

/** Axios Instances */
import { EndPoints, instance, instance2 } from "../../components/service/Route";

/** Tools */
import { SendSentryError } from "../../tools";
import {
  setLastSevenWeeksData,
  setLastSevenWeeksCorrected,
  setTableHistory,
} from "../../redux/slices";
import { percentageCalculation } from "../../utils/Utils";

export const useCategoryDetailsStore = () => {
  const { tableHistory, lastSevenWeeksData, lastSevenWeeksCorrected } =
    useSelector((state) => state.categoryDetails);
  const dispatch = useDispatch();

  const processLastSevenWeeksData = (data, title) => {
    const weekWiseProgressArray = data.reverse().map((weekWiseMetrics) => ({
      name: weekWiseMetrics.weekName,
      Prognos:
        weekWiseMetrics.attemptedUnderSixtyQuestions >= 20
          ? percentageCalculation(
              (
                (weekWiseMetrics.correctedUnderSixtyQuestions /
                  weekWiseMetrics.attemptedUnderSixtyQuestions) *
                100
              ).toFixed(2),
              title
            )
          : null,
    }));

    const processedData = {
      prognos: weekWiseProgressArray.at(-1)?.Prognos ?? 0,
      title: title,
      lastWeeksDataForGraph: weekWiseProgressArray,
    };

    return processedData;
  };

  const fetchLastSevenWeeksData = async (categoryId, title) => {
    try {
      const lastWeekDataURL =
        EndPoints.sevenWeeksMetricsOfCategory + categoryId;
      const response = await instance2.get(lastWeekDataURL);
      const processedData = processLastSevenWeeksData(response.data, title);
      dispatch(setLastSevenWeeksData(processedData));
    } catch (error) {
      console.error("Error fetching last seven weeks data:", error);
      SendSentryError("FETCH", "Error fetching last seven weeks data", {
        details: "Failed to fetch last seven weeks data",
        error,
      });
    }
  };

  const processCorrectedData = (data) => {
    const { correctedQuestionPerCategory, allCategoriesProgress } = data;
    const latestProgress = allCategoriesProgress.reverse();
    console.log("latestProgress", latestProgress);
    const processedData = {
      currentWeekCorrect: latestProgress.at(-1).correct,
      correctedQuestionPerCategory,
      lastWeeksCorrectForGraph: latestProgress,
    };
    return processedData;
  };

  const fetchsevenWeeksCorrected = async (categoryId) => {
    try {
      const URL = EndPoints.sevenWeeksCorrectedOfCategoryPerWeek + categoryId;
      const response = await instance2.get(URL);
      dispatch(setLastSevenWeeksCorrected(processCorrectedData(response.data)));
    } catch (error) {
      console.error(
        "Error fetching seven week corrected of category per week:",
        error
      );
      SendSentryError(
        "FETCH",
        "Error fetching seven week corrected of category per week",
        {
          details: "Failed to fetch seven week corrected of category per week",
          error,
        }
      );
    }
  };

  const fetchHistoryTable = async (categoryId) => {
    try {
      const URL = EndPoints.testHistory + categoryId;
      const response = await instance2.get(URL);
      dispatch(setTableHistory(response.data));
    } catch (error) {
      console.error("Error fetching test history:", error);
      SendSentryError("FETCH", "Error fetching test history", {
        details: "Failed to fetch test history",
        error,
      });
    }
  };

  const fetchTestHistory = async (
    currentSectionCategory,
    setQuestionCategories,
    questionType,
    setQuestionType,
    questionFormat,
    setQuestionFormat,
    categoryName,
    setCategoryError,
    selectAll,
    signal
  ) => {
    try {
      const URL =
        EndPoints.questionCategoryBysectionCategory +
        currentSectionCategory._id;
      const response = await instance2.get(URL, { signal });
      console.log("response13", response);
      if (!response.data.message) {
        setQuestionCategories(response.data);
        dispatch(setQuestionType({ categoryName, value: [] }));
        dispatch(setQuestionFormat({ categoryName, value: [] }));
        setCategoryError(false);
      }
      const newQuestionFormatIds = response.data
        .filter((item) => item.isLabel === true)
        .map((item) => item._id);

      const newQuestionTypeIds = response.data
        .filter((item) => item.isLabel !== true)
        .map((item) => item._id);

      const updatedQuestionFormat = [
        ...questionFormat,
        ...newQuestionFormatIds.filter((id) => !questionFormat.includes(id)),
      ];

      const updatedQuestionType = [
        ...questionType,
        ...newQuestionTypeIds.filter((id) => !questionType.includes(id)),
      ];

      dispatch(
        setQuestionType({
          categoryName,
          value: selectAll ? updatedQuestionType : questionType,
        })
      );
      dispatch(
        setQuestionFormat({
          categoryName,
          value: selectAll ? updatedQuestionFormat : questionFormat,
        })
      );
    } catch (error) {
      console.error("Error fetching question categories:", error);
      SendSentryError("FETCH", "Error fetching question categories", {
        details: "Failed to fetch question categories",
        error,
      });
    }
  };

  return {
    /** Values */
    tableHistory,
    lastSevenWeeksData,
    lastSevenWeeksCorrected,

    /** Functions */
    fetchHistoryTable,
    fetchLastSevenWeeksData,
    fetchsevenWeeksCorrected,
    fetchTestHistory,
  };
};
