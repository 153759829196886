/** Libraries */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

/** Atoms */
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { StyledButton } from "../../atom/Button/Button.styles";
import { Transition } from "../../atom/SlideTransition/SlideTransition";

/** Styled Components */
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "738px",
    padding: "4rem",
    width: "100%",
    backgroundColor: theme.palette.appcolors.secondaryColor,
    backgroundImage: "none",
  },
  [theme.breakpoints.down(600)]: {
    "& .MuiPaper-root": {
      padding: "1rem 2rem 3rem 2rem",
    },
  },
}));

const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: "1.5625rem",
    padding: "0rem 0rem 1rem 0rem",
    color: theme.palette.appcolors.black,
  },
  [theme.breakpoints.down(600)]: {
    "&.MuiTypography-root": {
      fontSize: "1.0rem",
    },
  },
  [theme.breakpoints.down(400)]: {
    "&.MuiTypography-root": {
      fontSize: "0.875rem",
    },
  },
}));

const StyledDescription = styled(DialogTitle)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: "1.125rem",
    color: theme.palette.appcolors.grey6,
    padding: "0rem 2.5rem 1rem 2.5rem",
  },
  [theme.breakpoints.down(600)]: {
    "&.MuiTypography-root": {
      fontSize: "0.75rem",
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
  },
  [theme.breakpoints.down(400)]: {
    "&.MuiTypography-root": {
      fontSize: "0.75rem",
      padding: "0rem",
    },
  },
}));

const ButtonsContainer = styled("div")(({ theme }) => ({
  width: "100%",
  maxHeight: "4rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  [theme.breakpoints.down(700)]: {
    flexDirection: "column",
  },
}));

export default function CommonPopup({
  closePopup,
  title,
  status,
  description,
  redirect,
  agreeBtnName,
  cancelBtnName,
  oneButtonPopup = false,
  questionPopup = false,
}) {
  const theme = useTheme();
  const handleSubmit = () => {
    redirect();
    closePopup();
  };

  return (
    <BootstrapDialog
      onClose={closePopup}
      TransitionComponent={Transition}
      aria-labelledby="customized-dialog-title"
      open={status}
      style={{
        textAlign: "center",
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <StyledCloseIconRight onClick={closePopup} />
      <DialogContent
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <StyledTitle variant="h4">{title}</StyledTitle>
        <StyledDescription gutterBottom variant="body2">
          {description}
        </StyledDescription>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          gap: questionPopup ? "40px" : !oneButtonPopup ? "9px" : null,
        }}
      >
        {oneButtonPopup ? (
          <Button
            onClick={handleSubmit}
            style={{
              backgroundColor: theme.palette.appcolors.primaryColor,
              color: theme.palette.appcolors.secondaryColor10,
              textTransform: "capitalize",
              fontWeight: "regular",
              padding: ".60rem 3rem",
              borderRadius: "8px",
              height: "40px",
            }}
          >
            {agreeBtnName}
          </Button>
        ) : (
          <ButtonsContainer>
            {cancelBtnName && (
              <StyledButton
                onClick={closePopup}
                style={{
                  backgroundColor: "transparent",
                  color: theme.palette.appcolors.primaryColor,
                }}
              >
                {cancelBtnName}
              </StyledButton>
            )}
            <StyledButton onClick={handleSubmit}>{agreeBtnName}</StyledButton>
          </ButtonsContainer>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
}

CommonPopup.propTypes = {
  closePopup: PropTypes.func,
};

CommonPopup.defaultProps = {
  closePopup: () => {},
};
