/** Libraries */
import { Box, Button, Tab, TextField, makeStyles } from "@material-ui/core";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InputAdornment from "@mui/material/InputAdornment";
import { styled, useTheme } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";

/** Organism */
import PaymentModal from "../../PayWallOrg/PaymentModal";
import CoursesRightBar from "../CoursesRightBar/CoursesRightBar";

/** Molecule */
import CoursesCard from "../../../molecule/CoursesCard/CoursesCard";
import MobileMenu from "../../../molecule/MobileMenu/MobileMenu";
import useWindowDimensions from "../../../molecule/WindowDimensions/dimension";

/** Assets */
import HatIcon from "../../../../assets/Icons/Hat.png";

/** Atoms */
import {
  HeaderContainer,
  MainContainer,
  MiddleContainer,
  MobilePremiumCardContainer,
  PremiumCardContainer,
  RightBarDisplay,
  StyledTabs,
  SubtitleOfHeader,
  TitleOfHeader,
  TitleOfTheExeciseBox,
  TitleOfTheExeciseBox2,
  WelcomeMessageBox,
  WhiteBoxContainer,
  WideScreenDisplay,
} from "../../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";
import {
  DownArrowIcon,
  UpArrowIcon,
} from "../../../atom/CustomIcons/ArrowIcons";

/** Custom hooks */
import { useMixpanel } from "../../../../hooks";

/** Utils */
import { appColors, scrollTop } from "../../../../utils/commonService";
import { SecondaryIsPremiumCard } from "../../../molecule/IsPremiumCard/IsPremiumCard";

/** Assets */
import ElfProvImage from "../../../../assets/Imgs/ElfProvImage.png";
import CustomExamCard from "../../../molecule/CustomExamCard/CustomExamCard";

/** Material UI - Custom elements */
const NormalScreenDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1280)]: {
    display: "none",
  },
}));

const TabletScreenDisplay = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(2400)]: {
    display: "none",
  },
}));

const TabLinks = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  margin: "1rem 0.5rem 0rem 1rem",
}));

const SearchBox = styled("div")(({ theme, isfocused }) => ({
  display: "flex",
  alignItems: "center",
  maxWidth: "14rem",
  marginTop: "1rem",
  marginBottom: "0.5rem",
  zIndex: 2,
  [theme.breakpoints.up(2400)]: {
    marginBottom: "1rem",
    minWidth: "22rem",
    marginLeft: "0.5rem",
  },
  [theme.breakpoints.down(800)]: {
    maxWidth: isfocused ? "30rem !important" : "8.75rem",
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: isfocused ? "30rem !important" : "6.25rem",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  maxWidth: "31.25rem",
  "& fieldset": {
    borderColor: theme.palette.appcolors.grey7,
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.appcolors.primaryColor,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.appcolors.primaryColor,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.appcolors.primaryColor,
      "&::placeholder": {
        color: theme.palette.appcolors.black,
      },
    },
  },
  "& .MuiOutlinedInput-inputMarginDense": {
    paddingTop: "3.5px",
    paddingBottom: "6.5px",
  },
}));

const CustomSearchIcon = styled(SearchRoundedIcon)(({ isfocused, theme }) => ({
  fontSize: "28px",
  marginRight: "-9px",
  marginTop: "-4px",
  color: isfocused
    ? theme.palette.appcolors.primaryColor
    : theme.palette.appcolors.grey7,
}));

const useStyles = makeStyles((theme) => ({
  tabToggle: {
    [theme.breakpoints.up(2400)]: {
      display: "none",
    },
  },
  customExam: {
    [theme.breakpoints.up(2400)]: {
      marginTop: "8rem",
    },
  },
  MainTitle: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
}));

const CoursesFeedContent = (props) => {
  const classes = useStyles();
  const mixpanel = useMixpanel();
  const [tabValue, setTabValue] = useState(0);
  const { user } = useSelector((state) => state.user);
  const isPremium = user.isPremium;
  const { width } = useWindowDimensions();
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);
  const [paymentModalPopup, setPaymentModalPopup] = useState(false);
  const [query, setQuery] = useState("");
  const theme = useTheme();

  let previousExams = props.previousExams;
  let provpassOrderBySeason = props.provpassOrderBySeason;
  let allCustomExams = props?.allCustomExams;
  const filteredExams = previousExams.filter(
    (item) =>
      item?.title?.toLowerCase().includes(query.toLowerCase()) ||
      item?.month?.toLowerCase().includes(query.toLowerCase())
  );

  const handleTabs = (e, val) => {
    setTabValue(val);
  };

  const handleInputChange = (e) => {
    mixpanel.inputUsed("EXAM-FEED-SEARCH");
    props.searchExams(e.target.value);
    setQuery(e.target.value);
  };

  const getSeasonQuizzzes = (seasons, item) => {
    return seasons.find((elem) => item?._id === elem.simuleraSeason?._id);
  };

  const TabPanel = (props) => {
    const { children, value, index } = props;
    return <div>{value === index && <div>{children}</div>}</div>;
  };

  const RepeatedAllaProvBox = () => {
    return (
      <div style={{ marginTop: "0.6rem", marginBottom: "2.5rem" }}>
        <TitleOfTheExeciseBox2>Tidigare högskoleprov</TitleOfTheExeciseBox2>
        <WhiteBoxContainer prognos="Prognos" data-testid="exam-cards-container">
          {filteredExams &&
            filteredExams.map((item, index) => {
              return (
                <CoursesCard
                  id={item?._id}
                  key={item?._id}
                  item={item}
                  index={index}
                  latestExam={props.latestExam._id === item._id}
                  progress={
                    props?.data &&
                    props?.data?.find(
                      (provHistory) =>
                        provHistory?.simuleraSeason?._id === item?._id
                    )
                  }
                  quizzes={
                    props.seasons && getSeasonQuizzzes(props.seasons, item)
                  }
                  provpassOrder={
                    props?.provpassOrderBySeason[item?._id] ?? null
                  }
                  popup={setPaymentModalPopup}
                />
              );
            })}
        </WhiteBoxContainer>
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              transform: "scale(1.03)",
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.appcolors.primaryColor,
              color: theme.palette.appcolors.secondaryColor10,
              padding: "8px 60px",
              fontSize: "14px",
              fontFamily: "Figtree !important",
              textTransform: "capitalize",
              fontWeight: "400",
              width: "222px",
              height: "40px",
              borderRadius: "8px",
              border: `1px solid ${theme.palette.appcolors.black2}`,
              borderBottom: `3px solid ${theme.palette.appcolors.black2}`,
            }}
            sx={{
              "& .MuiButton-label !important": {
                width: "196px",
              },
            }}
            onClick={() =>
              previousExams?.length < 7
                ? props.loadMore()
                : (props.loadLess(), scrollTop())
            }
          >
            <span style={{ marginRight: "6px", width: "196px" }}>
              {previousExams?.length < 7 ? "Fler prov" : "Färre prov"}
            </span>
            {previousExams?.length < 7 ? (
              <DownArrowIcon sx={{ fontSize: "1.2rem" }} />
            ) : (
              <UpArrowIcon sx={{ fontSize: "1.2rem" }} />
            )}
          </Button>
        </Box>
      </div>
    );
  };

  // #region custom exam card

  const RepeatedAnpassatProvBox = () => {
    return (
      <div className={classes.customExam}>
        <TitleOfTheExeciseBox>Anpassat prov</TitleOfTheExeciseBox>
        <WhiteBoxContainer>
          {allCustomExams &&
            allCustomExams.map((exam, index) => {
              return (
                <>
                  <CustomExamCard
                    key={exam._id}
                    index={index}
                    customExamData={exam}
                    paymentPopupSetter={setPaymentModalPopup}
                  />
                </>
              );
            })}
        </WhiteBoxContainer>
      </div>
    );
  };

  const RepeatedCoursesRightBar = () => {
    return (
      <CoursesRightBar
        data={props.data}
        previousExams={props.previousExams}
        provpassOrderBySeason={provpassOrderBySeason}
      />
    );
  };

  return (
    <MainContainer maxWidth={false}>
      <MiddleContainer anpassatprov>
        <MobileMenu />
        <HeaderContainer anpassatprov>
          <WelcomeMessageBox anpassatprov>
            <TitleOfHeader>
              Övningsprov{" "}
              <img src={HatIcon} style={{ marginLeft: "5px" }} alt="" />
            </TitleOfHeader>
            <SubtitleOfHeader>
              Här kan du testa dig på riktiga högskoleprov och se hur du mäter
              dig med provdeltagarna från tidigare år.
            </SubtitleOfHeader>
          </WelcomeMessageBox>
          <PremiumCardContainer>
            <SecondaryIsPremiumCard isPremium={isPremium} />
          </PremiumCardContainer>
        </HeaderContainer>

        <TabLinks>
          <PaymentModal
            open={paymentModalPopup}
            handleClose={() => setPaymentModalPopup(false)}
            img={ElfProvImage}
            version={2}
          />
          <StyledTabs
            value={tabValue}
            onChange={handleTabs}
            variant="scrollable"
            scrollButtons={"off"}
            aria-label="scrollable prevent tabs example"
            sx={{
              "& .MuiTabs-scrollable": {
                marginBottom: "1rem !important",
              },
            }}
            TabIndicatorProps={{
              style: {
                background: appColors.blueColor,
                borderBottom: `5px solid ${appColors.blueColor}`,
              },
            }}
          >
            <Tab
              style={{
                minWidth: "5rem",
                paddingTop: "1rem",
                marginRight: "0.2rem",
                textTransform: "initial",
                color:
                  tabValue === 0
                    ? theme.palette.appcolors.primaryColor
                    : theme.palette.appcolors.primaryColor2,
                opacity: 1,
                fontSize: "1rem",
              }}
              label="Alla prov"
              className={classes.tabToggle}
            />

            <Tab
              style={{
                minWidth: "5rem",
                paddingTop: "1rem",
                textTransform: "initial",
                color:
                  tabValue === 1
                    ? theme.palette.appcolors.primaryColor
                    : theme.palette.appcolors.primaryColor2,
                opacity: 1,
                fontSize: "1rem",
              }}
              label="Anpassat prov"
              className={classes.tabToggle}
            />
          </StyledTabs>
          <SearchBox isfocused={isFocused}>
            <StyledTextField
              ref={ref}
              variant="outlined"
              type="search"
              size="small"
              placeholder={
                width > 800 || isFocused ? "Sök efter prov" : "Sök.."
              }
              onChange={handleInputChange}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              InputProps={{
                style: {
                  color: theme.palette.appcolors.grey7,
                  fontFamily: "Poppins",
                  height: "45px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <CustomSearchIcon isfocused={isFocused} />
                  </InputAdornment>
                ),
              }}
            />
          </SearchBox>
        </TabLinks>
        <WideScreenDisplay>
          {RepeatedAllaProvBox()}
          {RepeatedAnpassatProvBox()}
        </WideScreenDisplay>

        <TabletScreenDisplay>
          <TabPanel value={tabValue} index={0}>
            {" "}
            {/* Tab which shows all exams */}
            {RepeatedAllaProvBox()}
            {RepeatedAnpassatProvBox()}
            <NormalScreenDisplay>
              {RepeatedCoursesRightBar()}
            </NormalScreenDisplay>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {" "}
            {/* Tab to view Anpassat prov tab */}
            {RepeatedAnpassatProvBox()}
          </TabPanel>
          <MobilePremiumCardContainer>
            <SecondaryIsPremiumCard isPremium={isPremium} />
          </MobilePremiumCardContainer>
        </TabletScreenDisplay>
      </MiddleContainer>
      <RightBarDisplay>{RepeatedCoursesRightBar()}</RightBarDisplay>
    </MainContainer>
  );
};

export default CoursesFeedContent;
