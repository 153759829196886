/** Libraries */
import { useNavigate, useSearchParams } from "react-router-dom";

export const useNavigateUTM = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const navigateUTM = (url, options = null) => {
    const utm_source = params?.get("utm_source") || null;
    const utm_medium = params?.get("utm_medium") || null;
    const utm_campaign = params?.get("utm_campaign") || null;
    if (utm_source || utm_medium || utm_campaign) {
      url = url + "?";
      if (utm_source) url = url + `utm_source=${utm_source}`;
      if (utm_medium) url = url + `&utm_medium=${utm_medium}`;
      if (utm_campaign) url = url + `&utm_campaign=${utm_campaign}`;
    }
    if (options) {
      console.log("In options");
      navigate(url, options);
    } else {
      console.log("out options");
      navigate(url);
    }
  };

  return navigateUTM;
};
