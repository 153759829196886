import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";

export const InfoMainContainer = styled("div")(({ theme }) => ({
  padding: "3rem",
  background: theme.palette.appcolors.secondaryColor2,
  border: `1px solid ${theme.palette.appcolors.grey5}`,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.down(1280)]: {
    alignItems: "flex-start",
    padding: "2rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: "1rem",
    paddingTop: "2rem",
  },
}));

export const InfoHeaderTitle = styled("div")(({ theme }) => ({
  fontSize: "23px",
  fontWeight: "500",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(600)]: {
    fontSize: "18px",
  },
}));

export const InfoHeaderIconContainer = styled("div")(
  ({ theme, tabletmargin, phonemargin }) => ({
    color: theme.palette.appcolors.black,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up(600)]: {
      marginRight: tabletmargin,
    },
    [theme.breakpoints.down(600)]: {
      marginRight: phonemargin,
    },
  })
);

export const InfoButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginTop: "1rem",
  marginBottom: "2rem",
}));

export const CustomExamInfoButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "1rem",
  marginBottom: "2rem",
}));

export const InfoOtherButton = styled(Button)(({ theme }) => ({
  color: `${theme.palette.appcolors.primaryColor} !important`,
  border: `1px solid ${theme.palette.appcolors.primaryColor} !important`,
  borderRadius: "8px !important",
  maxWidth: "182px",
  height: "40px",
  width: "100%",
  fontWeight: "600 !important",
  textTransform: "none !important",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.03)",
    transition: "transform 300ms",
    [theme.breakpoints.down(600)]: {
      fontSize: "13px !important",
    },
  },
  [theme.breakpoints.down(600)]: {
    maxWidth: "140px",
    height: "28px",
    fontSize: "12px !important",
  },
}));

export const InfoStartButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${theme.palette.appcolors.primaryColor} !important`,
  color: `${theme.palette.appcolors.secondaryColor10} !important`,
  borderRadius: "8px !important",
  maxWidth: "238px",
  height: "40px",
  width: "100%",
  textTransform: "none !important",
  "&:hover": {
    transform: "scale(1.03)",
    transition: "transform 300ms",
    [theme.breakpoints.down(600)]: {
      fontSize: "13px !important",
    },
  },
  [theme.breakpoints.down(600)]: {
    maxWidth: "140px",
    height: "28px",
    fontSize: "12px !important",
  },
}));
