import React from "react";

const Logout = () => {
  return (
    <div>
      <h3>Hello from logout</h3>
    </div>
  );
};

export default Logout;
