/** Libraries */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

/** Slices */
import {
  publicTestSQLogout,
  setAnswerVisiblePublicTestSQ,
  setNextQuestionInReviewPublicTestSQ,
  setNextQuestionPublicTestSQ,
  setPreviusQuestionInReviewPublicTestSQ,
  setPublicTestSQQuiz,
  setSelectedQuestionPublicTestSQ,
  uiOpenCommonPopup,
} from "../../../redux/slices";

/** Custom hooks */
import { useTimerStore } from "../../useTimer/useTimerStore";
import { useMixpanel } from "../../useMixpanel/useMixpanel";

/** Utils */
import { useNavigateUTM } from "../../utils/useNavigateUTM";

/** Env variables */
import config from "../../../config/config";

/** Assets */
import quizXYZ from "../../../assets/Static/PublicXYZQuestions.json";
import quizNOG from "../../../assets/Static/PublicNogQuestions.json";
import quizMEK from "../../../assets/Static/PublicMekQuestions.json";
import quizORD from "../../../assets/Static/PublicOrdQuestions.json";
import quizKVA from "../../../assets/Static/PublicKvaQuestions.json";
const quices = {
  XYZ: quizXYZ,
  NOG: quizNOG,
  MEK: quizMEK,
  KVA: quizKVA,
  ORD: quizORD,
};
const validCategories = ["XYZ", "ORD", "KVA", "NOG", "MEK"];

export const usePublicTestSQStore = () => {
  const {
    currTime,
    secondsCounter,
    totalTime,
    startTimer,
    startPausingTimer,
    startReactivetingTimer,
    startTimerLogout,
  } = useTimerStore();
  const mixpanel = useMixpanel();
  const navigateUTM = useNavigateUTM();
  const { pathname, state } = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const {
    quiz,
    currentQuestion,
    selectedIndex,
    totalQuestions,
    isAnswerVisible,
    topBar,
  } = useSelector((state) => state.publicTestSQ);

  useEffect(() => {
    if (
      !validCategories.includes(params?.category.toUpperCase()) &&
      pathname.split("/")[1] === "public"
    ) {
      return navigateUTM("/");
    }
  }, [params]);

  useEffect(() => {
    if (currTime === 0 && totalTime !== 0) {
      if (pathname.split("/")[1] === "public") {
        if (quiz[0]?.optionId) {
          dispatch(
            uiOpenCommonPopup({
              title: "Tiden är över.",
              description: "Bra kämpat! Gå vidare och checka ditt resultat.",
              agreeBtnName: "Se resultat",
              redirect: startSubmittingQuizPublicTestSQ,
            })
          );
        } else {
          dispatch(
            uiOpenCommonPopup({
              title: "Tiden är över.",
              description:
                "Ingen fråga är besvarad så du tas direkt tillbaka till övningssidan.",
              agreeBtnName: "Avsluta",
              redirect: () => {
                startTimerLogout();
                navigateUTM("/");
              },
            })
          );
        }
      }
    }
  }, [pathname, quiz, currTime]);

  const startSettingPublicTestSQ = () => {
    if (!state?.isReviewSection) {
      console.log("category: ", params?.category.toUpperCase());
      let quiz = quices[`${params?.category.toUpperCase()}`] || [];
      if (quiz.length !== 0) {
        if (params?.index) {
          if (params?.category.toUpperCase() === "ORD") {
            /** ORD receive exams names as index */
            quiz = quiz[params?.index] || [];
          } else if (
            params?.category.toUpperCase() === "XYZ" &&
            params?.index == 1
          ) {
            /** Redirect old xyz link to new xyz link*/
            return window.location.replace(
              `${config.REACT_APP_BASE_URL}/public/xyz/24`
            );
          } else {
            /** Most of the categories */
            if (params?.index !== "24") return navigateUTM("/");
            quiz = quiz[1] || [];
          }
        } else {
          /** Possible fail case handling */
          quiz = quiz[0] || [];
        }
      }
      if (quiz.length === 0) {
        navigateUTM("/");
      }
      console.log("quiz: ", quiz);

      mixpanel.activityStarted("Public Test", params?.category.toUpperCase());
      startTimer(10 * 60, true);
      dispatch(setPublicTestSQQuiz({ quiz, questionIndex: null }));
    } else {
      // const { quiz, time } = params?.state?.answerSubmittedState;
      startTimer(state?.time);
      startPausingTimer();
      dispatch(
        setPublicTestSQQuiz({
          quiz: state?.quiz?.question,
          questionIndex: state?.questionIndex,
        })
      );
    }
  };

  const startSubmittingQuizPublicTestSQ = () => {
    console.log("Submit quiz: ", quiz);
    startPausingTimer();
    let resultAnswer = {
      answer: [],
      question: [],
      attemptedQuestion: 0,
    };

    quiz.forEach((question) => {
      const finalQuestion = question?.optionId
        ? question
        : {
            ...question,
            spendtime: 0,
            timeleft: currTime,
            totaltime: totalTime,
          };
      resultAnswer = {
        answer: [...resultAnswer.answer, question.answerObj],
        question: [
          ...resultAnswer.question,
          { ...finalQuestion, answer: finalQuestion.answerObj },
        ],
        attemptedQuestion: resultAnswer.attemptedQuestion + 1,
      };
    });

    console.log("Formated quiz: ", resultAnswer);

    mixpanel.activityEnded("Public Test", params?.category.toUpperCase());
    startTimerLogout();
    navigateUTM(
      `/public/${params?.category.toUpperCase()}/${
        params?.index
      }/resultsummary`,
      {
        state: {
          sectionCategory: params?.category.toUpperCase(),
          time: currTime,
          answerSubmittedState: resultAnswer,
        },
        replace: true,
      }
    );
  };

  const startSettingSelectedAnswerPublicTestSQ = (question_id) => {
    console.log("question_id: ", question_id);
    dispatch(setSelectedQuestionPublicTestSQ(question_id));
  };

  const startSettingAnswerVisiblePublicTestSQ = () => {
    dispatch(
      setAnswerVisiblePublicTestSQ({
        spendtime: secondsCounter,
        timeleft: currTime,
        totaltime: totalTime,
      })
    );
    startPausingTimer();
  };

  const startNextQuestionPublicTestSQ = () => {
    if (selectedIndex !== totalQuestions - 1) {
      dispatch(setNextQuestionPublicTestSQ());
      startReactivetingTimer();
    } else {
      startSubmittingQuizPublicTestSQ();
    }
  };

  const startNavigatingResultPageInReview = () => {
    navigateUTM(
      `/public/${params?.category.toUpperCase()}/${
        params?.index
      }/resultsummary`,
      {
        state: {
          sectionCategory: params?.category.toUpperCase(),
          time: state?.time,
          answerSubmittedState: state?.quiz,
        },
      }
    );
  };

  const startSettingNextQuestionInReviewPublicTestSQ = () => {
    dispatch(setNextQuestionInReviewPublicTestSQ());
  };

  const startSettingPreviusQuestionInReviewPublicTestSQ = () => {
    dispatch(setPreviusQuestionInReviewPublicTestSQ());
  };

  const startClosingQuizPublicTestSQ = () => {
    if (!state?.isReviewSection) {
      if (quiz[0]?.optionId) {
        dispatch(
          uiOpenCommonPopup({
            title: "Vill du avsluta?",
            description: "Du tas nu till summeringssidan.",
            cancelBtnName: "Fortsätt öva",
            agreeBtnName: "Avsluta",
            redirect: startSubmittingQuizPublicTestSQ,
          })
        );
      } else {
        dispatch(
          uiOpenCommonPopup({
            title: "Vill du avsluta?",
            description: "Ingen fråga är besvarad.",
            cancelBtnName: "Fortsätt öva",
            agreeBtnName: "Avsluta",
            redirect: () => {
              mixpanel.activityEnded(
                "Public Test",
                params?.category.toUpperCase()
              );
              startTimerLogout();
              navigateUTM("/");
            },
          })
        );
      }
    } else {
      startNavigatingResultPageInReview();
    }
  };

  const startLogoutPublicTestSQ = () => {
    dispatch(publicTestSQLogout());
  };

  return {
    /** Values */
    quiz,
    currentQuestion,
    selectedIndex,
    totalQuestions,
    isReviewSection: state?.isReviewSection,
    isAnswerVisible: isAnswerVisible || state?.isReviewSection,
    topBar,
    categoryTitle: params?.category.toUpperCase(),

    /** Functions */
    startSettingPublicTestSQ,
    startSettingSelectedAnswerPublicTestSQ,
    startSettingAnswerVisiblePublicTestSQ,
    startNextQuestionPublicTestSQ,
    startClosingQuizPublicTestSQ,
    startNavigatingResultPageInReview,
    startSettingNextQuestionInReviewPublicTestSQ,
    startSettingPreviusQuestionInReviewPublicTestSQ,
    startSubmittingQuizPublicTestSQ,
    startLogoutPublicTestSQ,
  };
};
