export const config = {
  REACT_APP_SERVER_NAME: "PROD",
  REACT_APP_API_BASE_URL: "https://apibeta.hpappen.se",
  REACT_APP_BASE_URL: "https://app.hpappen.se",
  STRIPE_PUBLIC_KEY:
    "pk_live_51QBJS4A2JUTGq2muu9iCTTMU97VlyRUccgMLRlwNN04WIC1Vt4tSbYmq3UnTAXfEIl1xwcqgcz0neqf61AcEZ5UJ00OlGcYh63",
  FULL_ACCESS_PRICE_ID: "price_1QI6yPA2JUTGq2mum6eo0Tbs",
  PARTIAL_QUANTITATIVE_PRICE_ID: "price_1QI6zlA2JUTGq2muzPLcsaee",
  PARTIAL_VERBAL_PRICE_ID: "price_1QI6zJA2JUTGq2muINIWY1vg",
  FULL_ACCESS_6M_PRICE_ID: "price_1QJwwNA2JUTGq2mujAMvoWjP",
  PARTIAL_QUANTITATIVE_6M_PRICE_ID: "price_1QJx2SA2JUTGq2muePEhIyWP",
  PARTIAL_VERBAL_6M_PRICE_ID: "price_1QJx1pA2JUTGq2mumjleTfs1",
  REACT_APP_SENTRY_DSN:
    "https://d3f1d587e912423b91fda0d4d4742861@o4504945522180096.ingest.sentry.io/4504945526964224",
  REACT_APP_GOOGLE_CLIENT_ID:
    "762929285322-facjtf4j1v5g801f59hrk2corsg6t99h.apps.googleusercontent.com",
};
