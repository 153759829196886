/** Libraries */
import React from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Grid, List, ListItem } from "@material-ui/core";
import { Dialog } from "@mui/material";

/** Atoms */
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { Transition } from "../../atom/SlideTransition/SlideTransition";

/** Molecules */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";

/** Assets */
import FrameIconSave from "../../../assets/Icons/FrameIconSave.png";
import FrameIconClose from "../../../assets/Icons/FrameIconClose.png";
import FrameIconHistory from "../../../assets/Icons/FrameIconHistory.png";
import FrameIconTimeSuccess from "../../../assets/Icons/FrameIconTimeSuccess.png";
import FrameIconTimeFail from "../../../assets/Icons/FrameIconTimeFail.png";
import FrameIconTimer from "../../../assets/Icons/FrameIconTimer.png";
import FrameIconSuccessRequirement from "../../../assets/Icons/FrameIconSuccessRequirement.png";
import FrameIconTimeElapsed from "../../../assets/Icons/FrameIconTimeElapsed.png";
import FrameIconOverview from "../../../assets/Icons/FrameIconOverview.png";
import LectureInfoImage from "../../../assets/Imgs/LectureInfoImage.png";
import ExerciseInfoImage from "../../../assets/Imgs/ExerciseInfoImage.png";
import ExamInfoImage from "../../../assets/Imgs/ExamInfoImage.png";

/** Custom Hooks */
import { usePersistedStore } from "./../../../hooks";

/** Styled Components */
const MainContainer = styled(Box)(({ theme }) => ({
  borderRadius: "5px",
  height: "100%",
  width: "100%",
  padding: "30px",
  backgroundColor: theme.palette.appcolors.secondaryColor15,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down(800)]: {
    flexDirection: "column",
  },
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  zIndex: 1401,
  maxHeight: "100%",
  maxWidth: "100%",
  margin: "auto",
}));

const CustomGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  gap: "0px",
}));

const ListContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
  [theme.breakpoints.down(1080)]: {
    marginTop: "0px",
  },
}));

const ListIcon = styled("img")(({ theme }) => ({
  marginRight: "0.5rem",
  width: "20px",
  [theme.breakpoints.up(2400)]: {
    marginRight: "1rem",
    width: "24px",
  },
  [theme.breakpoints.down(1080)]: {
    width: "15px",
  },
}));

const MainImage = styled("img")(({ theme, darkmode }) => ({
  width: "100%",
  maxWidth: "406px",
  maxHeight: "308px",
  filter: darkmode && "hue-rotate(333deg)",
  [theme.breakpoints.down(1080)]: {
    maxWidth: "270px",
    maxHeight: "204px",
  },
  [theme.breakpoints.down(800)]: {
    maxWidth: "263px",
    maxHeight: "200px",
  },
  [theme.breakpoints.up(2400)]: {
    maxWidth: "500px",
    maxHeight: "380px",
    marginLeft: "3rem",
  },
}));

const TitleAndListContainer = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "20px",
  maxWidth: "366px",
  [theme.breakpoints.down(1080)]: {
    maxWidth: "254px",
  },
  [theme.breakpoints.down(800)]: {
    maxWidth: "305px",
    marginTop: "0px",
  },
  [theme.breakpoints.up(2400)]: {
    maxWidth: "366px",
  },
}));

const MainTitle = styled("p")(({ theme }) => ({
  fontWeight: "600",
  fontSize: "30px",
  paddingBottom: "1rem",
  [theme.breakpoints.down(1080)]: {
    textAlign: "center",
    fontSize: "14px",
  },
}));

const ListText = styled("span")(({ theme }) => ({
  fontFamily: "Figtree",
  cursor: "default",
  fontSize: "15px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.down(1080)]: {
    fontSize: "11px",
  },
  [theme.breakpoints.up(2400)]: {
    fontSize: "20px",
  },
}));

const RepeatedListItem = (img, text) => (
  <CustomGrid>
    <ListItem disableGutters>
      <ListContentContainer>
        <ListIcon src={img} />
        <ListText>{text}</ListText>
      </ListContentContainer>
    </ListItem>
  </CustomGrid>
);

export const LectureStartupDialog = () => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const { darkmode, lectureStartupDialog, startLectureStartupDialog } =
    usePersistedStore();

  return (
    <CustomDialog
      open={lectureStartupDialog}
      onClose={() => startLectureStartupDialog()}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          borderRadius: "5px",
          height: "100%",
          width: "100%",
          maxHeight:
            width <= 800
              ? "548px"
              : width <= 1080
              ? "365px"
              : width <= 2400
              ? "548px"
              : "700px",
          maxWidth:
            width <= 800
              ? "346px"
              : width <= 1080
              ? "580px"
              : width <= 2400
              ? "967px"
              : "1288px",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <MainContainer>
        <StyledCloseIconRight
          style={{ fontSize: "32px" }}
          onClick={() => startLectureStartupDialog()}
        />
        <TitleAndListContainer>
          <MainTitle>Välkommen till lektioner!</MainTitle>
          <List dense>
            {RepeatedListItem(
              FrameIconClose,
              "Du kan avbryta när som helst genom att klicka på krysset."
            )}
            {RepeatedListItem(
              FrameIconSave,
              "Dina resultat sparas så du kan fortsätta lektionen på samma ställe senare."
            )}
          </List>
        </TitleAndListContainer>
        <MainImage
          darkmode={darkmode}
          src={LectureInfoImage}
          alt="LectureInfoImage"
        />
      </MainContainer>
    </CustomDialog>
  );
};

export const ExerciseStartupDialog = () => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const { darkmode, exerciseStartupDialog, startExerciseStartupDialog } =
    usePersistedStore();

  return (
    <CustomDialog
      open={exerciseStartupDialog}
      onClose={() => startExerciseStartupDialog()}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          borderRadius: "5px",
          height: "100%",
          width: "100%",
          maxHeight:
            width <= 800
              ? "548px"
              : width <= 1080
              ? "365px"
              : width <= 2400
              ? "548px"
              : "700px",
          maxWidth:
            width <= 800
              ? "346px"
              : width <= 1080
              ? "580px"
              : width <= 2400
              ? "967px"
              : "1288px",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <MainContainer>
        <StyledCloseIconRight
          style={{ fontSize: "32px" }}
          onClick={() => startExerciseStartupDialog()}
        />
        <TitleAndListContainer>
          <MainTitle>Välkommen till övningsläget!</MainTitle>
          <List dense>
            {RepeatedListItem(
              FrameIconClose,
              "Du kan avbryta när som helst genom att klicka på krysset."
            )}
            {RepeatedListItem(
              FrameIconHistory,
              "Dina resultat sparas alltid i din historik."
            )}
            {RepeatedListItem(
              FrameIconTimeSuccess,
              "När du gör frågor på tid räknas dina svar med i prognosen."
            )}
            {RepeatedListItem(
              FrameIconTimeFail,
              "När du gör frågor utan tid, räknas de inte med."
            )}
            {RepeatedListItem(
              FrameIconTimer,
              "Du kan dölja tiden genom att trycka på tidsikonen."
            )}
          </List>
        </TitleAndListContainer>
        <MainImage
          darkmode={darkmode}
          src={ExerciseInfoImage}
          alt="ExerciseInfoImage"
        />
      </MainContainer>
    </CustomDialog>
  );
};

export const ExamStartupDialog = () => {
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const { darkmode, examStartupDialog, startExamStartupDialog } =
    usePersistedStore();

  return (
    <CustomDialog
      open={examStartupDialog}
      onClose={() => startExamStartupDialog()}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          borderRadius: "5px",
          height: "100%",
          width: "100%",
          maxHeight:
            width <= 800
              ? "548px"
              : width <= 1080
              ? "365px"
              : width <= 2400
              ? "548px"
              : "700px",
          maxWidth:
            width <= 800
              ? "346px"
              : width <= 1080
              ? "580px"
              : width <= 2400
              ? "967px"
              : "1288px",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <MainContainer>
        <StyledCloseIconRight
          style={{ fontSize: "32px" }}
          onClick={() => startExamStartupDialog()}
        />
        <TitleAndListContainer>
          <MainTitle>Välkommen till provpassvyn!</MainTitle>
          <List dense>
            {RepeatedListItem(
              FrameIconSuccessRequirement,
              "Slutfört prov krävs för att se facit och resultat, precis som när du gör högskoleprovet på plats."
            )}
            {RepeatedListItem(
              FrameIconTimeElapsed,
              "Provpasset lämnas in automatiskt när tiden är ute."
            )}
            {RepeatedListItem(
              FrameIconOverview,
              "Klicka på 'Överblick' för att se sparade frågor."
            )}
            {RepeatedListItem(
              FrameIconTimeFail,
              "Om du väljer att avbryta provpasset sparas inte dina svar, men provet kan göras om senare."
            )}
            {RepeatedListItem(
              FrameIconTimer,
              "Du kan dölja tiden genom att trycka på tidsikonen."
            )}
          </List>
        </TitleAndListContainer>
        <MainImage
          darkmode={darkmode}
          src={ExamInfoImage}
          alt="ExamInfoImage"
        />
      </MainContainer>
    </CustomDialog>
  );
};
