/** Libraries */
import React from "react";
import { Box, Container } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";

/** Molecules */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";

/** Atoms */
import { MainReviewCard } from "../../atom/ReviewCards/ProfileReviewCard";

/** Assets */
import { ReactComponent as Logo } from "../../../assets/Icons/mobileLogo.svg";

/** Material UI - Custom elements */
const AuthLayoutContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: "100vh",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  [theme.breakpoints.down(1080)]: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  [theme.breakpoints.down(600)]: {
    backgroundColor: theme.palette.appcolors.secondaryColor,
  },
}));

const MainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: theme.palette.appcolors.primaryColor3,
  [theme.breakpoints.down(1080)]: {
    padding: "1rem 0rem",
    borderRadius: "15px 15px 0px 0px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "0rem",
    borderRadius: "0px",
    backgroundColor: theme.palette.appcolors.secondaryColor15,
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "1rem",
  [theme.breakpoints.down(1080)]: {
    display: "none",
  },
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  padding: "0rem 1rem",
  [theme.breakpoints.down(1080)]: {
    padding: "1rem 0rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: "0rem",
    paddingBottom: "3rem",
    margin: "0rem",
  },
}));

const RadioContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const RadioButton = styled(Radio)(({ theme }) => ({
  color: theme.palette.appcolors.secondaryColor4,
  "&.Mui-checked": {
    color: theme.palette.appcolors.secondaryColor4,
  },
  [theme.breakpoints.down(600)]: {
    color: theme.palette.appcolors.primaryColor,
    "&.Mui-checked": {
      color: theme.palette.appcolors.primaryColor,
    },
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const DesktopDisplay = styled(Container)(({ theme }) => ({
  display: "flex",
  padding: "0rem !important",
  [theme.breakpoints.down(1080)]: {
    display: "none",
  },
}));

const LaptopDisplay = styled(Container)(({ theme }) => ({
  display: "flex",
  padding: "0rem !important",
  [theme.breakpoints.up(1080)]: {
    display: "none",
  },
}));

const AuthLayout = ({ children }) => {
  const [selectedValue, setSelectedValue] = React.useState(0);
  const { width } = useWindowDimensions();
  const theme = useTheme();

  const handleChange = (event) => {
    setSelectedValue(parseInt(event.target.value));
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setSelectedValue((prevValue) => (prevValue + 1) % 3);
    }, 7000);

    return () => clearInterval(timer);
  }, []);

  const repeatedTestimonialSection = () => {
    return (
      <MainContainer maxWidth="false" disableGutters>
        <LogoContainer>
          <Logo
            fill={theme.palette.appcolors.secondaryColor10}
            width="96px"
            height="35px"
          />
        </LogoContainer>
        <ImageContainer>
          {MainReviewCard(width, theme, selectedValue)}
          <RadioContainer>
            <RadioButton
              checked={selectedValue === 0}
              onChange={handleChange}
              value="0"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <RadioButton
              checked={selectedValue === 1}
              onChange={handleChange}
              value="1"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            <RadioButton
              checked={selectedValue === 2}
              onChange={handleChange}
              value="2"
              name="radio-buttons"
              inputProps={{ "aria-label": "C" }}
            />
          </RadioContainer>
        </ImageContainer>
      </MainContainer>
    );
  };

  return (
    <AuthLayoutContainer>
      <DesktopDisplay>{repeatedTestimonialSection()}</DesktopDisplay>

      <ContentContainer>{children}</ContentContainer>
      <LaptopDisplay>{repeatedTestimonialSection()}</LaptopDisplay>
    </AuthLayoutContainer>
  );
};

export default AuthLayout;
