/** Libraries */
import React from "react";
import ReactMarkdown from "react-markdown";
import { styled } from "@mui/material/styles";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import { usePersistedStore } from "../../../hooks";

const StyledReactMarkdown = styled(ReactMarkdown)(({ theme, darkmode }) => ({
  "& table": {
    marginTop: "10px",
    marginBottom: "10px",
  },
  "& thead": {
    borderBottom: `1px solid ${theme.palette.appcolors.grey10}`,
  },
  "& thead > tr > th:not(:last-child)": {
    borderRight: `1px solid ${theme.palette.appcolors.grey10}`,
  },
  "& tr:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.appcolors.grey10}`,
  },
  "& td:not(:last-child)": {
    borderRight: `1px solid ${theme.palette.appcolors.grey10}`,
  },
  "& th, & td": {
    padding: "6px 13px",
  },
  "& table tr:nth-child(2n)": {},
  "& img": {
    minHeight: "250px",
    maxWidth: "100%",
    filter: darkmode && "invert(0.9)",
    paddingTop: "1rem",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
  },
  "& p": {
    maxWidth: "100%",
  },
  "& .math": {
    overflow: "hidden",
  },
  "& strong": {
    fontWeight: 600,
  },
  "& .katex-display .katex": {
    textAlign: "left",
    fontSize: "clamp(14px, 2vw, 18px)",
    whiteSpace: "wrap",
    [theme.breakpoints.down(420)]: {
      fontSize: "clamp(11px, 2vw, 14px)",
    },
  },
}));

const MarkLatex = ({ content, className, ...props }) => {
  const { darkmode } = usePersistedStore();
  return (
    <StyledReactMarkdown
      children={content}
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeKatex]}
      skipHtml
      className={`${className}`}
      darkmode={darkmode}
      {...props}
    />
  );
};

export default MarkLatex;
