/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Axios Instances */
import {
  uiCloseExamsProgressBackdrop,
  uiOpenExamsProgressBackdrop,
  uiFeedbackPopupOpen,
  uiFeedbackPopupClose,
  uiOpenCommonPopup,
  uiCloseCommonPopup,
  uiSetErrorPopup,
  uiSetErrorRedirectionTrue,
  uiSetErrorRedirectionFalse,
  uiSetGuaranteePopup,
  setRerenderComponent,
  setRerenderComponentLeft,
  setRerenderComponentRight,
} from "../../redux/slices/ui/uiSlice";

export const useUiStore = () => {
  const dispatch = useDispatch();
  const {
    progressBackdrop,
    examsProgressBackdrop,
    feedbackPopup,
    commonPopup,
    errorPopup,
    errorRedirection,
    guaranteePopup,
    rerenderComponentAnimation,
  } = useSelector((state) => state.ui);

  const startRerenderComponent = () => {
    dispatch(setRerenderComponent((prev) => !prev));
  };

  const startRerenderComponentLeft = () => {
    dispatch(setRerenderComponentLeft());
  };

  const startRerenderComponentRight = () => {
    dispatch(setRerenderComponentRight());
  };

  const startGuaranteePopup = () => {
    dispatch(uiSetGuaranteePopup());
  };

  const startErrorPopup = () => {
    dispatch(uiSetErrorPopup());
  };

  const startErrorRedirectionTrue = () => {
    dispatch(uiSetErrorRedirectionTrue());
  };

  const startErrorRedirectionFalse = () => {
    dispatch(uiSetErrorRedirectionFalse());
  };

  const startUiFeedbackPopupOpen = () => {
    dispatch(uiFeedbackPopupOpen());
  };

  const startUiFeedbackPopupClose = () => {
    dispatch(uiFeedbackPopupClose());
  };

  const startUiOpenExamsProgressBackdrop = () => {
    dispatch(uiOpenExamsProgressBackdrop());
  };
  const startUiCloseExamsProgressBackdrop = () => {
    dispatch(uiCloseExamsProgressBackdrop());
  };

  const startUiOpenCommonPopup = (commonPopupData) => {
    dispatch(uiOpenCommonPopup(commonPopupData));
  };
  const startUiCloseCommonPopup = () => {
    dispatch(uiCloseCommonPopup());
  };

  return {
    /** Values */
    progressBackdrop,
    examsProgressBackdrop,
    feedbackPopup,
    commonPopup,
    errorPopup,
    errorRedirection,
    guaranteePopup,
    rerenderComponentAnimation,

    /** Functions */
    startUiOpenExamsProgressBackdrop,
    startUiCloseExamsProgressBackdrop,
    startUiFeedbackPopupOpen,
    startUiFeedbackPopupClose,
    startUiOpenCommonPopup,
    startUiCloseCommonPopup,
    startErrorPopup,
    startErrorRedirectionTrue,
    startErrorRedirectionFalse,
    startGuaranteePopup,
    startRerenderComponent,
    startRerenderComponentLeft,
    startRerenderComponentRight,
  };
};
