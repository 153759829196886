// Prices.js
// Highest order global price - Change price here. This will affect Checkout and other CTAs.

const planOne = {
  name: "Gratis",
  price: {
    monthly: 0,
    halfYearly: 0,
  },
  premiumLength: 3, //always in months, in this case 3 months
  pricePerMonth: 0, //Just for display on frontend, do not store in backend
  id: "planOne",
  discount: null,
  popular: false,
  guarantee: false,
  isGroupOrder: false,
};

const planThree = {
  name: "Full Premium",
  price: {
    monthly: 249,
    halfYearly: 149,
  },
  premiumLength: 12,
  pricePerMonth: 249 / 12, //Just for display on frontend, do not store in backend
  id: "planTwo",
  discount: null,
  popular: true,
  guarantee: true,
  isGroupOrder: false,
};

const planTwo = {
  name: "Verbal eller kvantitativ",
  price: {
    monthly: 149,
    halfYearly: 89,
  },
  premiumLength: 12,
  pricePerMonth: 149, //Just for display on frontend, do not store in backend
  id: "planThree",
  discount: null,
  popular: false,
  guarantee: true,
  isGroupOrder: false,
};

const payingFullFromPartial = {
  name: "Full Premium",
  price: 249,
  premiumLength: 12,
  pricePerMonth: 249, //Just for display on frontend, do not store in backend
  id: "payingFullFromPartial",
  discount: "15%",
  popular: true,
  guarantee: true,
  isGroupOrder: false,
};

export default { planOne, planTwo, planThree, payingFullFromPartial };
