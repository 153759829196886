/** Libraries */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

/** Slices */
import {
  aiTutorSlice,
  bannerSlice,
  discountSlice,
  examsSlice,
  exercisesMQReviewSlice,
  exercisesMQSlice,
  exercisesSectionCategoriesSlice,
  lectureDashboardSlice,
  lectureSlice,
  pdfDownloadSlice,
  persistedSlice,
  publicTestMQSlice,
  publicTestSQSlice,
  saveChoicesSlice,
  feedbackQuestionSlice,
  timeCorrectionSlice,
  timeSlice,
  timerSlice,
  uiSlice,
  userSlice,
  customExamSlice,
  allCustomExamSlice,
  questionViewerSlice,
  categoryDetailsSlice,
} from "./slices";
import config from "../config/config";
/** If we put the name of a slice
 * into the whitelist, it's going to be persisted.
 */
const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "persisted",
    "quizData",
    "lecture",
    "lectureDashboard",
    "exercisesMQ",
    "exercisesMQReview",
    "timer",
    "exercisesSectionCategories",
    "saveChoices",
    "allCustomExams",
    "customExam",
    "feedbackQuestion",
    "bannerChoices",
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: userSlice.reducer,
    ui: uiSlice.reducer,
    persisted: persistedSlice.reducer,
    quizData: timeSlice.reducer,
    exercisesMQ: exercisesMQSlice.reducer,
    exercisesMQReview: exercisesMQReviewSlice.reducer,
    exercisesSectionCategories: exercisesSectionCategoriesSlice.reducer,
    exams: examsSlice.reducer,
    lectureDashboard: lectureDashboardSlice.reducer,
    lecture: lectureSlice.reducer,
    timer: timerSlice.reducer,
    pdfDownload: pdfDownloadSlice.reducer,
    aiTutor: aiTutorSlice.reducer,
    timeCorrection: timeCorrectionSlice.reducer,
    discount: discountSlice.reducer,
    publicTestSQ: publicTestSQSlice.reducer,
    publicTestMQ: publicTestMQSlice.reducer,
    customExam: customExamSlice.reducer,
    allCustomExams: allCustomExamSlice.reducer,
    saveChoices: saveChoicesSlice.reducer,
    feedbackQuestion: feedbackQuestionSlice.reducer,
    bannerChoices: bannerSlice.reducer,
    questionViewer: questionViewerSlice.reducer,
    categoryDetails: categoryDetailsSlice.reducer,
  })
);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: config.REACT_APP_SERVER_NAME !== "PROD",
  middleware: [thunk],
});

export default store;
export const persistor = persistStore(store);
