/** Libraries */
import { createSlice, current } from "@reduxjs/toolkit";

export const lectureSlice = createSlice({
  name: "lecture",
  initialState: {
    isLoading: true,
    quiz: [],
    currLecture: null,
    currType: null,
    totalNumberQuestions: 0,
    selectedIndex: 0,
    lectureName: " ",
    lectureEnding: " ",
    topBar: {
      progress: 0,
      isBackArrowDisabled: true,
      isForwardArrowDisabled: false,
    },
    completedPageAanimationIndex: 0,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setLecture: (state, action) => {
      // console.log("action.payload?.bypass: ", action.payload?.bypass);

      const organizedData = action.payload.data.flatMap((question) => {
        const theoryEntries = question.theories.map((theory) => ({
          type: "Theory",
          content: theory,
          questionId: question._id,
        }));

        return [
          ...theoryEntries,
          {
            ...question,
            options: {
              ...question.options,
              options: question.options.options.sort(() => Math.random() - 0.5),
            },
            type: "Question",
            theories: null,
          },
        ];
      });
      const completedObj = {
        type: "Completed",
        content: action.payload?.lecture.LectureEnding,
      };
      organizedData.push(completedObj);
      // console.log("new data: ", organizedData);

      let lastQuestionIndex = 0;
      if (
        action.payload.lecture.score !== 0 &&
        action.payload.lecture.score !== 100
      ) {
        let lastQuestion_id = "";
        const indexDataQuestion = Math.round(
          (action.payload.lecture.score * action.payload.data.length) / 100
        );
        // console.log("indexQuestion: ", indexDataQuestion);
        lastQuestion_id = action.payload.data[indexDataQuestion]._id;

        for (let i = 0; i < organizedData.length - 1; i++) {
          const e = organizedData[i];

          if (e.type === "Theory") {
            if (e.questionId === lastQuestion_id) {
              lastQuestionIndex = i;
              break;
            }
          } else {
            if (e._id === lastQuestion_id) {
              lastQuestionIndex = i;
              break;
            }
            organizedData[i].answerExist = true;
            organizedData[i].selectedCurrLecture =
              organizedData[i].correctOption;
          }
        }
        state.topBar.progress = Math.round(
          (lastQuestionIndex / organizedData.length) * 100
        );

        if (lastQuestionIndex > 0) {
          state.topBar.isBackArrowDisabled = false;
        } else {
          state.topBar.isBackArrowDisabled = true;
        }
      } else {
        state.topBar.progress = 0;
        state.topBar.isBackArrowDisabled = true;
      }

      state.quiz = organizedData;
      state.currType = organizedData[lastQuestionIndex].type;
      state.currLecture = organizedData[lastQuestionIndex];
      state.totalNumberQuestions = action.payload.data.length;
      state.lectureName = action.payload?.lecture.lectureName;
      state.lectureEnding = organizedData[organizedData.length - 1].content;
      state.selectedIndex = lastQuestionIndex;
      state.topBar.isForwardArrowDisabled = false;
      state.completedPageAanimationIndex = Math.floor(Math.random() * 3);
    },
    setBackLectureQuestion: (state) => {
      const { quiz, selectedIndex } = state;
      if (selectedIndex > 0 && quiz[selectedIndex - 1]) {
        state.currLecture = quiz[selectedIndex - 1];
        state.currType = quiz[selectedIndex - 1].type;
        state.selectedIndex -= 1;
      }
    },
    setNextLectureQuestion: (state) => {
      const { quiz, selectedIndex } = state;

      if (selectedIndex < quiz.length - 1 && quiz[selectedIndex + 1]) {
        state.currLecture = quiz[selectedIndex + 1];
        state.currType = quiz[selectedIndex + 1].type;
        state.selectedIndex += 1;
      }
    },
    setBackArrowStatus: (state, action) => {
      state.topBar.isBackArrowDisabled = action.payload;
    },
    setForwardArrowStatus: (state, action) => {
      state.topBar.isForwardArrowDisabled = action.payload;
    },
    setProgressBar: (state, action) => {
      state.topBar.progress = Number(action.payload);
    },
    setToggleAnswerVisibility: (state) => {
      if (state.currLecture?.isAnswerVisible) {
        state.currLecture.isAnswerVisible = false;
      } else {
        state.currLecture.isAnswerVisible = true;
      }
    },
    setSelectedCurrLecture: (state, action) => {
      state.currLecture.selectedCurrLecture = action.payload?.optionId;
      state.currLecture.answerExist = true;

      state.quiz[state.selectedIndex].selectedCurrLecture =
        action.payload?.optionId;
      state.quiz[state.selectedIndex].answerExist = true;
    },
    setDiselectedCurrLecture: (state, action) => {
      delete state.currLecture.selectedCurrLecture;
      delete state.currLecture.answerExist;

      delete state.quiz[state.selectedIndex].selectedCurrLecture;
      delete state.quiz[state.selectedIndex].answerExist;
    },
    lecturePreLogout: (state) => {
      state.quiz = [];
      state.currLecture = null;
      // state.selectedIndex = 0;
      // state.totalNumberQuestions = 0;
    },
    lectureLogout: (state) => {
      state.isLoading = true;
      state.quiz = [];
      state.currLecture = null;
      state.currType = null;
      state.selectedIndex = 0;
      state.totalNumberQuestions = 0;
      state.lectureName = " ";
      state.lectureEnding = " ";

      state.topBar.progress = 0;
      state.topBar.isBackArrowDisabled = true;
      state.topBar.isForwardArrowDisabled = false;

      state.completedPageAanimationIndex = 0;
    },
  },
});
export const {
  setIsLoading,
  setLecture,
  setBackLectureQuestion,
  setNextLectureQuestion,
  setBackArrowStatus,
  setForwardArrowStatus,
  setProgressBar,
  setToggleAnswerVisibility,
  setSelectedCurrLecture,
  setDiselectedCurrLecture,
  lecturePreLogout,
  lectureLogout,
} = lectureSlice.actions;
