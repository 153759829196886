/** Libraries */
import React from "react";

import PropTypes from "prop-types";

/** Assets */
import EnterIcon from "../../../../assets/Icons/EnterIcon.svg";

/** Styles */
import {
  EnterCustomIcon,
  MainContainer,
  NotificationContainer,
  TextNotification,
} from "./PressEnterContinue.styles";

const PressEnterContinue = ({ children, isHidden, ...props }) => {
  return (
    <MainContainer maxWidth="sm" {...props}>
      {children}
      <NotificationContainer
        sx={{ visibility: isHidden ? "hidden" : "visible" }}
      >
        <TextNotification>
          Tryck <span>Enter</span>
        </TextNotification>
        <EnterCustomIcon src={EnterIcon} alt="" />
      </NotificationContainer>
    </MainContainer>
  );
};

PressEnterContinue.propTypes = {
  isHidden: PropTypes.bool,
};

PressEnterContinue.defaultProps = {
  isHidden: false,
};

export default PressEnterContinue;
