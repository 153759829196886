/** Libraries */
import { Box, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

/** Styles */
export const RowRadiusButtonFirstContainer = styled(Box)(
  ({ theme, disabled }) => ({
    width: "30px",
    height: "30px",
    cursor: !disabled && "pointer",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 0,
  })
);

export const RowRadiusButtonSecondContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const RowRadiusIndexContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "45px",
  height: "32.5px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "end",
}));

export const RadiusIndexText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    textTransform: "uppercase",
  },
}));
