/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import {
  setAllCustomExams,
  setCustomExamSessions,
  setCustomExamSessionQuestions,
} from "../../../redux/slices/customExam/AllCustomExamSlice/allCustomExamSlice";

/** Utils */
import { handleErrors } from "../../../utils/Errors";

/** Axios - endpoint */
import { EndPoints, instance2 } from "../../../components/service/Route";

export const useAllCustomExamStore = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const { allCustomExams, customExamSessions, customExam } = useSelector(
    (state) => state.allCustomExams
  );

  const customizedExams = async (signal) => {
    const customExamsURL = EndPoints.customExams;
    try {
      const response = await instance2.get(customExamsURL, { signal });
      dispatch(setAllCustomExams(response.data.data));
      return response.data.data;
    } catch (error) {
      handleErrors(error);
    }
  };

  const customizedExamSessions = async (customExamId) => {
    const customExamsURL = EndPoints.customExamsSession + customExamId;
    try {
      const response = await instance2.post(customExamsURL);
      dispatch(setCustomExamSessions(response.data));
      return response.data;
    } catch (error) {
      handleErrors(error);
      return null;
    }
  };

  const customizedExamSessionQuestions = async (customExamSessions) => {
    const customExamsURL =
      EndPoints.customExamsSessionQuestions + customExamSessions;
    try {
      const response = await instance2.get(customExamsURL);
      dispatch(setCustomExamSessionQuestions(response.data.data));
      return response.data.data;
    } catch (error) {
      handleErrors(error);
      return null;
    }
  };

  const submitExamSession = async (customExamId, customExamSessionId, quiz) => {
    const customExamsURL = EndPoints.submitSimuleraTest;
    try {
      const response = await instance2.post(customExamsURL, {
        customizedExam: customExamId,
        customizeSession: customExamSessionId,
        quiz: quiz,
      });
      return response;
    } catch (error) {
      handleErrors(error);
      return null;
    }
  };

  const customExamResult = async (customExamId, customExamSessionResult) => {
    const customExamsURL = EndPoints.updatePreviousExam;
    try {
      const response = await instance2.post(customExamsURL, {
        customizedExamId: customExamId,
        simuleraQuizResult: customExamSessionResult,
      });
      return response;
    } catch (error) {
      handleErrors(error);
      return null;
    }
  };

  return {
    /** Values */
    allCustomExams,
    customExam,
    customExamSessions,

    /** Functions */
    customizedExams,
    customizedExamSessions,
    customizedExamSessionQuestions,
    submitExamSession,
    customExamResult,
  };
};
