/** Libraries */
import { createSlice } from "@reduxjs/toolkit";

export const examsSlice = createSlice({
  name: "exams",
  initialState: {
    previousExams: [],
    allPreviousExams: [],
    provpassOrderBySeason: {},
    provHistoryData: [],
    provpassSeasons: [],
  },
  reducers: {
    setExamsData: (state, action) => {
      state.previousExams = action.payload.previousExams;
      state.allPreviousExams = action.payload.allPreviousExams;
      state.provpassOrderBySeason = action.payload.provpassOrderBySeason;
    },
    setHistoryExamsData: (state, action) => {
      state.provHistoryData = action.payload.provHistoryData;
      state.provpassSeasons = action.payload.provpassSeasons;
    },
    searchExams: (state, action) => {
      const searchRegex = new RegExp(action.payload, "i");

      state.previousExams = [...state.allPreviousExams].filter((exam) =>
        searchRegex.test(exam.title)
      );
    },
    loadMoreExams: (state) => {
      state.previousExams = state.allPreviousExams;
    },
    loadLessExams: (state) => {
      state.previousExams = state.allPreviousExams.slice(0, 5);
    },
    setPreviousExams: (state, action) => {
      state.previousExams = action.payload;
    },
    examsLogout: (state) => {
      state.previousExams = [];
      state.allPreviousExams = [];
      state.provpassOrderBySeason = {};
      state.provHistoryData = [];
      state.provpassSeasons = [];
    },
  },
});

export const {
  setExamsData,
  setHistoryExamsData,
  searchExams,
  loadMoreExams,
  loadLessExams,
  setPreviousExams,
  examsLogout,
} = examsSlice.actions;
