/** Libraries */
import React, { useEffect, useRef } from "react";

import { Button } from "@mui/material";

/** Molecules */
import PressEnterContinue from "../../../../../components/molecule/Alerts/PressEnterContinue/PressEnterContinue";

const SubmitButton = ({
  isAnswerVisible,
  isSvaraDisabled,
  isNastaDisabled,
  handleSvaraOnClick,
  handleNastaOnClick,
}) => {
  /** Most of the logic here is to handle the enter submit functionality and scroll down and up */
  const svaraButtonRef = useRef(null);
  const nastaButtonRef = useRef(null);

  const scrollToBottom = () => {
    const nastaButton = nastaButtonRef.current;
    if (nastaButton) {
      nastaButton.scrollIntoView({ behavior: "smooth", block: "end" });
      window.scrollBy(0, 1000);
    }
  };

  const handleSvaraClickAndScroll = async () => {
    await handleSvaraOnClick();
    setTimeout(() => {
      scrollToBottom();
    }, 250);
  };

  const handleNastaClickAndScroll = () => {
    handleNastaOnClick();
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 250);
  };

  useEffect(() => {
    const handleEnterClick = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        if (!isAnswerVisible) {
          svaraButtonRef.current.click();
          setTimeout(() => {
            scrollToBottom();
          }, 250);
        } else {
          handleNastaClickAndScroll();
        }
      }
    };
    document.addEventListener("keydown", handleEnterClick);

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [isAnswerVisible]);

  return !isAnswerVisible ? (
    <PressEnterContinue isHidden={isSvaraDisabled} sx={{ maxWidth: "100%" }}>
      <Button
        ref={svaraButtonRef}
        disabled={isSvaraDisabled}
        variant="contained"
        onClick={handleSvaraClickAndScroll}
        sx={{ width: "100%", marginBottom: "4rem" }}
      >
        Svara
      </Button>
    </PressEnterContinue>
  ) : (
    <PressEnterContinue isHidden={isNastaDisabled} sx={{ maxWidth: "100%" }}>
      <Button
        ref={nastaButtonRef}
        disabled={isNastaDisabled}
        variant="contained"
        onClick={handleNastaClickAndScroll}
        sx={{ marginBottom: "4rem" }}
      >
        Nästa
      </Button>
    </PressEnterContinue>
  );
};

export default SubmitButton;
