/** Libraries */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

/** Styles */
import { MainContainer } from "./NoneAnsweredAlert.styles";

const NoneAnsweredAlert = () => {
  const theme = useTheme();
  return (
    <MainContainer maxWidth="sm">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <WarningAmberRoundedIcon
          style={{
            marginRight: "1rem",
            color: theme.palette.appcolors.tertiaryColor11,
          }}
        />
        <Typography
          variant="body1"
          style={{ fontSize: ".75rem", fontWeight: 500, margin: 0 }}
        >
          Tiden gick ut och du hann inte svara på denna fråga.
        </Typography>
      </Box>
    </MainContainer>
  );
};

export default NoneAnsweredAlert;
