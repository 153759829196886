import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledQuestionInfo = styled(Box)(
  ({ theme, isanswered, description, width, dtk }) => ({
    backgroundColor: "transparent",
    width: "100%",
    maxWidth: "900px",
    height: !isanswered ? "auto" : "min-content",
    position: "relative",
    borderRadius: "5px",
    scrollbarWidth: "thin",
    "& .MuiTypography-subtitle1": {
      columnCount: description?.length > 200 && width > 600 ? "2" : "1",
      fontSize: "16px !important",
      fontFamily: "Noto Sans" + " !important",
    },
    "& .MuiTypography-h6": {
      fontSize: "20px !important",
      fontFamily: "Noto Sans" + " !important",
    },

    [theme.breakpoints.down(1200)]: {
      // minHeight: !dtk && "600px",
      overflow: "auto",
      paddingTop: "0",
    },

    [theme.breakpoints.down(600)]: {
      //  minHeight: !dtk && "495px",
    },
  })
);

export const MQDialogTitle = styled(Box)(() => ({
  fontWeight: "500",
  maxWidth: "1080px",
  margin: "auto",
}));
