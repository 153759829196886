/** Libraries */
import React, { useEffect, useState } from "react";
import { Box, Collapse, Fade, Typography } from "@material-ui/core";
import { DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

/** Atoms */
import RulerButton from "../../../../../atom/RulerButton/RulerButton";
import MarkLatex from "../../../../../atom/Marklatex/MarkLatex";
import {
  DownArrowIcon,
  UpArrowIcon,
} from "../../../../../atom/CustomIcons/ArrowIcons";
import { StyledQuestionInfo } from "../../../../../atom/SharedExamExerciseStyledComponents/MQDoubleColumnText";
import { StyledQuestionContainer } from "../../../../../atom/SharedExamExerciseStyledComponents/SharedExamExerciseStyledComponents";
import {
  StyledCorrectIcon,
  StyledWrongIcon,
} from "../../../../../atom/RadioIcons/RadioIcons";

/** Molecules */
import QuestionStatement from "../../../../../molecule/QuestionStatement/QuestionStatement";
import ResultFooter from "../../../../../molecule/ResultFooter/ResultFooter";
import MultiAnswer from "../../../../../molecule/MultiAnswer/MultiAnswer";
import useWindowDimensions from "../../../../../molecule/WindowDimensions/dimension";
import NoneAnsweredAlert from "../../../../../molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";

/** Custom hooks */
import {
  useAiTutorStore,
  useExercisesMQReviewStore,
} from "../../../../../../hooks";

/** Utils */
import { appColors } from "../../../../../../utils/commonService";

/** Styles */
import {
  AnswerAndMultiQuestionsContainer,
  StyledAnswersReviewContainer,
  StyledContainer,
  AnswerCard,
  AnswerCardText,
  AnswerAndImageContainer,
  MQQuestionReviewContainer,
  TextAndButtonImageContainer,
  TextContainer,
  UppgiftText,
  ButtonImageContainer,
} from "./../QuestionViewDtkOrg/SharedDtkOrg.styles";
import { formatText } from "../../../../../../tools";

function MultiQuestionSummary(props) {
  const {
    currMultiQuestion,
    sectionTitle,
    totalQuestions,
    selectedIndex: MqResultSelectedIndex,
    startSettingQuiz,
    startShowingQuestion,
    startNavigationRight,
    startNavigationLeft,
  } = useExercisesMQReviewStore();

  const { isActive } = useAiTutorStore();

  const [question, setQuestion] = useState();
  const [questions, setQuestions] = useState();
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isFeedbackOpen = useSelector(
    (state) => state.feedbackQuestion.isFeedbackPopupOpened
  );

  useEffect(() => {
    startSettingQuiz({ quiz: props.quiz, selectedIndex: props.selectedIndex });
  }, []);

  useEffect(() => {
    setQuestions(currMultiQuestion.questions);

    return () => {
      setQuestions([]);
    };
  }, [currMultiQuestion.questions]);

  const handleAnswerBorder = (isCorrect) => {
    if (isCorrect) {
      return `3px solid ${theme.palette.appcolors.success4}`;
    } else {
      return `3px solid ${theme.palette.appcolors.error6}`;
    }
  };

  useEffect(() => {
    const handleEnterClick = (e) => {
      if (e.keyCode === 13 && !isActive) {
        startNavigationRight();
      }
    };
    !isFeedbackOpen && document.addEventListener("keydown", handleEnterClick);

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [isActive, isFeedbackOpen]);

  useEffect(() => {
    const handleKeyRight = (event) => {
      if (event.key === "ArrowRight" && !isActive) {
        startNavigationRight();
      }
    };

    window.addEventListener("keydown", handleKeyRight);

    return () => {
      window.removeEventListener("keydown", handleKeyRight);
    };
  }, [isActive]);

  useEffect(() => {
    const handleKeyLeft = (event) => {
      if (event.key === "ArrowLeft" && !isActive) {
        startNavigationLeft();
      }
    };

    window.addEventListener("keydown", handleKeyLeft);

    return () => {
      window.removeEventListener("keydown", handleKeyLeft);
    };
  }, [isActive]);

  return (
    <StyledContainer>
      {!question?.optionId && question?.answer && <NoneAnsweredAlert />}
      {/** Description info section - START */}
      <Fade in={true} style={{ transitionDelay: "50ms" }}>
        <StyledQuestionInfo
          paddingY={2}
          description={currMultiQuestion.description}
          width={width}
          dtk={sectionTitle === "DTK"}
        >
          {currMultiQuestion.description && (
            <QuestionStatement
              key={currMultiQuestion.description}
              description={currMultiQuestion.description}
              title={sectionTitle !== "DTK" && sectionTitle}
              width={width}
              isMQ={true}
            />
          )}
          {props.quiz?.image && (
            <>
              <DialogTitle
                style={{
                  padding: "0 5rem 2rem",
                  flex: "1",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {props.quiz && (
                  <QuestionStatement
                    numberOfQuestions={quiz?.question.length}
                    title={quiz?.title === "DTK" && quiz?.title}
                    description={quiz?.description}
                    isMQ={true}
                  />
                )}
                <Box>
                  <RulerButton />
                </Box>
              </DialogTitle>
              <div
                style={{
                  padding: "0 5rem 2rem",
                  display: "flex",
                  border: `1px solid ${appColors.valentine_red}`,
                }}
              >
                <Box style={{ width: "90%" }}>
                  <img src={quiz?.image} style={{ width: "100%" }} alt="" />
                </Box>
              </div>
            </>
          )}
        </StyledQuestionInfo>
      </Fade>
      {/** Description info section - END */}

      {/** Options section - START */}
      <StyledQuestionContainer>
        <Fade in={true} style={{ transitionDelay: "100ms" }}>
          <MQQuestionReviewContainer>
            <StyledAnswersReviewContainer>
              {questions &&
                questions?.map((item, index) => {
                  return (
                    <AnswerAndMultiQuestionsContainer key={index}>
                      <AnswerCard
                        sx={{
                          border: handleAnswerBorder(
                            item.optionId === item?.answer?.option
                          ),
                        }}
                        onClick={() => startShowingQuestion(index)}
                      >
                        <TextAndButtonImageContainer>
                          <AnswerAndImageContainer>
                            {width > 600 && (
                              <>
                                {item.optionId === item?.answer?.option ? (
                                  <StyledCorrectIcon />
                                ) : (
                                  <StyledWrongIcon />
                                )}
                              </>
                            )}

                            <TextContainer>
                              <UppgiftText>
                                {"Uppgift " +
                                  `${index + 1}` +
                                  " av " +
                                  currMultiQuestion.currTotalMultiQuestions}
                              </UppgiftText>
                              {item?.questionStatement?.includes(
                                "hp-appen.s3.eu-north-1.amazonaws.com"
                              ) ? (
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  className="questionImage"
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  <MarkLatex
                                    content={formatText(item.questionStatement)}
                                  />
                                </Typography>
                              ) : (
                                <AnswerCardText
                                  content={formatText(item.questionStatement)}
                                />
                              )}
                            </TextContainer>
                          </AnswerAndImageContainer>
                          <ButtonImageContainer>
                            {item.showResult ? (
                              <UpArrowIcon />
                            ) : (
                              <DownArrowIcon />
                            )}
                          </ButtonImageContainer>
                        </TextAndButtonImageContainer>
                      </AnswerCard>

                      <Collapse
                        style={{
                          width: "100%",
                          "& .MuiCollapseWrapper": { maxWidth: "100%" },
                        }}
                        orientation="vertical"
                        in={item.showResult}
                      >
                        <MultiAnswer
                          isAiEnabled={true}
                          indexMQ={index}
                          question={item}
                          selectOption={item.optionId}
                          resultComponent={true}
                          feedbackPopup={feedbackPopup}
                          setFeedbackPopup={setFeedbackPopup}
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                        />
                      </Collapse>
                    </AnswerAndMultiQuestionsContainer>
                  );
                })}
            </StyledAnswersReviewContainer>
            {/** Navigation buttons - START */}
            <ResultFooter
              questionLength={totalQuestions}
              questionIndex={MqResultSelectedIndex}
              onResultHandler={() => props.onResultHandler()}
              onLeftClick={() => {
                startNavigationLeft();
              }}
              onRightClick={() => {
                startNavigationRight();
              }}
            />
            {/** Navigation buttons - END */}
          </MQQuestionReviewContainer>
        </Fade>
      </StyledQuestionContainer>
      {/** Options section - END */}
    </StyledContainer>
  );
}

export default MultiQuestionSummary;
