/** Libraries */
import { createSlice, current } from "@reduxjs/toolkit";

export const publicTestMQSlice = createSlice({
  name: "publicTestMQ",
  initialState: {
    quiz: [],
    currentQuestion: null,
    selectedIndex: 0,
    totalQuestions: 0,
    selectedCurrMQIndex: null,
    selectedQuestion: null,
    isAnswerVisible: false,
    areCurrMQSelected: false,
    topBar: {
      progress: 0,
      prevNumQuestion: 0,
      currNumQuestion: 0,
    },
  },
  reducers: {
    setPublicTestMQQuiz: (state, action) => {
      const { quiz, questionIndex } = action.payload;
      const isAtReviewPage = questionIndex !== null;
      // console.log("isAtReviewPage: ", isAtReviewPage);
      // console.log("questionIndex: ", questionIndex);
      // console.log("quiz: ", quiz);
      if (!isAtReviewPage) {
        state.quiz = quiz;
        state.currentQuestion = quiz[0];
        state.selectedIndex = 0;
        state.selectedCurrMQIndex = 0;
        state.areCurrMQSelected = false;
        state.topBar.progress = 0;
        state.topBar.prevNumQuestion = 0;
        state.topBar.currNumQuestion = 0;
      } else {
        /** ######################################################################### */
        /** Here we find the index of the MultiQuestion group based on the selectedIndex */
        let questionGroupIndex = -1;
        let questionCounter = 0;

        for (let i = 0; i < quiz.length; i++) {
          const grupo = quiz[i];
          if (questionCounter + grupo.question.length > questionIndex) {
            questionGroupIndex = i;
            break;
          }
          questionCounter += grupo.question.length;
        }

        /** Text to show in the header/top bar */
        const firstDigit = questionCounter + 1;
        const secondDigit =
          questionCounter + quiz[questionGroupIndex].question.length;

        /** Set values */
        state.quiz = quiz;
        state.currentQuestion = quiz[questionGroupIndex];
        state.selectedIndex = questionGroupIndex;
        state.selectedCurrMQIndex = 0;
        state.areCurrMQSelected = false;
        state.topBar.progress = 100;
        state.topBar.prevNumQuestion = 0;
        state.topBar.currNumQuestion =
          quiz[questionGroupIndex].question.length > 1
            ? `${firstDigit} - ${secondDigit}`
            : secondDigit;
      }

      let totalIndexAcc = 0;
      for (let i = 0; i <= quiz.length - 1; i++) {
        totalIndexAcc += quiz[i].question.length;
      }
      state.totalQuestions = totalIndexAcc;
    },
    setSelectedQuestionPublicTestMQ: (state, action) => {
      const { selectedCurrMQIndex, selectedIndex, totalQuestions } = state;

      /** Topbar */
      // Log the condition to check if the current question doesn't have an optionId selected
      // console.log(
      //   "Condition: ",
      //   !state.quiz[selectedIndex].question[selectedCurrMQIndex]?.optionId
      // );
      if (!state.quiz[selectedIndex].question[selectedCurrMQIndex]?.optionId) {
        state.topBar.progress += 10;
      }

      /** Select option */
      state.quiz[selectedIndex].question[selectedCurrMQIndex].optionId =
        action.payload?.optionId;
      state.currentQuestion.question[selectedCurrMQIndex].optionId =
        action.payload?.optionId;
      // state.quiz[state.selectedIndex].selectedQuestion = action.payload;

      /** Enable svara button */
      const answerLenght = state.currentQuestion.question.filter(
        (item) => item.optionId
      ).length;
      if (answerLenght === state.currentQuestion.question.length) {
        state.areCurrMQSelected = true;
      }
    },
    setSelectedCurrPublicTestMQIndex: (state, action) => {
      const { currentQuestion, selectedIndex, topBar } = state;

      state.topBar.currNumQuestion =
        topBar.prevNumQuestion + action.payload + 1;
      state.selectedCurrMQIndex = action.payload;
    },
    setAnswerVisiblePublicTestMQ: (state, action) => {
      state.isAnswerVisible = true;

      state.quiz[state.selectedIndex].question = state.quiz[
        state.selectedIndex
      ].question.map((item) => ({
        ...item,
        ...action.payload,
      }));
      state.topBar.prevNumQuestion =
        state.topBar.prevNumQuestion + state.currentQuestion.question.length;
    },
    setPublicTestMQToShow: (state, action) => {
      // console.log("action.payload: ", action.payload);
      let question = state.currentQuestion.question[action.payload];
      if (question?.showResult) {
        state.currentQuestion.question[action.payload].showResult = false;
      } else {
        state.currentQuestion.question[action.payload].showResult = true;
      }
    },
    setNextQuestionPublicTestMQ: (state) => {
      if (state.topBar.currNumQuestion !== state.totalQuestions) {
        state.topBar.currNumQuestion = state.topBar.prevNumQuestion + 1;
        state.currentQuestion = state.quiz[state.selectedIndex + 1];
        state.selectedIndex += 1;
        state.selectedQuestion = null;
        state.selectedCurrMQIndex = 0;
        state.areCurrMQSelected = false;
        state.isAnswerVisible = false;
      }
    },
    setNextQuestionInReviewPublicTestMQ: (state) => {
      const { quiz } = state;
      if (state.selectedIndex + 1 !== state.quiz.length) {
        const nextGroup = quiz[state.selectedIndex + 1];
        state.currentQuestion = state.quiz[state.selectedIndex + 1];
        state.selectedIndex += 1;
        state.selectedCurrMQIndex = 0;
        state.areCurrMQSelected = false;
        state.isAnswerVisible = false;

        /** Calculate new topbar text */
        let totalIndexAcc = 0;
        for (let i = 0; i <= state.selectedIndex; i++) {
          totalIndexAcc += quiz[i].question.length;
        }

        /** Update questionText */
        const firstDigit = totalIndexAcc - nextGroup.question.length + 1;
        // console.log("firstDigit: ", firstDigit);
        // console.log("totalIndexAcc or secondDigit: ", totalIndexAcc);
        state.topBar.currNumQuestion =
          nextGroup.question.length > 1
            ? `${firstDigit} - ${totalIndexAcc}`
            : totalIndexAcc;
      }
    },
    setPreviusQuestionInReviewPublicTestMQ: (state) => {
      const { quiz } = state;
      if (state.selectedIndex !== 0) {
        const previousGroup = quiz[state.selectedIndex - 1];
        state.currentQuestion = state.quiz[state.selectedIndex - 1];
        state.selectedIndex -= 1;

        /** Calculate new topbar text */
        let totalIndexAcc = 0;
        for (let i = 0; i < state.selectedIndex; i++) {
          totalIndexAcc += quiz[i].question.length;
        }

        /** Update questionText */
        const firstDigit = totalIndexAcc + 1;
        const secondDigit = totalIndexAcc + previousGroup.question.length;
        state.topBar.currNumQuestion =
          previousGroup.question.length > 1
            ? `${firstDigit} - ${secondDigit}`
            : secondDigit;
      }
    },
    publicTestMQLogout: (state) => {
      state.quiz = [];
      state.currentQuestion = null;
      state.selectedIndex = 0;
      state.totalQuestions = 0;
      state.selectedQuestion = null;
      state.selectedCurrMQIndex = null;
      state.areCurrMQSelected = false;
      state.isAnswerVisible = false;
      state.topBar = {
        progress: 0,
        prevNumQuestion: 0,
        currNumQuestion: 0,
      };
    },
  },
});
export const {
  setPublicTestMQQuiz,
  setSelectedQuestionPublicTestMQ,
  setSelectedCurrPublicTestMQIndex,
  setAnswerVisiblePublicTestMQ,
  setNextQuestionPublicTestMQ,
  setNextQuestionInReviewPublicTestMQ,
  setPublicTestMQToShow,
  setPreviusQuestionInReviewPublicTestMQ,
  publicTestMQLogout,
} = publicTestMQSlice.actions;
