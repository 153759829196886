/** Libraries */
import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Collapse } from "@material-ui/core";

/** Atoms */
import { StyledExamButton } from "../../../components/atom/Button/Button.styles";

/** Organisms */
import ExerciseTopBar from "../../../components/organism/TopBars/ExerciseTopBar/ExerciseTopBar";
import ExamTopBar from "../../../components/organism/TopBars/ExamTopBar/ExamTopBar";
import { SQDescription } from "../../../components/organism/Quiz/descriptions/SQDescription/SQDescription";
import Options from "../../../components/organism/Quiz/Options/Options";
import { Answer } from "../../../components/organism/Quiz/Answer/Answer";

/** Templates */
import { SQTemplate } from "../../../components/template";

/** Custom hooks */
import { useQuestionViewerStore } from "../../../hooks/questionViewer/useQuestionViewerStore";

/** Ui */
import { SubmitButton } from "../../PublicTest/PublicTestSQ/ui/index";

/** Styles */
import { NavigationButtonText } from "./QuestionViewerSQ.styles";

const QuestionViewerSQ = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  console.log("pathname: ", pathname.split("/")[1]);
  const { question } = useQuestionViewerStore();

  return (
    <SQTemplate
      sx={{
        paddingTop: "0px",
      }}
      topBar={
        pathname.split("/")[1] === "exercise" ? (
          <ExerciseTopBar
            selectedIndex={1}
            totalQuestions={1}
            params={{ value: true }}
            status={false}
            time={false}
            nextPress={() => {}}
            onCloseTimer={() => {}}
            quiz={[question]}
            timeLeft={(timer) => {}}
            callBackForTimer={(value) => {}}
            goBack={() => navigate("/home")}
            resultSummary
          />
        ) : (
          <ExamTopBar
            time={undefined}
            setShouldNavigate={() => {}}
            currentIndex={0}
            quiz={{ question: [question] }}
            setBackPressPopup={() => {}}
            setTestSubmitPopUp={() => {}}
            provpassNumber={1}
            handleOpenOversikt={() => {}}
            handleLamnaIn={() => {}}
            isResultSection={true}
            handleNavigationResultPage={() => navigate("/home")}
          />
        )
      }
      description={
        <SQDescription
          sectionTitle={question?.sectionCategory.title}
          questionDescription={question?.questionStatement}
          questionImage={question?.questionStatement?.includes(
            "hp-appen.s3.eu-north-1.amazonaws.com"
          )}
          questionIndications={[question?.information1, question?.information2]}
          sx={{
            marginTop: pathname.split("/")[1] === "exercise" ? 0 : "4rem",
          }}
        />
      }
      options={
        <Options
          sectionTitle={question?.sectionCategory.title}
          options={question?.options[0].options}
          answerId={question?.answer?.option}
          answerExist={true}
          selectedOption={question?.selectedQuestion}
          handleSelectOption={() => {}}
        />
      }
      primaryButton={
        pathname.split("/")[1] === "exercise" ? (
          <SubmitButton
            isAnswerVisible={true}
            isSvaraDisabled={true}
            isNastaDisabled={true}
            handleSvaraOnClick={() => {}}
            handleNastaOnClick={() => {}}
          />
        ) : (
          <StyledExamButton disabled={true} onClick={() => {}}>
            Föregående
          </StyledExamButton>
        )
      }
      secondaryButton={
        pathname.split("/")[1] === "exam" ? (
          <StyledExamButton disabled={true} onClick={() => {}}>
            <NavigationButtonText>Nästa</NavigationButtonText>
          </StyledExamButton>
        ) : null
      }
      answer={
        <Collapse
          orientation="vertical"
          in={true}
          sx={{ width: "100%" }}
          unmountOnExit
        >
          <Answer
            isAiTutor={false}
            isFeedbackDisabled={true}
            content={question?.answer?.answer}
            questionId={question.questionId}
            takeScreenshot={true}
          />
        </Collapse>
      }
    />
  );
};

export default QuestionViewerSQ;
