import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box } from "@material-ui/core";

/** Atoms */
import { PressEnterIcon } from "../../atom/PressEnter/PressEnter";

/** Hooks */
import { useAiTutorStore, useUiStore } from "../../../hooks";

/** Sound Effect */
import buttonClick from "../../../assets/Sounds/buttonClick2.wav";

const MainContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  zIndex: 1060,
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "738px",
  padding: "0rem 0rem 3rem 0rem",
  display: "flex",
  gap: "1rem",
  [theme.breakpoints.down(1200)]: {
    padding: "1rem 1rem 3rem 1rem",
  },
}));

const LeftButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  width: "50%",
}));

const RightButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  width: "50%",
}));

const AnswerButton = styled("div")(({ theme, left, disabled }) => ({
  display: "flex",
  width: "100%",
  padding: "8px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  borderRadius: "16px",
  cursor: !disabled && left ? "default" : "pointer",
  border: `1px solid ${
    left
      ? !disabled
        ? theme.palette.appcolors.grey8
        : theme.palette.appcolors.primaryColor
      : theme.palette.appcolors.black3
  }`,
  borderBottomWidth: "3px",
  background: left
    ? !disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.secondaryColor4
    : theme.palette.appcolors.primaryColor,
  "&:hover": {
    transform: !disabled && left ? "none" : "scale(1.03)",
    transition: "all 0.3s ease-in-out",
  },
}));

const TextButton = styled("p")(({ theme, left, disabled }) => ({
  color: left
    ? !disabled
      ? theme.palette.appcolors.grey7
      : theme.palette.appcolors.primaryColor
    : theme.palette.appcolors.secondaryColor10,
  fontWeight: 600,
  userSelect: "none",
  [theme.breakpoints.down(600)]: {
    fontSize: "14px",
  },
}));

const EmptyStyleContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "90px",
  height: "32px",
  display: "flex",
  justifyContent: "flex-start",
  [theme.breakpoints.down(1200)]: {
    display: "none",
  },
}));

const MqContainerFull = styled(Box)(({ theme }) => ({
  width: "100%",
  zIndex: 1060,
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 0,
}));

const MqMainContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1600px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 32px",
  flexDirection: "row",
  margin: "0rem auto",
  [theme.breakpoints.up(1200)]: {
    gap: "72px",
  },
  [theme.breakpoints.down(1200)]: {
    padding: "0px",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down(600)]: {
    padding: "0px",
  },
}));

const MqVoidContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "900px",
  display: "flex",
  justifyContent: "flex-start",
  [theme.breakpoints.down(1200)]: {
    display: "none",
  },
}));

const MqButtonContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "466px",
  padding: "0rem 0rem 3rem 0rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  gap: "1rem",
  backgroundColor: theme.palette.appcolors.secondaryColor3,
  [theme.breakpoints.down(1200)]: {
    padding: "1rem 1rem 3rem 1rem",
    maxWidth: "930px",
    justifyContent: "flex-start",
  },
}));

export const ExamBottomBar = (props) => {
  const {
    isActive: isAIActive,
    switchAIKeyPress,
    startAiTutorLogout,
    isRequestInProcess,
  } = useAiTutorStore();
  const isFeedbackOpen = useSelector(
    (state) => state.feedbackQuestion.isFeedbackPopupOpened
  );
  const {
    startRerenderComponent,
    startRerenderComponentLeft,
    startRerenderComponentRight,
  } = useUiStore();

  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(buttonClick);
    audioRef.current.load();

    const handleEnterClick = (e) => {
      if (e.keyCode === 13 || e.key === "ArrowRight" || e.key === "ArrowLeft") {
        playSound();
        e.preventDefault();

        if (e.keyCode === 13 || e.key === "ArrowRight") {
          if (props?.currentIndex + 1 < props?.quiz.question.length) {
            props?.setCurrentIndex(props?.currentIndex + 1);
            startRerenderComponentRight();
            startRerenderComponent();
          }
          if (props?.currentIndex + 1 === props?.quiz.question.length) {
            props?.handleLamnaIn();
          }
        }

        if (e.key === "ArrowLeft") {
          if (props?.currentIndex > 0) {
            props?.setCurrentIndex(props?.currentIndex - 1);
            startRerenderComponentLeft();
            startRerenderComponent();
          }
        }
      }
    };

    if (!isFeedbackOpen && !isAIActive && !isRequestInProcess) {
      document.addEventListener("keydown", handleEnterClick);
    }

    return () => {
      document.removeEventListener("keydown", handleEnterClick);
    };
  }, [
    props?.quiz,
    props?.currentIndex,
    props,
    isFeedbackOpen,
    isAIActive,
    isRequestInProcess,
  ]);

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Error playing sound:", error);
      });
    }
  };

  const handleBackClick = () => {
    playSound();
    props?.currentIndex > 0 && props?.setCurrentIndex(props?.currentIndex - 1);
    startRerenderComponentLeft();
    startRerenderComponent();
  };

  const handleForwardClick = () => {
    playSound();
    if (props?.isLastQuestion) {
      props?.handleLamnaIn();
    } else {
      props?.setCurrentIndex(props?.currentIndex + 1);
      startRerenderComponentRight();
      startRerenderComponent();
    }
  };

  return props?.mq ? (
    <MqContainerFull>
      <MqMainContainer>
        <MqVoidContainer />
        <MqButtonContainer>
          <LeftButtonContainer>
            <EmptyStyleContainer />
            <AnswerButton
              left
              disabled={props?.currentIndex > 0}
              onClick={handleBackClick}
            >
              <TextButton left disabled={props?.currentIndex > 0}>
                Föregående
              </TextButton>
            </AnswerButton>
          </LeftButtonContainer>
          <RightButtonContainer>
            <PressEnterIcon />
            <AnswerButton onClick={handleForwardClick}>
              <TextButton>
                {props?.isLastQuestion
                  ? props?.review
                    ? "Rättad Överblick"
                    : "Lämna in"
                  : "Nästa"}
              </TextButton>
            </AnswerButton>
          </RightButtonContainer>
        </MqButtonContainer>
      </MqMainContainer>
    </MqContainerFull>
  ) : (
    <MainContainer>
      <ButtonContainer>
        <LeftButtonContainer>
          <EmptyStyleContainer />
          <AnswerButton
            left
            disabled={props?.currentIndex > 0}
            onClick={handleBackClick}
          >
            <TextButton left disabled={props?.currentIndex > 0}>
              Föregående
            </TextButton>
          </AnswerButton>
        </LeftButtonContainer>
        <RightButtonContainer>
          <PressEnterIcon />
          <AnswerButton onClick={handleForwardClick}>
            <TextButton>
              {props?.isLastQuestion
                ? props?.review
                  ? "Rättad Överblick"
                  : "Lämna in"
                : "Nästa"}
            </TextButton>
          </AnswerButton>
        </RightButtonContainer>
      </ButtonContainer>
    </MainContainer>
  );
};
