/** Libraries */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** MUI styled elements */
export const MainContainer = styled(Box)(({ theme }) => ({
  minWidth: "100%",
  maxWidth: 615,
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  minWidth: "100%",
  maxWidth: 615,
  display: "flex",
  justifyContent: "space-between",
  marginTop: ".75rem",
  marginBottom: "1.5rem",
  gap: "5px",
}));

export const ButtonItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  gap: "5px",
}));

export const FontText = styled("h6")(({ theme }) => ({
  margin: 0,
  padding: 0,
  fontSize: "0.75rem",
  textTransform: "uppercase",
  color: theme.palette.appcolors.grey,
}));
