/** Libraries */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

/** Atoms */
import { NogOptionsInfo } from "../../../atom/NogInfoContainer/NogInfoContainer";

/** Molecules */
import OptionRowCard from "../../../molecule/Cards/OptionRowCard/OptionRowCard";
import OptionImageCard from "../../../molecule/Cards/OptionImageCard/OptionImageCard";

/** Styles */
import {
  MainContainer,
  ImageOptionsContainer,
  RowOptionsContainer,
} from "./Options.styles";
import { formatText } from "../../../../tools";

const Options = ({
  sectionTitle,
  areBorderColorsVisible,
  options,
  answerId,
  answerExist,
  selectedOption,
  handleSelectOption,
  hiddeCorrectChoice,
}) => {
  const isImageOption = useMemo(
    () => options[0]?.value.includes("hp-appen.s3.eu-north-1.amazonaws.com"),
    [options[0]]
  );
  const isNogCategory = useMemo(() => {
    if (sectionTitle) {
      if (sectionTitle?.toUpperCase() === "NOG") {
        return true;
      }
    }

    return false;
  }, [sectionTitle]);

  return (
    <MainContainer>
      {isNogCategory && <NogOptionsInfo />}
      {isImageOption ? (
        <ImageOptionsContainer>
          {options?.map((option, optionIndex) => {
            return (
              <OptionImageCard
                key={option?._id}
                optionId={option?._id}
                content={formatText(option?.value)}
                optionIndex={optionIndex}
                isCorrect={answerExist && option?._id === answerId}
                isIncorrect={
                  answerExist &&
                  option?._id !== answerId &&
                  option?._id === selectedOption
                }
                isSelected={option?._id === selectedOption}
                answerExist={answerExist}
                handleSelectOption={handleSelectOption}
                hiddeCorrectChoice={hiddeCorrectChoice}
                areBorderColorsVisible={areBorderColorsVisible}
              />
            );
          })}
        </ImageOptionsContainer>
      ) : (
        <RowOptionsContainer>
          {options?.map((option, optionIndex) => {
            return (
              <OptionRowCard
                key={option?._id}
                optionId={option?._id}
                content={formatText(option?.value)}
                optionIndex={optionIndex}
                isCorrect={answerExist && option?._id === answerId}
                isIncorrect={
                  answerExist &&
                  option?._id !== answerId &&
                  option?._id === selectedOption
                }
                isSelected={option?._id === selectedOption}
                answerExist={answerExist}
                handleSelectOption={handleSelectOption}
                hiddeCorrectChoice={hiddeCorrectChoice}
                areBorderColorsVisible={areBorderColorsVisible}
              />
            );
          })}
        </RowOptionsContainer>
      )}
    </MainContainer>
  );
};

Options.propTypes = {
  sectionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  areBorderColorsVisible: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  answerId: PropTypes.string.isRequired,
  hiddeCorrectChoice: PropTypes.bool,
  answerExist: PropTypes.bool,
  selectedOption: PropTypes.string,
  handleSelectOption: PropTypes.func.isRequired,
};

Options.defaultProps = {
  sectionTitle: false,
  areBorderColorsVisible: true,
  hiddeCorrectChoice: false,
  answerExist: false,
  selectedOption: null,
};

export default Options;
