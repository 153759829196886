import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { appColors } from "../../../utils/commonService";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down(600)]: {
    "& .MuiPaper-root": {
      minWidth: "97.5vw",
    },
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
}));

const StyledDialogContentTexts = styled(DialogContent)(({ theme }) => ({
  "&.MuiDialogContent-root": {
    padding: "2rem 5rem",
  },
  [theme.breakpoints.down(600)]: {
    "&.MuiDialogContent-root": {
      padding: 0,
    },
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down(405)]: {
    "&.MuiTypography-root": {
      fontSize: "1.6rem",
    },
  },
  [theme.breakpoints.down(284)]: {
    "&.MuiTypography-root": {
      fontSize: "1.3rem",
    },
  },
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down(405)]: {
    "&.MuiTypography-root": {
      fontSize: "0.75rem",
    },
  },
  [theme.breakpoints.down(284)]: {
    "&.MuiTypography-root": {
      fontSize: "0.65rem",
    },
  },
}));

export default function FeedbackPopup(props) {
  return (
    <div>
      <BootstrapDialog
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
        style={{
          textAlign: "center",
        }}
      >
        <StyledDialogContent>
          <IconButton
            aria-label="close"
            onClick={props.onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </StyledDialogContent>
        <StyledDialogContentTexts>
          <StyledTitle variant="h4">Tack för din feedback.</StyledTitle>
          <StyledDescription
            gutterBottom
            variant="body2"
            style={{ margin: "1rem 0" }}
          >
            Vi kommer att använda den för att förbättra vår app i framtiden.
          </StyledDescription>
        </StyledDialogContentTexts>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            autoFocus
            onClick={props.onClose}
            style={{
              backgroundColor: appColors.blueColor,
              color: appColors.whiteColor,
              textTransform: "capitalize",
              fontWeight: "regular",
              padding: ".55rem 2rem",
              marginBottom: "2rem",
            }}
          >
            Tillbaka
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
