import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableHistory: [],
  lastSevenWeeksData: {
    prognos: null,
    lastWeeksDataForGraph: [],
  },
  lastSevenWeeksCorrected: {
    currentWeekCorrect: null,
    correctedQuestionPerCategory: null,
    lastWeeksCorrectForGraph: [],
  },
};

export const categoryDetailsSlice = createSlice({
  name: "categoryDetails",
  initialState,
  reducers: {
    setTableHistory: (state, action) => {
      state.tableHistory = action.payload;
    },
    setLastSevenWeeksData: (state, action) => {
      state.lastSevenWeeksData = action.payload;
    },
    setLastSevenWeeksCorrected: (state, action) => {
      state.lastSevenWeeksCorrected = action.payload;
    },
  },
});

export const {
  setTableHistory,
  setLastSevenWeeksData,
  setLastSevenWeeksCorrected,
} = categoryDetailsSlice.actions;

export default categoryDetailsSlice.reducer;
