import { styled } from "@mui/material/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export const StyledCloseIcon = styled(CloseRoundedIcon)(({ theme }) => ({
  cursor: "pointer",
  fontSize: "30px",
  color: theme.palette.appcolors.grey,
  "&:hover": {
    color: theme.palette.appcolors.primaryColor,
    transform: "scale(1.1)",
    transition: "all 0.3s ease",
  },
  [theme.breakpoints.down(600)]: {
    marginRight: "0rem",
    fontSize: "22px",
  },
}));

export const StyledCloseIconRight = styled(CloseRoundedIcon)(({ theme }) => ({
  cursor: "pointer",
  position: "absolute",
  right: "10px",
  top: "10px",
  fontSize: "24px",
  color: theme.palette.appcolors.grey,
  "&:hover": {
    color: theme.palette.appcolors.primaryColor,
    transform: "scale(1.2)",
    transition: "all 0.2s ease-in-out",
  },
  [theme.breakpoints.down(600)]: {
    fontSize: "20px",
  },
}));
