import React from "react";
import Slide from "@material-ui/core/Slide";
import { keyframes } from "@emotion/react";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const slideInFromRight = keyframes`
  from {
    opacity: 0.75;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideInFromLeft = keyframes`
  from {
    opacity: 0.75;
    transform: translateX(-5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const appearFromMiddle = keyframes`
  from {
    opacity: 0.75;
    transform: scale(0.75);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;
