import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { appColors } from "../../../utils/commonService";

const AppLoader = ({ isLoading }) => {
  return (
    <Backdrop
      sx={{
        color: appColors.whiteColor,
        zIndex: (theme) => theme.zIndex.drawer + 250,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" size="5rem" />
    </Backdrop>
  );
};

export default AppLoader;
