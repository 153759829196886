import { useEffect, useState } from "react";
import GridLayout from "../../components/organism/GridOrg/GridLayout";
import LeftBar from "../../components/organism/LeftBarOrg/LeftBar";
import { SQDescription } from "../../components/organism/Quiz/descriptions/SQDescription/SQDescription";
import LabelField from "../../components/molecule/LabelField/LabelField";
import { Box, TextField, TextareaAutosize } from "@mui/material";

const Admin = () => {
  const [content, setContent] = useState("");

  //Change to $ signs on delimiters. extremely important for rendering LaTex correctly on our component, it pre-process the response from the API.
  const latexDelimiters = content
    ?.replace(/\\\(.*?\\\)/gs, (match) => `$${match.slice(2, -2).trim()}$`)
    .replace(/\\\[.*?\\\]/gs, (match) => `$$${match.slice(2, -2).trim()}$$`);

  const handleInputChange = (e) => {
    setContent(e.target.value.trim()); // Trim the input value
    console.log(JSON.stringify(e.target.value.trim()));
  };
  const renderMarkdownViewer = () => {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "2.5rem",
          marginBottom: "5rem",
          gap: "2.5rem",
        }}
      >
        <TextareaAutosize
          type="text"
          color="primary"
          placeholder="Write some markdown here"
          name="content"
          onChange={handleInputChange}
          value={content}
          style={{
            overflow: "auto",
            resize: "none",
            maxWidth: "1000px",
            fontSize: "16px",
            border: "1px solid",
            borderRadius: "5px",
            width: "100%",
            minWidth: "128px",
            margin: "10px 0px",
            minHeight: "350px",
            outline: "none",
          }}
        />
        <SQDescription
          sx={{
            maxWidth: "1000px",
          }}
          sectionTitle={""}
          questionDescription={latexDelimiters}
          questionImage={content?.includes(
            "hp-appen.s3.eu-north-1.amazonaws.com"
          )}
        />
      </Box>
    );
  };

  return (
    <GridLayout
      leftBar={<LeftBar currentPage="admin" />}
      middle={renderMarkdownViewer()}
    />
  );
};

export default Admin;
