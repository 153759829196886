/** Libraries */
import React from "react";
import PropTypes from "prop-types";

/** Styles */
import {
  MQTemplateContainer,
  TopbarContainer,
  BannerContainer,
  BodyContainer,
  DialogContainer,
  QuestionsContainer,
} from "./MQTemplate.styles";

const MQTemplate = ({
  topBar,
  banner,
  nonAnsweredAlert,
  dialogBox,
  questions,
  ...props
}) => {
  return (
    <MQTemplateContainer {...props}>
      <TopbarContainer>{topBar}</TopbarContainer>
      {banner && <BannerContainer>{banner}</BannerContainer>}
      <BodyContainer>
        <DialogContainer nonAnsweredQuestion={!!nonAnsweredAlert}>
          {nonAnsweredAlert && nonAnsweredAlert}
          {dialogBox}
        </DialogContainer>
        <QuestionsContainer>{questions}</QuestionsContainer>
      </BodyContainer>
    </MQTemplateContainer>
  );
};

MQTemplate.propTypes = {
  topBar: PropTypes.element.isRequired,
  banner: PropTypes.element,
  nonAnsweredAlert: PropTypes.element,
  dialogBox: PropTypes.element.isRequired,
  questions: PropTypes.element.isRequired,
};

export default MQTemplate;
