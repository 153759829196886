/** Libraries */
import styled from "@mui/material/styles/styled";
import React from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
require("dotenv").config();

/** Organisms */
import Menus from "./Menus";

/** Custom hooks */
import { useMixpanel, usePersistedStore } from "../../../hooks";

/** Molecules */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";

/** Assets */
import { ReactComponent as Logo } from "../../../assets/Icons/Logo.svg";
import { ReactComponent as LogoIcon } from "../../../assets/Icons/LogoIcon.svg";
import { ReactComponent as DiscordIcon } from "../../../assets/Icons/DiscordIcon.svg";

/** Material UI - Custom elements */
const MainContainer = styled("div")(({ theme }) => ({
  zIndex: 1300,
  width: "262px",
  height: "100%",
  borderRight: `1px solid ${theme.palette.appcolors.grey5}`,
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  position: "sticky",
  top: 0,
  [theme.breakpoints.down(960)]: {
    width: "6rem",
  },
  [theme.breakpoints.down("600")]: {
    position: "fixed",
    top: 0,
    width: "253px",
  },
}));

const LogoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "2rem 1.5rem 2rem 0.5rem",
  [theme.breakpoints.down(960)]: {
    padding: "1rem",
  },
  [theme.breakpoints.down(600)]: {
    padding: "2rem 1.5rem 1rem 2rem",
    width: "80%",
  },
}));

const DiscordContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.discord,
  paddingTop: "1rem",
  paddingBottom: "1rem",
  borderRadius: "4px",
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  cursor: "pointer",
  width: "216px",
  color: theme.palette.appcolors.secondaryColor10,
}));

const DiscordText = styled("p")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  margin: "0.5rem 2rem",
  marginTop: "0rem",
  [theme.breakpoints.up(600)]: {
    margin: "0.5rem",
  },
}));

const DiscordButton = styled("a")(({ theme }) => ({
  display: "flex",
  textDecoration: "none",
  borderRadius: "5px",
  border: "0px",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
  width: "171px",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  borderBottom: `3px solid ${theme.palette.appcolors.grey4}`,
}));

const DiscordButtonText = styled("p")(({ theme }) => ({
  fontSize: "16px",
  textTransform: "initial",
  color: theme.palette.appcolors.primaryColor2,
  fontWeight: "600",
  "&:hover": {
    fontSize: "17px",
  },
}));

const DiscordLogo = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: "35px",
}));

const MainDarkmodeContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  margin: "auto",
  cursor: "pointer",
  "& p": {
    color: theme.palette.appcolors.black,
  },
}));

const DarkmodeIconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "30px",
  height: "30px",
  backgroundColor: theme.palette.appcolors.grey14,
  borderRadius: "50%",
  marginRight: "10px",
  marginLeft: "1.5rem",
  [theme.breakpoints.down(960)]: {
    marginLeft: "1rem",
  },
}));

const DarkmodeTitle = styled("p")(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down(960)]: {
    display: "none",
  },
  [theme.breakpoints.down(600)]: {
    display: "flex",
  },
}));

const LeftBar = (props) => {
  const currentPage = useLocation().pathname;
  const mixpanel = useMixpanel();
  const { width, height } = useWindowDimensions();
  const theme = useTheme();
  const { darkmode, startUiDarkmode } = usePersistedStore();

  return (
    <MainContainer>
      <LogoContainer>
        {width <= 600 || width >= 960 ? (
          <Logo fill={theme.palette.appcolors.primaryColor} />
        ) : (
          <LogoIcon fill={theme.palette.appcolors.primaryColor} />
        )}
      </LogoContainer>

      <Menus
        currentPage={currentPage}
        toggleIcon={props.toggleIcon}
        setToggleIcon={props.setToggleIcon}
      />

      {width >= 960 || width <= 600 ? (
        <DiscordContainer>
          {height >= 750 && (
            <DiscordIcon
              fill={theme.palette.appcolors.secondaryColor10}
              style={{ marginBottom: "0.5rem" }}
            />
          )}
          <DiscordText>
            Få support {height >= 750 && `och ge feedback i vår Discordgrupp.`}
          </DiscordText>
          <DiscordButton
            href="https://discord.gg/rXnzaPsaj9"
            target="_blank"
            onClick={() => {
              mixpanel.buttonClicked("Discord-1");
            }}
          >
            <DiscordButtonText>Gå med i Discord</DiscordButtonText>
          </DiscordButton>
        </DiscordContainer>
      ) : (
        <a
          href="https://discord.gg/rXnzaPsaj9"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            mixpanel.buttonClicked("Discord-2");
          }}
        >
          <DiscordLogo>
            <DiscordIcon fill={theme.palette.appcolors.primaryColor2} />
          </DiscordLogo>
        </a>
      )}
      <MainDarkmodeContainer
        onClick={() => startUiDarkmode()}
        sx={{ padding: height >= 750 ? "2rem 1rem" : "1rem" }}
      >
        <DarkmodeIconBox>
          {darkmode ? (
            <LightModeRoundedIcon
              sx={{
                color: theme.palette.appcolors.secondaryColor10,
                padding: "2px",
              }}
            />
          ) : (
            <DarkModeRoundedIcon
              sx={{
                color: theme.palette.appcolors.secondaryColor10,
                padding: "2px",
              }}
            />
          )}
        </DarkmodeIconBox>
        <DarkmodeTitle>{darkmode ? "Ljust" : "Mörkt"} läge</DarkmodeTitle>
      </MainDarkmodeContainer>
    </MainContainer>
  );
};

export default LeftBar;
