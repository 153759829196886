import {
  InstructionsContainer,
  InstructionsTitle,
  SectionTitle,
  SectionSubtitle,
} from "../../atom/SharedInfoStyledComponents/SharedInfoStyledComponents";

export const HeltProvInfo = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Instruktioner</InstructionsTitle>
      <SectionTitle>Fyra provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Denna prov består av fyra provpass. Frågorna väljs ut slumpmässigt från
        vår databas, med prioritet på frågor som du inte tidigare har besvarat i
        HP-appen, förutsatt att sådana finns tillgängliga.
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Du kommer alltså att få möta obekanta frågor, precis som på det riktiga
        provet.
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Varje pass är 55 minuter långt med normal provtid, alternativt 80
        minuter med förlängd provtid. Du kommer att skriva två kvantitativa
        provpass, två verbala provpass.
      </SectionSubtitle>

      <SectionTitle>Kvantitativt provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Varje kvantitativt provpass består av uppgifter från fyra olika delprov:
      </SectionSubtitle>
      <SectionSubtitle>
        XYZ, matematisk problemlösning: 12 uppgifter
      </SectionSubtitle>
      <SectionSubtitle>
        KVA, kvantitativa jämförelser: 10 uppgifter
      </SectionSubtitle>
      <SectionSubtitle>
        NOG, kvantitativa resonemang: 6 uppgifter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        DTK, diagram, tabeller och kartor: 12 uppgifter
      </SectionSubtitle>

      <SectionTitle>Verbalt provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Varje verbalt provpass består av uppgifter från fyra olika delprov:
      </SectionSubtitle>

      <SectionSubtitle>ORD, ordförståelse: 10 uppgifter</SectionSubtitle>
      <SectionSubtitle>LÄS, svensk läsförståelse: 10 uppgifter</SectionSubtitle>
      <SectionSubtitle>MEK, meningskomplettering: 10 uppgifter</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        ELF, engelsk läsförståelse: 10 uppgifter
      </SectionSubtitle>

      <SectionTitle>Lämna in provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        När provpasset är slut avbryts provpasset automatiskt och ditt resultat
        registreras. Poängen för provet redovisas först efter att du gjort
        provets samtliga provpass. Mellan provpassen har du möjlighet att ta
        paus. Om du avbryter provpasset utan att lämna in får du ingen poäng,
        men du kan göra om provpasset senare.
      </SectionSubtitle>
      <SectionTitle>Hjälpmedel</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Du får inte använda miniräknare eller några andra digitala hjälpmedel på
        provet. Kladdpapper får du däremot använda!
      </SectionSubtitle>
      <SectionTitle>Rekommendationer</SectionTitle>
      <SectionSubtitle>
        Stressa inte men stanna inte heller länge på en uppgift du fastnat på.
        Tidsbegränsningen är en utmaning på Högskoleprovet, men det är precis
        därför det är så bra att träna på! Lycka till!
      </SectionSubtitle>
    </InstructionsContainer>
  );
};

export const VerbaltProvInfo = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Instruktioner</InstructionsTitle>
      <SectionTitle>Ett provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Provpasset innehåller 40 uppgifter och den totala provtiden är 55
        minuter långt med normal provtid, alternativt 80 minuter med förlängd
        provtid.
      </SectionSubtitle>

      <SectionTitle>Ord - Ordförståelse</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 3 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        I delprovet ORD inleds varje uppgift med ett ord i fetstil. Under detta
        finns fem svarsförslag. Välj det svarsförslag som bäst motsvarar
        innebörden av det fetstilta ordet.
      </SectionSubtitle>

      <SectionTitle>LÄS - Svenska läsförståelse</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 22 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet LÄS består av svenska texter från olika ämnesområden och av
        varierande längd. Varje uppgift består av en fråga med fyra
        svarsförslag, varav ett är rätt.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Ibland kan du klart se att ett av svarsförslagen är rätt och att de
        andra är fel. Ibland verkar först flera svar mer eller mindre rimliga.
        Då måste du, genom att jämföra de olika svarsförslagen, välja ut det som
        bäst besvarar frågan.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Observera att du ska lösa uppgifterna med ledning av den information som
        ges i texten.
      </SectionSubtitle>

      <SectionTitle>MEK - Meningskomplettering</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 8 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Uppgifterna i delprovet MEK består av korta textstycken där ett eller
        flera ord ersatts av en lucka markerad med _____. En uppgift kan
        innehålla en, två eller tre luckor.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Efter varje textstycke följer fyra svarsförslag. Välj det svarsförslag
        som innehållsligt och språkligt passar bäst in i textstycket som helhet.
      </SectionSubtitle>

      <SectionTitle>ELF - Engelsk läsförståelse</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 22 minuter
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Delprovet ELF består av engelska texter från olika ämnesområden och av
        varierande längd. Varje uppgift består av en fråga med fyra
        svarsförslag, varav ett är rätt.
      </SectionSubtitle>

      <SectionSubtitle>
        Ibland kan du klart se att ett av svarsförslagen är rätt och att de
        andra är fel. Ibland verkar först flera svar mer eller mindre rimliga.
        Då måste du, genom att jämföra de olika svarsförslagen, välja ut det som
        bäst besvarar frågan.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Observera att du ska lösa uppgifterna med ledning av den information som
        ges i respektive text.
      </SectionSubtitle>

      <SectionTitle>Rekommendationer</SectionTitle>
      <SectionSubtitle>
        Stressa inte men stanna inte heller länge på en uppgift du fastnat på.
        Tidsbegränsningen är en utmaning på Högskoleprovet, men det är precis
        därför det är så bra att träna på! Lycka till!
      </SectionSubtitle>
    </InstructionsContainer>
  );
};

export const SvårtVerbaltProvInfo = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Instruktioner</InstructionsTitle>
      <SectionTitle>Ett provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Detta prov innehåller endast svåra frågor från den verbala delen.
        Provpasset innehåller 40 uppgifter och den totala provtiden är 55
        minuter långt med normal provtid, alternativt 80 minuter med förlängd
        provtid.
      </SectionSubtitle>

      <SectionTitle>Ord - Ordförståelse</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 3 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        I delprovet ORD inleds varje uppgift med ett ord i fetstil. Under detta
        finns fem svarsförslag. Välj det svarsförslag som bäst motsvarar
        innebörden av det fetstilta ordet.
      </SectionSubtitle>

      <SectionTitle>LÄS - Svenska läsförståelse</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 22 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet LÄS består av svenska texter från olika ämnesområden och av
        varierande längd. Varje uppgift består av en fråga med fyra
        svarsförslag, varav ett är rätt.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Ibland kan du klart se att ett av svarsförslagen är rätt och att de
        andra är fel. Ibland verkar först flera svar mer eller mindre rimliga.
        Då måste du, genom att jämföra de olika svarsförslagen, välja ut det som
        bäst besvarar frågan.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Observera att du ska lösa uppgifterna med ledning av den information som
        ges i texten.
      </SectionSubtitle>

      <SectionTitle>MEK - Meningskomplettering</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 8 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Uppgifterna i delprovet MEK består av korta textstycken där ett eller
        flera ord ersatts av en lucka markerad med _____. En uppgift kan
        innehålla en, två eller tre luckor.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Efter varje textstycke följer fyra svarsförslag. Välj det svarsförslag
        som innehållsligt och språkligt passar bäst in i textstycket som helhet.
      </SectionSubtitle>

      <SectionTitle>ELF - Engelsk läsförståelse</SectionTitle>
      <SectionSubtitle> 10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 22 minuter
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Delprovet ELF består av engelska texter från olika ämnesområden och av
        varierande längd. Varje uppgift består av en fråga med fyra
        svarsförslag, varav ett är rätt.
      </SectionSubtitle>

      <SectionSubtitle>
        Ibland kan du klart se att ett av svarsförslagen är rätt och att de
        andra är fel. Ibland verkar först flera svar mer eller mindre rimliga.
        Då måste du, genom att jämföra de olika svarsförslagen, välja ut det som
        bäst besvarar frågan.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Observera att du ska lösa uppgifterna med ledning av den information som
        ges i respektive text.
      </SectionSubtitle>

      <SectionTitle>Rekommendationer</SectionTitle>
      <SectionSubtitle>
        Stressa inte men stanna inte heller länge på en uppgift du fastnat på.
        Tidsbegränsningen är en utmaning på Högskoleprovet, men det är precis
        därför det är så bra att träna på! Lycka till!
      </SectionSubtitle>
    </InstructionsContainer>
  );
};

export const KvantitativtProvInfo = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Instruktioner</InstructionsTitle>
      <SectionTitle>Ett provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Provpasset innehåller 40 uppgifter och den totala provtiden är 55
        minuter långt med normal provtid, alternativt 80 minuter med förlängd
        provtid.
      </SectionSubtitle>

      <SectionTitle>XYZ - Matematisk problemlösning</SectionTitle>
      <SectionSubtitle>12 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 12 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet XYZ handlar om matematisk problemlösning. Varje uppgift består
        av en fråga som följs av fyra svarsalternativ, varav endast ett är rätt.
      </SectionSubtitle>

      <SectionTitle>KVA - Kvantitativa jämförelser</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 10 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet KVA innehåller uppgifter med beskrivningar av två kvantiteter,
        I och II. Din uppgift är att jämföra de två kvantiteterna. I vissa fall
        ges inledande information som ska användas vid jämförelsen.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Till varje uppgift finns fyra svarsalternativ, varav endast ett är rätt.
        I KVA har alla uppgifter samma svarsalternativ.
      </SectionSubtitle>

      <SectionTitle>NOG - Kvantitativa resonemang</SectionTitle>
      <SectionSubtitle>6 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 10 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet NOG består av uppgifter med en fråga följd av två påståenden,
        (1) och (2), som innehåller information. Frågan kan ibland föregås av
        viss inledande information.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Din uppgift är att avgöra om frågan entydigt kan besvaras med hjälp av
        informationen i påståendena, och i så fall hur mycket av denna
        information som är tillräcklig. Till varje uppgift finns fem
        svarsalternativ, varav endast ett är rätt. I NOG har alla uppgifter
        samma svarsalternativ.
      </SectionSubtitle>

      <SectionTitle>DTK - Diagram, tabeller och kartor</SectionTitle>
      <SectionSubtitle>12 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 23 minuter
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Delprovet DTK innehåller diagram, tabeller, kartor och andra grafiska
        framställningar. Uppgifterna ska lösas med hjälp av den information som
        finns på respektive uppslag.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Till varje uppgift finns det fyra svarsförslag. Välj det som bäst
        besvarar frågan.
      </SectionSubtitle>

      <SectionTitle>Rekommendationer</SectionTitle>
      <SectionSubtitle>
        Stressa inte men stanna inte heller länge på en uppgift du fastnat på.
        Tidsbegränsningen är en utmaning på Högskoleprovet, men det är precis
        därför det är så bra att träna på! Lycka till!
      </SectionSubtitle>
    </InstructionsContainer>
  );
};

export const SvårtKvantitativtProvInfo = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Instruktioner</InstructionsTitle>
      <SectionTitle>Ett provpass</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Detta prov innehåller endast svåra frågor från den kvantitativa delen.
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Provpasset innehåller 40 uppgifter och den totala provtiden är 55
        minuter långt med normal provtid, alternativt 80 minuter med förlängd
        provtid.
      </SectionSubtitle>

      <SectionTitle>XYZ - Matematisk problemlösning</SectionTitle>
      <SectionSubtitle>12 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 12 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet XYZ handlar om matematisk problemlösning. Varje uppgift består
        av en fråga som följs av fyra svarsalternativ, varav endast ett är rätt.
      </SectionSubtitle>

      <SectionTitle>KVA - Kvantitativa jämförelser</SectionTitle>
      <SectionSubtitle>10 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 10 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet KVA innehåller uppgifter med beskrivningar av två kvantiteter,
        I och II. Din uppgift är att jämföra de två kvantiteterna. I vissa fall
        ges inledande information som ska användas vid jämförelsen.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Till varje uppgift finns fyra svarsalternativ, varav endast ett är rätt.
        I KVA har alla uppgifter samma svarsalternativ.
      </SectionSubtitle>

      <SectionTitle>NOG - Kvantitativa resonemang</SectionTitle>
      <SectionSubtitle>6 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 10 minuter
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Delprovet NOG består av uppgifter med en fråga följd av två påståenden,
        (1) och (2), som innehåller information. Frågan kan ibland föregås av
        viss inledande information.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Din uppgift är att avgöra om frågan entydigt kan besvaras med hjälp av
        informationen i påståendena, och i så fall hur mycket av denna
        information som är tillräcklig. Till varje uppgift finns fem
        svarsalternativ, varav endast ett är rätt. I NOG har alla uppgifter
        samma svarsalternativ.
      </SectionSubtitle>

      <SectionTitle>DTK - Diagram, tabeller och kartor</SectionTitle>
      <SectionSubtitle>12 uppgifter.</SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Rekommenderad provtid: 23 minuter
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Delprovet DTK innehåller diagram, tabeller, kartor och andra grafiska
        framställningar. Uppgifterna ska lösas med hjälp av den information som
        finns på respektive uppslag.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Till varje uppgift finns det fyra svarsförslag. Välj det som bäst
        besvarar frågan.
      </SectionSubtitle>

      <SectionTitle>Rekommendationer</SectionTitle>
      <SectionSubtitle>
        Stressa inte men stanna inte heller länge på en uppgift du fastnat på.
        Tidsbegränsningen är en utmaning på Högskoleprovet, men det är precis
        därför det är så bra att träna på! Lycka till!
      </SectionSubtitle>
    </InstructionsContainer>
  );
};
