/** Libraries */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/** Pages */
import { CustomExamsSQ } from "./CustomExamsSQ";
import { CustomExamsMQ } from "./CustomExamsMQ";
import * as Sentry from "@sentry/react";

/** Custom hooks */
import { useCustomExamsSQStore } from "../../hooks/customExams/SingleQuestions/useCustomExamsSQStore";
import { useSelector, useDispatch } from "react-redux";
import { useAllCustomExamStore } from "../../hooks/customExams/AllCustomExams/useAllCustomExamsStore";
import { selectQuestionsWithOptionId } from "../../redux/slices/customExam/selector";
import { setCustomExamSessions } from "../../redux/slices/customExam/AllCustomExamSlice/allCustomExamSlice";
import { setcuExamPopup } from "../../redux/slices";
import { useTimerStore } from "../../hooks";

const CustomExams = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sessionExams, examSessionNumber } = location.state;
  const [lamnaInhandIn, setLamnaInhandIn] = useState(false);
  const [backPressPopup, setBackPressPopup] = useState(false);
  const [overview, setOverview] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [timeOverPopUp, setTimeOverPopUp] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [isProvpassSubmited, setIsProvpassSubmited] = useState(false);

  const {
    currTime,
    totalTime,
    isPaused,
    startTimer,
    startPausingTimer,
    startReactivetingTimer,
    startTimerLogout,
  } = useTimerStore();

  useEffect(() => {
    if (currTime === 0 && totalTime !== 0) {
      setShouldNavigate(true);
      Sentry.captureMessage("Timer reached zero");
    }
  }, [currTime]);

  useEffect(() => {
    if (!isProvpassSubmited && shouldNavigate) {
      setTimeOverPopUp(true);
    } else {
      setTimeOverPopUp(false);
    }
  }, [isProvpassSubmited, shouldNavigate, timeOverPopUp]);

  const { submitExamSession, customExamResult, customExamSessions } =
    useAllCustomExamStore();
  const questionsWithOptionIds = useSelector(selectQuestionsWithOptionId);
  const { currentQuestion, startSettingCustomExam } = useCustomExamsSQStore();

  useEffect(() => {
    startSettingCustomExam();
  }, []);

  if (currentQuestion === null) return null;

  // console.log("hello testing", questionsWithOptionIds, "helloooo");
  const saveExamSession = () => {
    startPausingTimer(true);
    setIsProvpassSubmited(true);
    setTimeOverPopUp(false);
    dispatch(setcuExamPopup(false));
    setIsLoading(true);
    const attemptedSession = customExamSessions?.find(
      (session) => session.isAttempted === false
    );
    submitExamSession(
      attemptedSession.customizedExam,
      attemptedSession._id,
      questionsWithOptionIds
    )
      .then((response) => {
        if (response) {
          customExamResult(
            attemptedSession.customizedExam,
            response.data._id
          ).then((response) => {
            if (response) {
              const updateSession = customExamSessions.map((session) => {
                if (session._id === attemptedSession._id) {
                  return { ...session, isAttempted: true };
                }
                return session;
              });
              const isAllAttempted = updateSession.every(
                (session) => session.isAttempted === true
              );

              dispatch(setCustomExamSessions(updateSession));
              if (isAllAttempted) {
                navigate("/cuexresult", {
                  state: {
                    quizId: response?.data?.simuleraSeasonResult?._id,
                    provpassOrder: [],
                  },
                });
                setIsLoading(false);
              } else {
                navigate("/cuexsessioninfo", {
                  state: {
                    sessionExams: sessionExams,
                  },
                });
                setIsLoading(false);
              }
            }
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          startTimerLogout();
        }, 500);
      });
  };

  const lastAttempted = () => {
    const attemptedSession = customExamSessions?.find(
      (session) => session.isAttempted === false
    );

    const updateSession = customExamSessions?.map((session) => {
      if (session?._id === attemptedSession?._id) {
        return { ...session, isAttempted: true };
      }
      return session;
    });
    const isAllAttempted = updateSession?.every(
      (session) => session.isAttempted === true
    );

    return isAllAttempted;
  };

  const checkLastAttempted = lastAttempted();

  const isMultipart =
    Object.keys(currentQuestion?.multipartQuestion).length !== 0;
  const isNull = currentQuestion?.multipartQuestion === null;

  return isMultipart && !isNull ? (
    <CustomExamsMQ
      saveExamSession={saveExamSession}
      handleLamnaIn={setLamnaInhandIn}
      lamnaInhandIn={lamnaInhandIn}
      setBackPressPopup={setBackPressPopup}
      overview={overview}
      setOverview={setOverview}
      backPressPopup={backPressPopup}
      time={totalTime}
      isCustomExam={true}
      examNumber={examSessionNumber}
      setIsLoading={setIsLoading}
      loading={loading}
      checkLastAttempted={checkLastAttempted}
      timeOverPopUp={timeOverPopUp}
      setTimeOverPopUp={setTimeOverPopUp}
    />
  ) : (
    <CustomExamsSQ
      saveExamSession={saveExamSession}
      handleLamnaIn={setLamnaInhandIn}
      lamnaInhandIn={lamnaInhandIn}
      setBackPressPopup={setBackPressPopup}
      overview={overview}
      setOverview={setOverview}
      backPressPopup={backPressPopup}
      time={totalTime}
      isCustomExam={true}
      examNumber={examSessionNumber}
      setIsLoading={setIsLoading}
      loading={loading}
      checkLastAttempted={checkLastAttempted}
      timeOverPopUp={timeOverPopUp}
      setTimeOverPopUp={setTimeOverPopUp}
    />
  );
};

export default CustomExams;
