/** Libraries */
import { createSlice, current } from "@reduxjs/toolkit";

export const exercisesMQReviewSlice = createSlice({
  name: "exercisesMQReview",
  initialState: {
    quiz: [],
    sectionTitle: "",
    currMultiQuestion: {
      description: "",
      currTotalMultiQuestions: 0,
      questions: [],
    },
    currIndexMultiQuestion: 0,
    selectedIndex: 0,
    currQuestion: 0,
    questionText: "0",
    totalQuestions: 0,
  },
  reducers: {
    setTotalQuestions: (state, action) => {
      state.totalQuestions = action.payload;
    },
    setQuizReview: (state, action) => {
      /** FORMAT QUIZ DATA */
      const quizQuestions = action.payload.quiz;
      const quizMultiGroup = [];

      let currentGroup = [];

      for (let i = 0; i < quizQuestions.length; i++) {
        const question = quizQuestions[i];

        if (
          i === 0 ||
          areConsecutiveQuestions(question, quizQuestions[i - 1])
        ) {
          /** If it's the first question or if it's consecutive to the previous one, add it to the current group. */
          currentGroup.push(question);
        } else {
          /** If it's not consecutive, create a new group and add it to that group. */
          quizMultiGroup.push(currentGroup);
          currentGroup = [question];
        }
      }

      /** Add the last group to the result */
      if (currentGroup.length > 0) {
        quizMultiGroup.push(currentGroup);
      }

      /** Print the final result of the groups - Console logs for debugging */
      // console.log("Final groups:");
      // quizMultiGroup.forEach((group, index) => {
      //   console.log(
      //     `Group ${index}: ${group.map((question) => question._id).join(", ")}`
      //   );
      // });

      function areConsecutiveQuestions(question1, question2) {
        /** Compare if the questions are consecutive based on some criteria that you determine */
        /** For example, if they have the same set of IDs */
        return (
          question1.multipartQuestion.question.join(",") ===
          question2.multipartQuestion.question.join(",")
        );
      }

      /** ######################################################################### */
      /** Here we find the index of the MultiQuestion group based on the selectedIndex */
      let questionGroupIndex = -1;
      let questionCounter = 0;

      for (let i = 0; i < quizMultiGroup.length; i++) {
        const grupo = quizMultiGroup[i];
        if (questionCounter + grupo.length > action.payload.selectedIndex) {
          questionGroupIndex = i;
          break;
        }
        questionCounter += grupo.length;
      }

      /** SET QUIZ DATA */
      state.quiz = quizMultiGroup;
      state.totalQuestions = action.payload.quiz.length;
      state.currIndexMultiQuestion = questionGroupIndex;

      state.selectedIndex = action.payload.selectedIndex;
      state.currQuestion = action.payload.selectedIndex + 1;

      /** Text to show in the header/top bar */
      const firstDigit = questionCounter + 1;
      const secondDigit =
        questionCounter + quizMultiGroup[questionGroupIndex].length;

      state.questionText = `${firstDigit} - ${secondDigit}`;
      state.questionText =
        quizMultiGroup[questionGroupIndex].length > 1
          ? `${firstDigit} - ${secondDigit}`
          : secondDigit;

      /** SET INITIAL MULTIQUESTION DATA */
      /** Section title (ej: DTK, ELF, etc) */
      state.sectionTitle =
        quizMultiGroup[questionGroupIndex][0].multipartQuestion.title;
      /** Questions to show */
      state.currMultiQuestion.questions = quizMultiGroup[questionGroupIndex];
      /** Quantity of questions to show */
      state.currMultiQuestion.currTotalMultiQuestions =
        quizMultiGroup[questionGroupIndex].length;
      /** Description */
      state.currMultiQuestion.description =
        quizMultiGroup[questionGroupIndex][0].multipartQuestion.description;
    },
    setShowResultItem: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.currMultiQuestion.questions.length) {
        const question = state.currMultiQuestion.questions[index];
        question.showResult = !question.showResult;
      }
    },
    setNavigationLeft: (state, action) => {
      const { currIndexMultiQuestion, currMultiQuestion, quiz } = state;
      // console.log("Navigation left");
      if (currIndexMultiQuestion > 0) {
        const previousGroup = quiz[currIndexMultiQuestion - 1];
        currMultiQuestion.questions = previousGroup;
        currMultiQuestion.currTotalMultiQuestions = previousGroup.length;
        currMultiQuestion.description =
          previousGroup[0].multipartQuestion.description;

        // Update current group index
        state.currIndexMultiQuestion--;

        // Calculate the new selectedIndex and currQuestion
        let totalIndexAcc = 0;
        for (let i = 0; i < currIndexMultiQuestion; i++) {
          totalIndexAcc += quiz[i].length;
        }

        state.selectedIndex = totalIndexAcc - 1;
        state.currQuestion = totalIndexAcc;

        // Update sectionTitle
        state.sectionTitle = previousGroup[0].multipartQuestion.title;

        // Update questionText
        const firstDigit = totalIndexAcc - previousGroup.length + 1;
        state.questionText =
          previousGroup.length > 1
            ? `${firstDigit} - ${totalIndexAcc}`
            : totalIndexAcc;
      }
    },

    setNavigationRight: (state) => {
      const { currIndexMultiQuestion, quiz } = state;
      if (currIndexMultiQuestion + 1 < quiz.length) {
        const nextGroup = quiz[currIndexMultiQuestion + 1];

        // Update MultiQuestion groups index
        state.currIndexMultiQuestion++;

        // Calculate total questions before the current group
        let totalPreviousQuestions = 0;
        for (let i = 0; i < state.currIndexMultiQuestion; i++) {
          totalPreviousQuestions += quiz[i].length;
        }

        // Update state properties
        state.currMultiQuestion.questions = nextGroup;
        state.currMultiQuestion.currTotalMultiQuestions = nextGroup.length;
        state.currMultiQuestion.description =
          nextGroup[0].multipartQuestion.description;
        state.sectionTitle = nextGroup[0].multipartQuestion.title;
        state.selectedIndex = totalPreviousQuestions;
        state.currQuestion = totalPreviousQuestions + 1;

        // Update questionText
        const firstQuestionNumber = totalPreviousQuestions + 1;
        const lastQuestionNumber = totalPreviousQuestions + nextGroup.length;
        state.questionText =
          nextGroup.length > 1
            ? `${firstQuestionNumber} - ${lastQuestionNumber}`
            : `${lastQuestionNumber}`;
      }
    },

    multiQuestionLogout: (state) => {
      state.quiz = [];
      state.sectionTitle = "";

      state.currMultiQuestion.description = "";
      state.currMultiQuestion.currTotalMultiQuestions = 0;
      state.currMultiQuestion.questions = [];

      state.currIndexMultiQuestion = 0;
      state.selectedIndex = 0;
      state.currQuestion = 0;
      state.questionText = "0";
      state.totalQuestions = 0;
    },
  },
});
export const {
  setTotalQuestions,
  setQuizReview,
  setShowResultItem,
  setNavigationLeft,
  setNavigationRight,
  multiQuestionLogout,
} = exercisesMQReviewSlice.actions;
