/** Libraries */
import * as Sentry from "@sentry/react";

export const SendSentryError = (type, title, details) => {
  Sentry.captureEvent({
    message: `[${type}] ${title}`,
    level: "error",
    extra: {
      ...details,
    },
  });
};
