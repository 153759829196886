/** Libraries */
import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

/** Assets */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";
import Prices from "../../../assets/Static/Prices";

/** Organisms */
import PayButton from "./PayButton";
import {
  FullListValues,
  GratisListValues,
  PartialListValues,
} from "./AlternativeListValues";

/** Styles */
import {
  PlanBox,
  PopularityTab,
  HorizontalLine,
  DiscountLabel,
  PayButtonContainer,
  PriceContainer,
  PriceText,
  ManadText,
  MonthsTitle,
  PriceSubtitle,
  PaySectionContainer,
  ListContainer,
  RadioContainer,
  FullPriceLabel,
  FullPlanSwitchContainer,
  PriceTextContainer,
  PaymentTermText,
} from "../PaymentOrg/PricingPlans.styles";

/*  Hooks */
import { useDiscountStore, useMixpanel } from "../../../hooks";
import { useRef } from "react";
import CheckoutPopup from "../../molecule/CheckoutPopup/CheckoutPopup";
import { useSubsPopups } from "../../../hooks/utils/useSubsPopups";

const PricingPlan = ({
  defaultPlan,
  isHalfYearly,
  initiatePayment,
  price,
  amount_off,
  goPayment,
  disablePaymentButton,
}) => {
  const mixpanel = useMixpanel();
  const plans = Object.values(Prices);
  const { planId } = useSubsPopups();
  const { isCodeValid, discount: discountObject } = useDiscountStore();
  const { amount_off: discount } = discountObject;
  const [selectedPlan, setSelectedPlan] = useState(defaultPlan);
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const paymentButtonRef = useRef(null);

  const { user } = useSelector((state) => state.user);
  const [sectionToBuy, setSectionToBuy] = React.useState(
    user?.previousPlan ? user?.previousPlan : "kvantitativ"
  );
  const isPremium = user.isPremium;

  const isPartialPlan = useMemo(() => {
    if (user?.areVerbalFeaturesEnabled && !user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    if (!user?.areVerbalFeaturesEnabled && user?.areKvantiativFeaturesEnabled) {
      return true;
    }
    return false;
  }, [user?._id]);

  useEffect(() => {
    if (isPartialPlan) {
      if (user?.areVerbalFeaturesEnabled) {
        setSectionToBuy("verbal");
      } else {
        setSectionToBuy("kvantitativ");
      }
    }
  }, [isPartialPlan]);

  useEffect(() => {
    if (initiatePayment && selectedPlan === "planThree") {
      paymentButtonRef?.current?.click();
    }
  }, [sectionToBuy, selectedPlan, initiatePayment]);

  const displayedPlans = plans;

  const discountPrices = useMemo(() => {
    // Deep copy Prices and apply discount
    const copiedPrices = JSON.parse(JSON.stringify(Prices));

    Object.keys(copiedPrices).forEach((planKey) => {
      const plan = copiedPrices[planKey];
      if (discount) {
        plan.price -= discount;
        plan.pricePerMonth =
          plan.price % plan.premiumLength < 0.5
            ? Math.floor(plan.price / plan.premiumLength)
            : (plan.pricePerMonth = Math.ceil(plan.price / plan.premiumLength));
      }
    });

    return Object.values(copiedPrices);
  }, [discount]);

  const returnCurrentPlan = (plan) => {
    let currentPlan = "planOne";
    if (isPremium) {
      if (isPartialPlan) {
        currentPlan = "planThree";
      } else {
        currentPlan = "planTwo";
      }
    }
    return plan?.id === currentPlan ? (
      <MonthsTitle gratistitle>Nuvarande Plan</MonthsTitle>
    ) : (
      <PayButtonContainer>
        <PayButton
          setSelectedPlan={setSelectedPlan}
          sectionToBuy={sectionToBuy}
          price={price}
          paymentButtonRef={plan?.id === "planThree" ? paymentButtonRef : null}
          goPayment={(planDetails, sectionToBuy) =>
            goPayment(planDetails, sectionToBuy)
          }
          planId={plan?.id}
          planDetails={() => {
            const planWithoutDiscount = isPartialPlan
              ? Prices["payingFullFromPartial"]
              : plan;
            if (discount) {
              return discountPrices.find(
                (discountPlan) => discountPlan.id === plan?.id
              );
            } else {
              return planWithoutDiscount;
            }
          }}
          disablePaymentButton={disablePaymentButton}
        />
      </PayButtonContainer>
    );
  };

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
    mixpanel.premiumPlanClicked(planId);
  };

  const handleChange = (event) => {
    !user?.previousPlan && setSectionToBuy(event.target.value);
  };

  const {
    openCheckoutPopup,
    popupConfig,
    handleOpenCheckoutPopup,
    handleCloseCheckoutPopup,
    handleConfirm,
  } = useSubsPopups();

  return (
    <Grid
      container
      rowSpacing={4}
      columnSpacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {displayedPlans.map((plan, index) => (
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={4}
          key={index}
          sx={{
            display: plan?.id === "payingFullFromPartial" ? "none" : "block",
          }}
        >
          <CheckoutPopup
            goPayment={(planDetails, sectionToBuy) =>
              goPayment(planDetails, sectionToBuy)
            }
            plan={plan}
            sectionToBuy={sectionToBuy}
            isOpened={plan?.id === planId}
          />
          <PlanBox
            key={index}
            planid={plan.id}
            width={width}
            // onClick={() => handleSelectPlan(plan?.id)}
          >
            {plan?.popular && (
              <PopularityTab planid={plan?.id}>Rekommenderas</PopularityTab>
            )}
            <MonthsTitle planid={plan?.id}>{plan?.name}</MonthsTitle>
            <HorizontalLine planid={plan?.id} />
            {plan.discount && (
              <DiscountLabel>Spara {plan?.discount}</DiscountLabel>
            )}

            <PriceContainer>
              <PriceTextContainer>
                <PriceText>
                  {/* {plan?.id !== "planOne"
                    ? plan?.price - amount_off / 100
                    : plan?.price}{" "}
                  SEK */}
                  {isHalfYearly
                    ? plan?.price?.halfYearly
                    : plan?.price?.monthly}{" "}
                  SEK
                </PriceText>
                {plan?.id !== "planOne" && (
                  <PaymentTermText>
                    Betalas {isHalfYearly ? "halvårsvis" : "månadsvis"}
                  </PaymentTermText>
                )}
              </PriceTextContainer>

              {plan?.id !== "planOne" && <ManadText>/månad</ManadText>}
            </PriceContainer>
            <PaySectionContainer>
              {returnCurrentPlan(plan)}
              {plan?.id === "planThree" && (
                <>
                  <RadioContainer>
                    <FormControl fullWidth>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={sectionToBuy}
                        onChange={handleChange}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          flexWrap: "nowrap",
                          marginLeft: "1rem",
                          gap: "2rem",
                          "& .MuiButtonBase-root": {
                            paddingRight: "6px",
                            color: theme.palette.appcolors.primaryColor,
                          },
                          "& .MuiRadio-root.Mui-checked": {
                            color: theme.palette.appcolors.primaryColor,
                          },
                        }}
                      >
                        <FormControlLabel
                          value="kvantitativ"
                          control={<Radio size="small" />}
                          label="Kvantitativ"
                          disabled={["verbal", "kvantitativ"].includes(
                            user.previousPlan
                          )}
                        />
                        <FormControlLabel
                          value="verbal"
                          disabled={["verbal", "kvantitativ"].includes(
                            user.previousPlan
                          )}
                          control={<Radio size="small" />}
                          label="Verbal"
                        />
                      </RadioGroup>
                    </FormControl>
                  </RadioContainer>
                  <PriceSubtitle toptext>Välj del</PriceSubtitle>
                </>
              )}
            </PaySectionContainer>
            <ListContainer>
              {plan?.id === "planTwo" ? (
                <FullListValues />
              ) : plan?.id === "planOne" ? (
                <GratisListValues />
              ) : (
                <PartialListValues />
              )}
            </ListContainer>
          </PlanBox>
        </Grid>
      ))}
    </Grid>
  );
};

export default PricingPlan;
