/** Libraries */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

/** Slices */
import {
  setCurrQuestion,
  setCurrQuestionAsAnswered,
  setNextQuestion,
  setQuiz,
  setSelectedCurrMultiQuestionIndex,
  setSelectedQuestion,
  exerciseMultiQuestionLogout,
} from "../../../redux/slices/exercise/MultiQuestions/exercisesMQSlice";

export const useExercisesMQStore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useLocation();

  const {
    quiz,
    sectionTitle,
    currMultiQuestion,
    currNumberOfQuestions,
    areCurrMQSelected,
    isCurrQuestionAnswered,
    selectedCurrMQIndex,
    selectedIndex,
    currQuestion,
    totalQuestions,
  } = useSelector((state) => state.exercisesMQ);

  const startSettingQuiz = (data) => {
    dispatch(setQuiz(data));
  };

  const startSelectingQuestion = (
    { item, optionIndex, timeleft, totaltime, seconds },
    setSeconds
  ) => {
    dispatch(
      setSelectedQuestion({ item, optionIndex, timeleft, totaltime, seconds })
    );

    setSeconds(0);
  };

  const startMQNavigation = (questionIndex) => {
    dispatch(setSelectedCurrMultiQuestionIndex(questionIndex));

    dispatch(setCurrQuestion({ questionIndex }));
  };

  const startSettingCurrQuestionAsAnswered = () => {
    dispatch(setCurrQuestionAsAnswered());
  };

  const startNextQuestion = () => {
    if (selectedIndex + 1 === quiz.length) {
      navigate("/resultsummary", {
        state: {
          sectionCategory: params?.state?.sectionCategory,
          quizId: params?.state?.quizId,
          time: params?.state?.time,
        },
      });
    } else {
      dispatch(setNextQuestion());
    }
  };

  const startExerciseMultiQuestionLogout = () => {
    dispatch(exerciseMultiQuestionLogout());
  };

  return {
    /** Values */
    quiz,
    sectionTitle,
    currMultiQuestion,
    currNumberOfQuestions,
    areCurrMQSelected,
    isCurrQuestionAnswered,
    selectedCurrMQIndex,
    selectedIndex,
    currQuestion,
    totalQuestions,

    /** Functions */
    startSettingQuiz,
    startSelectingQuestion,
    startSettingCurrQuestionAsAnswered,
    startNextQuestion,
    startMQNavigation,
    startExerciseMultiQuestionLogout,
  };
};
