/** Libraries */
import React, { useEffect } from "react";
import { Collapse } from "@material-ui/core";

/** Molecules */
import PublicPremiumBanner from "../../../components/molecule/Banners/PublicPremiumBanner/PublicPremiumBanner";
import ResultFooter from "../../../components/molecule/ResultFooter/ResultFooter";
import NoneAnsweredAlert from "../../../components/molecule/Alerts/NoneAnsweredAlert/NoneAnsweredAlert";
import AnswerButtonSQ from "../../../components/molecule/AnswerButton/AnswerButtonSQ";

/** Organisms */
import ExerciseTopBar from "../../../components/organism/TopBars/ExerciseTopBar/ExerciseTopBar";
import { SQDescription } from "../../../components/organism/Quiz/descriptions/SQDescription/SQDescription";
import Options from "../../../components/organism/Quiz/Options/Options";
import { Answer } from "../../../components/organism/Quiz/Answer/Answer";

/** Templates */
import { SQTemplate } from "../../../components/template";

/** Custom hooks */
import { usePublicTestSQStore } from "../../../hooks/publicTest/SingleQuestions/usePublicTestSQStore";

const PublicTestSQ = () => {
  const {
    currentQuestion,
    selectedIndex,
    totalQuestions,
    isAnswerVisible,
    isReviewSection,
    topBar,
    categoryTitle,
    startSettingSelectedAnswerPublicTestSQ,
    startSettingAnswerVisiblePublicTestSQ,
    startNextQuestionPublicTestSQ,
    startSettingPublicTestSQ,
    startNavigatingResultPageInReview,
    startClosingQuizPublicTestSQ,
    startSettingNextQuestionInReviewPublicTestSQ,
    startSettingPreviusQuestionInReviewPublicTestSQ,
  } = usePublicTestSQStore();

  useEffect(() => {
    startSettingPublicTestSQ();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Your custom function to execute

      // Prompt the user with a confirmation dialog
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  if (currentQuestion === null) return null;

  return (
    <SQTemplate
      style={{ paddingTop: "0rem" }}
      topBar={
        <ExerciseTopBar
          selectedIndex={selectedIndex + 1}
          totalQuestions={totalQuestions}
          goBack={startClosingQuizPublicTestSQ}
          isReviewSection={isReviewSection}
        />
      }
      banner={
        <PublicPremiumBanner
          buttonText="Kom igång"
          text="Gör över 11 000 övningar och höj dina poäng på högskoleprovet"
          isResult={false}
        />
      }
      nonAnsweredAlert={
        currentQuestion?.optionId === null &&
        isReviewSection && <NoneAnsweredAlert />
      }
      description={
        <SQDescription
          sectionTitle={categoryTitle}
          questionDescription={currentQuestion?.questionStatement}
          questionImage={currentQuestion?.questionStatement?.includes(
            "hp-appen.s3.eu-north-1.amazonaws.com"
          )}
          questionIndications={[
            currentQuestion?.information1,
            currentQuestion?.information2,
          ]}
          sx={{
            marginTop:
              currentQuestion?.optionId === null && isReviewSection
                ? 0
                : "2.5rem",
          }}
        />
      }
      options={
        <Options
          sectionTitle={categoryTitle}
          options={currentQuestion?.options[0].options}
          answerId={currentQuestion?.answerObj?.option}
          answerExist={isAnswerVisible}
          selectedOption={currentQuestion?.selectedQuestion}
          handleSelectOption={startSettingSelectedAnswerPublicTestSQ}
        />
      }
      primaryButton={
        !isReviewSection ? (
          <AnswerButtonSQ
            question={currentQuestion}
            disabled={!currentQuestion?.selectedQuestion}
            startSettingAnswerVisiblePublicTestSQ={
              startSettingAnswerVisiblePublicTestSQ
            }
            startNextQuestionPublicTestSQ={startNextQuestionPublicTestSQ}
            isAnswerVisible={isAnswerVisible}
          />
        ) : (
          <ResultFooter
            questionLength={totalQuestions}
            questionIndex={selectedIndex}
            onResultHandler={() => {
              startNavigatingResultPageInReview();
            }}
            onLeftClick={() => {
              startSettingPreviusQuestionInReviewPublicTestSQ();
            }}
            onRightClick={() => {
              startSettingNextQuestionInReviewPublicTestSQ();
            }}
          />
        )
      }
      answer={
        <Collapse
          orientation="vertical"
          in={isAnswerVisible}
          sx={{ width: "100%" }}
          unmountOnExit
        >
          <Answer
            isAiTutor={false}
            isFeedbackDisabled={true}
            content={currentQuestion?.answerObj?.answer}
            questionId={currentQuestion.questionId}
            takeScreenshot={true}
          />
        </Collapse>
      }
    />
  );
};

export default PublicTestSQ;
