import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import UiProvider from "./components/Provider/UiProvider";
import { PersistGate } from "redux-persist/integration/react";

import "katex/dist/katex.min.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import semver from "semver";

// Get the current version from package.json
import currentVersion from "../package.json";

import config from "./config/config";
import { ThemeProvider } from "@mui/material";
import { lightTheme, darkTheme } from "./themes";
import { usePersistedStore } from "./hooks";

const AppWithTheme = () => {
  const { darkmode } = usePersistedStore();

  useEffect(() => {
    console.log("1.0.1");
  }, []);

  return (
    <ThemeProvider theme={darkmode ? darkTheme : lightTheme}>
      <UiProvider>
        <App />
      </UiProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={config.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppWithTheme />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
