/** Libraries */
import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import ProgressBar from "@ramonak/react-progress-bar";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

/** Utils */
import { appColors } from "../../../../utils/commonService";

/** Atoms */
import Timer from "../../../atom/Timer/timer";
import TimerPrimary from "../../../atom/Timer/TimerPrimary/TimerPrimary";
import HelpPopup from "../../../atom/HelpPopup/HelpPopup";
import { TimerIcon } from "../../../atom/Timer/TimerPrimary/TimerPrimary.styles";
import { BarChartIcon } from "../../../atom/BarChart/BarChartIcon";

/** Molecules */
import useWindowDimensions from "../../../molecule/WindowDimensions/dimension";

import {
  useExercisesSectionCategoriesStore,
  useTimerStore,
} from "../../../../hooks";

/** Material UI - Custom elements */
const MainContainer = styled(Box)(({ theme, bgprop }) => ({
  position: "sticky",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 1000,
  backgroundColor: appColors.grey2,
  margin: "auto",
  padding: "1rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down(600)]: {
    backgroundColor: bgprop ? appColors.grey2 : appColors.whiteColor,
  },
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1440px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const ExitButton = styled(CloseRoundedIcon)(({ theme }) => ({
  cursor: "pointer",
  marginRight: "2rem",
  fontSize: "30px",
  color: appColors.grey70,
  "&:hover": {
    color: appColors.blueColor,
    fontSize: "31px",
    marginTop: "-1px",
    marginLeft: "-1px",
  },
  [theme.breakpoints.down(600)]: {
    marginRight: "0rem",
    fontSize: "22px",
    "&:hover": {
      color: appColors.blueColor,
      fontSize: "23px",
      marginTop: "-1px",
      marginLeft: "-1px",
    },
  },
}));

const HelpIcon = styled(HelpOutlineRoundedIcon)(({ theme }) => ({
  cursor: "pointer",
  marginLeft: "2rem",
  fontSize: "30px",
  color: appColors.grey70,
  "&:hover": {
    color: appColors.blueColor,
    fontSize: "31px",
    marginTop: "-1px",
    marginRight: "-1px",
  },
  [theme.breakpoints.down(600)]: {
    marginLeft: "0rem",
    fontSize: "22px",
    "&:hover": {
      color: appColors.blueColor,
      fontSize: "23px",
      marginTop: "-1px",
      marginRight: "-1px",
    },
  },
}));

const HeaderTitle = styled("p")(({ theme }) => ({
  fontFamily: "Roboto",
  fontSize: "28px",
  color: appColors.blackColor,
  paddingBottom: "1rem",
  [theme.breakpoints.down(800)]: {
    fontSize: "21px",
    width: "100%",
    textAlign: "center",
    paddingBottom: "0.5rem",
  },
}));

const NumbersContainer = styled(Box)(({ theme }) => ({
  color: appColors.grey70,
  display: "flex",
  alignItems: "center",
  maxWidth: "120px",
  width: "100%",
  [theme.breakpoints.down(600)]: {
    marginTop: "1rem",
  },
}));

const NumbersText = styled("p")(({ theme }) => ({
  marginLeft: "0.4rem",
  fontFamily: "Poppins",
  lineHeight: "20px",
  [theme.breakpoints.down(600)]: {
    fontSize: "14px",
  },
}));

const TimerContainer = styled(Box)(({ theme }) => ({
  color: appColors.grey70,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  maxWidth: "115px",
  width: "100%",
  [theme.breakpoints.down(600)]: {
    marginTop: "1rem",
    width: "110px",
  },
  p: {
    fontFamily: "Poppins",
    lineHeight: "20px",
    [theme.breakpoints.down(600)]: {
      fontSize: "14px",
    },
  },
}));

const DesktopDisplay = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down(600)]: {
    display: "none",
  },
}));

const MobileDisplay = styled(Box)(({ theme, mobile }) => ({
  display: mobile === "true" ? "flex" : "none",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.up(600)]: {
    display: "none",
  },
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
}));

function PublicTestTopBar({
  progress,
  isReviewSection,
  categoryTitle,
  ...props
}) {
  const { width } = useWindowDimensions();
  const [helpPopup, setHelpPopup] = useState(false);

  const currentSectionCategory = useExercisesSectionCategoriesStore();

  const RepeatedTimer = () => {
    return (
      <TimerContainer>
        <TimerPrimary isResultSection={isReviewSection} />
      </TimerContainer>
    );
  };

  const RepeatedNumbersContainer = () => {
    return (
      <NumbersContainer>
        <BarChartIcon />
        <NumbersText>
          {props.selectedIndex} av {props.totalQuestions}
        </NumbersText>
      </NumbersContainer>
    );
  };

  const RepeatedHeaderContainer = () => {
    return (
      <HeaderTitle>
        {props?.resultSummary && "Sammanfattning- "}
        {categoryTitle}
      </HeaderTitle>
    );
  };

  return (
    <MainContainer bgprop={props?.resultSummary?.toString()}>
      {width >= 600 && <ExitButton onClick={props.goBack} />}
      <HeaderContainer>
        {width >= 600 && <Box>{helpPopup && <HelpPopup />}</Box>}
        <DesktopDisplay>
          {RepeatedNumbersContainer()}
          {RepeatedHeaderContainer()}
          {width > 600 && RepeatedTimer()}
        </DesktopDisplay>

        <MobileDisplay mobile={width < 600 ? "true" : "false"}>
          <ExitButton onClick={props.goBack} />
          {RepeatedHeaderContainer()}
          <HelpIcon onClick={() => setHelpPopup(!helpPopup)} />
          <Box>{helpPopup && <HelpPopup />}</Box>
        </MobileDisplay>

        <ProgressBarContainer>
          <ProgressBar
            completed={Math.floor(progress)}
            bgColor={appColors.darkMintGreen45}
            borderRadius="50px"
            height="13px"
            isLabelVisible={false}
            labelSize="12px"
          />
        </ProgressBarContainer>

        <MobileDisplay mobile={width < 600 ? "true" : "false"}>
          {RepeatedNumbersContainer()}
          {width < 600 && RepeatedTimer()}
        </MobileDisplay>
      </HeaderContainer>

      {width >= 600 && <HelpIcon onClick={() => setHelpPopup(!helpPopup)} />}
    </MainContainer>
  );
}

export default PublicTestTopBar;
