/** Libraries */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

/** Slices */
import {
  setTotalTime,
  setTime,
  setTimeText,
  setTimePaused,
  setSecondsCounter,
  timerLogout,
  setExtendedTimeTimer,
} from "../../redux/slices/utils/timerSlice";
import { timeLogout } from "../../redux/slices";

export const useTimerStore = () => {
  const dispatch = useDispatch();
  const {
    isTimeExtended,
    totalTime,
    totalTimeTextMin,
    currTextTime,
    currTime,
    secondsCounter,
    isPaused,
  } = useSelector((state) => state.timer);

  useEffect(() => {
    let timer;

    if (!isPaused && currTime > 0) {
      timer = setInterval(() => {
        dispatch(setTime(currTime - 1));
        dispatch(setSecondsCounter(secondsCounter + 1));
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [dispatch, currTime, isPaused]);

  useEffect(() => {
    if (currTime === 0) {
      dispatch(setTimePaused(true));
      dispatch(setTimeText("00:00 min"));
    }
  }, [dispatch, currTime]);

  const startSettingExtendedTime = (isTimeExtended) => {
    dispatch(setExtendedTimeTimer(isTimeExtended));
  };

  const startTimer = (totalTime, forseRestart) => {
    if (currTime === 0 || forseRestart) {
      dispatch(setTotalTime(totalTime || 3300));
      dispatch(setTime(totalTime || 3300));
      dispatch(setTimePaused(false));
    } else {
      dispatch(setTimePaused(false));
    }
  };

  const startSettingTotalTime = (totalTime) => {
    dispatch(setTotalTime(totalTime));
  };

  const startPausingTimer = () => {
    dispatch(setTimePaused(true));
  };

  const startReactivetingTimer = () => {
    dispatch(setSecondsCounter(0));
    dispatch(setTimePaused(false));
  };

  const startTimeToggle = (exetendedTime) => {
    let defaultTime = exetendedTime ? 4800 : 3300;
    return defaultTime;
  };

  const startTransformSecToMinutes = (secTime) => {
    return secTime / 60;
  };

  const startTimerLogout = () => {
    dispatch(timerLogout());
    dispatch(timeLogout());
  };

  return {
    /** Values */
    isTimeExtended,
    totalTime,
    totalTimeTextMin,
    currTime,
    currTextTime,
    secondsCounter,
    isPaused,

    /** Functions */
    startSettingExtendedTime,
    startTimer,
    startSettingTotalTime,
    startPausingTimer,
    startReactivetingTimer,
    startTimeToggle,
    startTransformSecToMinutes,
    startTimerLogout,
  };
};
