/** Libraries */
import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

export const aiTutorSlice = createSlice({
  name: "aitutor",
  initialState: {
    isActive: false,
    aiConversation: [],
    aiMQConversation: [],
    isRequestInProcess: false,
    isRequestFailed: false,
  },
  reducers: {
    setNewAiConversationMessage: (state, action) => {
      const { indexMQ, data } = action.payload;

      if (indexMQ !== undefined && typeof indexMQ === "number") {
        if (!state.aiMQConversation[indexMQ]) {
          state.aiMQConversation[indexMQ] = [];
        }
        state.aiMQConversation[indexMQ] = [
          ...state.aiMQConversation[indexMQ],
          ...data,
        ];
      } else {
        state.aiConversation = [...state.aiConversation, ...data];
      }
    },
    setIsRequestInProcess: (state, action) => {
      state.isRequestInProcess = action.payload;
    },
    setIsRequestFailed: (state, action) => {
      state.isRequestFailed = action.payload;
    },
    aiTutorLogout: (state) => {
      state.aiConversation = [];
      state.aiMQConversation = [];
      state.isRequestInProcess = false;
      state.isRequestFailed = false;
      state.isActive = false;
    },
    setIsActive: (state, action) => {
      state.isActive = action.payload;
    },
  },
});

export const {
  setNewAiConversationMessage,
  setIsRequestInProcess,
  setIsRequestFailed,
  aiTutorLogout,
  setIsActive,
} = aiTutorSlice.actions;
