/** Libraries */
import React, { useEffect, useMemo, useState } from "react";
import { TextField, Box, Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoMdInformationCircleOutline } from "react-icons/io";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import moment from "moment";

/** Molecules */
import Snackbar from "../../../molecule/Snackbar/Snackbar";
import MobileMenu from "../../../molecule/MobileMenu/MobileMenu";
import useWindowDimensions from "../../../molecule/WindowDimensions/dimension";
import PasswordUpdationDialog from "../../../molecule/PasswordUpdationDialog/PasswordUpdationDialog";

/** Hooks */
import { useAuthStore, useUiStore } from "../../../../hooks";

/** Tools */
import { SendSentryError } from "../../../../tools";
import { EndPoints, instance2 } from "../../../service/Route";
import { setSectionCategories } from "../../../../redux/slices";

/** Styled Components */
const MainContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  maxWidth: "800px",
  maxHeight: "900px",
  display: "flex",
  flexDirection: "column",
  margin: "2rem auto",
  padding: "2rem",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderRadius: "10px",
  [theme.breakpoints.down(1200)]: {
    margin: "2rem",
  },
  [theme.breakpoints.down(960)]: {
    margin: "1rem",
  },
  [theme.breakpoints.down(600)]: {
    border: "none",
    borderRadius: "0px",
    margin: "0rem",
    padding: "1rem",
    maxHeight: "100%",
  },
}));

const ProfileTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "2rem",
}));

const ProfileTitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
  fontSize: "32px",
  [theme.breakpoints.up(2400)]: {
    fontSize: "40px",
  },
}));

const SaveButton = styled(Box)(({ theme, disabled, password, upgrade }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: upgrade ? "2rem" : "2.5rem",
  maxWidth: password ? "12rem" : "7rem",
  width: "100%",
  borderRadius: "8px",
  color: disabled
    ? theme.palette.appcolors.grey7
    : theme.palette.appcolors.secondaryColor10,
  backgroundColor: disabled
    ? theme.palette.appcolors.primaryColor10
    : theme.palette.appcolors.primaryColor,
  cursor: "pointer",
  "&:hover": {
    transform: !disabled && "scale(1.03)",
    transition: "all 0.3s ease-in-out",
  },
}));

const ProfileImageContainer = styled(Box)(({ theme }) => ({
  marginTop: "15px",
  textAlign: "center",
}));

const ProfileImageCircle = styled(Box)(({ theme }) => ({
  width: "86px",
  height: "86px",
  backgroundColor: theme.palette.appcolors.grey3,
  borderRadius: " 50%",
  overflow: "clip",
  margin: "25px auto",
  color: theme.palette.appcolors.grey,
  cursor: "default",
  " & img": {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  " & p": {
    fontSize: "46px",
    textAlign: "center",
    fontFamily: "Figtree",
    margin: "0",
    padding: "0",
    lineHeight: "86px",
  },
}));

const EmailAlertContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
}));

const EmailAlertContents = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.appcolors.grey15,
  height: "48px",
  borderRadius: "8px",
  outline: "none",
  fontFamily: "Poppins",
  color: theme.palette.appcolors.success4,
  textAlign: "center",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0rem 2rem",
  [theme.breakpoints.down(600)]: {
    fontSize: "10px",
  },
}));

const InputFieldWrapper = styled(Box)(({ theme }) => ({
  margin: "2rem 6rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.down(960)]: {
    margin: "2rem",
  },
  [theme.breakpoints.down(600)]: {
    margin: "1rem",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "15px",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  borderRadius: "10px",
  height: "65px",
  overflow: "hidden",
  "& .MuiInputBase-input": {
    height: "100%",
    paddingLeft: "20px",
    backgroundColor: theme.palette.appcolors.secondaryColor3,
    fontSize: "18px",
  },
  "& .MuiFormLabel-root.MuiInputLabel-root": {
    position: "absolute",
    left: "0px",
    color: theme.palette.appcolors.grey7,
    fontSize: "17px",
  },
}));

const PremiumBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  border: `1px solid ${theme.palette.appcolors.grey4}`,
  width: "100%",
  maxWidth: "400px",
  margin: "auto",
  padding: "2rem",
  borderRadius: "10px",
}));

const PremiumTitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
  fontWeight: "600",
  textAlign: "center",
  paddingBottom: "5px",
  [theme.breakpoints.up(2400)]: {
    fontSize: "20px",
  },
}));

const PremiumSubitle = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
  fontFamily: "Figtree",
  fontWeight: "500",
  textAlign: "center",
  fontSize: "10px",
  paddingBottom: "10px",
  [theme.breakpoints.up(2400)]: {
    fontSize: "16px",
  },
}));

const CheckIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  borderRadius: " 50%",
  margin: "0px auto 15px",
  border: `3px solid ${theme.palette.appcolors.primaryColor}`,
}));

const StyledCheckIcon = styled(CheckRoundedIcon)(({ theme }) => ({
  color: theme.palette.appcolors.primaryColor,
}));

const LogoutContainer = styled(Box)(({ theme }) => ({
  marginTop: "2rem",
  cursor: "pointer",
}));

const LogoutTitle = styled("p")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: "600",
  color: theme.palette.appcolors.error6,
  [theme.breakpoints.up(2400)]: {
    fontSize: "14px",
  },
}));

const StyledGoToCheckoutButton = styled(Button)(({ theme, disabled }) => ({
  color: `${theme.palette.appcolors.secondaryColor10} !important`,
  backgroundColor: `${
    disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor
  } !important`,
  borderRadius: "16px !important",
  padding: "8px 16px !important",
  fontSize: "14px !important",
  fontWeight: "400 !important",
  fontFamily: "Poppins !important",
  lineHeight: "20px !important",
  textAlign: "center !important",
  textTransform: "none !important",
  borderBottom: `3px solid ${theme.palette.appcolors.black3}`,
  "&:hover": {
    backgroundColor: disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor,
  },
  [theme.breakpoints.down(1280)]: {
    width: "90% !important",
  },
}));

const ProfileFeedContent = () => {
  const navigate = useNavigate();
  const { startLogout, fetchUser } = useAuthStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdatedPassword, setIsUpdatedPassword] = useState(false);
  const [showSnack, setShowSnack] = useState();
  const [currentProfile, setCurrentProfile] = useState(null);
  const [updatedProfileFields, setUpdatedProfileFields] = useState({
    fullName: "",
    email: "",
    about: "",
  });

  const [updateEmail, setUpdateEmail] = useState(false);

  const { width } = useWindowDimensions();

  const { user, token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const fullName = user.fullName;
  const firstLetterOfName = fullName.charAt(0);
  const isPremium = user.isPremium;
  const isFullPremium =
    user?.areKvantiativFeaturesEnabled && user?.areVerbalFeaturesEnabled;
  const isOldPremiumUser =
    user?.isPremium &&
    !user?.areKvantiativFeaturesEnabled &&
    !user?.areVerbalFeaturesEnabled;
  const { startErrorPopup, startErrorRedirectionTrue } = useUiStore();
  const premiumExpiryDate = localStorage.getItem("premiumExpiryDate");
  const currentDate = new Date();
  const currentDateObj = moment(currentDate);
  const expiryDateObj = moment(premiumExpiryDate);
  const daysLeft = expiryDateObj.diff(currentDateObj, "days");
  const [fieldsUpdated, setFieldsUpdated] = useState(false);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    updatedProfileFields.fullName !== currentProfile?.fullName ||
    updatedProfileFields.email !== currentProfile?.email ||
    updatedProfileFields.about !== currentProfile?.about
      ? setFieldsUpdated(true)
      : setFieldsUpdated(false);
  }, [updatedProfileFields, currentProfile]);

  useEffect(() => {
    const storedProfileImage = localStorage.getItem("profileImage");
    console.log(profileImage);
    if (storedProfileImage) {
      setProfileImage(storedProfileImage);
    }
  }, []);

  const navigateCheckout = () => {
    navigate("/checkout");
  };

  const LogoutFunc = () => {
    console.log("log out function");
    dispatch(setSectionCategories([]));
    startLogout();
  };

  const controller = new AbortController();
  const signal = controller.signal;

  useMemo(() => {
    const fetchUserProfile = async () => {
      try {
        if (localStorage.getItem("token")) {
          const response = await fetchUser(signal);
          console.log("response11", response);
          const { data } = response;
          setCurrentProfile({
            email: data.user.email,
            fullName: data.user.fullName,
            about: data.user.about,
          });
          setUpdatedProfileFields({
            email: data.user.email,
            fullName: data.user.fullName,
            about: data.user.about,
          });
        } else {
          startErrorRedirectionTrue();
          startErrorPopup();
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        // Handle error here
      }
    };

    fetchUserProfile();
    return () => {
      controller.abort();
    };
  }, []);

  const handleUpdatePassword = () => {
    setIsUpdatedPassword(false);
    setIsOpen(true);
  };

  const handleSave = () => {
    if (!fieldsUpdated) return;
    setFieldsUpdated(false);

    const pointURL = EndPoints.updateUserInfo;
    instance2
      .put(pointURL, updatedProfileFields)
      .then((response) => {
        setCurrentProfile({
          email: updatedProfileFields.email,
          fullName: updatedProfileFields.fullName,
          about: updatedProfileFields.about,
        });
        if (response.status === 200) {
          if (updatedProfileFields.email !== currentProfile.email) {
            setUpdateEmail(true);
          }
        } else {
          console.log("unsuccessful", response);
        }
      })
      .catch((error) => {
        console.error("Error updating user info:", error);
        SendSentryError("PROFILE", "Error updating user info", {
          details: "Failed to update user info",
          error,
        });
      });
  };

  return (
    <MainContainer>
      <MobileMenu />
      <Snackbar show={showSnack} onClose={() => setShowSnack(false)} />
      <PasswordUpdationDialog
        showPopup={isOpen}
        hidePopup={() => setIsOpen(false)}
        showSnackbar={() => setShowSnack(true)}
        isUpdatedPassword={isUpdatedPassword}
        setIsUpdatedPassword={setIsUpdatedPassword}
      />
      <ProfileTitleContainer>
        <ProfileTitle>Profil</ProfileTitle>
        <SaveButton
          disabled={fieldsUpdated ? false : true}
          onClick={handleSave}
        >
          Spara
        </SaveButton>
      </ProfileTitleContainer>

      <ProfileImageContainer>
        {profileImage ? (
          <ProfileImageCircle>
            <img src={profileImage} alt="Pic" />
          </ProfileImageCircle>
        ) : (
          <ProfileImageCircle>
            <p>{firstLetterOfName}</p>
          </ProfileImageCircle>
        )}
      </ProfileImageContainer>

      {updateEmail && (
        <EmailAlertContainer>
          <EmailAlertContents>
            <IoMdInformationCircleOutline size={25} boxSizing={20} />
            &nbsp;&nbsp;Tack! En verifieringslänk har skickats till din email.
          </EmailAlertContents>
        </EmailAlertContainer>
      )}

      <InputFieldWrapper>
        <Box>
          <CustomTextField
            value={updatedProfileFields?.fullName}
            onChange={(e) =>
              setUpdatedProfileFields((prev) => ({
                ...prev,
                fullName: e.target.value,
              }))
            }
            name="name"
            label="Namn"
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            disabled
          />
          <CustomTextField
            value={updatedProfileFields?.email}
            onChange={(e) =>
              setUpdatedProfileFields((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            name="email"
            label="Email"
            type="email"
            placeholder="example@mail.com"
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            disabled
          />
          <CustomTextField
            value={updatedProfileFields?.about}
            onChange={(e) =>
              setUpdatedProfileFields((prev) => ({
                ...prev,
                about: e.target.value,
              }))
            }
            name="about"
            label="Om"
            type="text"
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
          />
        </Box>
      </InputFieldWrapper>

      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          style={{
            minWidth: "200px",
            flexBasis: width < 960 ? "auto" : "100%",
            margin: width < 960 && "auto",
          }}
        >
          <SaveButton password onClick={handleUpdatePassword}>
            Ändra lösenord
          </SaveButton>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {!isPremium ? (
            <PremiumBox>
              <PremiumTitle>Uppgradera till Premium</PremiumTitle>
              <PremiumSubitle>
                Få obegränsad tillgång till allt du behöver
              </PremiumSubitle>
              <SaveButton
                password
                upgrade
                sx={{ margin: "auto" }}
                onClick={() => navigateCheckout()}
              >
                Uppgradera
              </SaveButton>
            </PremiumBox>
          ) : (
            <PremiumBox>
              <CheckIconContainer>
                <StyledCheckIcon />
              </CheckIconContainer>
              {isOldPremiumUser ? (
                <PremiumBox>
                  <CheckIconContainer>
                    <StyledCheckIcon />
                  </CheckIconContainer>
                  <PremiumTitle>HP-appen Premium</PremiumTitle>
                  <PremiumSubitle>{daysLeft} dagar kvar</PremiumSubitle>
                </PremiumBox>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <PremiumTitle>
                    {isFullPremium
                      ? "Full Premium"
                      : user?.areVerbalFeaturesEnabled
                      ? "Verbal Plan"
                      : "Kvantitativ Plan"}
                  </PremiumTitle>
                  <StyledGoToCheckoutButton onClick={navigateCheckout}>
                    {isFullPremium ? "Hantera abonnemang" : "Välj plan"}
                  </StyledGoToCheckoutButton>
                </Box>
              )}
            </PremiumBox>
          )}
        </Grid>
      </Grid>

      <LogoutContainer onClick={() => LogoutFunc()}>
        <LogoutTitle>Logga ut</LogoutTitle>
      </LogoutContainer>
    </MainContainer>
  );
};

export default ProfileFeedContent;
