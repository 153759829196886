import { useTheme, styled } from "@mui/material/styles";
import { ReactComponent as EnterIcon } from "../../../assets/Icons/EnterIcon.svg";

const NotificationContainer = styled("div")(({ theme, mq }) => ({
  width: "90px",
  height: "16px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "3px",
  [theme.breakpoints.down(1200)]: {
    display: mq && "none",
  },
  [theme.breakpoints.down(900)]: {
    display: "none",
  },
}));

const TextNotification = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.grey,
  fontSize: "10px",
  marginRight: "5px",
  fontFamily: "Figtree",
  span: {
    fontWeight: 700,
  },
}));

export const PressEnterIcon = (mq) => {
  const theme = useTheme();

  return (
    <NotificationContainer mq={mq}>
      <TextNotification>
        Tryck <span>Enter</span>
      </TextNotification>
      <EnterIcon
        style={{
          width: "17px",
          height: "12px",
          flexShrink: 0,
          transform: "rotate(180deg) scaleX(-1)",
        }}
        stroke={theme.palette.appcolors.grey}
      />
    </NotificationContainer>
  );
};
