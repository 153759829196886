import { createSlice } from "@reduxjs/toolkit";

export const timeSlice = createSlice({
  name: "quiz",
  initialState: {
    time: 0,
    quiz: {},
    order_id: {},
    isPaused: false,
  },
  reducers: {
    setQuizTime: (state, action) => {
      state.time = action.payload;
    },
    setOrderId: (state, action) => {
      state.order_id = action.payload;
    },
    timeLogout: (state, action) => {
      state.time = 0;
      state.quiz = {};
      state.order_id = {};
      state.isPaused = false;
    },
  },
});
export const { setQuizTime, setOrderId, timeLogout } = timeSlice.actions;
