/** Libraries */
import React from "react";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { appColors } from "../../../utils/commonService";

/** Molecules */
import useWindowDimensions from "../../molecule/WindowDimensions/dimension";

/** MUI - Custom elements */
const Subtitle = styled("p")(({ mt }) => ({
  lineHeight: "1.5rem",
  fontSize: "0.85rem",
  marginTop: mt,
}));

const HelpPopup = ({ examMode = false, lectureMode = false }) => {
  const { width } = useWindowDimensions();

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          zIndex: 1010,
          right: width < 600 ? 12 : width < 1200 ? 15 : examMode ? 40 : 15,
          top:
            width < 600
              ? 70
              : width < 1200 && !examMode
              ? 90
              : width < 1200
              ? 70
              : examMode
              ? 100
              : 90,
          background: appColors.blackColor,
          width: "2rem",
          height: "2rem",
          transform: "translateY(-50%) rotate(45deg)",
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: appColors.blackColor,
          padding: "2rem 3rem",
          color: appColors.whiteColor,
          width: "75%",
          maxWidth: examMode ? "600px" : "450px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          position: "absolute",
          zIndex: 1010,
          top:
            width < 600
              ? 60
              : width < 1200 && !examMode
              ? 80
              : width < 1200
              ? 60
              : examMode
              ? 90
              : 80,
          right: width < 1200 ? 5 : examMode ? 30 : 5,
        }}
      >
        <Box>
          <Typography variant="h5">
            Välkommen till{" "}
            {examMode
              ? "provpassvyn"
              : lectureMode
              ? "lektioner"
              : "övningsläget"}
            !
          </Typography>
        </Box>
        <Box>
          <Subtitle mt={"1rem"}>
            {examMode ? (
              <>
                • Slutfört prov krävs för att se facit och resultat, precis som
                när du gör högskoleprovet på plats.
              </>
            ) : (
              <>• Du kan avbryta när som helst genom att klicka på krysset.</>
            )}
          </Subtitle>
          <Subtitle>
            {examMode ? (
              <>• Provpasset lämnas in automatiskt när tiden är ute.</>
            ) : lectureMode ? (
              <>
                • Dina resultat sparas så du kan fortsätta lektionen på samma
                ställe senare.
              </>
            ) : (
              <>• Dina resultat sparas alltid i din historik.</>
            )}
          </Subtitle>
          {!lectureMode && (
            <>
              <Subtitle>
                {examMode ? (
                  <>• Klicka på 'Överblick' för att se sparade frågor.</>
                ) : (
                  <>
                    • När du gör frågor på tid räknas dina svar med i prognosen.
                  </>
                )}
              </Subtitle>
              <Subtitle>
                {examMode ? (
                  <>
                    • Om du väljer att avbryta provpasset sparas inte dina svar,
                    men provet kan göras om senare.
                  </>
                ) : (
                  <>• När du gör frågor utan tid, räknas de inte med.</>
                )}
              </Subtitle>
              <Subtitle>
                <>• Du kan dölja tiden genom att trycka på tidsikonen.</>
              </Subtitle>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default HelpPopup;
