/** Libraries */
import { Dialog, Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

/** Utils */
import { StyledCloseIconRight } from "../../atom/CustomIcons/StyledCloseIcon";
import { Transition } from "../../atom/SlideTransition/SlideTransition";
import { useSubsPopups } from "../../../hooks";
import useWindowDimensions from "../WindowDimensions/dimension";
import { EndPoints, instance2 } from "../../service/Route";
import { SendSentryError } from "../../../tools";
import Prices from "../../../assets/Static/Prices";
import { useSelector } from "react-redux";

/** Material UI custom elements */
const ModalContainer = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "401px",
  // width: "90%",
  backgroundColor: theme.palette.appcolors.secondaryColor4,
  borderRadius: "10px",
  padding: "1rem",
  gap: "0.5rem",
}));

const CloseButton = styled("button")(({ theme }) => ({
  width: "100%",
  height: "20px",
  background: "none",
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginTop: "-5px",
  marginRight: "-15px",
  "& svg": {
    fontSize: "26px",
    color: theme.palette.appcolors.grey14,
  },
}));

const Title = styled("h2")(({ theme }) => ({
  width: "100%",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  marginBottom: "0.5rem",
  textAlign: "left",
  paddingRight: "1.5rem",
  color: theme.palette.appcolors.black,
}));

const Content = styled("p")(({ theme }) => ({
  fontFamily: "Figtree",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "22.4px",
  marginBottom: "1rem",
  textAlign: "left",
  color: theme.palette.appcolors.black,
}));

const ConfirmButton = styled("button")(({ theme }) => ({
  color: "white",
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Poppins",
  cursor: "pointer",
  width: "50%",
  padding: "12px",
  backgroundColor: theme.palette.primary.main,
  border: "none",
  borderRadius: "12.29px",
  borderBottom: "3.68816px solid rgba(37, 37, 37, 0.3)",
  transition: "background-color 0.2s",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CheckoutPopup = ({ goPayment, isOpened, plan, sectionToBuy }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { isOpen, planId, handleCloseCheckoutPopup } = useSubsPopups();
  const { user } = useSelector((state) => state.user);

  const getPopupConfig = () => {
    switch (planId) {
      case "planOne":
        return {
          title: "Vill du avsluta ditt abonnemang?",
          content:
            "Ditt abonnemang avslutas omedelbart, men du behåller tillgången till tjänsten under den pågående betalningsperioden.",
        };
      case "planTwo":
        return {
          title: "Vill du ändra ditt abonnemang?",
          content: "Ditt abonnemang uppgraderas till Full Premium omedelbart",
        };
      case "planThree":
        return {
          title: "Vill du ändra ditt abonnemang?",
          content:
            "Du kommer att ha tillgång till Full Premium tills den pågående betalningsperioden är slut.",
        };
      default:
        return {
          title: "",
          content: "",
        };
    }
  };

  const { title, content } = getPopupConfig();

  const handleCancelSubscription = () => {
    console.log("Cancel Subscription");
    cancelSubscription();
  };

  const handleUpgradeFromPartialToFullSubscription = (planDetails) => {
    console.log("Upgrade from partial to full Subscription");
    goPayment(planDetails, sectionToBuy);
  };

  const handleDowngradeToPartialSubscription = (planDetails) => {
    console.log("Downgrade to partial Subscription");
    goPayment(planDetails, sectionToBuy);
  };

  const handleConfirm = () => {
    if (planId === "planOne") {
      handleCancelSubscription();
    } else if (planId === "planTwo") {
      handleUpgradeFromPartialToFullSubscription(Prices.planThree);
    } else {
      handleDowngradeToPartialSubscription(Prices.planTwo);
    }
    handleCloseCheckoutPopup();
  };

  const cancelSubscription = () => {
    const url = EndPoints.cancelSubscription;
    instance2
      .post(url)
      .then((res) => {
        if (res.data.message == "No Active subscriptions") {
          SendSentryError("CANCEL", res.data.message, {
            details: `User Detail: name${user?.fullName}.`,
          });
        } else if (res.data.message) {
          SendSentryError("CANCEL", "Subscription canceled Successfully!", {
            details: `Subscription canceled Successfully!! 
        User Detail: name${user?.fullName}.`,
          });
        } else {
          SendSentryError("CANCEL", "Subscription not canceled!", {
            details: `Subscription not canceled!! 
        User Detail: name${user?.fullName}.`,
          });
        }
        navigate("/home");
      })
      .catch((err) => {
        SendSentryError("CANCEL", "Subscription not canceled!", {
          details: `Subscription not canceled!! 
      User Detail: name${user?.fullName}.`,
        });
      });
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleCloseCheckoutPopup}
      PaperProps={{
        sx: {
          display: planId ? "block" : "none",
          position:
            width > 1300
              ? plan?.id === "planThree"
                ? "block"
                : "absolute"
              : "block",
          left:
            width > 1300 && plan?.id !== "planThree"
              ? plan?.id === "planOne"
                ? "22.5%"
                : "65%"
              : "auto",
          boxShadow: "none",
          backgroundImage: "none",
          backgroundColor: "transparent",
          margin: "auto",
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: theme.palette.appcolors.popupOverlay,
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <ModalContainer>
        <CloseButton onClick={handleCloseCheckoutPopup}>
          <CloseIcon />
        </CloseButton>
        <Title>{title}</Title>
        <Content>{content}</Content>
        <ConfirmButton onClick={handleConfirm}>Bekräfta</ConfirmButton>
      </ModalContainer>
    </Dialog>
  );
};

export default CheckoutPopup;
