/** Libraries */
import { styled } from "@mui/material/styles";

/** Hooks */
import { useMixpanel } from "../../../hooks";
import { useSubsPopups } from "../../../hooks/utils/useSubsPopups";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

const StyledPayButton = styled(Button)(({ theme, disabled }) => ({
  color: `${theme.palette.appcolors.secondaryColor10} !important`,
  backgroundColor: `${
    disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor
  } !important`,
  borderBottom: `3px solid ${
    disabled ? theme.palette.appcolors.grey3 : theme.palette.appcolors.black3
  } !important`,
  borderRadius: "16px !important",
  padding: "8px 16px !important",
  minWidth: "200px !important",
  transition: "all 0s ease-in-out",
  fontSize: "18px !important",
  fontWeight: "600 !important",
  textTransform: "none !important",
  "&:hover": {
    backgroundColor: disabled
      ? theme.palette.appcolors.grey3
      : theme.palette.appcolors.primaryColor,
    transform: "scale(1.05)",
    transition: "all 0.3s ease-in-out",
  },
  [theme.breakpoints.down(1280)]: {
    width: "90% !important",
  },
}));

const PayButton = ({
  goPayment,
  disablePaymentButton,
  planDetails,
  planId,
  sectionToBuy,
  setSelectedPlan,
}) => {
  const mixpanel = useMixpanel();
  const { handleOpenCheckoutPopup } = useSubsPopups();
  const { user } = useSelector((state) => state.user);
  const isPremium = user?.isPremium;

  const handleGoPayment = async () => {
    if (!disablePaymentButton) {
      const plan = await planDetails();
      setSelectedPlan(plan?.id);
      if (!isPremium) {
        goPayment(plan, sectionToBuy);
        mixpanel.buttonClicked("Upgrade");
        return;
      }
      handleOpenCheckoutPopup(planId);
    }
  };

  return (
    <StyledPayButton disabled={disablePaymentButton} onClick={handleGoPayment}>
      Välj plan
    </StyledPayButton>
  );
};

export default PayButton;
