/** Atoms */
import {
  InstructionsContainer,
  InstructionsTitle,
  SectionTitle,
  SectionSubtitle,
  Notes,
} from "../../atom/SharedInfoStyledComponents/SharedInfoStyledComponents";

const KvantitativProvpassInfo = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Kvantitativt provpass</InstructionsTitle>
      <SectionTitle>Förberedande information</SectionTitle>
      <SectionSubtitle mb={"1rem"}>
        Här följer anvisningar till de kvantitativa delproven XYZ, KVA, NOG och
        DTK.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Provpasset innehåller 40 uppgifter och den totala provtiden är 55
        minuter långt med normal provtid, alternativt 80 minuter med förlängd
        provtid.
      </SectionSubtitle>

      <SectionTitle>XYZ - Matematisk problemlösning</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>12 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 12 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        Delprovet XYZ handlar om matematisk problemlösning. Varje uppgift består
        av en fråga som följs av fyra svarsalternativ, varav endast ett är rätt.
      </SectionSubtitle>

      <SectionTitle>KVA - Kvantitativa jämförelser</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>10 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 10 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        Delprovet KVA innehåller uppgifter med beskrivningar av två kvantiteter,
        I och II. Din uppgift är att jämföra de två kvantiteterna. I vissa fall
        ges inledande information som ska användas vid jämförelsen.
      </SectionSubtitle>
      <SectionSubtitle mb={"1rem"}>
        Till varje uppgift finns fyra svarsalternativ, varav endast ett är rätt.
        I KVA har alla uppgifter samma svarsalternativ.
      </SectionSubtitle>

      <SectionTitle>NOG - Kvantitativa resonemang</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>6 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 10 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        Delprovet NOG består av uppgifter med en fråga följd av två påståenden,
        (1) och (2), som innehåller information. Frågan kan ibland föregås av
        viss inledande information.
      </SectionSubtitle>

      <SectionSubtitle mb={"1rem"}>
        Din uppgift är att avgöra om frågan entydigt kan besvaras med hjälp av
        informationen i påståendena, och i så fall hur mycket av denna
        information som är tillräcklig. Till varje uppgift finns fem
        svarsalternativ, varav endast ett är rätt. I NOG har alla uppgifter
        samma svarsalternativ.
      </SectionSubtitle>

      <SectionTitle>DTK - Diagram, tabeller och kartor</SectionTitle>
      <Notes variant="subtitle1">
        <em style={{ margin: "0" }}>12 uppgifter.</em>
        <em style={{ margin: "0rem 0rem 1rem 0rem" }}>
          Rekommenderad provtid: 23 minuter
        </em>
      </Notes>

      <SectionSubtitle mb={"1rem"}>
        Delprovet DTK innehåller diagram, tabeller, kartor och andra grafiska
        framställningar. Uppgifterna ska lösas med hjälp av den information som
        finns på respektive uppslag.
      </SectionSubtitle>

      <SectionSubtitle>
        Till varje uppgift finns det fyra svarsförslag. Välj det som bäst
        besvarar frågan.
      </SectionSubtitle>
    </InstructionsContainer>
  );
};

export default KvantitativProvpassInfo;
