/** Libraries */
import { useDispatch, useSelector } from "react-redux";

/** Custom hooks */
import { useUiStore } from "../ui/useUiStore";

/** Axios Instances */
import { EndPoints, instance2 } from "../../components/service/Route";

export const useStudentStore = () => {
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { startErrorPopup } = useUiStore();

  /** This function updates the goal points to be achived by the student */
  const updateStudentPoints = async (newPoints) => {
    const pointURL = EndPoints.testDate + "User/" + user._id;

    instance2.put(pointURL, { point: newPoints }).catch((error) => {
      startErrorPopup();
      if (process.env.NODE_ENV !== "test") {
        console.log(error);
      }
    });
  };

  return {
    /** Values */
    // exampleValue1,
    // exampleValue2,
    // exampleValue3,

    /** Functions */
    updateStudentPoints,
  };
};
